import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';

const styles = theme => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -15,
    marginLeft: -15
  }
});
const LoadableButton = ({
  classes,
  loading,
  disabled,
  success,
  children,
  ...other
}) => {
  const buttonClassname = classNames({
    [classes.buttonSuccess]: success
  });
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          disabled={loading || disabled}
          className={buttonClassname}
          {...other}>
          {children}
        </Button>
        {loading && (
          <CircularProgress size={30} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  );
};

LoadableButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool,
  children: PropTypes.node.isRequired
};
export default withStyles(styles)(LoadableButton);
