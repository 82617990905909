import React from "react";
import { useField, ErrorMessage } from "formik";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import { FemaleIcon, MaleIcon } from "../Icons";

export const GenderField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <>
      <FormControl component="fieldset" error={!!errorText}>
        <FormLabel component="legend">{label}</FormLabel>
        <ErrorMessage name="gender" component="span" style={{ color: "red" }} />
        <RadioGroup aria-label="gender" row {...field} {...props}>
          <FormControlLabel
            value="male"
            control={
              <Radio
                icon={<MaleIcon grayscale />}
                checkedIcon={<MaleIcon size={80} />}
              />
            }
            label="Dreng"
          />
          <FormControlLabel
            value="female"
            control={
              <Radio
                icon={<FemaleIcon grayscale />}
                checkedIcon={<FemaleIcon size={80} />}
              />
            }
            label="Pige"
          />
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default GenderField;
