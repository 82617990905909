import React, { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";

type Hook = (studentId: string) => Grade[];

const useStudentGrades: Hook = (studentId) => {
  const [grades, setGrades] = useState<Grade[]>([]);
  const firestore = useFirestore();
  useEffect(() => {
    async function loadGrades() {
      const grades = await firestore
        .collection("students")
        .doc(studentId)
        .collection("grades")
        .get();

      const merged: Grade[] = [];
      for (const doc of grades.docs) {
        if (!doc.exists) continue;
        const grade = doc.data() as Grade;
        grade.id = doc.id;

        const subject = await firestore
          .collection("subjects")
          .doc(grade.subjectId)
          .get();

        grade.subject = subject.data() as Subject;
        merged.push(grade);
      }
      setGrades(merged);
    }
    loadGrades();
  }, []);

  return grades;
};

export default useStudentGrades;
