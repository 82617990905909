import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dashboard from "@material-ui/icons/Dashboard";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import People from "@material-ui/icons/People";
import GroupAdd from "@material-ui/icons/GroupAdd";
import grey from "@material-ui/core/colors/grey";
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import CalendarToday from "@material-ui/icons/CalendarToday";
import EventIcon from "@material-ui/icons/Event";
import Assignment from "@material-ui/icons/Assignment";
import SchoolIcon from "@material-ui/icons/School";
import MenuBookIcon from "@material-ui/icons/MenuBook";
{
  /* import PeopleIcon from "@material-ui/icons/People"; */
}
import Work from "@material-ui/icons/Work";
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector, useDispatch } from "react-redux";
import { Divider, ListItemIcon, Grid } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";
import { TOGGLE_SEMESTER_DIALOG } from "../../constants";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  button: {
    height: 60, // setting height/width is optional
    color: grey[500],
  },
  activeButton: {
    color: theme.palette.primary.main,
  },
  label: {
    // Aligns the content of the button vertically.
    flexDirection: "column",
    fontSize: "0.7rem",
    fontWeight: "600",
  },
  icon: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.5rem !important",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.5rem !important",
    },
    marginBottom: theme.spacing(),
  },
  studentMenu: {
    maxHeight: 350,
    overflow: "hidden",
  },
}));

function StudentsMenu({ matches, current }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();

  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useFirestoreConnect(["semesters"]);
  const semesters = useSelector(
    (state) => state.firestore.ordered.semesters || []
  );

  return (
    <div>
      <Button
        variant="text"
        classes={{ root: classes.button, label: classes.label }}
        className={classNames({
          [classes.activeButton]: current,
        })}
        onClick={handleClick}
      >
        <People className={classes.icon} />
        {matches ? "Studerende" : ""}
      </Button>

      <Menu
        anchorEl={anchorEl}
        className={classes.studentMenu}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {semesters
          .slice()
          .sort()
          .reverse()

          .map((semester) => {
            return (
              <MenuItem
                key={semester.id}
                onClick={() => {
                  handleClose();
                  history.push(`/semesters/${semester.id}`);
                }}
              >
                <ListItemIcon>
                  <CalendarToday fontSize="small" />
                </ListItemIcon>
                {semester.title}
              </MenuItem>
            );
          })}
        <Divider />
        <MenuItem
          onClick={() => {
            handleClose();
            dispatch({ type: TOGGLE_SEMESTER_DIALOG, semesterOpen: true });
          }}
        >
          <ListItemIcon>
            <Add fontSize="small" />
          </ListItemIcon>
          Tilføj semester
        </MenuItem>
        <MenuItem onClick={() => history.push("/exams")}>
          <ListItemIcon>
            <Add fontSize="small" />
          </ListItemIcon>
          Tilføj Eksamensresultater
        </MenuItem>
      </Menu>
    </div>
  );
}

function ExamsMenu({ matches, current }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  return (
    <div>
      <Button
        variant="text"
        classes={{ root: classes.button, label: classes.label }}
        className={classNames({
          [classes.activeButton]: current,
        })}
        onClick={handleClick}
      >
        <SchoolIcon className={classes.icon} />
        {matches ? "Eksamensresultater" : ""}
      </Button>

      <Menu
        anchorEl={anchorEl}
        className={classes.studentMenu}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            history.push(`/exams`);
          }}
        >
          <ListItemIcon>
            <Assignment fontSize="small" />
          </ListItemIcon>
          Tildel
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            history.push(`/exams/edit`);
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          Rediger
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            history.push(`/exams/add`);
          }}
        >
          <ListItemIcon>
            <Add fontSize="small" />
          </ListItemIcon>
          Tilføj
        </MenuItem>
      </Menu>
    </div>
  );
}

export const NavButtons = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const current = (path) => {
    const topLevelPath = path.split("/");
    const current = location.pathname.split("/");
    return current[1] === topLevelPath[1];
  };
  const matches = useMediaQuery("(min-width:1280px)");

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Button
            variant="text"
            onClick={() => history.push("/")}
            classes={{ root: classes.button, label: classes.label }}
            className={classNames({
              [classes.activeButton]: current("/"),
            })}
          >
            <Dashboard className={classes.icon} />
            {matches ? "Dashboard" : ""}
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => history.push("/applicants")}
            className={classNames({
              [classes.activeButton]: current("/applicants"),
            })}
            variant="text"
            classes={{ root: classes.button, label: classes.label }}
          >
            <GroupAdd className={classes.icon} />
            {matches ? "Ansøgere" : ""}
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => history.push("/events")}
            className={classNames({
              [classes.activeButton]: current("/events"),
            })}
            variant="text"
            classes={{ root: classes.button, label: classes.label }}
          >
            <EventIcon className={classes.icon} />
            {matches ? "Infoaftener" : ""}
          </Button>
        </Grid>
        <Grid item>
          <StudentsMenu matches={matches} current={current("/semesters")} />
        </Grid>
        <Grid item>
          <Button
            variant="text"
            onClick={() => history.push("/examiners")}
            classes={{ root: classes.button, label: classes.label }}
            className={classNames({
              [classes.activeButton]: current("/examiners"),
            })}
          >
            <Assignment className={classes.icon} />
            {matches ? "Censorer" : ""}
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => history.push("/employees")}
            className={classNames({
              [classes.activeButton]: current("/employees"),
            })}
            variant="text"
            classes={{ root: classes.button, label: classes.label }}
          >
            <Work className={classes.icon} />
            {matches ? "Ansatte" : ""}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="text"
            onClick={() => history.push("/contacts")}
            classes={{ root: classes.button, label: classes.label }}
            className={classNames({
              [classes.activeButton]: current("/contacts"),
            })}
          >
            <PermContactCalendar className={classes.icon} />
            {matches ? "Kontakter" : ""}
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => history.push("/subjects")}
            className={classNames({
              [classes.activeButton]: current("/subjects"),
            })}
            variant="text"
            classes={{ root: classes.button, label: classes.label }}
          >
            <MenuBookIcon className={classes.icon} />
            {matches ? "Fag" : ""}
          </Button>
        </Grid>
        {/* <Grid item>
          <ExamsMenu matches={matches} current={current("/exams")} />
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
};

export default NavButtons;
