import { db } from "../helpers/firebaseHelper";
import {
  FETCHING_SEMESTERS,
  FETCH_SEMESTERS_SUCCESS,
  FETCH_SEMESTERS_ERROR,
  CREATING_NEW_SEMESTER,
  CREATE_NEW_SEMESTER_SUCCESS,
  CREATE_NEW_SEMESTER_ERROR,
  TOGGLE_SEMESTER_DIALOG,
} from "../constants";

export function toggleSemesterDialog(open) {
  return {
    type: TOGGLE_SEMESTER_DIALOG,
    semesterOpen: open || false,
  };
}

export function fetchingSemesters() {
  return {
    type: FETCHING_SEMESTERS,
  };
}

export function fetchSemestersSuccess(semesters) {
  return {
    type: FETCH_SEMESTERS_SUCCESS,
    semesters: semesters,
  };
}

export function fetchSemestersError(e) {
  return {
    type: FETCH_SEMESTERS_ERROR,
    error: e,
  };
}

export function creatingSemester() {
  return {
    type: CREATING_NEW_SEMESTER,
  };
}

export function createSemesterSuccess() {
  return {
    type: CREATE_NEW_SEMESTER_SUCCESS,
  };
}

export function createSemesterError(e) {
  return {
    type: CREATE_NEW_SEMESTER_ERROR,
    error: e,
  };
}

export function fetchSemesters() {
  return (dispatch) => {
    dispatch(fetchingSemesters());
    db.collection("semesters").onSnapshot(
      (docs) => {
        const semesters = [];
        docs.forEach((doc) => {
          semesters.push(doc.id);
        });
        dispatch(fetchSemestersSuccess(semesters));
      },
      (error) => {
        dispatch(fetchSemestersError(error));
      }
    );
  };
}

export function createSemester(semester) {
  return async (dispatch) => {
    dispatch(creatingSemester());
    try {
      await db.collection("semesters").doc(semester).set({ title: semester });
      dispatch(createSemesterSuccess());
    } catch (err) {
      dispatch(createSemesterError(err));
    }
  };
}
