import React from "react";
import { FormControlLabel, FormControl } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { FieldAttributes, useField } from "formik";

type CheckboxProps = {
  label: string;
  disabled?: boolean;
} & FieldAttributes<Record<string, unknown>>;

const CheckboxField: React.FC<CheckboxProps> = ({
  label,
  disabled,
  ...props
}) => {
  const [field, meta] = useField({ name: props.name, type: "checkbox" });
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <FormControl error={!!errorText} disabled={disabled}>
      <FormControlLabel control={<Checkbox {...field} />} label={label} />
    </FormControl>
  );
};

export default CheckboxField;
