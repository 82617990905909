import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { parseDate, parseGender } from "../../../helpers/parser";

const NavigateTableCell = withRouter(({ history, id }) => {
  const newPath = history.location.pathname + `/student/${id}`;
  return (
    <TableCell padding="none">
      <IconButton onClick={() => history.push(newPath)}>
        <EditIcon />
      </IconButton>
    </TableCell>
  );
});

const StudentsTableBody = ({
  data,
  page,
  rowsPerPage,
  handleClick,
  handleKeyDown,
  isSelectedFunc,
}) => (
  <TableBody>
    {data
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((s) => {
        const isSelected = isSelectedFunc(s.id);
        return (
          <TableRow
            hover
            onClick={(event) => handleClick(event, s.id)}
            onKeyDown={(event) => handleKeyDown(event, s.id)}
            role="checkbox"
            aria-checked={isSelected}
            tabIndex={-1}
            key={s.id}
            selected={isSelected}
          >
            <TableCell padding="checkbox">
              <Checkbox checked={isSelected} />
            </TableCell>
            <NavigateTableCell id={s.id} />
            <TableCell padding="none">{s.firstName}</TableCell>
            <TableCell padding="none">{s.lastName}</TableCell>
            <TableCell padding="none">{parseGender(s.gender)}</TableCell>
            <TableCell padding="none">
              {parseDate(s.contractReceivedTimestamp, "L")}
            </TableCell>
            <TableCell padding="none">{parseDate(s.createdAt, "L")}</TableCell>
            <TableCell padding="none">{parseDate(s.updatedAt, "L")}</TableCell>
          </TableRow>
        );
      })}
  </TableBody>
);

StudentsTableBody.propTypes = {
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
  isSelectedFunc: PropTypes.func.isRequired,
};

export default StudentsTableBody;
