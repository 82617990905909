export function formatApplicantMessage(name, interviewDate) {
  const dateDisplayString = formatDate(interviewDate);
  const firstName = name.split(' ')[0];
  return `Hej ${firstName}. Vi glæder os til at se dig til samtale hos os ${dateDisplayString}. Vi mødes i foyeren i DGI Byen på det aftale tidspunkt.`;
}

export function formatInterviewerMessage(
  interviewer,
  applicantName,
  interviewDate
) {
  const dateDisplayString = formatDate(interviewDate);
  return `${interviewer.toUpperCase()} har samtale med ${applicantName} ${dateDisplayString}. Mødested: Foyeren.`;
}

function formatDate(interviewDate) {
  const today = new Date().setHours(0, 0, 0, 0);
  const date = new Date(interviewDate);

  let dateDisplayString = '';
  if (date === today) {
    dateDisplayString = `idag kl. ${date.getHours()}:${date.getMinutes()}`;
  } else if (date > today) {
    dateDisplayString = `d. ${date.toLocaleDateString()} kl. ${date.getHours()}:${date.getMinutes()}`;
  }
  return dateDisplayString;
}
