import {
  SUBMITTING_SHEET,
  SUBMIT_SHEET_SUCCESS,
  SUBMIT_SHEET_ERROR,
  CREATING_TRAVEL_INFO_SHEET,
  CREATE_TRAVEL_INFO_SHEET_SUCCESS,
  CREATE_TRAVEL_INFO_SHEET_ERROR,
  CREATING_CONTACTS_SHEET,
  CREATE_CONTACTS_SHEET_SUCCESS,
  CREATE_CONTACTS_SHEET_ERROR
} from "../constants";

const initialState = {
  isSubmitting: false,
  error: {},
  sheetURL: "",
  isCreatingTravelInfo: false,
  travelInfoError: {},
  isCreatingContactsSheet: false,
  contactSheetsError: {}
};

const sheets = (state = initialState, action) => {
  switch (action.type) {
    case SUBMITTING_SHEET:
      return { ...state, isSubmitting: true };
    case SUBMIT_SHEET_SUCCESS:
      return { ...state, isSubmitting: false, sheetURL: action.sheetURL };
    case SUBMIT_SHEET_ERROR:
      return { ...state, isSubmitting: false, error: action.error };
    case CREATING_TRAVEL_INFO_SHEET:
      return { ...state, isCreatingTravelInfo: true };
    case CREATE_TRAVEL_INFO_SHEET_SUCCESS:
      return { ...state, isCreatingTravelInfo: false };
    case CREATE_TRAVEL_INFO_SHEET_ERROR:
      return {
        ...state,
        isCreatingTravelInfo: false,
        travelInfoError: action.error
      };
    case CREATING_CONTACTS_SHEET:
      return { ...state, isCreatingContactsSheet: true };
    case CREATE_CONTACTS_SHEET_SUCCESS:
      return { ...state, isCreatingContactsSheet: false };
    case CREATE_CONTACTS_SHEET_ERROR:
      return {
        ...state,
        isCreatingContactsSheet: false,
        contactSheetsError: action.error
      };
    default:
      return state;
  }
};

export default sheets;
