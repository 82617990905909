import {
  FETCHING_STUDENTS,
  FETCH_STUDENTS_SUCCESS,
  FETCH_STUDENTS_ERROR,
  FETCHING_STUDENT,
  FETCH_STUDENT_SUCCESS,
  FETCH_STUDENT_ERROR,
  SAVING_STUDENT,
  SAVE_STUDENT_SUCCESS,
  SAVE_STUDENT_ERROR,
  FETCHING_SAFETY_INFORMATION,
  FETCH_SAFETY_INFORMATION_SUCCESS,
  FETCH_SAFETY_INFORMATION_ERROR,
  SAVING_SAFETY_INFORMATION,
  SAVE_SAFETY_INFORMATION_SUCCESS,
  SAVE_SAFETY_INFORMATION_ERROR,
  DELETING_STUDENTS,
  DELETE_STUDENTS_SUCCESS,
  DELETE_STUDENTS_ERROR,
  FETCHING_CLASSES,
  FETCH_CLASSES_SUCCESS,
  FETCH_CLASSES_ERROR,
  CREATE_ACCOUNT_FOR_STUDENT_ERROR,
  CREATE_ACCOUNT_FOR_STUDENT_SUCCESS,
  CREATING_ACCOUNT_FOR_STUDENT,
  FETCHING_STUDENT_GRADES,
  FETCH_STUDENT_GRADES_SUCCESS,
  FETCH_STUDENT_GRADES_ERROR,
  FETCHING_STUDENT_PAGINATION,
  FETCH_STUDENT_PAGINATION_SUCCESS,
  FETCH_STUDENT_PAGINATION_ERROR,
  CREATING_STUDENT,
  CREATE_STUDENT_SUCCESS,
  CREATE_STUDENT_ERROR,
  SET_QUERY_FOR_STUDENTS,
  SET_TAB_VALUE_STUDENTS,
  TOGGLE_GRADING_DIALOG,
  SETTING_STUDENT_PROPERTY,
  SETTING_PROPERTY_SUCCESS,
  SETTING_PROPERTY_ERROR
} from "../constants";

const initialState = {
  students: [],
  student: {},
  safetyInformation: {},
  isFetchingSafetyInformation: false,
  isFetching: false,
  error: {},
  isSaving: false,
  success: false,
  isDeleting: false,
  didDeleteItems: false,
  isFetchingClasses: false,
  classNames: {},
  creatingAccount: false,
  createAccountSuccess: false,
  createAccountError: {},
  grades: {},
  isFetchingGrades: false,
  fetchGradesSuccess: false,
  fetchGradesError: {},
  isFetchingPagination: false,
  pagination: {},
  paginationError: {},
  isCreating: false,
  createError: {},
  tabValue: 0,
  query: {},
  gradingDialogOpen: false,
  isSettingProperty: false,
  setPropertyError: { message: "" },
  setPropertySuccess: false
};

const students = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_STUDENTS:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_STUDENTS_SUCCESS:
      return {
        ...state,
        students: action.students,
        isFetching: false
      };
    case FETCH_STUDENTS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case FETCHING_STUDENT:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_STUDENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        student: action.student
      };
    case FETCH_STUDENT_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case FETCHING_SAFETY_INFORMATION:
      return {
        ...state,
        isFetchingSafetyInformation: true
      };
    case FETCH_SAFETY_INFORMATION_SUCCESS:
      return {
        ...state,
        isFetchingSafetyInformation: false,
        safetyInformation: action.safetyInformation
      };
    case FETCH_SAFETY_INFORMATION_ERROR:
      return {
        ...state,
        isFetchingSafetyInformation: false,
        error: action.error
      };
    case SAVING_STUDENT:
      return {
        ...state,
        isSaving: true
      };
    case SAVE_STUDENT_SUCCESS:
      return {
        ...state,
        isSaving: false,
        success: true
      };
    case SAVE_STUDENT_ERROR:
      return {
        ...state,
        isSaving: false,
        success: false,
        error: action.error
      };
    case SAVING_SAFETY_INFORMATION:
      return {
        ...state,
        isSaving: true
      };
    case SAVE_SAFETY_INFORMATION_SUCCESS:
      return {
        ...state,
        success: true,
        isSaving: false
      };
    case SAVE_SAFETY_INFORMATION_ERROR:
      return {
        ...state,
        isSaving: false,
        success: false,
        error: action.error
      };
    case DELETING_STUDENTS:
      return {
        ...state,
        isDeleting: true
      };
    case DELETE_STUDENTS_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        didDeleteItems: true
      };
    case DELETE_STUDENTS_ERROR:
      return {
        ...state,
        isDeleting: false,
        didDeleteItems: false,
        error: action.error
      };
    case FETCHING_CLASSES:
      return {
        ...state,
        isFetchingClasses: true
      };
    case FETCH_CLASSES_SUCCESS:
      return {
        ...state,
        isFetchingClasses: false,
        classNames: action.classes
      };
    case FETCH_CLASSES_ERROR:
      return {
        ...state,
        isFetchingClasses: false,
        error: action.error
      };
    case CREATING_ACCOUNT_FOR_STUDENT:
      return { ...state, creatingAccount: true };
    case CREATE_ACCOUNT_FOR_STUDENT_ERROR:
      return {
        ...state,
        creatingAccount: false,
        createAccountError: action.error
      };
    case FETCHING_STUDENT_GRADES:
      return { ...state, isFetchingGrades: true };
    case FETCH_STUDENT_GRADES_SUCCESS:
      return {
        ...state,
        isFetchingGrades: false,
        fetchGradesSuccess: true,
        grades: action.grades
      };
    case FETCH_STUDENT_GRADES_ERROR:
      return {
        ...state,
        isFetchingGrades: false,
        fetchGradesError: action.error,
        fetchGradesSuccess: false
      };
    case CREATE_ACCOUNT_FOR_STUDENT_SUCCESS:
      return { ...state, creatingAccount: false, createAccountSuccess: true };
    case FETCHING_STUDENT_PAGINATION:
      return { ...state, isFetchingPagination: true };
    case FETCH_STUDENT_PAGINATION_SUCCESS:
      return {
        ...state,
        isFetchingPagination: false,
        pagination: action.pagination
      };
    case FETCH_STUDENT_PAGINATION_ERROR:
      return {
        ...state,
        isFetchingPagination: false,
        paginationError: action.error
      };
    case CREATING_STUDENT:
      return { ...state, isCreating: true };
    case CREATE_STUDENT_SUCCESS:
      return { ...state, isCreating: false };
    case CREATE_STUDENT_ERROR:
      return { ...state, isCreating: false, error: action.error };
    case SET_QUERY_FOR_STUDENTS:
      return { ...state, query: action.query };
    case SET_TAB_VALUE_STUDENTS:
      return { ...state, tabValue: action.value };
    case TOGGLE_GRADING_DIALOG:
      return { ...state, gradingDialogOpen: action.gradingDialogOpen };
    case SETTING_STUDENT_PROPERTY:
      return { ...state, isSettingProperty: true };
    case SETTING_PROPERTY_SUCCESS:
      return {
        ...state,
        isSettingProperty: false,
        setPropertySuccess: true,
        gradingDialogOpen: false
      };
    case SETTING_PROPERTY_ERROR:
      return {
        ...state,
        isSettingProperty: false,
        setPropertySuccess: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default students;
