import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Field, Formik } from "formik";
import { TextField } from "formik-material-ui";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const styles = (theme) => ({
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  textFieldPolicy: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 150,
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonDiv: { display: "flex", alignItems: "center" },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -15,
    marginLeft: -15,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
});

class SafetyInsuranceForm extends Component {
  render() {
    const { classes, safetyInformation, saveSafetyInfo } = this.props;

    return (
      <Formik
        initialValues={safetyInformation}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting }) => {
          await saveSafetyInfo(values);
          setSubmitting(false);
        }}
        render={({ isSubmitting, resetForm, touched, handleSubmit }) => (
          <React.Fragment>
            {isSubmitting && <LinearProgress />}
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography type="subheading">
                    Forsikringsoplysninger og Sikkerhed
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="accident"
                    className={classes.textField}
                    component={TextField}
                    placeholder={"Ulykkesforsikring firma"}
                    label="Ulykkesforsikring firma"
                    type="text"
                  />
                  <Field
                    name="accidentPolicyNumber"
                    className={classes.textFieldPolicy}
                    component={TextField}
                    placeholder={"Policenummer"}
                    label="Policenummer"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="travel"
                    className={classes.textField}
                    component={TextField}
                    placeholder={"Rejseforsikring firma"}
                    label="Rejseforsikring firma"
                    type="text"
                  />
                  <Field
                    name="travelPolicyNumber"
                    className={classes.textFieldPolicy}
                    component={TextField}
                    placeholder={"Policenummer"}
                    label="Policenummer"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="home"
                    className={classes.textField}
                    component={TextField}
                    placeholder={"Indboforsikring firma"}
                    label="Indboforsikring firma"
                    type="text"
                  />
                  <Field
                    name="homePolicyNumber"
                    className={classes.textFieldPolicy}
                    component={TextField}
                    placeholder={"Policenummer"}
                    label="Policenummer"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 24, marginBottom: 24 }}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography type="subheading">
                    Kontaktoplysninger på pårørende
                  </Typography>
                  <Field
                    name="emergencyContactName"
                    className={classes.textField}
                    component={TextField}
                    placeholder={"Kontaktperson"}
                    label="Kontaktperson"
                    type="text"
                  />
                  <Field
                    name="emergencyContactRelation"
                    className={classes.textField}
                    component={TextField}
                    placeholder={"F.eks. Partner, Forældre etc."}
                    label="Kontaktpersonens relation"
                    type="text"
                  />
                  <Field
                    name="emergencyContactNumber"
                    className={classes.textFieldPolicy}
                    component={TextField}
                    placeholder={"Telefon nummer"}
                    label="Telefon"
                    type="tel"
                  />
                  <Field
                    name="emergencyContactEmail"
                    className={classes.textFieldPolicy}
                    component={TextField}
                    placeholder={"Email"}
                    label="Email"
                    type="email"
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 24, marginBottom: 24 }}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography type="subheading">Pas oplysninger</Typography>
                  <FormControlLabel
                    className={classes.textField}
                    control={
                      <Field
                        name="passportExpiresInSemester"
                        component={Checkbox}
                      />
                    }
                    label="Pas udløber i studieår"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="passportNumber"
                    className={classes.textFieldPolicy}
                    component={TextField}
                    placeholder={"123456789"}
                    label="Pasnummer"
                    type="text"
                  />
                  <Field
                    name="passportExpiry"
                    className={classes.textFieldPolicy}
                    component={TextField}
                    label="Udløbsdato"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <Field
                    name="passportName"
                    className={classes.textFieldPolicy}
                    component={TextField}
                    label="Navn i pas"
                    type="text"
                  />
                  <Field
                    name="passportNationality"
                    className={classes.textFieldPolicy}
                    component={TextField}
                    label="Nationalitet"
                    type="text"
                  />
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.buttonDiv}>
                    <div className={classes.wrapper}>
                      <Button
                        raised="true"
                        color="primary"
                        type="submit"
                        variant="contained"
                        className={classes.button}
                        disabled={isSubmitting}
                      >
                        Opdater
                      </Button>

                      {isSubmitting && (
                        <CircularProgress
                          size={30}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                    <Button
                      raised="true"
                      className={classes.button}
                      disabled={isSubmitting}
                      onClick={resetForm}
                    >
                      Fortryd
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </React.Fragment>
        )}
      />
    );
  }
}

export default withStyles(styles)(SafetyInsuranceForm);
