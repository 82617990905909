import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/database";
import "firebase/performance";

const config = {
  apiKey: "AIzaSyAZ-aRwH7h2O4ygs9q6NjE1N9flp-OVEXQ",
  authDomain: "ppkbh.firebaseapp.com",
  databaseURL: "https://ppkbh.firebaseio.com",
  projectId: "project-3438832429421041838",
  storageBucket: "project-3438832429421041838.appspot.com",
  messagingSenderId: "811450280011",
  appId: "1:811450280011:web:d683147ddb93aa1eee05ce",
};

firebase.initializeApp(config);
// Needed for redux-firestore
firebase.firestore();
if (process.env.NODE_ENV === "production") {
  firebase
    .firestore()
    .enablePersistence()
    .catch((err) => {
      if (err.code === "failed-precondition") {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
        console.log("Multiple tabs are open, persistence not enabled");
      } else if (err.code === "unimplemented") {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
        console.log("Browser unsupported for persistence");
      }
    });
}

export { firebase, config };
const firestore = firebase.firestore;

const firebaseAuth = firebase.auth;
const storage = firebase.storage();
const FieldValue = firebase.firestore.FieldValue;
const db = firebase.firestore();
const functions = firebase.functions();
// functions.useFunctionsEmulator("http://localhost:5001"); // for development
const perf = firebase.performance();

// Check to see if we are running on localhost and assign emulators accordingly
// if (location.hostname === "localhost") {
//   firebaseAuth().useEmulator("http://localhost:9099");
//   db.useEmulator("localhost", 8080);
//   storage.useEmulator("localhost", 9199);
//   functions.useEmulator("localhost", 5001);
// }

export { firestore, firebaseAuth, storage, FieldValue, db, functions, perf };
