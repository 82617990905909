import {
  FETCHING_SEMESTERS,
  FETCH_SEMESTERS_SUCCESS,
  FETCH_SEMESTERS_ERROR,
  CREATING_NEW_SEMESTER,
  CREATE_NEW_SEMESTER_SUCCESS,
  CREATE_NEW_SEMESTER_ERROR,
  TOGGLE_SEMESTER_DIALOG,
} from "../constants";

export const initialState = {
  semesters: [],
  isFetching: false,
  isCreating: false,
  didCreateSemester: false,
  semesterOpen: false,
  error: {},
};

const semesters = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_SEMESTERS:
      return {
        ...state,
        isFetching: true,
        //We reset the 'didCreateSemester' prop here, otherwise we cant reopen the NewSemesterDialog
        //after a successful creation
        didCreateSemester: false,
      };
    case FETCH_SEMESTERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        semesters: action.semesters,
      };
    case FETCH_SEMESTERS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case CREATING_NEW_SEMESTER:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_NEW_SEMESTER_SUCCESS:
      return {
        ...state,
        isCreating: false,
        didCreateSemester: true,
      };
    case CREATE_NEW_SEMESTER_ERROR:
      return {
        ...state,
        isCreating: false,
        error: action.error,
      };
    case TOGGLE_SEMESTER_DIALOG:
      return {
        ...state,
        semesterOpen: action.semesterOpen,
      };
    default:
      return state;
  }
};

export default semesters;
