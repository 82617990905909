import React, { Component } from "react";
import { ContactsForm } from "../../components";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "recompose";
import { createPrivateContact } from "../../actions/contacts";
import Contact from "../../Classes/Contact";

const styles = theme => ({
  root: { marginTop: 24 },
  paper: {
    padding: 16
  }
});

class NewContactContainer extends Component {
  handleSubmit = values => {
    this.props.createPrivateContact(values);
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid container justify="center" className={classes.root}>
        <Grid item xs={12} lg={8}>
          <Paper className={classes.paper}>
            <ContactsForm
              /* Set the default values here when creating a new contact */
              initialValues={new Contact()}
              isSaving={false}
              onSubmit={this.handleSubmit}
              savePrivateContact={this.handleSubmit}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({ contacts }) => {
  const { isCreating } = contacts;
  return {
    isCreating
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createPrivateContact: contact => dispatch(createPrivateContact(contact))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(NewContactContainer);
