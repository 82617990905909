import {
  AUTH_SUCCESS,
  AUTH_FAILURE,
  AUTHENICATING,
  UN_AUTH_SUCCESS,
  VERIFYING_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR
} from '../constants';

export const initialState = {
  user: {},
  isAuthed: false,
  isAuthenticating: false,
  isVerifying: false,
  verifySuccess: false,
  error: ''
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENICATING:
      return {
        ...state,
        isAuthenticating: true
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        isAuthed: true,
        user: action.user,
        isAuthenticating: false
      };
    case AUTH_FAILURE:
      return {
        ...state,
        isAuthed: false,
        error: action.error,
        isAuthenticating: false
      };
    case UN_AUTH_SUCCESS:
      return { ...state, isAuthed: false, user: {} };

    case VERIFYING_EMAIL:
      return { ...state, isVerifying: false };
    case VERIFY_EMAIL_SUCCESS:
      return { ...state, isVerifying: false, verifySuccess: true };
    case VERIFY_EMAIL_ERROR:
      return { ...state, isVerifying: false, verifySuccess: false };
    default:
      return state;
  }
};

export default auth;
