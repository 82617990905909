import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: 10,
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  paper: {
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 700,
  },
}));

type Props = {
  users: {
    providerData: any[];
    uid: string;
    displayName: string;
    email: string;
    emailVerified: boolean;
    avatarUrl: string;
  }[];
};

const UsersComponent: React.FC<Props> = ({ users }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={10}>
          <Paper className={classes.paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Billede</TableCell>
                  <TableCell>Navn</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Email bekræftet</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.length !== 0 ? (
                  users.map((user) => {
                    const uid = user.uid;
                    const providerData = user.providerData;
                    const providerDisplayName = providerData
                      ? providerData[0].displayName
                      : "";
                    const providerEmail = providerData
                      ? providerData[0].email
                      : "";
                    return (
                      <TableRow
                        key={uid}
                        hover
                        onClick={() => history.push(`/users/${uid}`)}
                      >
                        <TableCell>
                          <Avatar
                            alt={user.displayName}
                            src={user.avatarUrl}
                            className={classes.avatar}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {user.displayName
                            ? user.displayName
                            : providerDisplayName}
                        </TableCell>
                        <TableCell>
                          {user.email ? user.email : providerEmail}
                        </TableCell>
                        <TableCell>
                          {user.emailVerified ? "✅" : "🛑"}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <CircularProgress />
                )}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default UsersComponent;
