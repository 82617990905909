import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import DeleteIcon from "@material-ui/icons/Delete";
import NewIcon from "@material-ui/icons/Add";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { Divider } from "@material-ui/core";
import EmployeeIcon from "../../components/Icons/Employee";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 30,
  },
  cardRoot: {
    width: "100%",

    borderRadius: 10,
    boxShadow: "rgba(0,0,0,0.05) 1px 1px 10px 10px",
  },
  cardHeader: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "10 10 0 0",
    position: "relative",
  },
  cardContent: {
    padding: 10,
  },

  dischargeSelect: {
    padding: theme.spacing(2),
    minWidth: 300,
  },
  typography: { fontWeight: 600 },
  select: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

type HeaderProps = {
  selection: Record<string, unknown>[];
  newEmployee: () => void;
  deleteEmployees: () => void;
  createSpreadsheet: () => void;
};

const Header: React.FC<HeaderProps> = ({
  selection,
  newEmployee,
  deleteEmployees,
  createSpreadsheet,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.cardRoot}>
            <CardHeader className={classes.cardHeader} title={"Ansatte"} />

            <CardContent className={classes.cardContent}>
              <Grid
                item
                xs={12}
                container
                spacing={6}
                direction="row"
                alignItems="center"
              >
                <Grid item xs={12} md={2}>
                  <div style={{ textAlign: "center" }}>
                    <EmployeeIcon />
                  </div>
                </Grid>

                <Divider variant="middle" orientation="vertical" flexItem />
                <Grid item xs={12} md={3}>
                  <ButtonGroup
                    size="medium"
                    color="primary"
                    orientation="vertical"
                  >
                    <Button
                      className={classes.button}
                      variant="contained"
                      disableElevation
                      endIcon={<NewIcon />}
                      onClick={() => newEmployee()}
                    >
                      Ny Ansat
                    </Button>
                    <Button
                      className={classes.button}
                      endIcon={<ListAltIcon />}
                      onClick={() => createSpreadsheet()}
                      disabled={selection.length < 1}
                    >
                      Excel udtræk
                    </Button>
                    <Button
                      className={classes.button}
                      endIcon={<DeleteIcon />}
                      onClick={() => deleteEmployees()}
                      disabled={selection.length < 1}
                      color="secondary"
                    >
                      Slet
                    </Button>
                  </ButtonGroup>
                </Grid>
                <Divider variant="middle" orientation="vertical" flexItem />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Header;
