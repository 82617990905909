import React from "react";
import { useField, FieldAttributes } from "formik";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Radio,
  RadioGroup,
  FormHelperText,
} from "@material-ui/core";

type RadioOptions = { value: string; label: string };
type RadioProps = {
  label: string;
  options: RadioOptions[];
  row: boolean;
} & FieldAttributes<Record<string, unknown>>;

const RadioField: React.FC<RadioProps> = ({
  label,
  options,
  row,
  ...props
}) => {
  const [field, meta] = useField(props);

  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <FormControl component="fieldset" error={!!errorText}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup {...field}>
        <FormGroup row={row}>
          {options.map((option) => (
            <FormControlLabel
              key={`${option.value}`}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </FormGroup>
      </RadioGroup>
      <FormHelperText>{!!errorText && errorText}</FormHelperText>
    </FormControl>
  );
};

export default RadioField;
