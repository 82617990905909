const subjects = {
  anatomi: "Anatomi",
  bold: "Bold",
  "event-projektstying": "Event & Projektstyring",
  fmp: "Functional movement & Parkour",
  fysiologi: "Fysiologi",
  foerstehjaelp: "Førstehjælp",
  Idraetskultur: "Idrætskultur",
  "kommunikation-undervisning": "Kommunikation & Undervisning",
  livredning: "Livredning",
  loeb: "Løb",
  mountainbike: "Mountainbike - Sports",
  "personlig-traener": "Personlig træner",
  rbd: "Rytmisk bevægelse & Dans",
  spring: "Spring",
  svoemmeaktiviteter: "Svømmeaktiviteter",
  "udvikling-samarbejde": "Udvikling & Samarbejde",
  klatring: "Klatring",
  "loeb-trail-ocr": "Løb, Trail & OCR",
  "functional-movement-parkour": "Outdoor Functional Movement & Parkour",
  surf: "Surf",
  "svoemning-havsvoemning": "Svømning / Havsvømning",
  yoga: "Yoga",
  havkajak: "Havkajak",
  kano: "Kano",
  naturlaere: "Naturlære",
  "trekking-basic-bushcraft": "Trekking, Basic & Bushcraft",
  vinterfjeld: "Vinterfjeld",
  vaerkstedsfag: "Værkstedsfag"
};

export { subjects };
