import React, { useEffect } from "react";
import styled from "styled-components";
import {
  useTable,
  useSortBy,
  useFilters,
  useRowSelect,
  usePagination,
  useExpanded,
  FilterProps,
  Column,
  HeaderProps,
  Row as TableRow,
  CellProps,
  Hooks,
  HeaderGroup,
} from "react-table";
import { matchSorter } from "match-sorter";
import { FieldValue } from "../../helpers/firebaseHelper";
import {
  useFirestoreConnect,
  useFirestore,
  WhereOptions,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { parseDate, parseLine, convertToPlainDate } from "../../helpers/parser";
import MomentUtils from "@date-io/moment";
import * as _ from "lodash";
import { Link } from "react-router-dom";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  DateTimePicker,
} from "@material-ui/pickers";
import {
  Typography,
  Chip,
  Checkbox,
  TextField,
  Tooltip,
  ButtonGroup,
} from "@material-ui/core";
import colors from "../../helpers/colors";
import Today from "@material-ui/icons/Today";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Box from "../../BaseComponents/Box";
import DeleteIcon from "@material-ui/icons/Delete";
import NoteIcon from "@material-ui/icons/Note";
import { interviewers } from "../../helpers/interviewers";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import NavigateNext from "@material-ui/icons/NavigateNext";
import SkipPrevious from "@material-ui/icons/SkipPrevious";
import SkipNext from "@material-ui/icons/SkipNext";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { IApplicant } from "../Applicant";
import { AppState } from "../../reducers";
import { Query } from "../../helpers/applicantQueries";
import Check from "@material-ui/icons/Check";

type Data = IApplicant;

type Props = {
  columns: Column<Data>[];
  data: Data[];
  setSelection: (selection: Selections) => void;
};

type LinkProps = { linkcolor: string };
const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props: LinkProps) => props.linkcolor};
`;
const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify: center;
  align-items: center;
`;

type StyleProps = { tableColor: string };
const Styles = styled.div`
  table {
    border-spacing: 0;
    width: 100%;
    background-color: ${(props: StyleProps) => props.tableColor};
    box-shadow: rgba(0, 0, 0, 0.05) 1px 1px 10px 10px;
    border-radius: 10px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;

      :last-child {
        border-right: 0;
      }
    }
    tbody {
      text-align: center;
    }
  }
`;

const Row = styled.tr`
  border-bottom: solid black 0.5px;
  background-color: ${(props) => props.color};
`;

// Define a default UI for filtering
const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}: FilterProps<Data>) => {
  const count = preFilteredRows.length;

  return (
    <TextField
      value={filterValue || ""}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Søg ${count} ansøgere...`}
    />
  );
};

// This is a custom filter UI for selecting
// a unique option from a list
const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}: FilterProps<Data>) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options: Set<string> = new Set();
    preFilteredRows.forEach((row: any) => {
      // Check we are not adding an 'undefined' value
      if (row.values[id]) {
        options.add(row.values[id]);
      }
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Alle</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

function fuzzyTextFilterFn(
  rows: Array<TableRow<Data>>,
  id: string,
  filterValue: string
) {
  return matchSorter(rows, filterValue, {
    keys: [(row: TableRow<Data>) => row.values[id]],
  });
}

const SubRowComponent = React.memo(({ row }: { row: TableRow<Data> }) => {
  const [text, setText] = React.useState(row.original.note || "");
  const firestore = useFirestore();
  const handleUpdate = React.useCallback(async () => {
    const {
      original: { id },
    } = row;
    await firestore.collection("applicants").doc(id).update({ note: text });
  }, [firestore, text, row]);

  return (
    <span>
      <TextField
        value={text}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setText(e.target.value)
        }
        fullWidth
      />
      <Button color="primary" onClick={() => handleUpdate()}>
        Opdater
      </Button>
    </span>
  );
});

SubRowComponent.displayName = "SubRowComponent";

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: any) => !val;

// A simple way to support a renderRowSubComponent is to make a render prop
// This is NOT part of the React Table API, it's merely a rendering
// option we are creating for ourselves in our table renderer
const Table: React.FC<Props> = ({
  columns: userColumns,
  data,
  setSelection,
}) => {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
    }),
    []
  );

  const theme = useTheme();
  const defaultColumn = React.useMemo(
    () => ({
      // Set up default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    selectedFlatRows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    state: { pageIndex, pageSize },
  } = useTable<Data>(
    {
      columns: userColumns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageSize: 20, pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks: Hooks<Data>) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<Data>) => (
            <div>
              <Checkbox
                color="primary"
                indeterminate
                {...getToggleAllRowsSelectedProps()}
              />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }: CellProps<Data>) => (
            <div>
              <Checkbox
                color="primary"
                indeterminate
                {...row.getToggleRowSelectedProps()}
              />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    setSelection(selectedFlatRows.map((d: TableRow<Data>) => d.original));
  }, [selectedFlatRows, setSelection]);

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: HeaderGroup<Data>) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <span {...column.getSortByToggleProps()}>
                    {column.render("Header")}

                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={row.original.id}>
                <Row
                  color={
                    i % 2 === 0
                      ? theme.palette.background.paper
                      : theme.palette.background.default
                  }
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </Row>
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {/*
                        Inside it, call our renderRowSubComponent function. In reality,
                        you could pass whatever you want as props to
                        a component like this, including the entire
                        table instance. But for this example, we'll just
                        pass the row
                      */}
                      <SubRowComponent row={row} />
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <PaginationContainer className="pagination">
        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <SkipPrevious />
        </Button>
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          <NavigateBefore />
        </Button>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          <NavigateNext />
        </Button>
        <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          <SkipNext />
        </Button>
        Side{" "}
        <strong>
          {pageIndex + 1} af {pageOptions.length}
        </strong>{" "}
        <span style={{ marginRight: 10 }}> | Gå til side: </span>{" "}
        <TextField
          type="number"
          defaultValue={pageIndex + 1}
          style={{ marginRight: 16, width: "100px" }}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
        />
        <Select
          value={pageSize}
          style={{ marginLeft: 16 }}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[20, 30, 40, 50].map((pageSize) => (
            <MenuItem key={pageSize} value={pageSize}>
              Vis {pageSize}
            </MenuItem>
          ))}
        </Select>
      </PaginationContainer>
    </>
  );
};

type NameLinkableCellProps = { value: string; row: TableRow<Data> };
const NameLinkableCell: React.FC<NameLinkableCellProps> = ({
  value: initialValue,
  row: {
    original: { id },
  },
}) => {
  const theme = useTheme();
  return (
    <Typography variant="body1" style={{ textAlign: "left" }}>
      <StyledLink
        linkcolor={theme.palette.primary.main}
        to={`/applicants/${id}`}
      >
        {_.startCase(initialValue)}
      </StyledLink>
    </Typography>
  );
};

type NoteProps = { value: string | null };
const NoteCell: React.FC<NoteProps> = ({ value: initialValue }) => {
  return (
    <Tooltip title={initialValue || ""}>
      <IconButton aria-label="note" disabled={!initialValue}>
        <NoteIcon />
      </IconButton>
    </Tooltip>
  );
};

type TypographyProps = { value: string };
const TypographyCell: React.FC<TypographyProps> = ({ value: initialValue }) => {
  return <Typography variant="body1">{initialValue}</Typography>;
};

type DateDisplayableProps = { value: Date };
const DateDisplayable: React.FC<DateDisplayableProps> = ({
  value: initialValue,
}) => {
  return <Typography variant="body1">{parseDate(initialValue)}</Typography>;
};

type CustomDatePickerToolbarProps = {
  onDelete: () => void;
  onAccept?: () => void;
};
const CustomDatePickerToolbar: React.FC<CustomDatePickerToolbarProps> = ({
  onDelete,
  onAccept,
}) => {
  return (
    <ButtonGroup color="primary" variant="text">
      <Button onClick={() => onDelete()} style={{ width: "50%" }}>
        <DeleteIcon />
      </Button>
      <Button
        onClick={() => (onAccept ? onAccept() : null)}
        style={{ width: "50%" }}
      >
        <Check />
      </Button>
    </ButtonGroup>
  );
};

type InterviewerSelectCellProps = {
  value: string;
  row: TableRow<Data>;
  column: Column<Data>;
};
const InterviewerSelectCell: React.FC<InterviewerSelectCellProps> = ({
  value: initialValue,
  row: {
    original: { id },
  },
  column: { id: columnID },
}) => {
  const firestore = useFirestore();
  const ref = firestore.collection("applicants").doc(id);

  const setFirebaseValue = async (v: string) => {
    if (columnID) {
      await ref.update({ [columnID]: v });
    }
  };

  return (
    <select
      value={initialValue}
      onChange={(e) => {
        setFirebaseValue(e.target.value);
      }}
    >
      {interviewers.map((option, i) => (
        <option key={i} value={option}>
          {_.upperCase(option)}
        </option>
      ))}
    </select>
  );
};

type LineDisplayableProps = { value: string };
const LineDisplayable: React.FC<LineDisplayableProps> = ({
  value: initialValue,
}) => {
  let hex;
  switch (initialValue) {
    case "sport":
      hex = colors.sport.hex;
      break;
    case "outdoor":
      hex = colors.outdoor.hex;
      break;
    case "x-outdoor":
      hex = colors.xOutdoor.hex;
      break;
    default:
      hex = "#9e9e9e";
      break;
  }
  return (
    <Chip
      label={parseLine(initialValue) || "ingen linie"}
      style={{ backgroundColor: hex }}
    />
  );
};

type InterviewScheduledDateTimeCellProps = {
  value: Date | null;
  row: TableRow<Data>;
  column: Column<Data>;
};
const InterviewScheduledDateTimeCell: React.FC<InterviewScheduledDateTimeCellProps> =
  ({
    value: initialValue,
    row: {
      original: { id },
    },
    column: { id: columnID },
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);
    const [open, setOpen] = React.useState(false);
    const firestore = useFirestore();
    const ref = firestore.collection("applicants").doc(id);

    const onAccept = async (val: any) => {
      const update = val ? val : FieldValue.delete();
      try {
        await ref.update({
          [columnID || ""]: update,
          interviewScheduled: !!val,
        });
        setOpen(false);
        // Don't set value as it will trigger an update after the view has disappeared
      } catch (error) {
        console.log(error);
      }
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <Box mx="auto" style={{ width: "100%", textAlign: "center" }}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          {_.isDate(convertToPlainDate(value)) ? (
            <DateTimePicker
              value={convertToPlainDate(value)}
              variant="inline"
              format="DD MMM YYYY HH:mm"
              ampm={false}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              ToolbarComponent={() => (
                <CustomDatePickerToolbar
                  onDelete={() => onAccept(undefined)}
                  onAccept={() => onAccept(value)}
                />
              )}
              onChange={(val) => setValue(val ? val.toDate() : val)}
            />
          ) : (
            <IconButton onClick={() => setValue(new Date())}>
              <Today />
            </IconButton>
          )}
        </MuiPickersUtilsProvider>
      </Box>
    );
  };

type EditableDateCellProps = {
  value: string;
  row: TableRow<Data>;
  column: Column<Data>;
};
const EditableDateCell: React.FC<EditableDateCellProps> = ({
  value: initialValue,
  row: {
    original: { id },
  },
  column: { id: columnID },
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);
  const [open, setOpen] = React.useState(false);
  const firestore = useFirestore();
  const ref = firestore.collection("applicants").doc(id);

  const onChange = async (val: any) => {
    console.log(val);

    // Delete value if it's undefined
    const update = val ? val : FieldValue.delete();

    try {
      await ref.update({
        [columnID as string]: update,
      });
      setValue(val ? val : undefined);
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Box mx="auto" style={{ width: "100%", textAlign: "center" }}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {_.isDate(convertToPlainDate(value)) ? (
          <DatePicker
            value={convertToPlainDate(value)}
            variant="inline"
            format="DD MMM YYYY"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            ToolbarComponent={() => (
              <CustomDatePickerToolbar
                onDelete={() => onChange(undefined)}
                onAccept={() => onChange(value)}
              />
            )}
            onChange={(val) => onChange(val ? val.toDate() : val)}
          />
        ) : (
          <IconButton onClick={() => onChange(new Date())}>
            <Today />
          </IconButton>
        )}
      </MuiPickersUtilsProvider>
    </Box>
  );
};

const filterExactMatch = (
  rows: TableRow<Data>[],
  id: string,
  filterValue: string
) => {
  return rows.filter((row) => {
    const value = row.values[id];
    // The option for displaying all entries is an empty string
    if (value === "") {
      return true;
    } else {
      return value === filterValue;
    }
  });
};

const dateOrUndefinedSort = (
  rowA: TableRow<Data>,
  rowB: TableRow<Data>,
  id: string
) => {
  let a = rowA.values[id];
  let b = rowB.values[id];
  // equal items sort equally
  if (a === b) {
    return 0;
  }
  // undefined sort after anything else
  else if (a === undefined) {
    return 1;
  } else if (b === undefined) {
    return -1;
  }
  // highest sorts first
  else {
    a = a.toDate().getTime();
    b = b.toDate().getTime();
    return a < b ? 1 : -1;
  }
};

const getQuery = (query: Query, startYear: string): WhereOptions[] => {
  switch (query) {
    case Query.AwaitingCall:
      return [
        ["interviewScheduled", "==", false],
        ["contractSent", "==", false],
        ["isArchived", "==", false],
        ["isPurged", "==", false],
        ["startingYear", "array-contains", startYear],
      ];
    case Query.InterviewScheduled:
      return [
        ["interviewScheduled", "==", true],
        ["contractSent", "==", false],
        ["noShow", "==", false],
        ["isArchived", "==", false],
        ["isPurged", "==", false],
        ["startingYear", "array-contains", startYear],
      ];
    case Query.ContractSent:
      return [
        ["contractSent", "==", true],
        ["contractReceived", "==", false],
        ["isArchived", "==", false],
        ["isPurged", "==", false],
        ["startingYear", "array-contains", startYear],
      ];
    case Query.NoShow:
      return [
        ["interviewScheduled", "==", true],
        ["noShow", "==", true],
        ["isArchived", "==", false],
        ["isPurged", "==", false],
        ["startingYear", "array-contains", startYear],
      ];
    case Query.Archived:
      return [
        ["isArchived", "==", true],
        ["isPurged", "==", false],
        ["startingYear", "array-contains", startYear],
      ];
  }
};

type Selections = IApplicant[];
type TableProps = {
  query: Query;
  startYear: string;
  setSelection: (selections: Selections) => void;
};
const ApplicantsTable: React.FC<TableProps> = React.memo(
  ({ query, startYear, setSelection }) => {
    const theme = useTheme();
    const columns = React.useMemo(
      () => [
        {
          Header: "Info",
          columns: [
            {
              Header: "Navn",
              accessor: "name",
              filter: "fuzzyText",
              Cell: NameLinkableCell,
            },
            {
              Header: "Modtaget",
              accessor: "dateSent",
              sortType: (rowA: TableRow<Data>, rowB: TableRow<Data>) => {
                const dateA = rowA.values.dateSent;
                const dateB = rowB.values.dateSent;
                if (dateA < dateB) {
                  return 1;
                } else if (dateA > dateB) {
                  return -1;
                }
                return 0;
              },
              Cell: DateDisplayable,
            },
            {
              Header: "Linie",
              accessor: "line",
              Filter: SelectColumnFilter,
              filter: filterExactMatch,
              Cell: LineDisplayable,
            },
            {
              Header: "Tlf",
              accessor: "phone",
              filter: "fuzzyText",
              Cell: TypographyCell,
            },
          ],
        },
        {
          Header: "Opkald",
          columns: [
            {
              Header: "1.",
              accessor: "firstContactedTimestamp",
              sortType: dateOrUndefinedSort,
              disableFilters: true,
              Cell: EditableDateCell,
            },
            {
              Header: "2.",
              accessor: "secondContactedTimestamp",
              sortType: dateOrUndefinedSort,
              disableFilters: true,
              Cell: EditableDateCell,
            },
            {
              Header: "3.",
              accessor: "thirdContactedTimestamp",
              sortType: dateOrUndefinedSort,
              disableFilters: true,
              Cell: EditableDateCell,
            },
            {
              Header: "Samtale aftalt",
              accessor: "interviewDate",
              sortType: dateOrUndefinedSort,
              disableFilters: true,
              Cell: InterviewScheduledDateTimeCell,
            },
            {
              Header:
                query === Query.AwaitingCall ? "Ringes op af" : "Samtale med",
              Filter: SelectColumnFilter,
              filter: "equal",
              accessor: query === Query.AwaitingCall ? "caller" : "interviewer",
              Cell: InterviewerSelectCell,
            },
            {
              // Make an expander cell
              Header: () => null, // No header
              id: "expander", // It needs an ID
              disableFilters: true,
              Cell: ({ row }: CellProps<Data>) => (
                // Use Cell to render an expander for each row.
                // We can use the getToggleRowExpandedProps prop-getter
                // to build the expander.
                <span {...row.getToggleRowExpandedProps()}>
                  <NoteCell value={row.original.note} />
                </span>
              ),
            },
          ],
        },
      ],
      [query]
    );

    useFirestoreConnect([
      {
        collection: "applicants",
        where: getQuery(query, startYear),
      },
    ]);
    // Sort by line by default
    // Slice the array as other wise it may throw an error
    const data = useSelector(
      (state: AppState) => state.firestore.ordered.applicants || []
    )
      .slice()
      .sort((a, b) => ("" + a.line).localeCompare(b.line));

    const handleSetSelection = (selection: Selections) => {
      setSelection(selection);
    };

    return (
      <Styles tableColor={theme.palette.background.default}>
        <Table
          columns={columns}
          data={data}
          setSelection={(selection) => handleSetSelection(selection)}
        />
      </Styles>
    );
  }
);

export default ApplicantsTable;
