import { SNACKBAR_OPEN, SNACKBAR_CLOSE } from "../constants";

export const initialState = {
  message: "",
  open: false
};

const snackbar = (state = initialState, action) => {
  switch (action.type) {
    case SNACKBAR_OPEN:
      return {
        ...state,
        message: action.message,
        open: true
      };
    case SNACKBAR_CLOSE:
      return {
        ...state,
        message: "",
        open: false
      };
    default:
      return state;
  }
};

export default snackbar;
