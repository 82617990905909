import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import StudentsTable from "./Table/StudentsTable";
import Grid from "@material-ui/core/Grid";
import FilterList from "./FilterList";
import StudentsActionsList from "./StudentsActionsList";
import StudentsAppBar from "./StudentsAppBar";

const styles = (theme) => ({
  root: {
    paddingBottom: 16,
    flexGrow: 1,
    marginTop: theme.spacing(3),
  },
  appBar: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  sideBar: {
    marginTop: 20,
  },
  row: {
    display: "flex",
    justifyContent: "center",
  },
  card: {
    minWidth: 275,
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  pos: {
    marginBottom: 12,
    color: theme.palette.text.secondary,
  },
});

function TabContainer(props) {
  return <div style={{ padding: 20 }}>{props.children}</div>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class StudentsComponent extends Component {
  state = {
    checkedValues: {},
    selectedClass: "",
    line: "",
  };

  handleCheckboxChange = (name) => (event) => {
    let { checkedValues } = this.state;

    if (name === "gender") {
      // The gender attribute is a string
      checkedValues[name] = event;
    } else if (name === "class") {
      // The class attribute is a string
      checkedValues[name] = event;
    } else {
      checkedValues[name] = event.target.checked;
    }

    this.setState({ checkedValues }, () => this.performRefetch());
  };

  removeKey = (key) => {
    const { checkedValues } = this.state;
    if (!(key in checkedValues)) return;
    delete checkedValues[key];
    this.setState({ checkedValues }, () => this.performRefetch());
  };

  performRefetch = () => {
    const { checkedValues, selectedClass, line } = this.state;

    const queries = {
      ...checkedValues,
      line,
    };

    if (selectedClass !== "") {
      queries["class"] = selectedClass;
    }

    this.props.fetchStudents(queries);
  };

  handleLineChange = (line) => {
    this.setState({ line }, () => this.performRefetch());
  };

  handleClassSelect = (className) => {
    this.setState({ selectedClass: className }, () => this.performRefetch());
  };

  handleCreateNewStudent = () => {
    const {
      params: { semester },
    } = this.props.match;
    this.props.history.push(`/semesters/${semester}/student/new`);
  };

  render() {
    const {
      classes,
      isFetching,
      students,
      handleDeleteItems,
      handleSendMessages,
      handleCreateSpreadsheet,
      handleCreateTravelInfo,
      handleCreateCertificateRequest,
      handleSetGraduated,
      handleSetProp,
    } = this.props;
    //Find the currently selected tab, get the selected line then get the classes for that line
    return (
      <div>
        <Paper className={classes.root} elevation={4}>
          <Grid container>
            <Grid item xs={12} style={{ padding: "0 8px 8px 8px" }}>
              <div className={classes.appBar}>
                <StudentsAppBar handleLineChange={this.handleLineChange} />
                {isFetching && <LinearProgress />}
              </div>
            </Grid>
            <Grid item xs={3} className={classes.sideBar}>
              <StudentsActionsList
                handleCreateNewStudent={this.handleCreateNewStudent}
                handleGetClassImages={this.props.handleGetClassImages}
              />
              <FilterList
                handleChange={this.handleCheckboxChange}
                removeKey={this.removeKey}
              />
            </Grid>
            <Grid item xs={9}>
              <TabContainer>
                <StudentsTable
                  students={students}
                  handleDeleteItems={(items) => handleDeleteItems(items)}
                  handleSendMessages={handleSendMessages}
                  handleCreateSpreadsheet={(items) =>
                    handleCreateSpreadsheet(items)
                  }
                  handleCreateTravelInfo={(items) =>
                    handleCreateTravelInfo(items)
                  }
                  handleCreateCertificateRequest={(items) =>
                    handleCreateCertificateRequest(items)
                  }
                  handleSetGraduated={(date, items) =>
                    handleSetGraduated(date, items)
                  }
                  handleSetProp={(prop, items) => handleSetProp(prop, items)}
                />
              </TabContainer>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(StudentsComponent));
