export const drawerWidth = 240;
export const DEFAULT_PROFILE_IMG_URL =
  "https://firebasestorage.googleapis.com/v0/b/project-3438832429421041838.appspot.com/o/defaultProfile%2FdefaultProfile.png?alt=media&token=2f67597a-835b-4513-95ad-294a46fd78a7";

export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const UN_AUTH_SUCCESS = "UN_AUTH_SUCCESS";
export const AUTHENICATING = "AUTHENICATING";
export const VERIFYING_EMAIL = "VERIFYING_EMAIL";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_ERROR = "VERIFY_EMAIL_ERROR";

//Applicants
//Mutliple
export const FETCH_APPLICANTS_SUCCESS = "FETCH_APPLICANTS_SUCCESS";
export const FETCHING_APPLICANTS = "FETCHING_APPLICANTS";
export const FETCH_APPLICANTS_ERROR = "FETCH_APPLICANS_ERROR";
//Single
export const FETCH_APPLICANT_SUCCESS = "FETCH_APPLICANT_SUCCESS";
export const FETCH_APPLICANT_ERROR = "FETCH_APPLICANT_ERROR";
export const FETCHING_APPLICANT = "FETCHING_APPLICANT";
//Save
export const SAVE_APPLICANT_SUCCESS = "SAVE_APPLICANT_SUCCESS";
export const SAVE_APPLICANT_ERROR = "SAVE_APPLICANT_ERROR";
export const SAVING_APPLICANT = "SAVING_APPLICANT";
//Delete
export const DELETING_APPLICANT = "DELETING_APPLICANT";
export const DELETE_APPLICANT_SUCCESS = "DELETE_APPLICANT_SUCCESS";
export const DELETE_APPLICANT_ERROR = "DELETE_APPLICANT_ERROR";
export const DELETING_APPLICANTS = "DELETING_APPLICANTS";
export const DELETE_APPLICANTS_SUCCESS = "DELETE_APPLICANTS_SUCCESS";
export const DELETE_APPLICANTS_ERROR = "DELETE_APPLICANTS_ERROR";
//Move
export const MOVING_APPLICANT = "MOVING_APPLICANT";
export const MOVE_APPLICANT_SUCCESS = "MOVE_APPLICANT_SUCCESS";
export const MOVE_APPLICANT_ERROR = "MOVE_APPLICANT_ERROR";
export const CREATING_APPLICANT = "CREATING_APPLICANT";
export const CREATE_APPLICANT_SUCCESS = "CREATE_APPLICANT_SUCCESS";
export const CREATE_APPLICANT_ERROR = "CREATE_APPLICANT_ERROR";
export const FETCHING_APPLICANT_WITH_RANGE_QUERY =
  "FETCHING_APPLICANT_WITH_RANGE_QUERY";
export const FETCH_APPLICANT_RANGE_QUERY_SUCCESS =
  "FETCH_APPLICANT_RANGE_QUERY_SUCCESS";
export const FETCH_APPLICANT_RANGE_QUERY_ERROR =
  "FETCH_APPLICANT_RANGE_QUERY_ERROR";

//Reminders
export const SENDING_REMINDER = "SENDING_REMINDER";
export const SEND_REMINDER_SUCCESS = "SEND_REMINDER_SUCCESS";
export const SEND_REMINDER_ERROR = "SEND_REMINDER_ERROR";
export const REMINDER_DID_SET = "REMINDER_DID_SET";
export const CLOSE_AND_RESET_REMINDER = "CLOSE_AND_RESET_REMINDER";

//Notifications
export const FETCHING_NOTIFICATIONS = "FETCHING_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_ERROR = "FETCH_NOTIFICATIONS_ERROR";
export const SET_NOTIFICATION_READ_SUCCESS = "SET_NOTIFICATION_READ_SUCCESS";
export const SET_NOTIFICATION_READ_ERROR = "SET_NOTIFICATION_READ_ERROR";
export const CLEAR_NOTIFICATION_SUCCESS = "CLEAR_NOTIFICATION_SUCCESS";
export const CLEAR_NOTIFICATION_ERROR = "CLEAR_NOTIFICATION_ERROR";
export const TOGGLE_NOTIFICATIONS = "TOGGLE_NOTIFICATIONS";

//Students
export const FETCHING_STUDENTS = "FETCHING_STUDENTS";
export const FETCH_STUDENTS_SUCCESS = "FETCH_STUDENTS_SUCCESS";
export const FETCH_STUDENTS_ERROR = "FETCH_STUDENTS_ERROR";
export const FETCHING_STUDENT = "FETCHING_STUDENT";
export const FETCH_STUDENT_SUCCESS = "FETCH_STUDENT_SUCCESS";
export const FETCH_STUDENT_ERROR = "FETCH_STUDENT_ERROR";
export const SAVING_STUDENT = "SAVING_STUDENT";
export const SAVE_STUDENT_SUCCESS = "SAVE_STUDENT_SUCCESS";
export const SAVE_STUDENT_ERROR = "SAVE_STUDENT_ERROR";
export const FETCHING_SAFETY_INFORMATION = "FETCHING_SAFETY_INFORMATION";
export const FETCH_SAFETY_INFORMATION_SUCCESS =
  "FETCH_SAFETY_INFORMATION_SUCCESS";
export const FETCH_SAFETY_INFORMATION_ERROR = "FETCH_SAFETY_INFORMATION_ERROR";
export const SAVING_SAFETY_INFORMATION = "SAVING_SAFETY_INFORMATION";
export const SAVE_SAFETY_INFORMATION_SUCCESS =
  "SAVE_SAFETY_INFORMATION_SUCCESS";
export const SAVE_SAFETY_INFORMATION_ERROR = "SAVE_SAFETY_INFORMATION_ERROR";
export const DELETING_STUDENTS = "DELETING_STUDENTS";
export const DELETE_STUDENTS_SUCCESS = "DELETE_STUDENTS_SUCCESS";
export const DELETE_STUDENTS_ERROR = "DELETE_STUDENTS_ERROR";
export const FETCHING_CLASSES = "FETCHING_CLASSES";
export const FETCH_CLASSES_SUCCESS = "FETCH_CLASSES_SUCCESS";
export const FETCH_CLASSES_ERROR = "FETCH_CLASSES_ERROR";
export const CREATING_ACCOUNT_FOR_STUDENT = "CREATING_ACCOUNT_FOR_STUDENT";
export const CREATE_ACCOUNT_FOR_STUDENT_ERROR =
  "CREATE_ACCOUNT_FOR_STUDENT_ERROR";
export const CREATE_ACCOUNT_FOR_STUDENT_SUCCESS =
  "CREATE_ACCOUNT_FOR_STUDENT_SUCCESS";
export const FETCHING_STUDENT_GRADES = "FETCHING_STUDENT_GRADES";
export const FETCH_STUDENT_GRADES_SUCCESS = "FETCH_STUDENT_GRADES_SUCCESS";
export const FETCH_STUDENT_GRADES_ERROR = "FETCH_STUDENT_GRADES_ERROR";
export const FETCHING_STUDENT_PAGINATION = "FETCHING_STUDENT_PAGINATION";
export const FETCH_STUDENT_PAGINATION_SUCCESS =
  "FETCH_STUDENT_PAGINATION_SUCCESS";
export const FETCH_STUDENT_PAGINATION_ERROR = "FETCH_STUDENT_PAGINATION_ERROR";
export const CREATING_STUDENT = "CREATING_STUDENT";
export const CREATE_STUDENT_SUCCESS = "CREATE_STUDENT_SUCCESS";
export const CREATE_STUDENT_ERROR = "CREATE_STUDENT_ERROR";
export const SET_TAB_VALUE_STUDENTS = "SET_TAB_VALUE_STUDENTS";
export const SET_QUERY_FOR_STUDENTS = "SET_QUERY_FOR_STUDENTS";
export const TOGGLE_GRADING_DIALOG = "TOGGLE_GRADING_DIALOG";
export const SETTING_STUDENT_PROPERTY = "SETTING_STUDENT_PROPERTY";
export const SETTING_PROPERTY_SUCCESS = "SETTING_PROPERTY_SUCCESS";
export const SETTING_PROPERTY_ERROR = "SETTING_PROPERTY_ERROR";

//File uploads
export const FILE_UPLOAD_PROGRESS = "FILE_UPLOAD_PROGRESS";
export const FILE_UPLOAD_SUCCEEDED = "FILE_UPLOAD_SUCCEEDED";
export const FILE_UPLOAD_FAILED = "FILE_UPLOAD_FAILED";
export const FILES_UPLOAD_PROGRESS = "FILES_UPLOAD_PROGRESS";
export const FILES_UPLOAD_SUCCEEDED = "FILES_UPLOAD_SUCCEEDED";
export const FILES_UPLOAD_ERROR = "FILES_UPLOAD_ERROR";
export const FETCHING_FILES_FOR_COLLECTION = "FETCHING_FILES_FOR_COLLECTION";
export const FETCH_FILES_FOR_COLLECTION_SUCCESS =
  "FETCH_FILES_FOR_COLLECTION_SUCCESS";
export const FETCH_FILES_FOR_COLLECTION_ERROR =
  "FETCH_FILES_FOR_COLLECTION_ERROR";
export const DELETING_FILE = "DELETING_FILE";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_ERROR = "DELETE_FILE_ERROR";

//Semesters
export const FETCHING_SEMESTERS = "FETCHING_SEMESTERS";
export const FETCH_SEMESTERS_SUCCESS = "FETCH_SEMESTERS_SUCCESS";
export const FETCH_SEMESTERS_ERROR = "FETCH_SEMESTERS_ERROR";
export const CREATING_NEW_SEMESTER = "CREATING_NEW_SEMESTER";
export const CREATE_NEW_SEMESTER_SUCCESS = "CREATE_NEW_SEMESTER_SUCCESS";
export const CREATE_NEW_SEMESTER_ERROR = "CREATE_NEW_SEMESTER_ERROR";
//templates
export const FETCHING_CLASS_TEMPLATES = "FETCHING_CLASS_TEMPLATES";
export const FETCH_CLASS_TEMPLATES_SUCCESS = "FETCH_CLASS_TEMPLATES_SUCCESS";
export const FETCH_CLASS_TEMPLATES_ERROR = "FETCH_CLASS_TEMPLATES_ERROR";
export const CREATING_NEW_CLASS = "CREATING_NEW_CLASS";
export const CREATE_NEW_CLASS_SUCCESS = "CREATE_NEW_CLASS_SUCCESS";
export const CREATE_NEW_CLASS_ERROR = "CREATE_NEW_CLASS_ERROR";
export const TOGGLE_SEMESTER_DIALOG = "TOGGLE_SEMESTER_DIALOG";

//Snackbar
export const SNACKBAR_OPEN = "SNACKBAR_OPEN";
export const SNACKBAR_CLOSE = "SNACKBAR_CLOSE";

//Messages
export const SENDING_MESSAGES = "SENDING_MESSAGES";
export const SEND_MESSAGES_SUCCESS = "SEND_MESSAGES_SUCCESS";
export const SEND_MESSAGES_ERROR = "SEND_MESSAGES_ERROR";
export const RECIPIENTS_DID_SET = "RECIPIENTS_DID_SET";
export const REMOVE_RECIPIENT = "REMOVE_RECIPIENT";
export const FETCHING_MESSAGES = "FETCHING_MESSAGES";
export const FETCH_MESSAGES_SUCCESS = "FETCH_MESSAGES_SUCCESS";
export const FETCH_MESSAGES_ERROR = "FETCH_MESSAGES_ERROR";

//Uploads
export const FETCHING_UPLOADS = "FETCHING_UPLOADS";
export const FETCH_UPLOADS_SUCCESS = "FETCH_UPLOADS_SUCCESS";
export const FETCH_UPLOADS_ERROR = "FETCH_UPLOADS_ERROR";
export const FETCHING_USER_UPLOADS = "FETCHING_USER_UPLOADS";
export const FETCH_USER_UPLOADS_SUCCESS = "FETCH_USER_UPLOADS_SUCCESS";
export const FETCH_USER_UPLOADS_ERROR = "FETCH_USER_UPLOADS_ERROR";
export const USER_UPLOADS_PROGRESS = "USER_UPLOADS_PROGRESS";

//Exams
export const ADDING_EXAM_SUBJECT = "ADDING_EXAM_SUBJECT";
export const FETCHING_EXAM_SUBJECTS = "FETCHING_EXAM_SUBJECTS";
export const FETCH_EXAM_SUBJECT_SUCCESS = "FETCH_EXAM_SUBJECT_SUCCESS";
export const FETCH_EXAM_SUBJECT_ERROR = "FETCH_EXAM_SUBJECT_ERROR";
export const SETTING_EXAMS = "SETTING_EXAMS";
export const SET_EXAMS_SUCCESS = "SET_EXAMS_SUCCESS";
export const SET_EXAMS_ERROR = "SET_EXAMS_ERROR";
export const UPDATING_EXAM = "UPDATING_EXAM";
export const UPDATE_EXAM_SUCCESS = "UPDATE_EXAM_SUCCESS";
export const UPDATE_EXAM_ERROR = "UPDATE_EXAM_ERROR";
export const DELETING_GRADES = "DELETING_GRADES";
export const DELETE_GRADES_SUCCESS = "DELETE_GRADES_SUCCESS";
export const DELETE_GRADES_ERROR = "DELETE_GRADES_ERROR";
export const SAVING_GRADE = "SAVING_GRADE";
export const SAVE_GRADE_SUCCESS = "SAVE_GRADE_SUCCESS";
export const SAVE_GRADE_ERROR = "SAVE_GRADE_ERROR";

//Settings
export const FETCHING_SETTINGS = "FETCHING_SETTINGS";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
export const FETCH_SETTINGS_ERROR = "FETCH_SETTINGS_ERROR";
export const UPDATING_SETTINGS = "UPDATING_SETTINGS";
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_ERROR = "UPDATE_SETTINGS_ERROR";

//Examiners
export const FETCHING_EXAMINERS = "FETCHING_EXAMINERS";
export const FETCH_EXAMINERS_SUCCESS = "FETCH_EXAMINERS_SUCCESS";
export const FETCH_EXAMINERS_ERROR = "FETCH_EXAMINERS_ERROR";
export const DELETING_EXAMINERS = "DELETING_EXAMINERS";
export const DELETE_EXAMINERS_SUCCESS = "DELETE_EXAMINERS_SUCCESS";
export const DELETE_EXAMINERS_ERROR = "DELETE_EXAMINERS_ERROR";
export const FETCHING_EXAMINER = "FETCHING_EXAMINER";
export const FETCH_EXAMINER_SUCCESS = "FETCH_EXAMINER_SUCCESS";
export const FETCH_EXAMINER_ERROR = "FETCH_EXAMINER_ERROR";
export const SAVING_EXAMINER = "SAVING_EXAMINER";
export const SAVE_EXAMINER_SUCCESS = "SAVE_EXAMINER_SUCCESS";
export const SAVE_EXAMINER_ERROR = "SAVE_EXAMINER_ERROR";
export const CREATING_EXAMINER = "CREATING_EXAMINER";
export const CREATE_EXAMINER_SUCCESS = "CREATE_EXAMINER_SUCCESS";
export const CREATE_EXAMINER_ERROR = "CREATE_EXAMINER_ERROR";
export const FETCHING_EXAMINER_PAGINATION = "FETCHING_EXAMINER_PAGINATION";
export const FETCH_EXAMINER_PAGINATION_SUCCESS =
  "FETCH_EXAMINER_PAGINATION_SUCCESS";
export const FETCH_EXAMINER_PAGINATION_ERROR =
  "FETCH_EXAMINER_PAGINATION_ERROR";

// Sheets
export const SUBMITTING_SHEET = "SUBMITTING_SHEET";
export const SUBMIT_SHEET_SUCCESS = "SUBMIT_SHEET_SUCCESS";
export const SUBMIT_SHEET_ERROR = "SUBMIT_SHEET_ERROR";
export const CREATING_TRAVEL_INFO_SHEET = "CREATING_TRAVEL_INFO_SHEET";
export const CREATE_TRAVEL_INFO_SHEET_SUCCESS =
  "CREATE_TRAVEL_INFO_SHEET_SUCCESS";
export const CREATE_TRAVEL_INFO_SHEET_ERROR = "CREATE_TRAVEL_INFO_SHEET_ERROR";
export const CREATING_CONTACTS_SHEET = "CREATING_CONTACTS_SHEET";
export const CREATE_CONTACTS_SHEET_SUCCESS = "CREATE_CONTACTS_SHEET_SUCCESS";
export const CREATE_CONTACTS_SHEET_ERROR = "CREATE_CONTACTS_SHEET_ERROR";
export const CREATING_EXAMINERS_SHEET = "CREATING_EXAMINERS_SHEET";
export const CREATE_EXAMINERS_SHEET_SUCCESS = "CREATE_EXAMINERS_SHEET_SUCCESS";
export const CREATE_EXAMINERS_SHEET_ERROR = "CREATE_EXAMINERS_SHEET_ERROR";

export const FETCHING_USERS = "FETCHING_USERS";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_ERROR = "FETCH_USERS_ERROR";
export const SAVING_USER = "SAVING_USER";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const SAVE_USER_ERROR = "SAVE_USER_ERROR";
export const FETCHING_USER = "FETCHING_USER";
export const FETCH_USER_SUCCES = "FETCH_USER_SUCCES";
export const FETCH_USER_ERROR = "FETCH_USER_ERROR";
export const DELETING_USER = "DELETING_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

export const FETCHING_CONTACTS = "FETCHING_CONTACTS";
export const FETCH_CONTACTS_SUCCESS = "FETCH_CONTACTS_SUCCESS";
export const FETCH_CONTACTS_ERROR = "FETCH_CONTACTS_ERROR";
export const CREATING_PRIVATE_CONTACT = "CREATING_PRIVATE_CONTACT";
export const CREATE_PRIVATE_CONTACT_SUCCESS = "CREATE_PRIVATE_CONTACT_SUCCESS";
export const CREATE_PRIVATE_CONTACT_ERROR = "CREATE_PRIVATE_CONTACT_ERROR";
export const FETCHING_CONTACT = "FETCHING_CONTACT";
export const FETCH_CONTACT_SUCCESS = "FETCH_CONTACT_SUCCESS";
export const FETCH_CONTACT_ERROR = "FETCH_CONTACT_ERROR";
export const SAVING_PRIVATE_CONTACT = "SAVING_PRIVATE_CONTACT";
export const SAVE_PRIVATE_CONTACT_SUCCESS = "SAVE_PRIVATE_CONTACT_SUCCESS";
export const SAVE_PRIVATE_CONTACT_ERROR = "SAVE_PRIVATE_CONTACT_ERROR";
export const DELETING_CONTACTS = "DELETING_CONTACTS";
export const DELETE_CONTACTS_SUCCESS = "DELETE_CONTACTS_SUCCESS";
export const DELETE_CONTACTS_ERROR = "DELETE_CONTACTS_ERROR";

export const FETCHING_SEARCH_RESULTS = "FETCHING_SEARCH_RESULTS";
export const FETCH_SEARCH_RESULTS_SUCCESS = "FETCH_SEARCH_RESULTS_SUCCESS";
export const FETCH_SEARCH_RESULTS_ERROR = "FETCH_SEARCH_RESULTS_ERROR";

export const FETCHING_CONTACTS_PAGINATION = "FETCHING_CONTACTS_PAGINATION";
export const FETCH_CONTACTS_PAGINATION_SUCCESS =
  "FETCH_CONTACTS_PAGINATION_SUCCESS";
export const FETCH_CONTACTS_PAGINATION_ERROR =
  "FETCH_CONTACTS_PAGINATION_ERROR";

export const CREATING_CERTIFICATE_REQUEST = "CREATING_CERTIFICATE_REQUEST";
export const CREATE_CERTIFICATE_REQUEST_SUCCESS =
  "CREATE_CERTIFICATE_REQUEST_SUCCESS";
export const CREATE_CERTIFICATE_REQUEST_ERROR =
  "CREATE_CERTIFICATE_REQUEST_ERROR";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
