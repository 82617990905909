import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  select: {
    width: 200
  }
});

const ExamSemesterSelect = ({
  classes,
  selectedSemester,
  handleSemesterChange,
  semesters,
  isFetching
}) => (
  <form className={classes.root} autoComplete="off">
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="examSemesterSelect">Semester</InputLabel>
      <Select
        className={classes.select}
        value={selectedSemester}
        disabled={isFetching}
        onChange={handleSemesterChange}
        inputProps={{
          name: 'examSemester',
          id: 'examSemesterSelect'
        }}>
        {semesters.map(semester => {
          return (
            <MenuItem key={semester} value={semester}>
              {semester}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  </form>
);

ExamSemesterSelect.propTypes = {
  selectedSemester: PropTypes.string.isRequired,
  handleSemesterChange: PropTypes.func.isRequired,
  semesters: PropTypes.array.isRequired
};

export default withStyles(styles)(ExamSemesterSelect);
