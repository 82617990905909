import React, { Component } from 'react';
import {
  fetchUploads,
  fetchUserUploads,
  downloadImagesForStudent,
  deleteImagesForStudent
} from '../../actions/uploads';
import { connect } from 'react-redux';
import { UploadersComponent, UserUploadsComponent } from '../../components';
import Grid from '@material-ui/core/Grid';

class UploadsContainer extends Component {
  state = {
    selectedStudent: ''
  };

  componentDidMount() {
    this.props.fetchUploads();
  }

  userDidSelect = sid => {
    this.setState({ selectedStudent: sid });
    this.props.fetchUserUploads(sid);
  };

  downloadAll = () => {
    this.props.downloadImagesForStudent(this.state.selectedStudent);
  };

  deleteAll = () => {
    if (window) {
      if (
        window.confirm(
          'Vil du slette alle billeder for den valgte studerende?'
        ) === true
      ) {
        this.props.deleteImagesForStudent(this.state.selectedStudent);
      }
    }
  };

  render() {
    const { uploads, userUploads, isFetching, progress } = this.props;
    const { selectedStudent } = this.state;
    return (
      <div>
        <Grid container>
          <Grid item xs={12} md={3} lg={3}>
            <UploadersComponent
              uploads={uploads}
              isFetching={isFetching}
              userDidSelect={this.userDidSelect}
              selectedStudent={selectedStudent}
            />
          </Grid>
          <Grid item xs={12} md={9} lg={9}>
            <UserUploadsComponent
              userUploads={userUploads}
              progress={progress}
              downloadAll={() => this.downloadAll()}
              deleteAll={() => this.deleteAll()}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ uploads }) => {
  const {
    isFetching,
    isFetchingUserUploads,
    success,
    userUploads,
    progress,
    error
  } = uploads;
  return {
    uploads: uploads.uploads,
    userUploads,
    isFetching,
    isFetchingUserUploads,
    success,
    progress,
    error
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchUploads: () => dispatch(fetchUploads()),
    fetchUserUploads: sid => dispatch(fetchUserUploads(sid)),
    downloadImagesForStudent: sid => dispatch(downloadImagesForStudent(sid)),
    deleteImagesForStudent: sid => dispatch(deleteImagesForStudent(sid))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadsContainer);
