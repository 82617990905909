export const lines = ["sport", "x-outdoor", "outdoor", "discharged"];
export const classNames = {
  sport: ["classic-sports-1", "classic-sports-2"],
  "x-outdoor": ["x-outdoor-1", "x-outdoor-2"],
  outdoor: ["outdoor-1", "outdoor-2"],
  discharged: [
    "classic-sports-1",
    "classic-sports-2",
    "x-outdoor-1",
    "x-outdoor-2",
    "outdoor-1",
    "outdoor-2"
  ]
};
