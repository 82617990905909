import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    minWidth: 150,
  },

  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

type CardProps = {
  count: number;
  title: string;
  icon: React.ReactNode;
};
const NumberCard: React.FC<CardProps> = ({ count, title, icon }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader avatar={icon} title={title} />
      <CardContent>
        <Typography variant="h2" component="p">
          {count}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default NumberCard;
