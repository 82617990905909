import { useState, useEffect } from "react";
import { useFirestore } from "react-redux-firebase";
import Student from "../Classes/Student";

type Props = {
  semester: string;
  line: string;
};

export default function ({ line, semester }: Props): Student[] {
  const [students, setStudents] = useState<Student[]>([]);
  const firestore = useFirestore();

  useEffect(() => {
    firestore
      .collection("students")
      .where("line", "==", line)
      .where("semester", "==", semester)
      .onSnapshot(
        (snapshot) => {
          const students = snapshot.docs
            .filter((d) => d.exists)
            .map((d) => new Student(d.data() as Student));
          setStudents(students);
        },
        (error) => {
          console.log(error);
        }
      );
  }, [line, semester]);
  return students;
}
