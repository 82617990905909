import { Dispatch } from "redux";
import { SNACKBAR_OPEN, SNACKBAR_CLOSE } from "../constants";

export function snackbarOpen(message: string) {
  return {
    type: SNACKBAR_OPEN,
    message,
  };
}
export function snackbarClose() {
  return {
    type: SNACKBAR_CLOSE,
  };
}

export function openSnackbar(message: string) {
  return async (dispatch: Dispatch) => {
    dispatch(snackbarOpen(message));
    await sleep(5000);
    dispatch(snackbarClose());
  };
}

export function closeSnackbar() {
  return (dispatch: Dispatch) => {
    dispatch(snackbarClose());
  };
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
