import React, { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";

type Hook = (semester?: string) => Statistic[];

const useGraphData: Hook = (semester) => {
  const [data, setData] = useState<Statistic[]>([]);
  const firestore = useFirestore();

  useEffect(() => {
    async function loadData() {
      if (!semester) return [];
      const statistics = await firestore
        .collection("statistics")
        .where("semester", "==", semester)
        .get();
      console.log(statistics.docs.length);

      const data = statistics.docs
        .filter((doc) => doc.exists)
        .map((doc) => doc.data() as Statistic);

      setData(data);
    }
    loadData();
  }, [semester]);

  return data;
};

export default useGraphData;
