import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";

const styles = (theme) => ({
  root: theme.mixins.gutters({
    marginTop: theme.spacing(3),
  }),
  paper: {
    padding: theme.spacing(2),
  },
  grid: {
    marginBottom: theme.spacing(3),
  },
  textField: {
    width: 200,
  },
  button: {
    marginTop: theme.spacing(3),
  },
});

const SettingsComponent = ({
  classes,
  email,
  emailVerified,
  handleNotificationChange,
  applicantsOverdue,
  newApplicant,
  handleVerifyEmail,
  isVerifying,
  verifySuccess,
  isUpdating,
  isFetching,
}) => {
  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12} md={8} className={classes.root}>
          <Typography variant="caption">Verificer email</Typography>
          <Paper elevation={6} className={classes.paper}>
            <Typography variant="body1">Verificer email</Typography>

            {emailVerified ? (
              <Typography>Din email er verificeret</Typography>
            ) : (
              <Badge badgeContent={1} color="secondary">
                <Typography>
                  For at kunne modtage notifikationer og email skal du
                  verificere din email.
                </Typography>
              </Badge>
            )}

            <Grid container>
              <Grid item xs={10}>
                <form noValidate autoComplete="off">
                  <TextField
                    id="name"
                    label="Name"
                    className={classes.textField}
                    value={email}
                    disabled={true}
                    margin="normal"
                  />
                </form>
              </Grid>
              <Grid item xs={2}>
                <Button
                  disabled={isVerifying || verifySuccess || emailVerified}
                  className={classes.button}
                  onClick={handleVerifyEmail}
                  color="primary"
                >
                  Verificer
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={12} md={8} className={classes.root}>
          <Typography variant="caption">Notifikationer</Typography>
          <Paper elevation={6} className={classes.paper}>
            <Grid container>
              <Grid item xs={12} className={classes.grid}>
                <Typography variant="body1">
                  Indstil hvilke notifikationer du får
                </Typography>
                <Typography>
                  Dine notifikationer vil blive sendt til den email du er logget
                  ind med: <i>{email}</i>
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Ansøgere
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="subtitle1" gutterBottom>
                  Nye ansøgere
                </Typography>
                <Typography gutterBottom>
                  Modtag notifikationer for nye ansøgere
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Switch
                  checked={newApplicant}
                  disabled={isUpdating || isFetching}
                  onChange={handleNotificationChange("newApplicant")}
                  aria-label="new-applicant"
                />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="subtitle1" gutterBottom>
                  Ansøgere der har overskredet 2 uger
                </Typography>
                <Typography gutterBottom>
                  Modtag notifikationer for ansøgere der har overskredet 2 uger
                  siden afsendt kontrakt
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Switch
                  checked={applicantsOverdue}
                  disabled={isUpdating || isFetching}
                  onChange={handleNotificationChange("applicantsOverdue")}
                  aria-label="applicants-overdue"
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(SettingsComponent);
