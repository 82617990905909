import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { StudentForm } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { fetchClasses, createStudent } from "../../actions/students";
import { fetchSemesters } from "../../actions/semesters";
import { AppState } from "../../reducers";
import { firestore } from "../../helpers/firebaseHelper";
import Student from "../../Classes/Student";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
}));

const NewStudentContainer: React.FC = () => {
  const { semester }: { semester: string } = useParams();
  const classes = useStyles();
  const {
    semesters: { semesters },
    classNames,
  } = useSelector((state: AppState) => {
    return {
      semesters: state.semesters,
      classNames: state.students.classNames,
    };
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchClasses(semester));
    dispatch(fetchSemesters());
  }, [dispatch]);

  const handleSubmit = (values: any) => {
    dispatch(createStudent(values));
  };

  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Paper className={classes.paper}>
            <StudentForm
              saveStudent={handleSubmit}
              semesters={semesters || []}
              initialValues={
                new Student({
                  admissionFeeReceived: false,
                  birthday: new Date(),
                  booksIssued: false,
                  city: "",
                  class: "",
                  clothingBottom: "---",
                  clothingRemark: "",
                  clothingTop: "---",
                  confirmationSent: false,
                  consentSigned: false,
                  contractReceived: false,
                  contractReceivedTimestamp: new Date(),
                  contractSent: false,
                  contractSentDate: new Date(),
                  createdAt: firestore.FieldValue.serverTimestamp(),
                  createdInEconomics: false,
                  createdInSU: false,
                  discharged: false,
                  dischargedDate: null,
                  dischargeReasons: [],
                  email: "",
                  examSubjects: [],
                  firstName: "",
                  gender: "",
                  graduated: false,
                  graduatedDate: null,
                  hasAccount: false,
                  id: firestore().collection("students").doc().id,
                  imageUrl: "",
                  infoLetter: false,
                  interviewer: "",
                  introductionEmail: false,
                  origin: "intra",
                  lastName: "",
                  lectio: false,
                  line: "sport",
                  note: "",
                  phone: "",
                  postcode: "",
                  rate: "",
                  rulesSigned: false,
                  semester: semester,
                  ssn: "",
                  street: "",
                  studentCard: false,
                  subjectsNotPassed: [],
                  userId: null,
                  workExperienceContract: false,
                  workExperienceContract2: false,
                })
              }
              classNames={classNames}
              success={false}
              removeSemester={() => {
                console.log("not implemented");
              }}
              actionsMenu={
                <Button variant="contained" disabled>
                  Handlinger
                </Button>
              }
              // isSaving={false}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default NewStudentContainer;
