import moment from "moment";
import "moment/locale/da";
import _ from "lodash";

const parseLine = (line) => {
  switch (line) {
    case "sport":
      return "Sports";
    case "x-outdoor":
    case "xOutdoor":
      return "X-Outdoor";
    case "outdoor":
      return "Friluft";
    case "discharged":
      return "Udmeldte";
    default:
      return line || "";
  }
};

const parseClass = (className) => {
  switch (className) {
    case "classic-sports-1":
      return "Sports 1";
    case "sports-1":
      return "Sports 1";
    case "classic-sports-2":
      return "Sports 2";
    case "sports-2":
      return "Sports 2";
    case "x-outdoor-1":
      return "X-Outdoor 1";
    case "x-outdoor-2":
      return "X-Outdoor 2";
    case "outdoor-1":
      return "Friluft 1";
    case "outdoor-2":
      return "Friluft 2";
    default:
      return className;
  }
};

const convertToPlainDate = (date) => {
  if (_.isDate(date)) return date;
  if (_.isEmpty(date)) return null;
  let d;

  // If toDate is a function it means the date is a firebase date
  if (typeof date.toDate === "function") {
    d = new Date(date.toDate());
  } else if (date._seconds) {
    d = new Date(date._seconds * 1000);
  } else {
    d = new Date(date);
  }
  return d;
};

const parseDate = (date, format = "LL") => {
  if (!date) return "-";
  const d = convertToPlainDate(date);
  let localMoment = moment(d);

  localMoment.utcOffset(0);
  return localMoment.format(format);
};

const parseRelativeDate = (date, format = "") => {
  if (!date) return "-";
  const d = convertToPlainDate(date);
  let localMoment = moment(d);
  localMoment.utcOffset(0);
  return localMoment.fromNow();
};

const parseApproved = (a) => {
  switch (a) {
    case ("yes", true):
      return "Ja";
    case ("no", false):
      return "Nej";
    case "lapsed":
      return "Udløbet";
    default:
      return "Ej angivet";
  }
};

const parseGender = (gender) => {
  switch (gender) {
    case "male":
      return "Dreng";
    case "female":
      return "Pige";
    default:
      return "Ej angivet";
  }
};

const parseExamType = (examType) => {
  switch (examType) {
    case "graded":
      return "Karakter";
    case "pass_fail":
      return "Bestået / Ikke bestået";
    case "attendance":
      return "Mødte op";
  }
};

const parseExamResult = (result) => {
  switch (result) {
    case "pass":
      return "Bestået";
    case "fail":
      return "Ikke bestået";
    case "attended":
      return "Mødte op";
    case "not_attended":
      return "Mødte ikke op";
    default:
      return result;
  }
};

export {
  parseLine,
  parseClass,
  parseDate,
  parseRelativeDate,
  parseApproved,
  parseGender,
  convertToPlainDate,
  parseExamType,
  parseExamResult,
};
