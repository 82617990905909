import React, { Component } from 'react';
import { connect } from 'react-redux';
import { openSnackbar, closeSnackbar } from '../../actions/snackbar';
import { SnackbarComponent } from '../../components';

class SnackbarContainer extends Component {
  handleRequestClose = () => this.props.closeSnackbar();

  render() {
    const { open, message } = this.props;
    return (
      <SnackbarComponent
        open={open}
        message={message}
        requestClose={this.handleRequestClose}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openSnackbar: msg => dispatch(openSnackbar(msg)),
    closeSnackbar: () => dispatch(closeSnackbar())
  };
};

const mapStateToProps = ({ snackbar }) => {
  const { open, message } = snackbar;
  return {
    open,
    message
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarContainer);
