export enum Query {
  AwaitingCall,
  InterviewScheduled,
  ContractSent,
  NoShow,
  Archived,
}

const queries: { name: string; id: string; where: Query }[] = [
  {
    name: "Ringes op med henblik på en samtale",
    id: "applicantsToContact",
    where: Query.AwaitingCall,
  },
  {
    name: "Samtale Aftalt",
    id: "applicantsForInterview",
    where: Query.InterviewScheduled,
  },
  {
    name: "Kontrakt afsendt - afventer modtagelse",
    id: "applicantsAwaitingContract",
    where: Query.ContractSent,
  },
  {
    name: "Mødte ikke op til samtale",
    id: "applicantsNoShow",
    where: Query.NoShow,
  },
  {
    name: "Arkiveret",
    id: "applicantsArchived",
    where: Query.Archived,
  },
];

export default queries;
