import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { blue } from "@material-ui/core/colors";
import useStudents from "../../hooks/useStudents";
import { parseLine } from "../../helpers/parser";
import IconButton from "@material-ui/core/IconButton";
import green from "@material-ui/core/colors/green";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  dialog: {
    maxHeight: 560,
  },
  checkmark: {
    color: green[500],
  },
});

type Props = {
  line: string;
  semester: string;
  handleCancel: () => void;
  handleAssign: (ids: string[]) => void;
  handleUnassign: (ids: string[]) => void;
  open: boolean;
  subjectId: string;
};
const AssignStudentsDialog: React.FC<Props> = ({
  line,
  semester,
  handleCancel,
  handleAssign,
  handleUnassign,
  open,
  subjectId,
}) => {
  const students = useStudents({ line, semester });
  const classes = useStyles();
  return (
    <Dialog
      onClose={handleCancel}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.dialog}
    >
      <DialogTitle id="simple-dialog-title">
        {parseLine(line)} - {semester}
      </DialogTitle>
      <List>
        {students.map((student) => {
          const name = `${student.firstName} ${student.lastName}`;
          const isAssigned = student.examSubjects.includes(subjectId);
          return (
            <ListItem key={student.id}>
              <ListItemAvatar>
                <IconButton
                  onClick={() =>
                    isAssigned
                      ? handleUnassign([student.id])
                      : handleAssign([student.id])
                  }
                >
                  {isAssigned ? (
                    <CheckCircleOutlineIcon className={classes.checkmark} />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </IconButton>
              </ListItemAvatar>
              <ListItemText primary={name} />
            </ListItem>
          );
        })}
      </List>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          OK
        </Button>
        <Button
          onClick={() => {
            handleAssign(students.map((s) => s.id));
          }}
          color="primary"
          variant="contained"
          autoFocus
        >
          Tildel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignStudentsDialog;
