import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ExaminerForm from "./components/ExaminerForm";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useSelector, useDispatch } from "react-redux";
import { openSnackbar } from "../actions/snackbar";
import { AppState } from "../reducers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
  })
);

const EXAMINERS_COLLECTION = "examiners";

const ExaminerComponent: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const firestore = useFirestore();

  useFirestoreConnect([
    { collection: EXAMINERS_COLLECTION, doc: id },
    { collection: "semesters" },
    { collection: "examSubjects" },
  ]);
  const examiner = useSelector(
    ({ firestore: { data } }: AppState) => data.examiners && data.examiners[id]
  );
  const semesters = useSelector(
    ({ firestore: { ordered } }: AppState) => ordered.semesters || []
  );

  const handleSubmit = async (values: Examiner) => {
    try {
      const actual = Object.keys(values);
      const required = [
        "id",
        "firstName",
        "lastName",
        "approved",
        "paymentInformationForwarded",
        "examinerReport",
        "note",
        "isArchived",
      ];
      const difference = required.filter((key) => !actual.includes(key));
      console.log(difference);
      await firestore
        .collection(EXAMINERS_COLLECTION)
        .doc(values.id)
        .update(values);
      dispatch(
        openSnackbar(
          `✅ ${examiner.firstName} ${examiner.lastName} blev opdateret`
        )
      );
    } catch (error: any) {
      console.error(error);
      dispatch(
        openSnackbar(
          `🛑 Kunne ikke opdatere ${examiner.firstName} ${examiner.lastName}: ${error.message}`
        )
      );
    }
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={12} lg={10}>
        <Paper elevation={6} className={classes.root}>
          {examiner && (
            <ExaminerForm
              examiner={examiner}
              semesters={semesters}
              handleSubmit={handleSubmit}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ExaminerComponent;
