import { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { parseDate } from "../helpers/parser";

const EVENTS_COLLECTION = "info_events";
const ATTENDEES_COLLECTION = "attendees";

type NextEventProps = {
  name: string;
  nameKey: string;
  value: number;
};

const statusKeys: { [key: string]: string } = {
  attending: "Deltager",
  not_attending: "Deltager ikke",
  unconfirmed: "Afventer svar",
};

type MappedEvent = {
  nextEventStats: NextEventProps[];
  nextEventDate: string;
};
const useNextEventStats = (): MappedEvent => {
  const firestore = useFirestore();
  const [nextEventStats, setNextEventStats] = useState<NextEventProps[]>([]);
  const [nextEventDate, setNextEventDate] = useState("Intet event fundet");

  useEffect(() => {
    async function fetch() {
      try {
        const eventsRef = firestore.collection(EVENTS_COLLECTION);
        const events = await eventsRef
          .where("date", ">", new Date())
          .where("completed", "==", false)
          .orderBy("date", "asc")
          .limit(1)
          .get();
        if (events.docs[0]) {
          const infoEvent = events.docs[0];
          const attendees = await infoEvent.ref
            .collection(ATTENDEES_COLLECTION)
            .get();

          const chartDict: Record<string, number> = {
            attending: 0,
            not_attending: 0,
            unconfirmed: 0,
          };
          attendees.docs
            .filter((doc) => doc.exists)
            .map((doc) => doc.data())
            .map((data) => {
              if (data.status === "attending") {
                chartDict[data.status] += parseInt(data.participants);
              } else {
                chartDict[data.status] += 1;
              }
            });
          setNextEventStats(
            Object.keys(chartDict).map((key) => {
              return {
                name: statusKeys[key],
                nameKey: key,
                value: chartDict[key],
              };
            })
          );
          setNextEventDate(parseDate(infoEvent.data().date, "MMM Do"));
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetch();
  }, []);
  return { nextEventStats, nextEventDate };
};

export default useNextEventStats;
