import React from "react";
import { green, red, orange } from "@material-ui/core/colors";
import { ResponsiveContainer, PieChart, Pie, Cell, Sector } from "recharts";

const renderActiveShape = (props: any, total: any, nextEventDate: string) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        style={{ fontSize: "1.5rem", fontWeight: 900 }}
      >
        {nextEventDate}
      </text>
      <text
        x={cx}
        y={cy + 16}
        dy={8}
        textAnchor="middle"
        style={{ fontSize: "0.75rem" }}
      >
        Antal tilmelte {total}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${payload.name} ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

const getColour = (entry: string): string => {
  switch (entry) {
    case "attending":
      return green[500];
    case "not_attending":
      return red[500];
    case "unconfirmed":
      return orange[500];
  }
  return "fff";
};
type ChartEntry = { name: string; nameKey: string; value: number };
type ChartProps = {
  data: ChartEntry[];
  nextEventDate: string;
};
const NextEventStatsPieChart: React.FC<ChartProps> = ({
  data,
  nextEventDate,
}) => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  const onPieEnter = (data: any, index: number) => {
    setActiveIndex(index);
  };

  const total = data.reduce((acc: any, data: any) => {
    return acc + data["value"];
  }, 0);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={(props) =>
            renderActiveShape(props, total, nextEventDate)
          }
          data={data}
          dataKey="value"
          nameKey="name"
          innerRadius={100}
          outerRadius={130}
          fill="#8884d8"
          onMouseEnter={onPieEnter}
        >
          {data.map((entry) => (
            <Cell key={entry.name} fill={getColour(entry.nameKey)} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default NextEventStatsPieChart;
