import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const styles = theme => ({
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  }
});

const SnackbarComponent = ({ open, message, classes, requestClose }) => (
  <div>
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={open}
      autoHideDuration={6000}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
      message={<span id="message-id">{message}</span>}
      action={
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={requestClose}
        >
          <CloseIcon />
        </IconButton>
      }
    />
  </div>
);

SnackbarComponent.propTypes = {
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired
};

export default withStyles(styles)(SnackbarComponent);
