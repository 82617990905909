import { useState, useEffect } from "react";
import { useFirestore } from "react-redux-firebase";

type Props = {
  setQuery: React.Dispatch<
    React.SetStateAction<{ line: string; semester: string }>
  >;
  line: string;
  semester: string;
};
export default function ({ setQuery, line, semester }: Props): Subject[] {
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const firestore = useFirestore();

  useEffect(() => {
    const unsubscribe = firestore
      .collection("subjects")
      .where("lines", "array-contains", line)
      .where("semester", "==", semester)
      .onSnapshot(
        (snapshot) => {
          const data = snapshot.docs.map((doc) => doc.data() as Subject);
          setSubjects(data);
        },
        (error) => {
          console.error(error);
        }
      );

    return () => {
      unsubscribe();
    };
  }, [setQuery, line, semester]);
  return subjects;
}
