import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import { LoadableButton } from "../../components";
export const fields = {
  imageUrl: false,
  firstName: false,
  lastName: false,
  email: false,
  phone: false,
  class: false,
  street: false,
  postcode: false,
  city: false,
  contractReceived: false,
  contractSent: false,
  createdInSU: false,
  discharged: false,
  gender: false,
  infoLetter: false,
  introductionEmail: false,
  line: false,
  semester: false,
  note: false,
  ssn: false,
};

export const translations = {
  imageUrl: "Billede",
  firstName: "Fornavn",
  lastName: "Efternavn",
  email: "Email",
  phone: "Telefon",
  class: "Hold",
  street: "Adresse",
  postcode: "Postnummer",
  city: "By",
  contractReceived: "Kontrakt modtaget",
  contractSent: "Kontrakt sendt",
  createdInSU: "Oprettet i SU",
  discharged: "Udmeldt",
  gender: "Køn",
  infoLetter: "Informationsbrev",
  introductionEmail: "Intro email",
  line: "Linie",
  semester: "Semester",
  note: "Note",
  ssn: "CPR",
};

class CreateSpreadsheetDialog extends Component {
  state = {
    title: `Udtræk studerende - d.${new Date().toLocaleDateString("da-DK")}`,

    fields: {
      ...fields,
    },
  };

  handleFieldChange = (name) => (event) => {
    this.setState({
      fields: { ...this.state.fields, [name]: event.target.checked },
    });
  };

  handleChange = (event) => {
    this.setState({ title: event.target.value });
  };

  render() {
    const { isSubmitting, sheetURL } = this.props;
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Excel udtræk</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Vælg de felter du gerne vil have i dit spreadsheet og klik Opret.
            </DialogContentText>
            <TextField
              disabled={isSubmitting}
              autoFocus
              margin="dense"
              value={this.state.title}
              onChange={this.handleChange}
              id="title"
              label="Titel"
              placeholder="Eks. 'Kontooplysninger Sports 1 18-19'"
              type="title"
              fullWidth
            />
            <FormGroup row>
              {Object.keys(this.state.fields).map((key) => {
                return (
                  <FormControlLabel
                    disabled={isSubmitting}
                    key={key}
                    control={
                      <Checkbox
                        checked={this.state.fields[key]}
                        onChange={this.handleFieldChange(key)}
                        value={key}
                      />
                    }
                    label={translations[key]}
                  />
                );
              })}
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.handleClose}
              disabled={isSubmitting}
              color="primary"
            >
              Annuller
            </Button>
            {sheetURL.length > 0 ? (
              <a
                style={{ textDecoration: "none" }}
                href={sheetURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  variant="contained"
                  disabled={isSubmitting}
                  color="primary"
                >
                  Se arbejdsark
                </Button>
              </a>
            ) : (
              <LoadableButton
                onClick={() =>
                  this.props.handleCreate(this.state, translations)
                }
                loading={isSubmitting}
                success={this.props.sheetURL.length > 0}
                disabled={isSubmitting || this.state.title.length < 1}
                color="primary"
              >
                Opret
              </LoadableButton>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = ({ sheets }) => {
  const { sheetURL, isSubmitting, error } = sheets;
  return {
    sheetURL,
    isSubmitting,
    error,
  };
};

export default connect(mapStateToProps, null)(CreateSpreadsheetDialog);
