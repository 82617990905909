import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import {
  uploadFiles,
  fetchFilesForCollection,
  unsubscribeFilesListener,
  deleteFile,
} from "../../actions/fileUploads";
import { FilesComponent } from "../../components";
import { AppState } from "../../reducers";
import { DropzoneFileListProps, DropzoneFileTypes } from "./DropzoneTypes";

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  background-color: #fafafa;
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const DropzoneFileList: React.FC<DropzoneFileListProps> = ({
  collection,
  id,
  multiple,
  heading,
  type = DropzoneFileTypes.Files,
}) => {
  const dispatch = useDispatch();
  const { files, isUploading, isFetching } = useSelector(
    (state: AppState) => state.fileUploads
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: (files) => handleUploadFiles(type)(files),
    noClick: true,
    multiple,
  });

  useEffect(() => {
    dispatch(fetchFilesForCollection(collection, id));
    return () => {
      unsubscribeFilesListener();
    };
  }, [dispatch]);

  const handleUploadFiles = (type: string) => (files: File[]) => {
    if (!id) {
      alert("Kunne ikke finde id");
      return;
    }

    dispatch(uploadFiles(collection, id, type, files));
  };

  const handleDeleteFile = (file: File) => {
    if (window) {
      if (window.confirm(`Er du sikker på at du vil slette ${file.name}`)) {
        dispatch(deleteFile(file));
      }
    }
  };

  let fetchStatus = "";
  if (isFetching) {
    fetchStatus = "Henter";
  } else if (isUploading) {
    fetchStatus = "Uploader";
  } else {
    fetchStatus = "";
  }

  return (
    <div className="container">
      <Container
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        <p>Træk filer hertil for at uploade</p>
        <FilesComponent
          isFetching={isFetching}
          isUploading={isUploading}
          fetchStatus={fetchStatus}
          files={files}
          type={type}
          heading={heading}
          handleDeleteFile={handleDeleteFile}
        />
      </Container>
    </div>
  );
};

export default DropzoneFileList;
