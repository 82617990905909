import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from "recharts";

import { functions } from "../../helpers/firebaseHelper";

type Data = {
  name: string;
  sport: number;
  outdoor: number;
  xOutdoor: number;
  noLine: number;
  total: number;
};

type ChartProps = {
  semester?: string;
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload) {
    const { sport, outdoor, xOutdoor, total } = payload[0].payload;
    return (
      <div style={{ background: "rgba(0,0,0,0.6)", color: "#fff", padding: 8 }}>
        <p style={{ fontWeight: 900 }} className="label">{`Uge ${label}`}</p>

        <p className="intro">{`Total: ${total}`}</p>
        <p className="intro">{`Original Sport: ${sport}`}</p>
        <p className="intro">{`Friluft: ${outdoor}`}</p>
        <p className="intro">{`X-Outdoor: ${xOutdoor}`}</p>
      </div>
    );
  }

  return null;
};

export const ApplicantsChart: React.FC<ChartProps> = ({ semester }) => {
  const [data, setData] = useState<Data[]>([]);

  useEffect(() => {
    async function loadContracts() {
      if (!semester) return [];
      try {
        const applicantsFunction = functions.httpsCallable(
          "statistics-applicants"
        );
        const startYear = parseInt(semester.split("-")[0]);

        const result = await applicantsFunction({ startYear });
        setData(result.data);
      } catch (error: any) {
        console.log(error.message);
      }
    }
    loadContracts();
  }, [semester]);
  return (
    <ResponsiveContainer width="100%" height="95%">
      <LineChart
        width={500}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid />
        <XAxis dataKey="name">
          <Label value="Uge nr." offset={0} position="insideBottomLeft" />
        </XAxis>

        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend margin={{ bottom: 16 }} height={16} />
        <Line
          name="Antal ansøgere totalt"
          type="monotone"
          dataKey="total"
          stroke="#4caf50"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
