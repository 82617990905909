import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import DeleteIcon from "@material-ui/icons/Delete";
import NewIcon from "@material-ui/icons/Add";
import { Divider } from "@material-ui/core";
import EventsIcon from "../../components/Icons/EventsIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 30,
  },
  cardRoot: {
    width: "100%",

    borderRadius: 10,
    boxShadow: "rgba(0,0,0,0.05) 1px 1px 10px 10px",
  },
  cardHeader: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "10 10 0 0",
    position: "relative",
  },
  cardContent: {
    padding: 10,
  },

  dischargeSelect: {
    padding: theme.spacing(2),
    minWidth: 300,
  },
  typography: { fontWeight: 600 },
  select: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  radio: {
    marginTop: theme.spacing(2),
  },
}));

type HeaderProps = {
  selection: Record<string, unknown>[];
  handleCreateEvent: () => void;
  handleDeleteEvents: () => void;
  semester: string;
  setSemester: (semester: string) => void;
};

const Header: React.FC<HeaderProps> = ({
  selection,
  handleCreateEvent,
  handleDeleteEvents,
  semester,
  setSemester,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.cardRoot}>
            <CardHeader className={classes.cardHeader} title={"Infoaftener"} />

            <CardContent className={classes.cardContent}>
              <Grid item xs={12} container direction="row" alignItems="center">
                <Grid item xs={12} md={2}>
                  <div style={{ textAlign: "center" }}>
                    <EventsIcon />
                  </div>
                </Grid>

                <Divider variant="middle" orientation="vertical" flexItem />
                <Grid item xs={12} md={2}>
                  <ButtonGroup
                    size="medium"
                    color="primary"
                    orientation="vertical"
                  >
                    <Button
                      className={classes.button}
                      variant="contained"
                      disableElevation
                      endIcon={<NewIcon />}
                      onClick={() => handleCreateEvent()}
                    >
                      Ny infoaften
                    </Button>
                    <Button
                      className={classes.button}
                      endIcon={<DeleteIcon />}
                      onClick={() => handleDeleteEvents()}
                      disabled={selection.length < 1}
                      color="secondary"
                    >
                      Slet
                    </Button>
                  </ButtonGroup>
                </Grid>
                <Divider variant="middle" orientation="vertical" flexItem />

                <Grid item xs={12} md={2}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Vælg årgang
                    </InputLabel>
                    <Select
                      value={semester}
                      onChange={(e) => setSemester(e.target.value as string)}
                    >
                      <MenuItem value="2018">2018-2019</MenuItem>
                      <MenuItem value="2019">2019-2020</MenuItem>
                      <MenuItem value="2020">2020-2021</MenuItem>
                      <MenuItem value="2021">2021-2022</MenuItem>
                      <MenuItem value="2022">2022-2023</MenuItem>
                      <MenuItem value="2023">2023-2024</MenuItem>
                      <MenuItem value="2024">202420-25</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Divider variant="middle" orientation="vertical" flexItem />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Header;
