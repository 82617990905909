import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import classnames from "classnames";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import indigo from "@material-ui/core/colors/indigo";
import blue from "@material-ui/core/colors/blue";
import { parseClass } from "../../helpers/parser";
import {
  DischargedSelect,
  DateField,
  GenderField,
  SelectField,
  DisplayImage,
} from "..";
import { Field, Formik, FieldArray } from "formik";
import { TextField, Select } from "formik-material-ui";
import * as Yup from "yup";
import { interviewers } from "../../helpers/interviewers";
import CheckboxField from "../FormFields/CheckboxField";

const useStyles = makeStyles((theme) => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
  divider: {
    height: 1,
    backgroundColor: indigo[300],
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textArea: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor: "rgba(128, 128, 128, 0.1)",
    padding: 10,
  },
  typography: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    fontWeight: 600,
  },
  radio: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField: {
    width: "25ch",
  },
  margin: {
    margin: theme.spacing(1),
  },
  buttonWarning: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    marginLeft: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  chipInput: {
    marginBottom: theme.spacing(1),
  },

  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },

  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
  select: {
    margin: theme.spacing(1),
    width: "100%",
  },
  checkbox: {
    marginLeft: theme.spacing(1),
  },
  dischargeSelect: {
    margin: theme.spacing(1),
    minWidth: 400,
    maxWidth: 600,
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  avatar: {
    height: 150,
    width: 150,
    border: `solid ${blue.A400} 5px`,
  },
  chipList: {
    display: "flex",
    // justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const validationSchema = Yup.object().shape({
  firstName: Yup.string().label("Fornavn").min(2).required(),
  lastName: Yup.string().label("Efternavn").min(2).required(),
  gender: Yup.string().label("Køn").required(),
  semester: Yup.string().label("Semester").required(),
  email: Yup.string().email().label("Email").required(),
  phone: Yup.string().label("Telefonnummer (inkl. landekode").min(8),
  line: Yup.string().label("Linie").required(),
  ssn: Yup.string().label("CPR").min(6),
  subjectsNotPassed: Yup.array(Yup.string().min(2)).label("Fag ikke bestået"),
});

type StudentFormProps = {
  initialValues: any;
  classNames: string[];
  success: boolean;
  semesters: string[];
  removeSemester: () => void;
  actionsMenu: React.ReactNode;
  saveStudent: (student: any) => void;
};

const StudentForm: React.FC<StudentFormProps> = ({
  initialValues,
  classNames,
  semesters,
  removeSemester,
  actionsMenu,
  saveStudent,
}) => {
  const [chipTextValue, setChipTextValue] = useState("");
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values, { setSubmitting }) => {
        saveStudent(values);
        setSubmitting(false);
      }}>
      {({
        values: {
          id,
          graduated,
          subjectsNotPassed,
          discharged,
          interviewer,
          imageUrl,
          previousSemester,
        },
        handleSubmit,
        resetForm,
        isSubmitting,
        errors,
        touched,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              {/* Create the top level container for the form */}
              <Grid container>
                <Grid item xs={12} container justify="center">
                  <DisplayImage
                    imageURL={imageUrl}
                    collection="students"
                    itemID={id}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  justify="center"
                  style={{ marginTop: 8 }}>
                  {actionsMenu}
                </Grid>

                {/* Insert container below for the personal detail fields */}
                <Grid item xs={12} container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      className={classes.typography}
                      variant="subtitle2">
                      Personlig information
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Field
                      name="firstName"
                      fullWidth
                      component={TextField}
                      placeholder={"Fornavn"}
                      label="Fornavn"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Field
                      name="lastName"
                      fullWidth
                      component={TextField}
                      placeholder={"Efternavn"}
                      label="Efternavn"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Field
                      name="ssn"
                      fullWidth
                      component={TextField}
                      placeholder={"CPR"}
                      label="CPR"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="phone"
                      fullWidth
                      component={TextField}
                      label="Telefon"
                      placeholder={"Telefon"}
                      type="tel"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="email"
                      fullWidth
                      component={TextField}
                      placeholder={"Email"}
                      label="Email"
                      type="email"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      name="street"
                      fullWidth
                      component={TextField}
                      placeholder={"Gade"}
                      label="Gade"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      name="postcode"
                      fullWidth
                      component={TextField}
                      placeholder={"Postnummer"}
                      label="Postnummer"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      name="city"
                      fullWidth
                      component={TextField}
                      placeholder={"By"}
                      label="By"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SelectField
                      name="clothingTop"
                      label="Overdel størrelse"
                      options={[
                        { value: "---", label: "---" },
                        { value: "xs", label: "XS" },
                        { value: "s", label: "S" },
                        { value: "m", label: "M" },
                        { value: "l", label: "L" },
                        { value: "xl", label: "XL" },
                        { value: "xxl", label: "XXL" },
                        { value: "xxxl", label: "XXXL" },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SelectField
                      name="clothingBottom"
                      label="Underdel størrelse"
                      options={[
                        { value: "---", label: "---" },
                        { value: "xs", label: "XS" },
                        { value: "s", label: "S" },
                        { value: "m", label: "M" },
                        { value: "l", label: "L" },
                        { value: "xl", label: "XL" },
                        { value: "xxl", label: "XXL" },
                        { value: "xxxl", label: "XXXL" },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      name="clothingRemark"
                      className={classes.textArea}
                      component={TextField}
                      placeholder="Tøj bemærkelse"
                      type="text"
                      multiline
                      fullWidth
                      rows={6}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <GenderField name="gender" label="Køn" />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl style={{ width: "25%" }}>
                      <InputLabel htmlFor="class">
                        Samtale afholdt af
                      </InputLabel>
                      <Field name="interviewer" component={Select} type="text">
                        {/* 
                      We need to check if the interviewer does not exist in the array and then add that option to the select.
                      This can happen if an employee has stopped and has been removed from the array
                      */}
                        {!interviewers.includes(interviewer) ? (
                          <MenuItem value={interviewer}>
                            {_.upperCase(interviewer)}
                          </MenuItem>
                        ) : null}
                        {interviewers.map((i) => (
                          <MenuItem key={i} value={i}>
                            {i.toUpperCase()}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                </Grid>
                {/* End of personal infomation */}
                {/* Date and cehckbox fields for contracts */}
                <Grid item xs={12} container>
                  <Grid item xs={12}>
                    <Typography
                      className={classes.typography}
                      variant="subtitle2">
                      Kontrakt
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CheckboxField name="contractSent" label="Kontrakt sendt" />
                    <DateField
                      name="contractSentDate"
                      label={"Dato for afsendelse"}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CheckboxField
                      name="contractReceived"
                      label="Kontrakt modtaget"
                    />
                    <DateField
                      name={"contractReceivedTimestamp"}
                      label={"Dato for modtagelse"}
                    />
                  </Grid>
                </Grid>
                {/* Checkbox fields */}
                <Grid item xs={12} md={6} container direction="column">
                  <Typography
                    variant="subtitle2"
                    className={classes.typography}>
                    Før studiestart
                  </Typography>
                  <CheckboxField
                    name="admissionFeeReceived"
                    label="Indmeldelsesgebyr modtaget"
                  />
                  <CheckboxField
                    name="confirmationSent"
                    label="Bekræftelse om optagelse sendt"
                  />
                  <CheckboxField
                    name="infoLetter"
                    label="Informationsbrev sendt"
                  />
                  <CheckboxField
                    name="introductionEmail"
                    label="Velkomst email sendt"
                  />
                </Grid>
                <Grid item xs={12} md={6} container direction="column">
                  <Typography
                    variant="subtitle2"
                    className={classes.typography}>
                    Efter studiestart
                  </Typography>
                  <CheckboxField name="lectio" label="Oprettet i Lectio" />
                  <CheckboxField name="createdInSU" label="Oprettet i SU" />
                  <CheckboxField name="studentCard" label="Studiekort" />
                  <CheckboxField
                    name="consentSigned"
                    label="Samtykkeerklæring underskrevet"
                  />
                  <CheckboxField
                    name="rulesSigned"
                    label="Regelsæt underskrevet"
                  />
                  <CheckboxField
                    name="booksIssued"
                    label="Bogpakke udleveret"
                  />

                  <CheckboxField
                    name="workExperienceContract"
                    label="Praktik kontrakt"
                  />
                  <CheckboxField
                    name="workExperienceContract2"
                    label="Praktik kontrakt 2"
                  />
                </Grid>

                <Grid item xs={12} container>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      className={classes.typography}>
                      Betalingsinformation
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CheckboxField
                      name="createdInEconomics"
                      type="checkbox"
                      label="Oprettet i Economics"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="rate"
                      component={TextField}
                      placeholder={"Rate"}
                      label="Rate"
                      type="text"
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} container>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      className={classes.typography}>
                      Studie slut
                    </Typography>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <CheckboxField name="graduated" label="Dimitteret" />
                      <DateField
                        name="graduatedDate"
                        label="Dimitteret dato"
                        disabled={!graduated}
                      />
                    </Grid>
                    <Grid item xs={12} container>
                      <Grid item xs={12} md={6}>
                        <CheckboxField
                          name="discharged"
                          label={"Udmeldt d. "}
                        />
                        <DateField name="dischargedDate" label="Udmeldt d." />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {discharged && (
                          <DischargedSelect
                            type="student"
                            classes={classes}
                            setFieldValue={setFieldValue}
                            disabled={!discharged}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <FieldArray name="subjectsNotPassed">
                      {({ remove, push }) => (
                        <>
                          <FormControl
                            className={classnames(
                              classes.margin,
                              classes.textField
                            )}>
                            <InputLabel htmlFor="subjects-not-passed">
                              Fag Ikke Bestået
                            </InputLabel>
                            <Input
                              id="subjects-not-passed"
                              type="text"
                              value={chipTextValue}
                              onChange={(e) => setChipTextValue(e.target.value)}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="Add subject not passed"
                                    onClick={() => push(chipTextValue)}
                                    onMouseDown={(e) => e.preventDefault()}>
                                    <AddIcon />
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          <ul className={classes.chipList}>
                            {/* subjectsNotPassed may be null in some cases where the student is of a legacy schema */}
                            {subjectsNotPassed &&
                              subjectsNotPassed.map(
                                (s: string, index: number) => {
                                  return (
                                    <li key={s}>
                                      <Chip
                                        className={classes.chip}
                                        label={s}
                                        color="secondary"
                                        onDelete={() => {
                                          remove(index);
                                        }}
                                      />
                                    </li>
                                  );
                                }
                              )}
                          </ul>
                        </>
                      )}
                    </FieldArray>
                  </Grid>
                </Grid>

                <Grid item xs={12} container>
                  <Grid item xs={12} md={6} style={{ padding: 16 }}>
                    <Grid item xs={12}>
                      <Field
                        name="note"
                        className={classes.textArea}
                        component={TextField}
                        placeholder="Note"
                        type="text"
                        multiline
                        fullWidth
                        rows={6}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} style={{ padding: 16 }}>
                    <Grid item xs={12}>
                      <SelectField
                        name="class"
                        label="Hold"
                        options={
                          classNames
                            ? Object.keys(classNames)
                                .filter((cn) => cn !== "discharged")
                                .map((cn) => ({
                                  value: cn,
                                  label: parseClass(cn),
                                }))
                            : []
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectField
                        name="line"
                        label="Vælg linie"
                        options={[
                          { value: "sport", label: "Sports" },
                          { value: "x-outdoor", label: "X-Outdoor" },
                          { value: "outdoor", label: "Friluft" },
                          { value: "discharged", label: "udmeldt" },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectField
                        name="semester"
                        label="Årgang"
                        options={semesters.map((s) => ({
                          value: s,
                          label: s,
                        }))}
                      />
                      {previousSemester && (
                        <Typography variant="caption">{`Rykket fra årgang: ${previousSemester}`}</Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {Object.keys(errors).length > 0 &&
                    Object.values(errors).map((error, i) => (
                      <pre key={`${error}-${i}`} style={{ color: "red" }}>
                        {error}
                      </pre>
                    ))}
                </Grid>
                <Grid item xs={12} container spacing={1}>
                  <Grid item xs={12}>
                    <Button
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                      type="submit">
                      Opdater
                    </Button>
                    <Button
                      variant="contained"
                      disabled={!touched || isSubmitting}
                      onClick={() => resetForm()}>
                      Fortryd
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.buttonWarning}
                      onClick={removeSemester}>
                      Fjern årgang
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </form>
        );
      }}
    </Formik>
  );
};

export default StudentForm;
