import React from "react";
import { moveApplicantToStudents, deleteApplicant } from "./actions/applicants";
import { makeStyles } from "@material-ui/core/styles";
import NoApplicantFound from "./components/NoApplicantFound";
import Form from "./components/Form";
import {
  formatApplicantMessage,
  formatInterviewerMessage,
} from "../helpers/messageFormatter";
import { openSnackbar } from "../actions/snackbar";
import { setRecipients } from "../actions/messages";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { AppState } from "../reducers";
import { IApplicant } from "./Applicant";

const useStyles = makeStyles((theme) => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
}));

const ApplicantContainer: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const { id }: { id: string } = useParams();
  const history = useHistory();
  useFirestoreConnect([`applicants/${id}`, "semesters"]);

  const { applicant, semesters } = useSelector(
    ({ firestore: { data, ordered } }: AppState) => ({
      applicant: data.applicants && data.applicants[id],
      semesters: ordered.semesters || [],
    })
  );

  const handleDeleteApplicant = () => {
    if (window) {
      if (
        window.confirm(`Er du sikker på at du vil slette ${applicant.name}?`)
      ) {
        dispatch(deleteApplicant(id));
      }
    }
  };

  const sendReminder = (reminderType: string) => {
    if (reminderType === "applicant") {
      const recipient = {
        name: applicant.name,
        email: applicant.email,
        phone: applicant.phone,
        collection: "applicants",
        docId: applicant.id,
        status: "not_submitted",
        message: formatApplicantMessage(
          applicant.name,
          applicant.interviewDateString
        ),
      };
      dispatch(setRecipients([recipient]));
    } else if (reminderType === "interviewer") {
      const recipient = {
        name: applicant.interviewer,
        email: `${applicant.interviewer}@ppkbh.dk`,
        phone: "",
        status: "not_submitted",
        message: formatInterviewerMessage(
          applicant.interviewer,
          applicant.name,
          applicant.interviewDateString
        ),
      };
      setRecipients([recipient]);
    }
  };

  const handleMove = () => {
    moveApplicantToStudents(applicant);
  };

  const saveApplicant = async (updated: IApplicant) => {
    try {
      await firestore.collection("applicants").doc(id).update(updated);
      dispatch(openSnackbar(`✅ ${updated.name} opdateret`));
      // Client wan't the applicant to be moved automatically to /students
      // if the contract has been received
      if (updated.contractReceived) {
        dispatch(moveApplicantToStudents(updated));
      } else {
        history.push("/applicants");
      }
    } catch (error: any) {
      dispatch(openSnackbar(`🛑 Kunne ikke gemme ansøger ${error.message}`));
    }
  };

  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12} lg={10} xl={8}>
          <Paper className={classes.root} elevation={6}>
            {applicant ? (
              <Form
                doSubmit={(a: IApplicant) => saveApplicant(a)}
                sendReminder={(reminderType: string) => {
                  sendReminder(reminderType);
                }}
                handleDeleteApplicant={handleDeleteApplicant}
                applicant={applicant}
                moveApplicant={() => handleMove()}
                semesters={semesters}
              />
            ) : (
              <NoApplicantFound />
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ApplicantContainer;
