import React, { Component } from "react";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";

const columnData = [
  { id: "edit", align: "left", disablePadding: true, label: "Rediger" },
  { id: "firstName", align: "left", disablePadding: true, label: "Fornavn" },
  { id: "lastName", align: "left", disablePadding: true, label: "Efternavn" },
  { id: "gender", align: "left", disablePadding: true, label: "Køn" },
  {
    id: "contractReceivedTimestamp",
    align: "left",
    disablePadding: true,
    label: "Kontrakt Modtaget",
  },
  { id: "createdAt", align: "left", disablePadding: true, label: "Oprettet" },
  { id: "updatedAt", align: "left", disablePadding: true, label: "Opdateret" },
];

class StudentsTableHead extends Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {columnData.map((column) => {
            return (
              <TableCell
                key={column.id}
                align={column.align}
                padding={column.disablePadding ? "none" : "default"}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={order}
                  onClick={this.createSortHandler(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

StudentsTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default StudentsTableHead;
