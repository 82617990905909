import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import DeleteIcon from "@material-ui/icons/Delete";
import NewIcon from "@material-ui/icons/Add";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import ListAltIcon from "@material-ui/icons/ListAlt";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { Divider } from "@material-ui/core";

import SubjectsIcon from "./SubjectsIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 30,
  },
  cardRoot: {
    width: "100%",

    borderRadius: 10,
    boxShadow: "rgba(0,0,0,0.05) 1px 1px 10px 10px",
  },
  cardHeader: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "10 10 0 0",
    position: "relative",
  },
  cardContent: {
    padding: 10,
  },

  dischargeSelect: {
    padding: theme.spacing(2),
    minWidth: 300,
  },
  typography: { fontWeight: 600 },
  select: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  radio: {
    marginTop: theme.spacing(2),
  },
}));

type HeaderProps = {
  selection: Subject[];
  newSubject: () => void;
  deleteSubjects: (ids: string[]) => void;
  // filters: Filters;
  // setFilters: (filters: Filters) => void;
  semesters: { title: string }[];
  semester: string;
  setSemester: (semester: string) => void;
};

const Header: React.FC<HeaderProps> = ({
  selection,
  newSubject,
  deleteSubjects,
  semester,
  setSemester,
  semesters,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.cardRoot}>
            <CardHeader className={classes.cardHeader} title={"Fag"} />

            <CardContent className={classes.cardContent}>
              <Grid item xs={12} container direction="row" alignItems="center">
                <Grid item xs={12} md={3}>
                  <div style={{ textAlign: "center" }}>
                    <SubjectsIcon />
                  </div>
                </Grid>

                <Divider variant="middle" orientation="vertical" flexItem />
                <Grid item xs={12} md={2}>
                  <ButtonGroup
                    size="medium"
                    color="primary"
                    orientation="vertical"
                  >
                    <Button
                      className={classes.button}
                      variant="contained"
                      disableElevation
                      endIcon={<NewIcon />}
                      onClick={() => newSubject()}
                    >
                      Nyt fag
                    </Button>
                    <Button
                      className={classes.button}
                      endIcon={<ListAltIcon />}
                      onClick={() => console.log("Not implemented")}
                      disabled={selection.length < 1}
                    >
                      Excel udtræk
                    </Button>
                    <Button
                      className={classes.button}
                      endIcon={<DeleteIcon />}
                      onClick={() => deleteSubjects(selection.map((s) => s.id))}
                      disabled={selection.length < 1}
                      color="secondary"
                    >
                      Slet fag
                    </Button>
                  </ButtonGroup>
                </Grid>
                <Divider variant="middle" orientation="vertical" flexItem />
                <Divider variant="middle" orientation="vertical" flexItem />
                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <Typography variant="body1" style={{ marginTop: 16 }}>
                      Vis fag for given årgang
                    </Typography>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="semester-select-label">
                        Semester
                      </InputLabel>
                      <Select
                        labelId="semester-select-label"
                        value={semester}
                        onChange={(e) => setSemester(e.target.value as string)}
                      >
                        <MenuItem value={""}>Vælg</MenuItem>
                        {semesters.map((semester: { title: string }) => (
                          <MenuItem value={semester.title} key={semester.title}>
                            {semester.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      color="primary"
                      onClick={() =>
                        // If the query is already set, reset it. Otherwise set it
                        // setQuery(
                        //   query
                        //     ? null
                        //     : {
                        //         line,
                        //         semester,
                        //         subject,
                        //       }
                        // )
                        console.log("not implemented")
                      }
                    >
                      {/* {query ? "Ryd" : "Vis"} */}
                    </Button>
                  </FormGroup>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Header;
