import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { timeAgo } from "../../helpers/timeAgo";
import PropTypes from "prop-types";
import classnames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import blue from "@material-ui/core/colors/blue";

const styles = theme => ({
  card: {
    maxWidth: 400,
    padding: 0,
    overflow: "initial"
  },
  cardUnread: {
    backgroundColor: blue[100]
  },
  cardRead: {
    backgroundColor: "inherit",
    transition: theme.transitions.create("background-color", {
      duration: theme.transitions.duration.shorter
    })
  },
  avatar: {}
});

const NotificationCardComponent = withRouter(
  ({ history, notification, classes, notificationRead, clearNotification }) => (
    <Card
      className={classnames(classes.card, {
        [classes.cardUnread]: !notification.read,
        [classes.cardRead]: notification.read
      })}
    >
      <CardHeader
        avatar={
          <Avatar aria-label="applicant" className={classes.avatar}>
            {notification.type.toUpperCase().match(/\b(\w)/g)}
          </Avatar>
        }
        title={notification.title}
        subheader={timeAgo(notification.created_at)}
      />

      <CardContent>
        <Typography component="p">{notification.message}</Typography>
      </CardContent>
      <CardActions disableSpacing>
        {notification.read ? (
          <IconButton onClick={() => clearNotification([notification.id])}>
            <ClearIcon />
          </IconButton>
        ) : (
          <IconButton onClick={() => notificationRead([notification.id])}>
            <DoneIcon />
          </IconButton>
        )}
        <Button
          color="primary"
          onClick={() => {
            if (!notification.read) {
              notificationRead([notification.id]);
            }
            notification.isDownload
              ? window.open(notification.link, "_blank")
              : history.push(notification.link);
          }}
        >
          {notification.actionTitle || "Gå"}
        </Button>
      </CardActions>
    </Card>
  )
);

NotificationCardComponent.propTypes = {
  notification: PropTypes.object.isRequired,
  notificationRead: PropTypes.func.isRequired,
  clearNotification: PropTypes.func.isRequired
};

export default withStyles(styles)(NotificationCardComponent);
