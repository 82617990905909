import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { deleteApplicants } from "./actions/applicants";
import { setRecipients } from "../actions/messages";
import ApplicantsTable from "./components/ApplicantsTable";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Divider, Select, MenuItem } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { ApplicantsIcon } from "../components/Icons";
import queries from "../helpers/applicantQueries";
import DeleteIcon from "@material-ui/icons/Delete";
import Message from "@material-ui/icons/Message";
import NewIcon from "@material-ui/icons/Add";
import NewApplicantDialog from "./components/NewApplicantDialog";
import { IApplicant } from "./Applicant";
import { Query } from "../helpers/applicantQueries";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 30,
  },
  cardRoot: {
    width: "100%",

    borderRadius: 10,
    boxShadow: "rgba(0,0,0,0.05) 1px 1px 10px 10px",
  },
  cardHeader: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "10 10 0 0",
    position: "relative",
  },
  cardContent: {
    padding: 10,
  },

  dischargeSelect: {
    padding: theme.spacing(2),
    minWidth: 300,
  },
  typography: { fontWeight: 600 },
  select: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

type Selection = IApplicant[];

const ApplicantsContainer: React.FC = () => {
  const classes = useStyles();

  const [selection, setSelection] = useState<Selection>([]);

  const [dialogOpen, setDialogOpen] = useState(false);

  const storage = window.localStorage.getItem("applicants-query");
  const initial = storage ? JSON.parse(storage) : null;
  const [query, setQuery] = useState<{
    name: string;
    id: string;
    where: Query;
  }>(initial ? initial.query : queries[0]);
  const [startYear, setStartYear] = useState<string>(
    initial ? initial.startYear : "2020"
  );

  const reduxDispatch = useDispatch();
  // Use useCallback here so we can memoize the table component in order to prevent
  // rerenders that triggers an infinite loop
  const selectionCallback = React.useCallback(
    (ids) => setSelection(ids),
    [setSelection]
  );

  useEffect(() => {
    window.localStorage.setItem(
      "applicants-query",
      JSON.stringify({ query, startYear })
    );
  }, [query, startYear]);

  const sendMessage = () => {
    // Remove any unnecessary props
    const recipients = selection
      .map((recipient) => {
        return _.pick(recipient, ["name", "phone", "email", "id"]);
      })
      .map((recipient) => {
        return {
          ...recipient,
          collection: "applicants",
          docId: recipient.id,
          status: "not_submitted",
        };
      });

    // And send the recipients to the redux store, this will automatically change the route to /messages as well
    // this.props.setRecipients(recipientsArray);
    reduxDispatch(setRecipients(recipients));
  };

  const handleDeleteApplicants = () => {
    if (
      window.confirm(
        `Er du sikker på du vil slette ${selection.length} ansøger(e)`
      )
    ) {
      // Map the array of applicants to ids
      reduxDispatch(deleteApplicants(selection.map((a) => a.id)));
    }
  };

  const handleStartYearChange = (startYear: string) => {
    setStartYear(startYear);
  };

  const handleSetQuery = (value: string) => {
    const newQuery = queries.filter((q) => q.id === value)[0];
    setQuery(newQuery);
  };

  return (
    <div>
      <NewApplicantDialog open={dialogOpen} setOpen={setDialogOpen} />
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card className={classes.cardRoot}>
              <CardHeader className={classes.cardHeader} title={"Ansøgere"} />

              <CardContent className={classes.cardContent}>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={6}
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={12} md={2}>
                    <div style={{ textAlign: "center" }}>
                      <ApplicantsIcon size={200} />
                    </div>
                  </Grid>

                  <Divider variant="middle" orientation="vertical" flexItem />

                  <Grid item xs={12} md={2}>
                    <Typography className={classes.typography} variant="body1">
                      Filtrer ansøgere:
                    </Typography>
                    <Select
                      className={classes.select}
                      onChange={(e) => handleSetQuery(e.target.value as string)}
                      value={query.id}
                    >
                      {queries.map((query) => {
                        return (
                          <MenuItem key={query.id} value={query.id}>
                            {query.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <Select
                      style={{ marginRight: 16 }}
                      className={classes.select}
                      value={startYear}
                      onChange={(e) =>
                        handleStartYearChange(e.target.value as string)
                      }
                      label={"Studiestart"}
                      inputProps={{
                        name: "startYear",
                        id: "startYear",
                      }}
                    >
                      <MenuItem value={"2019"}>Studiestart 2019</MenuItem>
                      <MenuItem value={"2020"}>Studiestart 2020</MenuItem>
                      <MenuItem value={"2021"}>Studiestart 2021</MenuItem>
                      <MenuItem value={"2022"}>Studiestart 2022</MenuItem>
                      <MenuItem value={"2023"}>Studiestart 2023</MenuItem>
                      <MenuItem value={"2024"}>Studiestart 2024</MenuItem>
                      <MenuItem value={"2025"}>Studiestart 2025</MenuItem>
                      <MenuItem value={"2026"}>Studiestart 2026</MenuItem>
                    </Select>
                  </Grid>
                  <Divider variant="middle" orientation="vertical" flexItem />
                  <Grid item xs={12} md={3}>
                    <ButtonGroup
                      size="medium"
                      color="primary"
                      orientation="vertical"
                    >
                      <Button
                        className={classes.button}
                        variant="contained"
                        disableElevation
                        endIcon={<NewIcon />}
                        onClick={() => setDialogOpen(true)}
                      >
                        Ny Ansøger
                      </Button>
                      <Button
                        className={classes.button}
                        endIcon={<Message />}
                        onClick={() => sendMessage()}
                        disabled={selection.length < 1}
                      >
                        Send beskeder
                      </Button>
                      <Button
                        className={classes.button}
                        endIcon={<DeleteIcon />}
                        onClick={() => handleDeleteApplicants()}
                        disabled={selection.length < 1}
                      >
                        Slet
                      </Button>
                    </ButtonGroup>
                  </Grid>
                  <Divider variant="middle" orientation="vertical" flexItem />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={12}>
            <ApplicantsTable
              query={query.where}
              startYear={startYear}
              setSelection={selectionCallback}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ApplicantsContainer;
