import {
  SUBMITTING_SHEET,
  SUBMIT_SHEET_SUCCESS,
  SUBMIT_SHEET_ERROR,
  CREATING_TRAVEL_INFO_SHEET,
  CREATE_TRAVEL_INFO_SHEET_SUCCESS,
  CREATE_TRAVEL_INFO_SHEET_ERROR,
  CREATING_CONTACTS_SHEET,
  CREATE_CONTACTS_SHEET_SUCCESS,
  CREATE_CONTACTS_SHEET_ERROR,
  CREATING_EXAMINERS_SHEET,
  CREATE_EXAMINERS_SHEET_SUCCESS,
  CREATE_EXAMINERS_SHEET_ERROR,
} from "../constants";
import { db } from "../helpers/firebaseHelper";
import { openSnackbar } from "./snackbar";
import { parseDate } from "../helpers/parser";
import * as _ from "lodash";
import { returnStatement } from "@babel/types";

export function submitSheet(values, items, translations) {
  return async (dispatch, getState) => {
    dispatch({ type: SUBMITTING_SHEET });
    let { title, fields } = values;
    // Remove any false values
    fields = Object.keys(fields).filter((key) => fields[key]);
    // Get translated values for fields submitted. The translations has to be the first item in the object we submit
    const translatedFields = fields.map((key) => translations[key]);
    const data = {
      0: translatedFields,
    };
    // Get the students from the current state since they are already loaded
    const students = getState().students.students.filter((s) =>
      items.includes(s.id)
    );

    let i = 1;
    //eslint-disable-next-line no-unused-vars
    for (const s of students) {
      const student = [];
      //eslint-disable-next-line no-unused-vars
      for (let key of fields) {
        // If images are included make sure they adhere to the =IMAGE formula
        // It's a pain to implement server side with the API so use this dumb implementation
        if (key === "imageUrl") {
          const imageUrl = `=IMAGE("${s[key]}")`;
          student.push(imageUrl);
          continue;
        }
        // If the contractReceived field is ticked, make sure to include the date it was received as well
        if (key === "contractReceived") {
          const contractReceived = s[key]
            ? `Ja (${parseDate(s.contractReceivedTimestamp, "L")})`
            : "Nej";
          student.push(contractReceived);
          continue;
        }
        // Convert any true / false to danish
        if (typeof s[key] === "boolean") {
          const val = s[key] ? "Ja" : "Nej";
          student.push(val);
          continue;
        }

        // s[key] might still be null, so we have to check for it before we append
        // it to the array
        if (s[key]) {
          student.push(s[key]);
        } else {
          student.push(null);
        }
      }
      data[i] = student;
      i++;
    }
    dispatch(submit(title, data, false));
  };
}

export function createTravelInfoSheet(ids) {
  return async (dispatch, getState) => {
    dispatch({ type: CREATING_TRAVEL_INFO_SHEET });
    // Extract all the students who's ids where chosen
    const students = getState().students.students.filter((s) =>
      ids.includes(s.id)
    );

    // Define the translations for the accident and safety information
    const translations = [
      "Navn",
      "Ulykke firma",
      "Ulykke police nr.",
      "Indbo firma",
      "Indbo police nr.",
      "Rejse firma",
      "Rejse police nr.",
      "Pas nummer",
      "Pas udløbsdato",
      "Pas navn",
      "Pas nationalitet",
    ];
    const data = {
      0: translations,
    };

    // Define the fields for the accident and safety information
    let fields = {
      accident: true,
      accidentPolicyNumber: true,
      home: true,
      homePolicyNumber: true,
      travel: true,
      travelPolicyNumber: true,
      passportNumber: true,
      passportExpiry: true,
      passportName: true,
      passportNationality: true,
    };
    fields = Object.keys(fields);

    try {
      let i = 1;
      //eslint-disable-next-line no-unused-vars

      for (let s of students) {
        const entry = [];
        const safetyInfoRef = db
          .collection("students")
          .doc(s.id)
          .collection("additionalInformation")
          .doc("safetyInformation");
        const safetyInfo = await safetyInfoRef.get();
        const name = `${s.firstName} ${s.lastName}`;
        entry.push(name);

        let info = {};
        if (safetyInfo.exists) {
          info = safetyInfo.data();
        }
        //eslint-disable-next-line no-unused-vars
        for (let key of fields) {
          if (info[key]) {
            entry.push(info[key]);
          } else {
            entry.push("MANGLER");
          }
        }
        data[i] = entry;
        i++;
      }

      dispatch(submit("Rejse info", data));
      dispatch({ type: CREATE_TRAVEL_INFO_SHEET_SUCCESS });
    } catch (e) {
      console.error(e);
      dispatch({ type: CREATE_TRAVEL_INFO_SHEET_ERROR, error: e });
    }
  };
}

export function createContactsSheet(ids, isExternal) {
  return async (dispatch, getState) => {
    dispatch({ type: CREATING_CONTACTS_SHEET });
    const contacts = getState().contacts.contacts.filter((c) =>
      ids.includes(c.id)
    );

    let translations = [
      "Adresse",
      "By",
      "Navn",
      isExternal ? null : "Efternavn",
      "Telefon",
      "Email",
      "Titel",
    ];

    translations = _.reject(translations, _.isNull);

    const data = {
      0: translations,
    };

    let fields = {
      address: true,
      city: true,
      [isExternal ? "name" : "firstName"]: true,
      lastName: !isExternal,
      phone: true,
      email: true,
      title: true,
    };

    fields = Object.keys(fields).filter((f) => fields[f]);

    try {
      let i = 1;
      //eslint-disable-next-line no-unused-vars
      for (let contact of contacts) {
        const entry = [];
        //eslint-disable-next-line no-unused-vars
        for (let key of fields) {
          if (contact[key]) {
            entry.push(contact[key]);
          } else {
            entry.push(null);
          }
        }
        data[i] = entry;
        i++;
      }
      dispatch(submit("Kontakter", data));
      dispatch({ type: CREATE_CONTACTS_SHEET_SUCCESS });
    } catch (error) {
      dispatch(openSnackbar(`Kunne ikke oprette spreadsheet: ${error}`));
      dispatch({ type: CREATE_CONTACTS_SHEET_ERROR, error: error });
    }
  };
}

export function createExaminersSheet(examiners) {
  return async (dispatch) => {
    dispatch({ type: CREATING_EXAMINERS_SHEET });

    let translations = [
      "Fornavn",
      "Efternavn",
      "Telefon",
      "Email",
      "Godkendt",
      "Godkendt til",
      "Løninformation Videresendt",
      "Censor indberetning",
    ];

    // translations = _.reject(translations, _.isNull);

    const data = {
      0: translations,
    };

    let fields = {
      firstName: true,
      lastName: true,
      phone: true,
      email: true,
      approved: true,
      approvedUntil: true,
      paymentInformationForwarded: true,
      examinerReport: true,
    };

    fields = Object.keys(fields).filter((f) => fields[f]);

    try {
      let i = 1;
      //eslint-disable-next-line no-unused-vars
      for (let examiner of examiners) {
        const entry = [];
        //eslint-disable-next-line no-unused-vars
        for (let key of fields) {
          if (examiner[key]) {
            entry.push(examiner[key]);
          } else {
            entry.push(null);
          }
        }
        data[i] = entry;
        i++;
      }
      dispatch(submit("Censorer", data));
      dispatch(openSnackbar(`✅ Opretter spreadsheet`));
      dispatch({ type: CREATE_EXAMINERS_SHEET_SUCCESS });
    } catch (error) {
      dispatch(openSnackbar(`🛑 Kunne ikke oprette spreadsheet: ${error}`));
      dispatch({ type: CREATE_EXAMINERS_SHEET_ERROR, error: error });
    }
  };
}

export const submit = (title, data, openWithDialogue = true) => {
  return async (dispatch) => {
    try {
      const doc = db.collection("sheets").doc();
      console.log(data);
      await doc.set({ title, data });
      const unsubscribe = doc.onSnapshot((doc) => {
        if (!doc.exists) return;

        if (doc.data().sheetURL) {
          dispatch({
            type: SUBMIT_SHEET_SUCCESS,
            sheetURL: doc.data().sheetURL,
          });
          console.log(doc.data());
          unsubscribe();
          // TODO
          // Hardcode for now, needs better solution
          if (window && openWithDialogue) {
            if (window.confirm("Åben excel ark?")) {
              window.open(doc.data().sheetURL, "_blank");
            }
          }
        }
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: SUBMIT_SHEET_ERROR, error: e });
    }
  };
};
