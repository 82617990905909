import React from "react";
import { useFirestore } from "react-redux-firebase";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import useSemesters from "../hooks/useSemesters";
import SubjectForm from "./Components/SubjectForm";
import { openSnackbar } from "../actions/snackbar";
import useSubject from "../hooks/useSubject";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const SubjectComponent: React.FC = () => {
  const { id: subjectID }: { id: string } = useParams();
  const semesters = useSemesters();
  const classes = useStyles();
  const firestore = useFirestore();
  const dispatch = useDispatch();

  const subject = useSubject({ subjectID });

  const onSubmit = (values: Subject): Promise<void> =>
    firestore
      .collection("subjects")
      .doc(subjectID)
      .update({ ...values });

  const assignStudents = async (ids: string[]) => {
    // Add a student to the 'students' prop of the subject. Then add the subject to the students exam subjects as well
    const subjectRef = firestore.collection("subjects").doc(subjectID);
    const studentsCollection = firestore.collection("students");

    const batch = firestore.batch();
    try {
      batch.update(subjectRef, {
        students: firestore.FieldValue.arrayUnion(...ids),
      });
      for (const id of ids) {
        batch.update(studentsCollection.doc(id), {
          examSubjects: firestore.FieldValue.arrayUnion(subjectID),
        });
      }
      await batch.commit();
      dispatch(
        openSnackbar(
          `✅ Tildelte ${ids.length} studerende eksamensfaget ${subject?.name}`
        )
      );
    } catch (error: any) {
      dispatch(openSnackbar(`🛑 ${error.message}`));
      console.error(error);
    }
  };

  const unassignStudents = async (ids: string[]) => {
    // Add a student to the 'students' prop of the subject. Then add the subject to the students exam subjects as well
    const subjectRef = firestore.collection("subjects").doc(subjectID);
    const studentsCollection = firestore.collection("students");

    const batch = firestore.batch();
    try {
      batch.update(subjectRef, {
        students: firestore.FieldValue.arrayRemove(...ids),
      });
      for (const id of ids) {
        batch.update(studentsCollection.doc(id), {
          examSubjects: firestore.FieldValue.arrayRemove(subjectID),
        });
      }
      await batch.commit();
      dispatch(
        openSnackbar(
          `✅ Fjernede eksamensfaget ${subject?.name} fra ${ids.length} studerende`
        )
      );
    } catch (error: any) {
      dispatch(openSnackbar(`🛑 ${error.message}`));
      console.error(error);
    }
  };

  return (
    <Grid container direction="row" justify="center">
      <Grid item xs={12} md={10}>
        <Paper className={classes.root} elevation={4}>
          {subject && (
            <SubjectForm
              initialValues={subject}
              onSubmit={onSubmit}
              semesters={semesters}
              isCreate={false}
              assignStudents={assignStudents}
              unassignStudents={unassignStudents}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SubjectComponent;
