import React, { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";

type Hook = (semester?: string) => ChartEntry[];

const useStatistics: Hook = (semester) => {
  const [statistics, setStatistics] = useState<ChartEntry[]>([]);
  const firestore = useFirestore();

  useEffect(() => {
    async function loadSemesters() {
      if (!semester) return [];
      const statistics = await firestore
        .collection("graph_data")
        .doc(semester)
        .collection("months")
        .get();
      const data = statistics.docs
        .filter((doc) => doc.exists)
        .map((doc) => doc.data() as ChartEntry)
        .sort((a: ChartEntry, b: ChartEntry) => a.date - b.date);
      setStatistics(data);
    }
    loadSemesters();
  }, [semester]);

  return statistics;
};

export default useStatistics;
