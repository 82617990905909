import React, { Component } from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { parseLine } from "../../helpers/parser";
import { lines } from "../../helpers/lines";
import { connect } from "react-redux";
import { setTabValue } from "../../actions/students";

class StudentsAppBar extends Component {
  state = {};

  componentDidMount() {
    if (lines[0]) {
      // Set the line on first load
      this.props.handleLineChange(lines[0]);
    }
  }

  handleSetValue = (event, value) => {
    this.props.setTabValue(value);
  };
  render() {
    const { tabValue, handleLineChange } = this.props;
    return (
      <Tabs
        value={tabValue || 0}
        onChange={this.handleSetValue}
        indicatorColor="primary"
        textColor="primary"
        scrollButtons="auto"
        centered
      >
        {lines.map((line) => {
          return (
            <Tab
              key={line}
              label={parseLine(line)}
              onClick={() => handleLineChange(line)}
            />
          );
        })}
      </Tabs>
    );
  }
}

StudentsAppBar.propTypes = {};

const mapStateToProps = ({ students }) => {
  const { tabValue } = students;
  return {
    tabValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTabValue: (value) => dispatch(setTabValue(value)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentsAppBar);
