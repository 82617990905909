import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell, Sector } from "recharts";
import { pieChartData } from "../../helpers/statisticsCalculator";
import renderActiveShape from "./renderActiveShape";

type ChartProps = { data: ChartEntry[] };
const StudentsPieChart: React.FC<ChartProps> = ({ data }) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const colors: { [key: string]: string } = {
    sport: "#9C1918",
    outdoor: "#5B5A37",
    "x-outdoor": "#364B55",
  };
  const onPieEnter = (data: any, index: number) => {
    setActiveIndex(index);
  };

  const total = pieChartData(data).reduce((acc: any, data: any) => {
    return acc + data["value"];
  }, 0);

  return (
    <ResponsiveContainer width={"100%"} height="100%">
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={(props) =>
            renderActiveShape(props, total, colors["total"])
          }
          data={pieChartData(data)}
          dataKey="value"
          nameKey="name"
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          onMouseEnter={onPieEnter}
        >
          {pieChartData(data).map((entry: any) => (
            <Cell key={entry} fill={colors[entry.nameKey]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default StudentsPieChart;
