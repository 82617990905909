import { db } from '../helpers/firebaseHelper';
import {
  FETCHING_CLASS_TEMPLATES,
  FETCH_CLASS_TEMPLATES_SUCCESS,
  FETCH_CLASS_TEMPLATES_ERROR,
  CREATING_NEW_CLASS,
  CREATE_NEW_CLASS_SUCCESS,
  CREATE_NEW_CLASS_ERROR
} from '../constants';

export function fetchingClassTemplates() {
  return {
    type: FETCHING_CLASS_TEMPLATES
  };
}

export function fetchClassTemplatesSuccess(classes) {
  return {
    type: FETCH_CLASS_TEMPLATES_SUCCESS,
    classes: classes
  };
}

export function fetchClassTemplatesError(e) {
  return {
    type: FETCH_CLASS_TEMPLATES_ERROR,
    error: e
  };
}

export function creatingClass() {
  return {
    type: CREATING_NEW_CLASS
  };
}

export function createClassSuccess() {
  return {
    type: CREATE_NEW_CLASS_SUCCESS
  };
}
export function createClassError(e) {
  return {
    type: CREATE_NEW_CLASS_ERROR,
    error: e
  };
}

export function fetchClassTemplates() {
  return dispatch => {
    dispatch(fetchingClassTemplates());
    db
      .collection('templates')
      .doc('classes')
      .onSnapshot(
        docs => {
          if (!docs.exists) {
            dispatch(
              fetchClassTemplatesError({
                error: 'Kunne ikke finde skabeloner til hold'
              })
            );
            return;
          }
          dispatch(fetchClassTemplatesSuccess(docs.data()));
        },
        error => dispatch(fetchClassTemplatesError(error))
      );
  };
}

export function createClass(classTemplate) {
  return async dispatch => {
    dispatch(creatingClass());
    try {
      await db
        .collection('templates')
        .doc('classes')
        .set(classTemplate, { merge: true });
      dispatch(createClassSuccess());
    } catch (e) {
      dispatch(createClassError(e));
    }
  };
}
