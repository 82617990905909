import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

const styles = (theme) => ({
  root: { maxHeight: 800 },
});

class ArchivedSemesterDialog extends React.Component {
  constructor(props) {
    super();
    this.state = {
      value: props.value,
    };
  }

  handleCancel = () => {
    this.props.onClose();
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { semesters, history, classes, onClose, open } = this.props;

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        className={classes.root}
        aria-labelledby="confirmation-dialog-title"
        onClose={onClose}
        open={open}
      >
        <DialogTitle id="confirmation-dialog-title">Vælg Semester</DialogTitle>
        <DialogContent>
          <List component="nav">
            {semesters.map((semester) => (
              <ListItem
                button
                key={semester.id}
                onClick={() => {
                  onClose();
                  history.push(`/semesters/${semester.title}`);
                }}
              >
                <ListItemText primary={semester} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
            Luk
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ArchivedSemesterDialog.propTypes = {
  onClose: PropTypes.func,
  value: PropTypes.string,
};

export default withRouter(withStyles(styles)(ArchivedSemesterDialog));
