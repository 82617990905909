import {
  CREATING_CERTIFICATE_REQUEST,
  CREATE_CERTIFICATE_REQUEST_SUCCESS,
  CREATE_CERTIFICATE_REQUEST_ERROR,
  TOGGLE_MODAL,
} from "../constants";

const initialState = {
  creating: false,
  success: false,
  status: "",
  ids: [],
  error: "",
  isOpen: false,
};

const certificates = (state = initialState, action) => {
  switch (action.type) {
    case CREATING_CERTIFICATE_REQUEST:
      return { ...state, creating: true, status: action.status };
    case CREATE_CERTIFICATE_REQUEST_SUCCESS:
      return {
        ...state,
        creating: false,
        success: true,
        ids: [],
        status: action.status,
      };
    case CREATE_CERTIFICATE_REQUEST_ERROR:
      return {
        ...state,
        creating: false,
        error: action.error,
        success: false,
        status: action.status,
      };
    case TOGGLE_MODAL:
      // If the modal gets closed, reset the ids
      return {
        ...state,
        isOpen: action.value,
        ids: action.value ? action.ids : [],
      };
    default:
      return state;
  }
};

export default certificates;
