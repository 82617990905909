import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

type ChartData = {
  name: string;
  attendance: number;
  attending: number;
  not_attending: number;
  unconfirmed: number;
  completed: boolean;
  interviews: number;
  interviews_sport: number;
  interviews_outdoor: number;
  interviews_xoutdoor: number;
  facebook_interested: number;
  facebook_participants: number;
};

type ChartProps = {
  data: ChartData[];
};

type TooltipProps = {
  active: boolean;
  payload: any;
  label: string;
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload) {
    const {
      attendance,
      attending,
      not_attending,
      unconfirmed,
      completed,
      interviews,
      interviews_sport,
      interviews_outdoor,
      interviews_xoutdoor,
      facebook_interested,
      facebook_participants,
    } = payload[0].payload;
    return (
      <div style={{ background: "rgba(0,0,0,0.6)", color: "#fff", padding: 8 }}>
        <p style={{ fontWeight: 900 }} className="label">{`${label}`}</p>
        {completed ? (
          <>
            <p className="intro">{`Mødte op: ${attendance}`}</p>
            <p className="intro">{`Deltager: ${attending}`}</p>
            <p className="intro">{`Deltager Ikke: ${not_attending}`}</p>
            <p className="intro">{`Ubesvaret: ${unconfirmed}`}</p>
            <p className="intro">{`Samtaler Aftalt: ${interviews}`}</p>
            <p className="intro">{`Samtaler Sport: ${interviews_sport}`}</p>
            <p className="intro">{`Samtaler Friluft: ${interviews_outdoor}`}</p>
            <p className="intro">{`Samtaler X-Outdoor: ${interviews_xoutdoor}`}</p>
            <p className="intro">{`Facebook Interesserede: ${facebook_interested}`}</p>
            <p className="intro">{`Facebook Deltagere: ${facebook_participants}`}</p>
          </>
        ) : (
          <p>Ej afviklet</p>
        )}
      </div>
    );
  }

  return null;
};

const EventStatsLineChart: React.FC<ChartProps> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line
          name="Mødte op"
          type="monotone"
          dataKey="attendance"
          stroke="#4caf50"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default EventStatsLineChart;
