import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  root: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  button: { marginLeft: theme.spacing(2) }
});
const ExamsComponent = ({ classes }) => (
  <Grid container>
    <Grid item xs={12} md={8}>
      <Typography variant="h5">Eksamensresultater</Typography>
      <Typography>{`
          Her kan du oprette eksamens fag og tildele karakterer til studerende for en given årgang og hold
        `}</Typography>
    </Grid>
    <Grid item xs={12} md={8} />
  </Grid>
);

export default withStyles(styles)(ExamsComponent);
