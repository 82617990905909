import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import useSubjects from "../hooks/useSubjects";
import useSemesters from "../hooks/useSemesters";
import MenuItem from "@material-ui/core/MenuItem";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
import AssignExamResultsComponent from "./AssignExamResultsComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  radio: {
    marginTop: theme.spacing(2),
  },
  select: {
    minWidth: 200,
  },
}));

const ExamResultsComponent: React.FC = () => {
  const [query, setQuery] = useState({ line: "sport", semester: "2022-2023" });
  const [subject, setSubject] = useState<string>("");
  const subjects = useSubjects({ setQuery, ...query });
  const semesters = useSemesters();
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Paper className={classes.root} elevation={4}>
          <Grid item container justify="center" alignItems="center" xs={12}>
            <Typography variant="body1">
              Vælg først linie og semester nedenfor. Derefter vælg det fag du
              vil give karakterer for.
            </Typography>
          </Grid>
          <Grid item container justify="center" alignItems="center" xs={12}>
            <FormControl
              component="fieldset"
              className={classes.radio}
              style={{ marginRight: 16 }}
            >
              <FormLabel component="legend">Linie</FormLabel>
              <RadioGroup
                id="line-select"
                row
                name="line"
                value={query.line}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setQuery({
                    line: e.target.value,
                    semester: query.semester,
                  })
                }
              >
                <FormControlLabel
                  value="sport"
                  control={<Radio />}
                  label={"Sports"}
                />
                <FormControlLabel
                  value="xOutdoor"
                  control={<Radio />}
                  label={"X-Outdoor"}
                />
                <FormControlLabel
                  value="outdoor"
                  control={<Radio />}
                  label={"Friluft"}
                />
              </RadioGroup>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="semester-select">Semester</InputLabel>
              <Select
                id="semester-select"
                style={{ marginRight: 16 }}
                className={classes.select}
                value={query.semester}
                onChange={(e) => {
                  setQuery({
                    semester: e.target.value as string,
                    line: query.line,
                  });
                }}
              >
                {semesters.map((semester) => (
                  <MenuItem value={semester.title} key={semester.title}>
                    {semester.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="subject-select">Fag</InputLabel>
              <Select
                id="subject-select"
                style={{ marginRight: 16 }}
                label="Fag"
                className={classes.select}
                value={subject}
                disabled={subjects.length === 0}
                onChange={(e) => setSubject(e.target.value as string)}
              >
                <MenuItem value={""}>Vælg</MenuItem>
                {subjects.map((subject) => (
                  <MenuItem value={subject.id} key={subject.id}>
                    {subject.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.root} elevation={4}>
          <AssignExamResultsComponent subjectID={subject || ""} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ExamResultsComponent;
