import React from "react";
import Grid from "@material-ui/core/Grid";
import { CheckboxField } from "../../components";
import { TextField } from "formik-material-ui";
import { Field } from "formik";

const EquipmentForm: React.FC = () => {
  return (
    <>
      <Grid item xs={4}>
        <CheckboxField
          name="equipment.computer"
          type="checkbox"
          label={"Computer"}
        />
        <CheckboxField
          name="equipment.computerLoanAgreementSigned"
          type="checkbox"
          style={{ paddingLeft: 8 }}
          label={"Låneaftale underskrevet"}
        />
      </Grid>
      <Grid item xs={8}>
        <Field
          name="equipment.computerDescription"
          fullWidth
          component={TextField}
          placeholder={"eks. MacBook Pro 2018"}
          type="text"
        />
      </Grid>
      <Grid item xs={4}>
        <CheckboxField
          name="equipment.phone"
          type="checkbox"
          label={"Telefon"}
        />
        <CheckboxField
          name="equipment.phoneLoanAgreementSigned"
          type="checkbox"
          style={{ paddingLeft: 8 }}
          label={"Låneaftale underskrevet"}
        />
      </Grid>
      <Grid item xs={8}>
        <Field
          name="equipment.phoneDescription"
          fullWidth
          component={TextField}
          placeholder={"eks. iPhone 7"}
          type="text"
        />
      </Grid>
      <Grid item xs={12}>
        <CheckboxField
          name="employeeHandbookSigned"
          type="checkbox"
          label={"Medarbejderhåndbog underskrevet"}
        />
        <CheckboxField
          name="teachersHandbookSigned"
          type="checkbox"
          label={"Underviserhåndbog underskrevet"}
        />
      </Grid>
    </>
  );
};

export default EquipmentForm;
