import { useState, useEffect } from "react";
import { useFirestore } from "react-redux-firebase";

type Props = {
  subjectID: string;
};
export default function ({ subjectID }: Props): Subject | null {
  const [subject, setSubject] = useState<Subject | null>(null);
  const firestore = useFirestore();

  useEffect(() => {
    async function loadSubject() {
      if (subjectID === "") return null;

      const unsubscribe = firestore
        .collection("subjects")
        .doc(subjectID)
        .onSnapshot(
          (snap) => {
            if (snap.exists) {
              setSubject(snap.data() as Subject);
            }
          },
          (error) => {
            console.error(error);
          }
        );

      return () => unsubscribe();
    }
    loadSubject();
  }, [subjectID]);
  return subject;
}
