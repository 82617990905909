import {
  FETCHING_CONTACTS,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_ERROR,
  CREATING_PRIVATE_CONTACT,
  CREATE_PRIVATE_CONTACT_SUCCESS,
  CREATE_PRIVATE_CONTACT_ERROR,
  FETCHING_CONTACT,
  FETCH_CONTACT_SUCCESS,
  FETCH_CONTACT_ERROR,
  SAVING_PRIVATE_CONTACT,
  SAVE_PRIVATE_CONTACT_SUCCESS,
  SAVE_PRIVATE_CONTACT_ERROR,
  DELETING_CONTACTS,
  DELETE_CONTACTS_SUCCESS,
  DELETE_CONTACTS_ERROR,
  FETCHING_CONTACTS_PAGINATION,
  FETCH_CONTACTS_PAGINATION_SUCCESS,
  FETCH_CONTACTS_PAGINATION_ERROR
} from "../constants";

const initialState = {
  contacts: [],
  contact: {},
  isFetching: false,
  error: {},
  isCreating: false,
  isSaving: false,
  createSuccess: false,
  createError: {},
  isDeleting: false,
  deleteSuccess: true,
  pagination: {},
  isFetchingPagination: false,
  paginationError: {}
};

const contacts = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_CONTACTS:
      return { ...state, isFetching: true };
    case FETCH_CONTACTS_SUCCESS:
      return { ...state, isFetching: false, contacts: action.contacts };
    case FETCH_CONTACTS_ERROR:
      return { ...state, isFetching: false, error: action.error };
    case CREATING_PRIVATE_CONTACT:
      return { ...state, isCreating: true, createSuccess: false };
    case CREATE_PRIVATE_CONTACT_SUCCESS:
      return { ...state, isCreating: false, createSuccess: true };
    case CREATE_PRIVATE_CONTACT_ERROR:
      return {
        ...state,
        isCreating: false,
        createSuccess: false,
        createError: action.error
      };
    case FETCHING_CONTACT:
      return { ...state, isFetching: true };
    case FETCH_CONTACT_SUCCESS:
      return { ...state, isFetching: false, contact: action.contact };
    case FETCH_CONTACT_ERROR:
      return { ...state, isFetching: false, error: action.error };
    case SAVING_PRIVATE_CONTACT:
      return { ...state, isSaving: true };
    case SAVE_PRIVATE_CONTACT_SUCCESS:
      return { ...state, isSaving: false };
    case SAVE_PRIVATE_CONTACT_ERROR:
      return {
        ...state,
        isSaving: false,
        error: action.error
      };
    case DELETING_CONTACTS:
      return { ...state, isDeleting: true };
    case DELETE_CONTACTS_SUCCESS:
      return { ...state, isDeleting: false, deleteSuccess: true };
    case DELETE_CONTACTS_ERROR:
      return { ...state, isDeleting: false, error: action.error };
    case FETCHING_CONTACTS_PAGINATION:
      return { ...state, isFetchingPagination: true };
    case FETCH_CONTACTS_PAGINATION_SUCCESS:
      return {
        ...state,
        isFetchingPagination: false,
        pagination: action.pagination
      };
    case FETCH_CONTACTS_PAGINATION_ERROR:
      return {
        ...state,
        isFetchingPagination: false,
        paginationError: action.error
      };

    default:
      return state;
  }
};

export default contacts;
