import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },

  menuItem: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& $primary, & $icon': {
        color: theme.palette.common.white
      }
    }
  },
  primary: {},
  icon: {}
});

const UploadersComponent = ({
  uploads,
  classes,
  userDidSelect,
  selectedStudent,
  isFetching
}) => {
  return (
    <Paper>
      <MenuList>
        {isFetching && (
          <MenuItem key="loading" className={classes.menuItem}>
            <CircularProgress />
            <ListItemText
              classes={{ primary: classes.primary }}
              inset
              primary="Henter..."
            />
          </MenuItem>
        )}
        {Object.keys(uploads).map(key => {
          const uploader = uploads[key];
          return (
            <MenuItem
              key={key}
              className={classes.menuItem}
              onClick={() => userDidSelect(key)}>
              <Avatar alt={uploader.displayName} src={uploader.photoURL} />
              <ListItemText
                classes={{ primary: classes.primary }}
                inset
                primary={uploader.displayName}
              />
            </MenuItem>
          );
        })}
      </MenuList>
    </Paper>
  );
};

UploadersComponent.propTypes = {
  uploads: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  userDidSelect: PropTypes.func.isRequired
};

export default withStyles(styles)(UploadersComponent);
