import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { fetchContact, savePrivateContact } from "../../actions/contacts";
import { ContactsForm } from "../../components";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";

const styles = () => ({
  root: { marginTop: 24 },
  paper: {
    padding: 16,
  },
});

class ContactContainer extends Component {
  componentDidMount() {
    const {
      params: { id },
    } = this.props.match;
    this.props.fetchContact(id);
  }

  render() {
    const {
      contact,
      isSaving,
      isFetching,
      classes,
      pagination,
      isFetchingPagination,
    } = this.props;
    return (
      <Grid container justify="center" className={classes.root}>
        <Grid item xs={12} lg={10}>
          {isFetching && <LinearProgress />}
          <Paper className={classes.paper}>
            {contact && !isFetching && (
              <ContactsForm
                initialValues={contact}
                isSaving={isSaving}
                savePrivateContact={(c) => this.props.savePrivateContact(c)}
                handleDeleteFile={this.handleDeleteFile}
                handleFileDrop={this.handleFileDrop}
              />
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} lg={10}>
          <PreviousNextButtonsWithRouter
            pagination={pagination}
            isFetching={isFetchingPagination}
          />
        </Grid>
      </Grid>
    );
  }
}

const previousNextStyles = (theme) => ({
  buttonRight: { float: "right" },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
});

const PreviousNextButtons = ({ history, pagination, isFetching, classes }) => {
  const next = pagination["next"];
  const previous = pagination["previous"];
  let nextKey, previousKey;
  if (next) {
    nextKey = Object.keys(next)[0];
  }
  if (previous) {
    previousKey = Object.keys(previous)[0];
  }
  return (
    <div>
      <Button
        disabled={!previousKey || isFetching}
        color="primary"
        variant="contained"
        onClick={() => history.push(`/contacts/${previousKey}`)}
      >
        <KeyboardArrowLeft className={classes.leftIcon} />
        {(previousKey && previous[previousKey]) || "Ej muligt"}
      </Button>
      <Button
        disabled={!nextKey || isFetching}
        color="primary"
        variant="contained"
        className={classes.buttonRight}
        onClick={() => history.push(`/contacts/${nextKey}`)}
      >
        {(nextKey && next[nextKey]) || "Ej muligt"}
        <KeyboardArrowRight className={classes.rightIcon} />
      </Button>
    </div>
  );
};

const PreviousNextButtonsWithRouter = withRouter(
  withStyles(previousNextStyles)(PreviousNextButtons)
);

const mapStateToProps = ({ contacts, fileUploads }) => {
  const {
    contact,
    publicProfile,
    isFetching,
    isSaving,
    error,
    pagination,
    isFetchingPagination,
  } = contacts;
  return {
    contact,
    publicProfile,
    isFetching,
    isSaving,
    error,
    pagination,
    isFetchingPagination,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchContact: (id) => dispatch(fetchContact(id)),
    savePrivateContact: (contact) => dispatch(savePrivateContact(contact)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ContactContainer);
