import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import MomentUtils from "@date-io/moment";
import { TextField } from "formik-material-ui";
import { Formik, Field } from "formik";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import { FormLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { referrers } from "../../helpers/referrers";
import { parseDate } from "../../helpers/parser";
import {
  DischargedSelect,
  DateField,
  DateTimeField,
  SelectField,
  GenderField,
  CheckboxGroupField,
  RadioField,
  CheckboxField,
} from "../../components";

import ApplicantsActionMenu from "./ApplicantsActionMenu";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { interviewers } from "../../helpers/interviewers";
import { lines } from "../../helpers/lines";
import { IApplicant } from "../Applicant";

const useStyles = makeStyles((theme) => ({
  textFieldContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  textfield: {},
  textFieldSmall: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 100,
  },
  textArea: {
    padding: 10,
    backgroundColor: "rgba(128, 128, 128, 0.1)",
  },
  topButton: {
    padding: "8px 16px 8px 0",
    marginRight: theme.spacing(1),
  },
  typography: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    fontWeight: 600,
  },
  progress: {
    margin: `0 ${theme.spacing(2)}px`,
  },
  radio: {
    display: "flex",
  },
  button: {
    margin: theme.spacing(1),
  },
  dates: {
    marginLeft: theme.spacing(2),
  },
  select: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  dischargeSelect: {
    margin: theme.spacing(1),
    minWidth: 400,
    maxWidth: 600,
  },
  label: {
    marginLeft: theme.spacing(1),
  },
}));

const validationSchema = Yup.object().shape({
  name: Yup.string().label("Navn").min(2).required(),
  email: Yup.string().email().required(),
  line: Yup.string().label("Linie").oneOf(lines),
  gender: Yup.string().label("Køn").oneOf(["male", "female"]).required(),
});

type FormProps = {
  applicant: IApplicant;
  doSubmit: (updated: IApplicant) => Promise<void>;
  moveApplicant: () => void;
  sendReminder: (type: string) => void;
  handleDeleteApplicant: () => void;
  semesters: { title: string }[];
};
const Form: React.FC<FormProps> = ({
  applicant,
  doSubmit,
  semesters,
  moveApplicant,
  sendReminder,
  handleDeleteApplicant,
  applicant: { sms, id, textMessageTemplate },
}) => {
  const history = useHistory();
  const classes = useStyles();

  const onDeleteApplicant = () => {
    handleDeleteApplicant();
  };

  return (
    <Formik
      initialValues={applicant}
      onSubmit={async (values) => {
        await doSubmit(values);
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({
        values: {
          isArchived,
          line,
          semester,
          contractSent,
          contractReceived,
          interviewScheduled,
        },
        touched,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        errors,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={3}>
                <ApplicantsActionMenu
                  handleSendMessage={() => sendReminder("applicant")}
                  handleViewMessages={() =>
                    history.push(`/messages/applicants/${id}`)
                  }
                  handleMoveToStudents={() => moveApplicant()}
                  moveDisabled={line === "" || semester === ""}
                  deleteApplicant={() => onDeleteApplicant()}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <DateField name="dateSent" label={"Ansøger indsendt d."} />
              </Grid>

              <Grid item xs={12} md={3}>
                <Field
                  name="name"
                  label="Navn*"
                  type="text"
                  component={TextField}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Field
                  name="email"
                  label="Email*"
                  component={TextField}
                  placeholder={"Email"}
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  name="phone"
                  label="Telefon"
                  component={TextField}
                  placeholder={"Telefon"}
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <DateField name="birthday" label="Fødselsdag" />
              </Grid>

              <Grid item xs={12} md={3}>
                <Field
                  name="street"
                  label="Gade"
                  component={TextField}
                  placeholder={"Gade"}
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  name="postcode"
                  component={TextField}
                  label="Postnr."
                  placeholder={"Postnummer"}
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  name="city"
                  label="By"
                  component={TextField}
                  placeholder={"By"}
                  type="text"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <GenderField name="gender" label="Køn" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  name="education"
                  label="Uddannelse"
                  component={TextField}
                  placeholder={"Uddannelse"}
                  type="text"
                  className={classes.textArea}
                  multiline
                  fullWidth
                  rows={6}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  name="motivation"
                  label="Motivation"
                  component={TextField}
                  placeholder={"Erfaring"}
                  type="text"
                  className={classes.textArea}
                  multiline
                  fullWidth
                  rows={6}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                {sms && (
                  <Typography variant="caption">
                    SMS:{" "}
                    {sms.status === 400
                      ? `🛑 ${sms.message}`
                      : `✅ afsendt d. ${parseDate(sms.dateCreated)}`}
                  </Typography>
                )}
                <Field
                  name="textMessageTemplate"
                  component={TextField}
                  className={classes.textArea}
                  label={`SMS besked (${
                    textMessageTemplate ? 160 - textMessageTemplate.length : 160
                  })`}
                  type="text"
                  multiline
                  fullWidth
                  rows={6}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormLabel component="legend">Valgte linier</FormLabel>
                <CheckboxField name="sport" label="Sports" />
                <CheckboxField name="outdoor" label="Friluft" />
                <CheckboxField name="xOutdoor" label="X-Outdoor" />
              </Grid>
              <Grid item xs={12} md={6}>
                <CheckboxGroupField
                  name="startingYear"
                  label="Valgte startdatoer:"
                  row
                  options={["2020", "2021", "2022", "2023", "2024", "2025"].map(
                    (year) => ({ label: year, value: year })
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <RadioField
                  row
                  name="line"
                  label="Tildel linie"
                  options={[
                    { value: "sport", label: "Sports" },
                    { value: "x-outdoor", label: "X-Outdoor" },
                    { value: "outdoor", label: "Friluft" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={4} container direction="column">
                <CheckboxField
                  name="interviewScheduled"
                  label="Samtale aftalt"
                />

                <CheckboxField
                  disabled={!interviewScheduled}
                  name="noShow"
                  label="Mødte ikke op"
                />
                <DateTimeField
                  disabled={!interviewScheduled}
                  name="interviewDate"
                  label={"Dato for samtale"}
                  showDeleteIcon
                />
              </Grid>
              <Grid item xs={12} md={4} container direction="column">
                <CheckboxField name="contractSent" label="Kontrakt afsendt" />
                <DateField
                  name="contractSentDate"
                  disabled={!contractSent}
                  label={"Dato for afsendelse"}
                  showDeleteIcon
                />
              </Grid>
              <Grid item xs={12} md={4} container direction="column">
                <CheckboxField
                  name="contractReceived"
                  label="Kontrakt modtaget"
                />

                <DateField
                  disabled={!contractReceived}
                  name="contractReceivedTimestamp"
                  label={"Dato for modtagelse"}
                  showDeleteIcon
                />
              </Grid>
              <Grid item xs={12}>
                <CheckboxField
                  name="admissionFeeReceived"
                  label="Indmeldelsesgebyr Modtaget"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <RadioField
                  row
                  name="interviewer"
                  label="Samtale med"
                  options={interviewers.map((interviewer) => ({
                    value: interviewer,
                    label: _.upperCase(interviewer),
                  }))}
                />
              </Grid>
              <Grid item xs={12} md={4} container direction="column">
                <DateField
                  name="firstContactedTimestamp"
                  label={"1. Opkald"}
                  showDeleteIcon
                />
                <DateField
                  name="secondContactedTimestamp"
                  label={"2. Opkald"}
                  showDeleteIcon
                />
                <DateField
                  name="thirdContactedTimestamp"
                  label={"3. Opkald"}
                  showDeleteIcon
                />
              </Grid>

              <Grid item xs={12} md={6} direction="column">
                <SelectField
                  name="semester"
                  label="Vælg årgang"
                  // Only show the latest five semesters
                  options={semesters
                    .slice(Math.max(semesters.length - 5, 0))
                    .map((semester) => ({
                      label: semester.title,
                      value: semester.title,
                    }))}
                />
                <SelectField
                  name="referrer"
                  label="Hørte om os"
                  options={referrers.map((r) => ({ value: r, label: r }))}
                />
                <SelectField
                  name="origin"
                  label="Ansøgning via"
                  options={[
                    { label: "Hjemmeside", value: "hjemmeside" },
                    { label: "Indsendt via email", value: "email" },
                    { label: "Telefon", value: "telephone" },
                    { label: "Info aften", value: "info-aften" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  name="note"
                  label="Note"
                  component={TextField}
                  placeholder={"Note"}
                  type="text"
                  className={classes.textArea}
                  multiline
                  fullWidth
                  rows={6}
                />
              </Grid>
              <Grid item xs={12} container alignItems="center">
                <CheckboxField name="isArchived" label="Arkiveret" />
                <DischargedSelect
                  type="applicant"
                  classes={classes}
                  disabled={!isArchived}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Grid item xs={12}>
                {Object.keys(errors).length > 0 &&
                  Object.values(errors).map((error, i) => (
                    <pre key={`${error}-${i}`} style={{ color: "red" }}>
                      {error}
                    </pre>
                  ))}
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.button}
                  disabled={isSubmitting}
                >
                  Opdater
                </Button>

                <Button
                  className={classes.button}
                  disabled={!touched}
                  onClick={() => resetForm()}
                >
                  Fortryd
                </Button>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </form>
      )}
    </Formik>
  );
};

export default Form;
