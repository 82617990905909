import React, { useEffect, useState } from "react";
import UsersComponent from "../../components/Users/UsersComponent";
import { functions } from "../../helpers/firebaseHelper";

const UsersContainer: React.FC = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function loadUsers() {
      const usersFunctions = functions.httpsCallable("users-fetchUsers");
      try {
        const result = await usersFunctions();
        console.log(result);
        setUsers(result.data);
      } catch (error: any) {
        console.error(error);
      }
    }
    loadUsers();
  }, []);
  return <UsersComponent users={users} />;
};
export default UsersContainer;
