import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "recompose";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Switch from "@material-ui/core/Switch";
import Collapse from "@material-ui/core/Collapse";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { parseClass } from "../../helpers/parser";
import { lines, classNames } from "../../helpers/lines";

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    background: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

const checkboxValues = {
  booksIssued: "Bogpakke udleveret",
  contractReceived: "Kontrakt modtaget",
  confirmationSent: "Bekræftigelse sendt",
  admissionFeeReceived: "Indmeldelsesgebyr modtaget",
  studentCard: "Studiekort",
  consentSigned: "Samtykkeerklæring underskrevet",
  rulesSigned: "Regelsæt underskrevet",
  infoLetter: "Informations brev",
  introductionEmail: "Velkomst mail sendt",
  createdInSU: "Oprettet i SU",
  lectio: "Indskrevet i Lectio",
  workExperienceContract: "Praktik kontrakt",
  workExperienceContract2: "Praktik kontrakt 2",
  graduated: "Dimitteret",
  discharged: "Udmeldt",
};

class FilterList extends Component {
  render() {
    const { handleChange, removeKey, classes, tabValue, query } = this.props;
    const selected = lines[tabValue];
    const classesForLine = classNames[selected];

    return (
      <div className={classes.root}>
        <List subheader={<ListSubheader>Filtre</ListSubheader>} dense={true}>
          {/* Hardcode the gender attribute since it's a string value and not boolean*/}
          <ListItem button onClick={() => removeKey("gender")}>
            <ListItemIcon>
              {"gender" in query ? (
                <CloseIcon color="secondary" />
              ) : (
                <VisibilityOffIcon />
              )}
            </ListItemIcon>
            <ListItemText primary={"Køn"} />
          </ListItem>
          <Collapse in={true} timeout="auto" unmountOnExit>
            <List component="div" disablePadding dense={true}>
              <ListItem
                button
                className={classes.nested}
                onClick={() => handleChange("gender")("male")}
              >
                {query["gender"] === "male" && (
                  <ListItemIcon>
                    <AccountCircle color="primary" />
                  </ListItemIcon>
                )}
                <ListItemText primary="Dreng" />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                onClick={() => handleChange("gender")("female")}
              >
                {query["gender"] === "female" && (
                  <ListItemIcon>
                    <AccountCircle color="primary" />
                  </ListItemIcon>
                )}
                <ListItemText primary="Pige" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => removeKey("class")}>
            <ListItemIcon>
              {"class" in query ? (
                <CloseIcon color="secondary" />
              ) : (
                <VisibilityOffIcon />
              )}
            </ListItemIcon>
            <ListItemText primary={"Hold"} />
          </ListItem>
          <Collapse in={true} timeout="auto" unmountOnExit>
            <List component="div" disablePadding dense={true}>
              {classesForLine.map((name, i) => {
                return (
                  <ListItem
                    key={i}
                    button
                    className={classes.nested}
                    onClick={() => handleChange("class")(name)}
                  >
                    {query["class"] === name && (
                      <ListItemIcon>
                        <AccountCircle color="primary" />
                      </ListItemIcon>
                    )}
                    <ListItemText primary={parseClass(name)} />
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
          {Object.keys(checkboxValues).map((key, i) => {
            return (
              <ListItem key={i} button onClick={() => removeKey(key)}>
                <ListItemIcon>
                  {key in query ? (
                    <CloseIcon color="secondary" />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </ListItemIcon>
                <ListItemText primary={checkboxValues[key]} />
                <ListItemSecondaryAction>
                  <Switch
                    onChange={handleChange(key)}
                    checked={query[key] || false}
                    color="primary"
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </div>
    );
  }
}

const mapStateToProps = ({ students }) => {
  const { tabValue, query } = students;
  return {
    tabValue,
    query,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, null)
)(FilterList);
