import React, { useEffect } from "react";
import styled from "styled-components";
import {
  useTable,
  useSortBy,
  Column,
  CellProps,
  HeaderProps,
  usePagination,
  useRowSelect,
  useFilters,
  Row as TableRow,
  FilterProps,
} from "react-table";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useFirestoreConnect, WhereOptions } from "react-redux-firebase";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  Button,
  Select,
  TextField,
  MenuItem,
  Typography,
  Checkbox,
} from "@material-ui/core";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import NavigateNext from "@material-ui/icons/NavigateNext";
import SkipPrevious from "@material-ui/icons/SkipPrevious";
import SkipNext from "@material-ui/icons/SkipNext";
import { AppState } from "../../reducers";
import { parseDate } from "../../helpers/parser";
import { subjectsList } from "../../helpers/subjectsList";

type StyleProps = { tableColor: string };
type Data = Examiner;

const EXAMINERS_COLLECTION = "examiners";

const Styles = styled.div`
  table {
    border-spacing: 0;
    width: 100%;
    background-color: ${(props: StyleProps) => props.tableColor};
    box-shadow: rgba(0, 0, 0, 0.05) 1px 1px 10px 10px;
    border-radius: 10px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;

      :last-child {
        border-right: 0;
      }
    }
    tbody {
      text-align: center;
    }
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify: center;
  align-items: center;
`;

const Row = styled.tr`
  border-bottom: solid black 0.5px;
  background-color: ${(props) => props.color};
  height: 60px;
`;

type LinkProps = { linkcolor: string };
const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props: LinkProps) => props.linkcolor};
`;

type NameLinkableCellProps = { value: string; row: TableRow<Data> };
const NameLinkableCell: React.FC<NameLinkableCellProps> = ({
  value: initialValue,
  row: {
    original: { id },
  },
}) => {
  const theme = useTheme();
  return (
    <Typography variant="body1" style={{ textAlign: "left", paddingLeft: 16 }}>
      <StyledLink
        linkcolor={theme.palette.primary.main}
        to={`/examiners/${id}`}
      >
        {_.startCase(initialValue)}
      </StyledLink>
    </Typography>
  );
};

type DateCellProps = { value: string; row: TableRow<Data> };
const DateCell = ({
  row: {
    original: { lastUsed },
  },
}: DateCellProps) => {
  return <Typography variant="body1">{parseDate(lastUsed, "L")}</Typography>;
};

type ApprovedSubjectsCellProps = { value: string; row: TableRow<Data> };
const ApprovedSubjectsCell = ({
  row: {
    original: { approvedSubjects },
  },
}: ApprovedSubjectsCellProps) => {
  return (
    <Typography variant="caption">{approvedSubjects?.join(", ")}</Typography>
  );
};

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter },
}: FilterProps<Data>) {
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {subjectsList.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectBooleanColumnFilter({
  column: { filterValue, setFilter },
}: FilterProps<Data>) {
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      <option value={"Ja"}>Ja</option>
      <option value={"Nej"}>Nej</option>
    </select>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter() {
  return <></>;
}

type Props = {
  columns: Column<Data>[];
  data: Data[];
  setSelection: (selection: Selection) => void;
};

const Table: React.FC<Props> = ({ columns, data, setSelection }) => {
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  // Use the state and functions returned from useTable to build your UI
  const theme = useTheme();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    selectedFlatRows,
    page,
    // Pagination
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 20, pageIndex: 0 },
      defaultColumn,
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    setSelection(selectedFlatRows.map((d: TableRow<Data>) => d.original));
  }, [selectedFlatRows, setSelection]);
  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Render the columns filter UI */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Row
                {...row.getRowProps()}
                color={
                  i % 2 === 0
                    ? theme.palette.background.paper
                    : theme.palette.background.default
                }
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </Row>
            );
          })}
        </tbody>
      </table>
      <PaginationContainer className="pagination">
        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <SkipPrevious />
        </Button>
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          <NavigateBefore />
        </Button>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          <NavigateNext />
        </Button>
        <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          <SkipNext />
        </Button>
        Side{" "}
        <strong>
          {pageIndex + 1} af {pageOptions.length}
        </strong>{" "}
        <span style={{ marginRight: 10 }}> | Gå til side: </span>{" "}
        <TextField
          type="number"
          defaultValue={pageIndex + 1}
          style={{ marginRight: 16, width: "100px" }}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
        />
        <Select
          value={pageSize}
          style={{ marginLeft: 16 }}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[20, 30, 40, 50].map((pageSize) => (
            <MenuItem key={pageSize} value={pageSize}>
              Vis {pageSize}
            </MenuItem>
          ))}
        </Select>
      </PaginationContainer>
    </>
  );
};

const getQuery = (query: string | null): WhereOptions[] => {
  if (query) {
    return [
      ["subjects", "array-contains", query],
      ["isArchived", "==", false],
    ];
  } else {
    return [["isArchived", "==", false]];
  }
};

type Selection = Examiner[];
type TableProps = {
  setSelection: (selection: Selection) => void;
  query: string | null;
  filters: Filters;
};
const ExaminersTable: React.FC<TableProps> = React.memo(
  ({ setSelection, query }) => {
    const theme = useTheme();
    const columns = React.useMemo(
      () => [
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<Data>) => (
            <div>
              <Checkbox
                color="primary"
                indeterminate
                {...getToggleAllRowsSelectedProps()}
              />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }: CellProps<Data>) => (
            <div>
              <Checkbox color="primary" {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        {
          Header: "Navn",
          columns: [
            {
              Header: "Fornavn",
              accessor: "firstName",
              canFilter: false,
              Cell: NameLinkableCell,
            },
            {
              Header: "Efternavn",
              accessor: "lastName",
              canFilter: false,
              Cell: NameLinkableCell,
            },
          ],
        },
        {
          Header: "Kontakt info",
          columns: [
            {
              Header: "Email",
              accessor: "email",
              canFilter: false,
              disableSortBy: true,
            },
            {
              Header: "Telefon",
              accessor: "phone",
              canFilter: false,
              disableSortBy: true,
            },
            {
              Header: "Godkendt",
              accessor: "approved",
              Cell: ({ row }: CellProps<Data>) => (
                // Use Cell to render an expander for each row.
                // We can use the getToggleRowExpandedProps prop-getter
                // to build the expander.
                <p>{row.original.approved ? "Ja" : "Nej"}</p>
              ),
              disableSortBy: true,
              Filter: SelectBooleanColumnFilter,
              filter: (
                rows: Array<TableRow>,
                columnIds: Array<string>,
                filterValue: any
              ) => {
                if (filterValue === "Ja") {
                  return rows.filter((row) => row.values.approved);
                } else if (filterValue === "Nej") {
                  return rows.filter((row) => !row.values.approved);
                } else {
                  return rows;
                }
              },
            },
            {
              Header: "Godkendt i",
              accessor: "approvedSubjects",
              Cell: ApprovedSubjectsCell,
              Filter: SelectColumnFilter,
              filter: "includes",
              disableSortBy: true,
            },
            {
              Header: "Sidst anvedt",
              accessor: "lastUsed",
              Cell: DateCell,
            },
          ],
        },
      ],
      []
    );
    useFirestoreConnect([
      { collection: EXAMINERS_COLLECTION, where: getQuery(query) },
    ]);
    const examiners = useSelector(
      ({ firestore: { ordered } }: AppState) => ordered.examiners || []
    );

    return (
      <Styles tableColor={theme.palette.background.default}>
        <Table
          columns={columns}
          data={_.sortBy(examiners, ["firstName", "lastName"])}
          setSelection={(selections) => setSelection(selections)}
        />
      </Styles>
    );
  }
);

export default ExaminersTable;
