import { Dispatch } from "redux";
import { db, FieldValue } from "../../helpers/firebaseHelper";
import { push } from "connected-react-router";
import { openSnackbar } from "../../actions/snackbar";
import moment from "moment";
import {
  MOVING_APPLICANT,
  MOVE_APPLICANT_SUCCESS,
  MOVE_APPLICANT_ERROR,
  FETCHING_APPLICANT_WITH_RANGE_QUERY,
  FETCH_APPLICANT_RANGE_QUERY_SUCCESS,
  FETCH_APPLICANT_RANGE_QUERY_ERROR,
  DELETING_APPLICANT,
  DELETE_APPLICANT_SUCCESS,
  DELETE_APPLICANT_ERROR,
  DELETING_APPLICANTS,
  DELETE_APPLICANTS_SUCCESS,
  DELETE_APPLICANTS_ERROR,
} from "../../constants";
import Student from "../../Classes/Student";
import { IApplicant } from "../Applicant";

function movingApplicant() {
  return {
    type: MOVING_APPLICANT,
  };
}

function moveApplicantSuccess() {
  return {
    type: MOVE_APPLICANT_SUCCESS,
  };
}

function moveApplicantError(e: string) {
  return {
    type: MOVE_APPLICANT_ERROR,
    error: e,
  };
}

export function moveApplicantToStudents(applicant: IApplicant) {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(movingApplicant());
    try {
      // Check if the required properties exists
      if (
        !applicant.semester ||
        applicant.semester.length < 1 ||
        !applicant.line ||
        applicant.line.length < 1
      ) {
        throw new Error(`Manglende semester eller linie`);
      }
      const batch = db.batch();
      const fromRef = db.collection("applicants").doc(applicant.id);
      const toRef = db.collection("students").doc(applicant.id);
      const conversionRef = db.collection("conversions").doc();
      // Split the name prop into a first and last name and delete the old prop
      const { name } = applicant;
      const names = name.split(" ");
      const lastName = names.pop() || "";
      const firstName = names.join(" ");

      const student = new Student({
        birthday: applicant.birthday,
        city: applicant.city || "",
        email: applicant.email,
        examSubjects: [],
        firstName: firstName,
        gender: applicant.gender,
        id: applicant.id,
        imageUrl: null,
        lastName: lastName,
        phone: applicant.phone || "",
        postcode: applicant.postcode || "",
        interviewer: applicant.interviewer || "",
        ssn: "",
        street: applicant.street || "",
        line: applicant.line,
        admissionFeeReceived: applicant.admissionFeeReceived,
        confirmationSent: false,
        contractReceived: applicant.contractReceived,
        contractReceivedTimestamp:
          applicant.contractReceivedTimestamp || new Date(),
        contractSent: applicant.contractSent,
        contractSentDate: applicant.contractSentDate,
        createdAt: FieldValue.serverTimestamp(),
        createdInEconomics: false,
        createdInSU: false,
        dischargedDate: null,
        dischargeReasons: [],
        discharged: false,
        graduated: false,
        graduatedDate: null,
        hasAccount: false,
        infoLetter: false,
        introductionEmail: false,
        origin: applicant.origin || "hjemmeside",
        lectio: false,
        note: applicant.note,
        rate: "",
        class: "",
        semester: applicant.semester,
        studentCard: false,
        consentSigned: false,
        rulesSigned: false,
        booksIssued: false,
        subjectsNotPassed: [],
        userId: null,
        workExperienceContract2: false,
        workExperienceContract: false,
        clothingTop: "---",
        clothingBottom: "---",
        clothingRemark: "",
      });

      // Create the student, then set the 'old' applicant entry on the student so we can refer back to it
      batch.set(toRef, { ...student });
      batch.set(toRef.collection("applicant").doc(student.id), {
        ...applicant,
      });

      // Create a conversion event in the system so we can use it for statistics and notifications
      batch.set(conversionRef, {
        conversionDate: FieldValue.serverTimestamp(),
        studentID: toRef.id,
        applicantID: toRef.id,
        contractReceivedDate: applicant.contractReceivedTimestamp,
        applicationDate: applicant.dateSent,
        interviewer: applicant.interviewer || "n/a",
      });
      // Set the applicant to archived. It will then get purged by the scheduled cloud function
      batch.update(fromRef, { isArchived: true });

      await batch.commit();
      dispatch(moveApplicantSuccess());
      dispatch(push(`/semesters/${applicant.semester}/student/${toRef.id}`));
      dispatch(openSnackbar(`✅ ${student.firstName} blev flyttet`) as any);
    } catch (e: any) {
      dispatch(moveApplicantError(e));
      dispatch(
        openSnackbar(`🛑 ${applicant.name} kunne ikke flyttes: ${e}`) as any
      );
      console.log(e);
    }
  };
}

export function fetchApplicantsWithRangeQuery(
  startDate: string,
  endDate: string,
  opts: { comparison: string }
) {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: FETCHING_APPLICANT_WITH_RANGE_QUERY });
    const ref = db.collection("applicants");

    try {
      // Fetch the currently selected range first
      const query = ref
        .where("dateSent", ">", new Date(startDate))
        .where("dateSent", "<", new Date(endDate));

      const applicants = await query.get();
      const obj: { [key: string]: any } = {};
      applicants.forEach((a) => {
        if (!a.exists) return;
        obj[a.id] = a.data();
      });

      // Then, if a comparison range is selected, fetch that
      const comparison: { [key: string]: any } = {};
      let previousStartDate;
      let previousEndDate;

      if (opts.comparison === "lastMonth") {
        previousStartDate = moment(startDate).subtract(1, "months");
        previousEndDate = moment(endDate).subtract(1, "months");
      } else if (opts.comparison === "lastYear") {
        previousStartDate = moment(startDate).subtract(1, "years");
        previousEndDate = moment(endDate).subtract(1, "years");
      }

      if (previousStartDate && previousEndDate) {
        const comparisonQuery = ref
          .where("dateSent", ">", previousStartDate.toDate())
          .where("dateSent", "<", previousEndDate.toDate());
        const comparisonResults = await comparisonQuery.get();
        comparisonResults.forEach((r) => {
          if (!r.exists) return;
          comparison[r.id] = r.data();
        });
      }

      dispatch({
        type: FETCH_APPLICANT_RANGE_QUERY_SUCCESS,
        applicants: obj,
        comparison,
      });
    } catch (e) {
      dispatch({ type: FETCH_APPLICANT_RANGE_QUERY_ERROR, error: e });
      dispatch(
        openSnackbar(`🛑 Kunne ikke hente ansøger statistik: ${e}`) as any
      );
    }
  };
}

export function deleteApplicants(ids: string[]) {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: DELETING_APPLICANTS });
    const batch = db.batch();
    try {
      for (const id of ids) {
        const aRef = db.collection("applicants").doc(id);
        batch.delete(aRef);
      }
      await batch.commit();
      dispatch({ type: DELETE_APPLICANTS_SUCCESS });
      dispatch(openSnackbar(`✅ Slettede ${ids.length} ansøgere`) as any);
    } catch (e) {
      dispatch({ type: DELETE_APPLICANTS_ERROR, error: e });
      console.error(e);
    }
  };
}

export function deleteApplicant(id: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: DELETING_APPLICANT });
    const applicantRef = db.collection("applicants").doc(id);
    try {
      await applicantRef.delete();
      dispatch({ type: DELETE_APPLICANT_SUCCESS });
      dispatch(openSnackbar(`✅ Ansøger slettet`) as any);
      dispatch(push("/applicants"));
    } catch (e) {
      dispatch({ type: DELETE_APPLICANT_ERROR, error: e });
      dispatch(openSnackbar(`🛑 Kunne ikke slette ansøger: ${e}`) as any);
    }
  };
}
