import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { CheckboxWithLabel, TextField, Select } from "formik-material-ui";
import { Field, Formik } from "formik";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import FormControl from "@material-ui/core/FormControl";
import { internalContactGroups } from "../../helpers/contactGroups";
import { subjects } from "../../helpers/subjects";
import { keyOptions } from "../../helpers/keyOptions";
import { Typography } from "@material-ui/core";
import { DropzoneFileList } from "../../containers";
import MUITextField from "@material-ui/core/TextField";
import * as Yup from "yup";
const listHeight = 300;
const topMargin = 16;

const styles = (theme) => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textArea: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  radio: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: listHeight,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  select: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectBox: {
    marginTop: topMargin,
    padding: 8,
  },
  dropzone: {
    height: 100,
  },
});

const validationSchema = Yup.object().shape({
  email: Yup.string().email(),
});

const renderTextField = ({ field, form, ...props }) => {
  const isError =
    form.touched[field.name] && form.errors[field.name] ? true : false;
  return (
    <MUITextField
      helperText={form.touched[field.name] && form.errors[field.name]}
      InputLabelProps={{
        error: isError,
      }}
      {...field}
      {...props}
    />
  );
};

const Equipment = ({ classes }) => {
  return (
    <Grid container style={{ marginTop: 8 }}>
      <Grid item xs={4}>
        <Field
          name="equipment.computer"
          component={CheckboxWithLabel}
          type="checkbox"
          Label={{ label: "Computer" }}
        />
        <Field
          name="equipment.computerLoanAgreementSigned"
          component={CheckboxWithLabel}
          type="checkbox"
          style={{ paddingLeft: 8 }}
          Label={{ label: "Låneaftale underskrevet" }}
        />
      </Grid>
      <Grid item xs={8}>
        <Field
          name="equipment.computerDescription"
          className={classes.textField}
          fullWidth
          component={renderTextField}
          placeholder={"eks. MacBook Pro 2018"}
          type="text"
        />
      </Grid>
      <Grid item xs={4}>
        <Field
          name="equipment.phone"
          component={CheckboxWithLabel}
          type="checkbox"
          Label={{ label: "Telefon" }}
        />
        <Field
          name="equipment.phoneLoanAgreementSigned"
          component={CheckboxWithLabel}
          type="checkbox"
          style={{ paddingLeft: 8 }}
          Label={{ label: "Låneaftale underskrevet" }}
        />
      </Grid>
      <Grid item xs={8}>
        <Field
          name="equipment.phoneDescription"
          className={classes.textField}
          fullWidth
          component={renderTextField}
          placeholder={"eks. iPhone 7"}
          type="text"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="employeeHandbookSigned"
          component={CheckboxWithLabel}
          type="checkbox"
          Label={{ label: "Medarbejderhåndbog underskrevet" }}
        />
        <Field
          name="teachersHandbookSigned"
          component={CheckboxWithLabel}
          type="checkbox"
          Label={{ label: "underviserhåndbog underskrevet" }}
        />
      </Grid>
    </Grid>
  );
};

const ExternalGroupSelect = ({ classes }) => {
  return (
    <FormControl>
      <InputLabel htmlFor="group">Vælg gruppe</InputLabel>
      <Field
        name="group"
        className={classes.select}
        component={Select}
        placeholder="Vælg gruppe"
      >
        <MenuItem value={"denmark"}>Danmark</MenuItem>
        <MenuItem value={"international"}>Udlandet</MenuItem>

        <MenuItem value={"boardingschool"}>Højskolebesøg</MenuItem>
      </Field>
    </FormControl>
  );
};

const BelongsToLine = ({ classes }) => {
  return (
    <Grid item xs={12} className={classes.gridTopMargin}>
      <Typography
        variant="caption"
        color="textSecondary"
        style={{ marginLeft: 8, marginRight: 8 }}
      >
        Tilknyttet
      </Typography>
      <Field
        name="associatedLines.sports"
        component={CheckboxWithLabel}
        type="checkbox"
        Label={{ label: "Sport" }}
      />
      <Field
        name="associatedLines.outdoor"
        component={CheckboxWithLabel}
        type="checkbox"
        Label={{ label: "Friluft" }}
      />
    </Grid>
  );
};

const KeyOptions = ({ classes }) => {
  return (
    <Grid item xs={6} lg={4} className={classes.selectBox}>
      <List className={classes.list}>
        <li className={classes.listSection}>
          <ul className={classes.ul}>
            <ListSubheader component="div">Nøgler</ListSubheader>
            {Object.keys(keyOptions).map((key) => (
              <ListItem key={key} dense button className={classes.listItem}>
                <Field
                  name={`keys.${key}`}
                  component={CheckboxWithLabel}
                  type="checkbox"
                />
                <ListItemText primary={keyOptions[key]} />
              </ListItem>
            ))}
          </ul>
        </li>
      </List>
    </Grid>
  );
};

const ContactGroups = ({ classes }) => {
  return (
    <Grid item xs={6} lg={4} className={classes.selectBox}>
      <List className={classes.list}>
        <li className={classes.listSection}>
          <ul className={classes.ul}>
            <ListSubheader component="div">Medlem af</ListSubheader>
            {Object.keys(internalContactGroups).map((key) => (
              <ListItem key={key} dense button className={classes.listItem}>
                <Field
                  name={`memberOf.${key}`}
                  component={CheckboxWithLabel}
                  type="checkbox"
                />
                <ListItemText primary={internalContactGroups[key]} />
              </ListItem>
            ))}
          </ul>
        </li>
      </List>
    </Grid>
  );
};

const TeachingIn = ({ classes }) => {
  return (
    <Grid item xs={6} lg={4} className={classes.selectBox}>
      <List className={classes.list}>
        <li className={classes.listSection}>
          <ul className={classes.ul}>
            <ListSubheader component="div">Tilkyttet fag</ListSubheader>
            {Object.keys(subjects).map((key) => (
              <ListItem key={key} dense button className={classes.listItem}>
                <Field
                  name={`subjects.${key}`}
                  component={CheckboxWithLabel}
                  type="checkbox"
                />
                <ListItemText primary={subjects[key]} />
              </ListItem>
            ))}
          </ul>
        </li>
      </List>
    </Grid>
  );
};

class ContactsForm extends Component {
  state = {
    checked: [""],
  };
  handleChange = (line) => (event) => {
    let { lines } = this.state;
    lines[line] = event.target.checked;
    this.setState({ lines });
  };

  handleToggle = (value) => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  };

  render() {
    const { classes, initialValues, savePrivateContact } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await savePrivateContact(values);
          setSubmitting(false);
        }}
        render={({
          handleSubmit,
          isSubmitting,
          resetForm,
          values: { isExternal },
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <Field
                  name="isExternal"
                  component={CheckboxWithLabel}
                  type="checkbox"
                  Label={{ label: "Ekstern kontakt" }}
                />
                <Field
                  name="isArchived"
                  type="checkbox"
                  component={CheckboxWithLabel}
                  Label={{ label: "Arkiveret" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  name={"title"}
                  className={classes.textField}
                  component={renderTextField}
                  placeholder={"F.eks. 'Underviser'"}
                  label={"Titel"}
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} className={classes.gridTopMargin}>
                <Field
                  name={isExternal ? "name" : "firstName"}
                  className={classes.textField}
                  component={renderTextField}
                  placeholder={isExternal ? "Firma Navn" : "Fornavn"}
                  label={isExternal ? "Firma Navn" : "Fornavn"}
                  type="text"
                />
                <Field
                  name={isExternal ? "att" : "lastName"}
                  className={classes.textField}
                  component={renderTextField}
                  placeholder={isExternal ? "Kontakt navn." : "Efternavn"}
                  label={isExternal ? "Kontakt navn." : "Efternavn"}
                  type="text"
                />
                <Field
                  name="email"
                  className={classes.textField}
                  component={renderTextField}
                  placeholder={"Email"}
                  label="Email"
                  type="text"
                />
                <Field
                  name="phone"
                  className={classes.textField}
                  component={renderTextField}
                  placeholder={"Telefon"}
                  label="Telefon"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} className={classes.gridTopMargin}>
                <Field
                  name="address"
                  className={classes.textField}
                  component={renderTextField}
                  placeholder={"Adresse"}
                  label="Adresse"
                  type="text"
                />
                <Field
                  name="postcode"
                  className={classes.textField}
                  component={renderTextField}
                  placeholder={"Postnummer"}
                  label="Postnummer"
                  type="text"
                />
                <Field
                  name="city"
                  className={classes.textField}
                  component={renderTextField}
                  placeholder={"By"}
                  label="By"
                  type="text"
                />
                {isExternal ? (
                  <ExternalGroupSelect classes={classes} />
                ) : (
                  <BelongsToLine classes={classes} />
                )}
                <Grid item xs={12} className={classes.gridTopMargin}>
                  <Divider />
                </Grid>
                <Grid item xs={12} className={classes.gridTopMargin}>
                  {!isExternal && <Equipment classes={classes} />}
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: topMargin }}>
                <Divider />
              </Grid>
              {!isExternal && (
                <React.Fragment>
                  <ContactGroups classes={classes} />
                  <TeachingIn classes={classes} />
                  <KeyOptions classes={classes} />
                  <Grid item xs={6} lg={4} className={classes.selectBox}>
                    <DropzoneFileList
                      collection="contacts"
                      id={initialValues.id}
                    />
                  </Grid>
                </React.Fragment>
              )}
              <Grid item xs={6} className={classes.selectBox}>
                <Field
                  name="note"
                  component={TextField}
                  placeholder={"Kommentar"}
                  type="text"
                  multiline
                  fullWidth
                  rows={6}
                />
              </Grid>
              <Grid item xs={6} className={classes.gridTopMargin}>
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Opdater
                </Button>
                <Button
                  variant="contained"
                  className={classes.button}
                  disabled={isSubmitting}
                  onClick={resetForm}
                >
                  Fortryd
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    );
  }
}

ContactsForm.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactsForm);
