import { db, storage } from "../helpers/firebaseHelper";
import {
  FETCHING_UPLOADS,
  FETCH_UPLOADS_SUCCESS,
  FETCH_UPLOADS_ERROR,
  FETCHING_USER_UPLOADS,
  FETCH_USER_UPLOADS_SUCCESS,
  FETCH_USER_UPLOADS_ERROR,
  USER_UPLOADS_PROGRESS
} from "../constants";

function fetchingUploads() {
  return {
    type: FETCHING_UPLOADS
  };
}

function fetchUploadsSuccess(uploads) {
  return {
    type: FETCH_UPLOADS_SUCCESS,
    uploads
  };
}

function fetchUploadsError(e) {
  return {
    type: FETCH_UPLOADS_ERROR,
    error: e
  };
}

function fetchingUserUploads() {
  return {
    type: FETCHING_USER_UPLOADS
  };
}

function fetchUserUploadsSuccess(userUploads) {
  return {
    type: FETCH_USER_UPLOADS_SUCCESS,
    userUploads
  };
}

function fetchUserUploadsError(e) {
  return {
    type: FETCH_USER_UPLOADS_ERROR,
    error: e
  };
}

function userUploadsProgress(progress) {
  return {
    type: USER_UPLOADS_PROGRESS,
    progress
  };
}

export function fetchUploads() {
  return async dispatch => {
    dispatch(fetchingUploads());
    const uploadsRef = db.collection("uploads");
    try {
      const uploadDocs = await uploadsRef.get();
      const uploads = {};
      //eslint-disable-next-line no-unused-vars
      for (let doc of uploadDocs.docs) {
        if (!doc.exists) return;
        const user = await fetchUserForUID(doc.id);
        if (!user.exists) return;
        uploads[doc.id] = user.data();
      }
      dispatch(fetchUploadsSuccess(uploads));
    } catch (e) {
      console.log(e);
      dispatch(fetchUploadsError(e));
    }
  };
}

export function fetchUserUploads(uid) {
  return async dispatch => {
    try {
      dispatch(fetchingUserUploads());
      const userUploadsDocs = await db
        .collection("uploads")
        .doc(uid)
        .collection("uploads")
        .get();
      const userUploads = {};
      userUploadsDocs.forEach(doc => {
        if (!doc.exists) return;
        userUploads[doc.id] = doc.data();
      });
      dispatch(fetchUserUploadsSuccess(userUploads));
    } catch (e) {
      console.log(e);
      dispatch(fetchUserUploadsError(e));
    }
  };
}

export function downloadImagesForStudent(sid) {
  return async dispatch => {
    const actionRef = db.collection("actions").doc();
    try {
      await actionRef.set({
        studentId: sid,
        actionType: "downloadImagesForStudent"
      });
    } catch (e) {
      console.log(e);
    }

    const unsubscribe = actionRef.onSnapshot(doc => {
      if (!doc.exists) return;
      if (doc.data().name) {
        storage
          .ref(doc.data().name)
          .getDownloadURL()
          .then(url => {
            const progress = { ...doc.data(), mediaLink: url };
            dispatch(userUploadsProgress(progress));
          });
      }
      dispatch(userUploadsProgress(doc.data()));
      if (doc.data().status === "done") unsubscribe();
    });
  };
}

export function deleteImagesForStudent(sid) {
  return async dispatch => {
    const actionRef = db.collection("actions").doc();
    try {
      await actionRef.set({
        studentId: sid,
        actionType: "deleteImagesForStudent"
      });
    } catch (e) {
      console.log(e);
    }
  };
}

async function fetchUserForUID(uid) {
  return db
    .collection("users")
    .doc(uid)
    .get();
}
