import React from "react";
import { useFirestore } from "react-redux-firebase";
import { Field, Formik } from "formik";
import { TextField } from "formik-material-ui";
import Grid from "@material-ui/core/Grid";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  eventID: string;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
});

const AddAttendeeDialog: React.FC<DialogProps> = ({
  open,
  handleClose,
  eventID,
}) => {
  const firestore = useFirestore();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <Formik
          initialValues={{ email: "", name: "" }}
          validationSchema={validationSchema}
          onSubmit={async ({ email, name }, actions) => {
            try {
              actions.setSubmitting(true);
              const eventRef = firestore.collection("info_events").doc(eventID);
              const attendeeRef = eventRef.collection("attendees").doc();
              await attendeeRef.set({
                email,
                name,
                emailSent: false,
                shouldSendConfirmation: false,
                followUpEmailSent: false,
                shouldSendFollowUp: false,
                participants: 1,
                id: attendeeRef.id,
                createdAt: new Date(),
                status: "unconfirmed",
              });
              handleClose();
            } catch (error) {
              console.error(error);
            } finally {
              actions.setSubmitting(false);
            }
          }}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle id="form-dialog-title">Tilføj deltager</DialogTitle>
              <DialogContent>
                <Grid container>
                  <Grid item xs={12}>
                    <Field component={TextField} name="name" label={"Navn"} />
                  </Grid>
                  <Grid item xs={12}>
                    <Field component={TextField} name="email" label={"Email"} />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Annuller
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Tilføj
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default AddAttendeeDialog;
