import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import NotificationIcon from "@material-ui/icons/Notifications";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import Search from "@material-ui/icons/Search";
import { drawerWidth } from "../../constants";
import { login, logout } from "../../helpers/auth";
import { useSelector } from "react-redux";
import { SearchContainer } from "../../containers";
import grey from "@material-ui/core/colors/grey";
import Settings from "@material-ui/icons/Settings";
import { useHistory } from "react-router-dom";
import NavButtons from "./NavButtons";
import { Grid, Typography } from "@material-ui/core";
import * as packageInfo from "../../../package.json";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const styles = (theme) => ({
  appBar: {
    position: "absolute",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
    background: "white",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
    color: grey[500],
  },
  hide: {
    display: "none",
  },
  typography: { color: grey[500] },
  displayName: {
    flex: 1,
    color: grey[500],
    fontWeight: 600,
    fontSize: "1.3rem",
    marginLeft: theme.spacing(2),
  },
  badge: {
    margin: `${theme.spacing(2)}px`,
  },
  button: {
    height: 60, // setting height/width is optional
    color: grey[500],
  },
  label: {
    // Aligns the content of the button vertically.
    flexDirection: "column",
    fontSize: "0.7rem",
    fontWeight: "600",
  },
  icon: {
    fontSize: "1.5rem !important",
    marginBottom: theme.spacing(),
  },
});

const NavBar = ({ classes, open, handleToggleDrawer, toggleNotifications }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const user = useSelector((state) => state.auth.user || {});
  const history = useHistory();
  const { uid, displayName } = user;

  const notifications = useSelector(
    (state) => state.firestore.ordered[`${uid}-notifications`] || []
  );
  const matches = useMediaQuery("(min-width:1280px)");
  const notificationCount = notifications.filter((n) => !n.read).length;
  return (
    <div className="no-print">
      <SearchContainer open={searchOpen} setOpen={setSearchOpen} />

      <AppBar
        className={classNames(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar disableGutters={!open}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Button
                variant="text"
                endIcon={<Settings />}
                className={classes.displayName}
                onClick={() => history.push("/settings")}
              >
                {user.uid && matches ? displayName : ""}
              </Button>
              <Typography className={classes.typography} variant="caption">
                v{packageInfo.version}
              </Typography>
            </Grid>
            <Grid item>
              <NavButtons />
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                onClick={() => setSearchOpen(true)}
                endIcon={<Search />}
              >
                Søg
              </Button>
            </Grid>
            <Grid item>
              {user.uid ? (
                <Button
                  onClick={() => {
                    logout();
                  }}
                >
                  Logout
                </Button>
              ) : (
                <Button
                  className={classes.button}
                  onClick={() => {
                    login();
                  }}
                >
                  Login
                </Button>
              )}

              <IconButton color="inherit" onClick={() => toggleNotifications()}>
                {notificationCount > 0 ? (
                  <Badge
                    className={classes.badge}
                    color="primary"
                    badgeContent={notificationCount}
                  >
                    <NotificationIcon style={{ color: grey[500] }} />
                  </Badge>
                ) : (
                  <NotificationIcon style={{ color: grey[500] }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,

  handleToggleDrawer: PropTypes.func.isRequired,
};

export { NavBar as TestableNavBar };

export default withStyles(styles)(NavBar);
