import React, { Component, lazy, Suspense } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { UserIsAuthenticated, UserIsNotAuthenticated } from "../Authentication";
import { firebaseAuth } from "../../helpers/firebaseHelper";
import { Route, Switch } from "react-router";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { authSuccess, authFailed } from "../../actions/auth";
//Custom containers and component

const MainContainer = lazy(() => import("../Main/MainContainer"));
const ApplicantsContainer = lazy(() =>
  import("../../Applicants/ApplicantsContainer")
);
const ApplicantContainer = lazy(() =>
  import("../../Applicants/ApplicantContainer")
);
const StudentsContainer = lazy(() => import("../Students/StudentsContainer"));
const StudentContainer = lazy(() => import("../Students/StudentContainer"));
const NewStudentContainer = lazy(() =>
  import("../Students/NewStudentContainer")
);
const LoginContainer = lazy(() => import("../Login/LoginContainer"));
const MessagesContainer = lazy(() => import("../Messages/MessagesContainer"));
const UploadsContainer = lazy(() => import("../Uploads/UploadsContainer"));
const ExamsContainer = lazy(() => import("../Exams/ExamsContainer"));

const SettingsContainer = lazy(() => import("../Settings/SettingsContainer"));
const ChangesContainer = lazy(() => import("../Changes/ChangesContainer"));
const UsersContainer = lazy(() => import("../Users/UsersContainer"));
const UserContainer = lazy(() => import("../Users/UserContainer"));
const MessageHistoryContainer = lazy(() =>
  import("../Messages/MessageHistoryContainer")
);
const ContactsContainer = lazy(() => import("../Contacts/ContactsContainer"));
const ContactContainer = lazy(() => import("../Contacts/ContactContainer"));
const NewContactContainer = lazy(() =>
  import("../Contacts/NewContactContainer")
);
const SearchContainer = lazy(() => import("../Search/SearchContainer"));

import { DashboardComponent } from "../../components";
import EmployeesContainer from "../../Employees/EmployeesContainer";
import EmployeeContainer from "../../Employees/EmployeeContainer";
import ExaminersComponent from "../../Examiners/ExaminersComponent";
import ExaminerComponent from "../../Examiners/ExaminerComponent";

import EventsComponent from "../../InfoEvents/EventsComponent";
import EventComponent from "../../InfoEvents/EventComponent";

import SubjectsComponent from "../../Subjects/SubjectsComponent";
import SubjectComponent from "../../Subjects/SubjectComponent";

import ExamResultsComponent from "../../ExamResults/ExamResultsComponent";

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#E22222",
    },
    primary: {
      main: "#416ff4",
    },
    warning: {
      main: "#EE6352",
    },
    background: {
      paper: "#fff",
      default: "#ecf0f4",
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['"Lato"', "sans-serif"].join(","),
  },
});

class AppContainer extends Component {
  componentDidMount() {
    this.removeListener = firebaseAuth().onAuthStateChanged((user) => {
      if (user) {
        this.props.authSuccess(user);
      } else {
        this.props.authFailed("Could not auth user or user signed out");
      }
    });
  }

  componentWillUnmount() {
    this.removeListener();
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<div>Loading...</div>}>
          <MainContainer>
            <Switch>
              <Route
                path="/login"
                component={UserIsNotAuthenticated(LoginContainer)}
              />
              <Route
                path="/"
                exact
                component={UserIsAuthenticated(DashboardComponent)}
              />
              <Route
                path="/applicants"
                exact
                component={UserIsAuthenticated(ApplicantsContainer)}
              />
              <Route
                path="/applicants/:id"
                exact
                component={UserIsAuthenticated(ApplicantContainer)}
              />
              <Route
                path="/contacts"
                exact
                component={UserIsAuthenticated(ContactsContainer)}
              />
              <Route
                path="/contacts/new"
                exact
                component={UserIsAuthenticated(NewContactContainer)}
              />
              <Route
                path="/contacts/:id"
                exact
                component={UserIsAuthenticated(ContactContainer)}
              />

              <Route
                path="/messages"
                exact
                component={UserIsAuthenticated(MessagesContainer)}
              />
              <Route
                path="/messages/:collection/:id"
                exact
                component={UserIsAuthenticated(MessageHistoryContainer)}
              />
              <Route
                exact
                path="/semesters/:semester"
                component={UserIsAuthenticated(StudentsContainer)}
              />
              <Route
                path="/semesters/:semester/student/new"
                exact
                component={UserIsAuthenticated(NewStudentContainer)}
              />
              <Route
                path="/semesters/:semester/student/:id"
                component={UserIsAuthenticated(StudentContainer)}
              />
              <Route
                path="/uploads"
                component={UserIsAuthenticated(UploadsContainer)}
              />
              <Route
                path="/exams"
                exact
                component={UserIsAuthenticated(ExamsContainer)}
              />
              <Route
                path="/exam_results"
                exact
                component={UserIsAuthenticated(ExamResultsComponent)}
              />
              <Route
                path="/examiners"
                exact
                component={UserIsAuthenticated(ExaminersComponent)}
              />
              <Route
                path="/examiners/:id"
                component={UserIsAuthenticated(ExaminerComponent)}
              />
              <Route
                path="/employees"
                exact
                component={UserIsAuthenticated(EmployeesContainer)}
              />
              <Route
                path="/employees/:id"
                exact
                component={UserIsAuthenticated(EmployeeContainer)}
              />
              <Route
                path="/events"
                exact
                component={UserIsAuthenticated(EventsComponent)}
              />
              <Route
                path="/events/:id"
                exact
                component={UserIsAuthenticated(EventComponent)}
              />
              <Route
                path="/search"
                component={UserIsAuthenticated(SearchContainer)}
              />
              <Route
                path="/settings"
                component={UserIsAuthenticated(SettingsContainer)}
              />
              <Route
                path="/subjects"
                exact
                component={UserIsAuthenticated(SubjectsComponent)}
              />
              <Route
                path="/subjects/:id"
                exact
                component={UserIsAuthenticated(SubjectComponent)}
              />
              <Route
                path="/changes"
                component={UserIsAuthenticated(ChangesContainer)}
              />
              <Route
                path="/users"
                exact
                component={UserIsAuthenticated(UsersContainer)}
              />
              <Route
                path="/users/:id"
                component={UserIsAuthenticated(UserContainer)}
              />
            </Switch>
          </MainContainer>
        </Suspense>
      </ThemeProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authSuccess: (user) => dispatch(authSuccess(user)),
    authFailed: () => dispatch(authFailed()),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(AppContainer));
