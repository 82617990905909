import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Add from "@material-ui/icons/Add";
import Collections from "@material-ui/icons/Collections";
const styles = theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    background: theme.palette.background.paper
  }
});

const StudentsActionsList = ({
  classes,
  handleCreateNewStudent,
  handleGetClassImages
}) => {
  return (
    <div className={classes.root}>
      <List>
        <ListItem dense button onClick={handleCreateNewStudent}>
          <ListItemIcon>
            <Add />
          </ListItemIcon>
          <ListItemText primary="Opret ny studerende" />
        </ListItem>
        <ListItem button onClick={handleGetClassImages}>
          <ListItemIcon>
            <Collections />
          </ListItemIcon>
          <ListItemText primary="Billedesæt og studiekortbilleder" />
        </ListItem>
      </List>
      <Divider />
    </div>
  );
};

StudentsActionsList.propTypes = {
  handleCreateNewStudent: PropTypes.func.isRequired,
  handleGetClassImages: PropTypes.func.isRequired
};

export default withStyles(styles)(StudentsActionsList);
