import React, { useState } from "react";
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  DataGrid,
  ValueFormatterParams,
  GridColDef,
  GridRowId,
} from "@material-ui/data-grid";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CancelIcon from "@material-ui/icons/Cancel";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import PeopleIcon from "@material-ui/icons/People";
import { green, red, orange } from "@material-ui/core/colors";

import { AppState } from "../reducers";
import { parseDate, parseRelativeDate } from "../helpers/parser";
import EventForm from "./components/EventForm";
import NumberCard from "./components/NumberCard";
import AddAttendeeDialog from "./components/AddAttendeeDialog";
import SendFinalReminderDialog from "./components/SendFinalReminderDialog";

const EVENTS_COLLECTION = "info_events";
const ATTENDEES_COLLECTION = "attendees";
const ATTENDEES_KEY = "attendees";

enum AttendeeStatus {
  Unconfirmed = "unconfirmed",
  Attending = "attending",
  NotAttending = "not_attending",
}
const formatParticipants = (params: ValueFormatterParams) => {
  const status = params.getValue("status");
  const attending = status === AttendeeStatus.Attending;

  return attending ? params.getValue("participants") : "-";
};
const formatStatus = (status = "unconfirmed"): string => {
  switch (status) {
    case AttendeeStatus.Unconfirmed:
      return "🟠";
    case AttendeeStatus.Attending:
      return "🟢";
    case AttendeeStatus.NotAttending:
      return "🔴";
  }
  return "🟠";
};

const formatEmailSent = (sent: boolean) => (sent ? "🟢" : "🔴");

const columns: GridColDef[] = [
  { field: "name", headerName: "Navn", width: 250 },
  { field: "email", headerName: "Email", width: 250 },
  {
    field: "participants",
    headerName: "Antal Deltagere",
    width: 150,
    valueFormatter: (params: ValueFormatterParams) =>
      formatParticipants(params),
  },
  {
    field: "createdAt",
    headerName: "Dato for tilmelding",
    width: 200,
    valueFormatter: (params: ValueFormatterParams) =>
      `${parseDate(
        params.getValue("createdAt"),
        "MMM Do"
      )} (${parseRelativeDate(params.getValue("createdAt"))})`,
  },
  {
    field: "status",
    headerName: "Status",
    width: 130,
    valueFormatter: (params: ValueFormatterParams) =>
      formatStatus(params.getValue("status") as string),
  },
  {
    field: "emailSent",
    headerName: "Deltager email afsendt",
    width: 195,
    valueFormatter: (params: ValueFormatterParams) =>
      formatEmailSent(params.getValue("emailSent") as boolean),
  },
  {
    field: "finalReminderSent",
    headerName: "Bekræftelses email afsendt",
    width: 220,
    valueFormatter: (params: ValueFormatterParams) =>
      formatEmailSent(params.getValue("finalReminderSent") as boolean),
  },
  {
    field: "followUpEmailSent",
    headerName: "Opfølgningsmail afsendt",
    width: 210,
    valueFormatter: (params: ValueFormatterParams) =>
      formatEmailSent(params.getValue("followUpEmailSent") as boolean),
  },
];

const EventComponent: React.FC = () => {
  const { id }: { id: string } = useParams();
  const [selection, setSelection] = useState<GridRowId[]>([]);
  const [addAttendeeOpen, setAddAttendeeOpen] = useState<boolean>(false);
  const [finalReminderOpen, setFinalReminderOpen] = useState<boolean>(false);

  useFirestoreConnect([
    {
      collection: EVENTS_COLLECTION,
      doc: id,
    },
    {
      collection: EVENTS_COLLECTION,
      doc: id,
      subcollections: [{ collection: ATTENDEES_COLLECTION }],
      storeAs: ATTENDEES_KEY,
    },
  ]);

  const event: InfoEvent = useSelector(
    ({ firestore: { data } }: AppState) =>
      data[EVENTS_COLLECTION] && data[EVENTS_COLLECTION][id]
  );

  const attendees: Attendee[] = useSelector(
    ({ firestore: { ordered } }: AppState) => ordered[ATTENDEES_KEY] || []
  );

  const firestore = useFirestore();
  const attendeesRef = firestore
    .collection(EVENTS_COLLECTION)
    .doc(id)
    .collection(ATTENDEES_COLLECTION);

  const sendEmails = async () => {
    if (
      !window.confirm(
        `Er du sikker på du vil sende emails til ${selection.length} deltagere?`
      )
    ) {
      return;
    }
    const batch = firestore.batch();

    for (const attendeeID of selection) {
      batch.update(attendeesRef.doc(attendeeID.toString()), {
        shouldSendConfirmation: true,
      });
    }
    try {
      await batch.commit();
    } catch (error) {
      console.error(error);
    }
  };

  const sendFollowUpEmails = async () => {
    if (
      !window.confirm(
        `Er du sikker på du vil sende opfølgnings emails til ${selection.length} deltagere?`
      )
    ) {
      return;
    }
    const batch = firestore.batch();

    for (const attendeeID of selection) {
      batch.update(attendeesRef.doc(attendeeID.toString()), {
        shouldSendFollowUp: true,
      });
    }
    try {
      await batch.commit();
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAttendees = async () => {
    if (
      !window.confirm(
        `Er du sikker på du vil slette ${selection.length} deltagere?`
      )
    ) {
      return;
    }
    const batch = firestore.batch();
    for (const attendeeID of selection) {
      batch.delete(attendeesRef.doc(attendeeID.toString()));
    }
    try {
      await batch.commit();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12} lg={10}>
        <Paper elevation={6} style={{ padding: 16 }}>
          <AddAttendeeDialog
            open={addAttendeeOpen}
            handleClose={() => setAddAttendeeOpen(false)}
            eventID={id}
          />
          <SendFinalReminderDialog
            open={finalReminderOpen}
            handleClose={() => setFinalReminderOpen(false)}
            eventID={id}
          />
          {event && <EventForm event={event} />}
          <Grid container spacing={3} item xs={12} style={{ marginTop: 24 }}>
            <Grid item xs>
              <NumberCard
                count={attendees.length}
                title={"Antal tilmeldte"}
                icon={<PeopleIcon />}
              />
            </Grid>
            <Grid item xs>
              <NumberCard
                count={
                  attendees.filter(
                    (a) => a.status === AttendeeStatus.Unconfirmed
                  ).length
                }
                title={"Afventer svar"}
                icon={<HourglassFullIcon style={{ color: orange[500] }} />}
              />
            </Grid>
            <Grid item xs>
              <NumberCard
                count={
                  attendees.filter(
                    (a) => a.status === AttendeeStatus.NotAttending
                  ).length
                }
                title={"Afmeldte"}
                icon={<CancelIcon style={{ color: red[500] }} />}
              />
            </Grid>
            <Grid item xs>
              <NumberCard
                count={attendees
                  .filter((a) => a.status === AttendeeStatus.Attending)
                  .map((a) => parseInt(a.participants))
                  .reduce((acc, next) => acc + next, 0)}
                title={"Kommer"}
                icon={<CheckBoxIcon style={{ color: green[500] }} />}
              />
            </Grid>
            <Grid item xs>
              {event && event.completed && (
                <NumberCard
                  count={event.attendance || 0}
                  title={"Mødte op"}
                  icon={<EventAvailableIcon style={{ color: green[500] }} />}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 24 }}>
            {event && (
              <ButtonGroup
                color="primary"
                aria-label="contained button group"
                style={{ marginBottom: 8 }}
              >
                <Button
                  disabled={event.completed || selection.length === 0}
                  color="secondary"
                  onClick={() => deleteAttendees()}
                >
                  Slet
                </Button>
                <Button
                  disabled={event.completed || selection.length === 0}
                  onClick={() => sendEmails()}
                >
                  Send deltager emails
                </Button>
                <Button
                  disabled={event.completed}
                  onClick={() => setFinalReminderOpen(true)}
                >
                  Send bekræftelsesemails
                </Button>
                <Button
                  disabled={event.completed || selection.length === 0}
                  onClick={() => sendFollowUpEmails()}
                >
                  Send Opfølgningsmail
                </Button>
                <Button onClick={() => setAddAttendeeOpen(true)}>Tilføj</Button>
              </ButtonGroup>
            )}
          </Grid>
          <Grid item xs={12}>
            <div style={{ minHeight: 1300 }}>
              <DataGrid
                autoHeight
                rows={attendees}
                columns={columns}
                pageSize={20}
                checkboxSelection
                onSelectionModelChange={(selection: any) =>
                  setSelection(selection.selectionModel)
                }
              />
            </div>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EventComponent;
