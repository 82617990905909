import React from "react";
import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
const PersonsTableFooter = props => (
  <TableFooter>
    <TableRow>
      <TablePagination {...props} />
    </TableRow>
  </TableFooter>
);
PersonsTableFooter.propTypes = {
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired
};
export default PersonsTableFooter;
