import React from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { parseDate } from "../../helpers/parser";
import { AppState } from "../../reducers";
const useStyles = makeStyles({
  card: {
    minWidth: 275,
    marginBottom: 16,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const MessageHistoryContainer: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const { collection, id }: { collection: string; id: string } = useParams();

  const messages = useSelector(
    ({ firestore: { ordered } }: AppState) => ordered.messages || []
  );

  useFirestoreConnect({
    collection: collection,
    doc: id,
    subcollections: [{ collection: "messages" }],
    storeAs: "messages",
    orderBy: ["sent", "desc"],
  });

  return (
    <Grid container justify="center">
      <Grid item xs={12} lg={8}>
        <Button color="primary" onClick={() => history.goBack()}>
          Tilbage
        </Button>
      </Grid>
      {messages.map((message: Message, idx: number) => {
        return (
          <Grid key={idx} item xs={12} lg={8}>
            <Card className={classes.card}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary">
                  {parseDate(message.sent, "LLL")}
                </Typography>
                <Typography variant="subtitle1">
                  Sendt til: {message.name}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                  {message.isForEmailDelivery ? message.email : null} •{" "}
                  {message.isForSMSDelivery ? message.phone : null}
                </Typography>
                <Typography component="p">{message.message}</Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MessageHistoryContainer;
