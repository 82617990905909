import React from "react";
import { useField, FieldAttributes, FieldArray } from "formik";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
  FormHelperText,
  FormGroup,
} from "@material-ui/core";

type CheckboxOption = { value: string; label: string };
type CheckboxGroupProps = {
  label: string;
  options: CheckboxOption[];
  row: boolean;
} & FieldAttributes<Record<string, unknown>>;

const CheckboxGroupField: React.FC<CheckboxGroupProps> = ({
  label,
  options,
  row,
  ...props
}) => {
  const [field, meta] = useField({ name: props.name, type: "checkbox" });
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <FieldArray
        name={field.name}
        render={(arrayHelpers) => (
          <FormGroup row={row}>
            {options.map((option) => (
              <FormControlLabel
                key={`${option.value}`}
                control={
                  <Checkbox
                    value={option.value}
                    checked={meta.value.includes(option.value)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        arrayHelpers.push(option.value);
                      } else {
                        const idx = field.value.indexOf(option.value);
                        arrayHelpers.remove(idx);
                      }
                    }}
                  />
                }
                label={option.label}
              />
            ))}
          </FormGroup>
        )}
      />
      <FormHelperText>{!!errorText && errorText}</FormHelperText>
    </FormControl>
  );
};

export default CheckboxGroupField;
