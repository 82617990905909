import React from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PermContactIcon from "@material-ui/icons/PermContactCalendar";
import SecurityIcon from "@material-ui/icons/Security";
import FolderIcon from "@material-ui/icons/Folder";
import SchoolIcon from "@material-ui/icons/School";

const StudentTabBar = ({ value, handleChange, isFetching }) => {
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      <Tab
        label="Generelle oplysninger"
        icon={<PermContactIcon />}
        disabled={isFetching}
      />
      <Tab
        label="Forsikring & Sikkerhed"
        icon={<SecurityIcon />}
        disabled={isFetching}
      />
      <Tab label="Journal mappe" icon={<FolderIcon />} disabled={isFetching} />
      <Tab
        label="Eksamensresultater"
        icon={<SchoolIcon />}
        disabled={isFetching}
      />
    </Tabs>
  );
};

StudentTabBar.propTypes = {
  value: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default StudentTabBar;
