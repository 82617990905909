import {
  CREATING_CERTIFICATE_REQUEST,
  CREATE_CERTIFICATE_REQUEST_SUCCESS,
  CREATE_CERTIFICATE_REQUEST_ERROR,
  TOGGLE_MODAL,
} from "../constants";

import { db, firestore } from "../helpers/firebaseHelper";
const COLLECTION = "certificateRequest";

export function setModalOpen(value, ids) {
  return (dispatch) => {
    dispatch({ type: TOGGLE_MODAL, value, ids });
  };
}

export function createCertificateRequest(file, fieldName, certificateName) {
  return async (dispatch, getState) => {
    dispatch({ type: CREATING_CERTIFICATE_REQUEST });
    const { ids } = getState().certificates;
    const students = getState().students.students.filter((s) =>
      ids.includes(s.id)
    );

    try {
      let data = {};

      const arrayBuffer = await readFile(file);
      const buffer = firestore.Blob.fromUint8Array(new Uint8Array(arrayBuffer));
      //eslint-disable-next-line no-unused-vars
      for (const student of students) {
        if (!student.studentId && !student.id) {
          throw new Error(
            `Kunne ikke hente ID for ${student.firstName} ${student.lastName}`
          );
        }

        data[
          student.studentId ? student.studentId : student.id
        ] = `${student.firstName} ${student.lastName}`;
      }
      const certificateRef = db.collection(COLLECTION).doc();
      await certificateRef.set({
        fieldName,
        certificateName,
        pdfData: buffer,
        data,
        status: "pending",
      });
      const unsubscribe = certificateRef.onSnapshot((snapshot) => {
        const data = snapshot.data();
        const { status } = data;

        switch (status) {
          case "error":
            dispatch({
              type: CREATE_CERTIFICATE_REQUEST_ERROR,
              error: data.error,
              status,
            });
            unsubscribe();
            break;
          case "working":
            dispatch({ type: CREATING_CERTIFICATE_REQUEST, status });
            break;
          case "success":
            dispatch({ type: CREATE_CERTIFICATE_REQUEST_SUCCESS, status });
            unsubscribe();
            break;
          default:
            break;
        }
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: CREATE_CERTIFICATE_REQUEST_ERROR,
        error: error.message,
      });
    }
  };
}

function readFile(file) {
  return new Promise((resolve, reject) => {
    var fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.readAsArrayBuffer(file);
  });
}
