import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  select: {
    width: 200
  }
});

const ExamClassSelect = ({
  classes,
  classNames,
  selectedClass,
  handleClassChange,
  isFetching
}) => (
  <form className={classes.root} autoComplete="off">
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="examClassSelect">Hold</InputLabel>
      <Select
        className={classes.select}
        value={selectedClass}
        onChange={handleClassChange}
        disabled={isFetching}
        inputProps={{
          name: 'examClass',
          id: 'examClassSelect'
        }}>
        {Object.keys(classNames).map(key => {
          const className = classNames[key];
          return (
            <MenuItem key={key} value={key}>
              {className}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  </form>
);

ExamClassSelect.propTypes = {
  classNames: PropTypes.object.isRequired,
  selectedClass: PropTypes.string.isRequired,
  handleClassChange: PropTypes.func.isRequired
};

export default withStyles(styles)(ExamClassSelect);
