import { firebase } from "../helpers/firebaseHelper";

interface StudentProps {
  admissionFeeReceived: boolean;
  birthday: Date;
  city: string | null;
  class: string;
  confirmationSent: boolean;
  contractReceived: boolean;
  contractReceivedTimestamp: Date;
  contractSent: boolean;
  contractSentDate: Date;
  createdAt: firebase.firestore.FieldValue;
  createdInEconomics: boolean;
  createdInSU: boolean;
  dischargeReasons: string[];
  discharged: boolean;
  dischargedDate: Date | null;
  email: string;
  examSubjects: string[];
  firstName: string;
  gender: string;
  graduated: boolean;
  graduatedDate: Date | null;
  hasAccount: boolean;
  id: string;
  imageUrl: string | null;
  infoLetter: boolean;
  introductionEmail: boolean;
  lastName: string;
  lectio: boolean;
  line: string;
  note: string | null;
  origin: string;
  phone: string | null;
  postcode: string | null;
  interviewer: string | null;
  rate: string | null;
  semester: string;
  ssn: string;
  street: string | null;
  studentCard: boolean;
  consentSigned: boolean;
  rulesSigned: boolean;
  booksIssued: boolean;
  subjectsNotPassed: string[];
  userId: string | null;
  workExperienceContract2: boolean;
  workExperienceContract: boolean;
  clothingTop: string | null;
  clothingBottom: string | null;
  clothingRemark: string | null;
}

export default class Student {
  constructor(obj: StudentProps) {
    this.admissionFeeReceived = obj.admissionFeeReceived;
    this.birthday = obj.birthday;
    this.city = obj.city;
    this.class = obj.class;
    this.confirmationSent = obj.confirmationSent;
    this.contractReceived = obj.contractReceived;
    this.contractReceivedTimestamp = obj.contractReceivedTimestamp;
    this.contractSent = obj.contractSent;
    this.contractSentDate = obj.contractSentDate;
    this.createdAt = obj.createdAt;
    this.createdInEconomics = obj.createdInEconomics;
    this.createdInSU = obj.createdInSU;
    this.dischargedDate = obj.dischargedDate;
    this.dischargeReasons = obj.dischargeReasons;
    this.discharged = obj.discharged;
    this.email = obj.email;
    this.examSubjects = obj.examSubjects || [];
    this.firstName = obj.firstName;
    this.gender = obj.gender;
    this.graduated = obj.graduated;
    this.graduatedDate = obj.graduatedDate;
    this.hasAccount = obj.hasAccount;
    this.id = obj.id;
    this.origin = obj.origin;
    this.imageUrl = obj.imageUrl;
    this.infoLetter = obj.infoLetter;
    this.introductionEmail = obj.introductionEmail;
    this.lastName = obj.lastName;
    this.lectio = obj.lectio;
    this.line = obj.line;
    this.note = obj.note;
    this.phone = obj.phone;
    this.postcode = obj.postcode;
    this.interviewer = obj.interviewer;
    this.rate = obj.rate;
    this.semester = obj.semester;
    this.ssn = obj.ssn;
    this.street = obj.street;
    this.studentCard = obj.studentCard;
    this.consentSigned = obj.consentSigned;
    this.rulesSigned = obj.rulesSigned;
    this.booksIssued = obj.booksIssued;
    this.subjectsNotPassed = obj.subjectsNotPassed;
    this.userId = obj.userId;
    this.workExperienceContract = obj.workExperienceContract;
    this.workExperienceContract2 = obj.workExperienceContract2;
    this.clothingTop = obj.clothingTop;
    this.clothingBottom = obj.clothingBottom;
    this.clothingRemark = obj.clothingRemark;
  }
  id: string;
  city: string | null;
  email: string;
  examSubjects: string[];
  birthday: Date;
  firstName: string;
  lastName: string;
  gender: string;
  imageUrl: string | null;
  phone: string | null;
  postcode: string | null;
  interviewer: string | null;
  ssn: string;
  street: string | null;
  admissionFeeReceived: boolean;
  confirmationSent: boolean;
  contractReceived: boolean;
  contractReceivedTimestamp: Date;
  contractSent: boolean;
  contractSentDate: Date;
  createdAt: any;
  createdInEconomics: boolean;
  createdInSU: boolean;
  dischargeReasons: string[];
  discharged: boolean;
  dischargedDate: Date | null;
  graduated: boolean;
  graduatedDate: Date | null;
  hasAccount: boolean;
  infoLetter: boolean;
  introductionEmail: boolean;
  origin: string;
  lectio: boolean;
  line: string;
  class: string;
  note: string | null;
  rate: string | null;
  semester: string;
  studentCard: boolean;
  consentSigned: boolean;
  rulesSigned: boolean;
  booksIssued: boolean;
  subjectsNotPassed: string[];
  userId: string | null;
  workExperienceContract2: boolean;
  workExperienceContract: boolean;
  clothingTop: string | null;
  clothingBottom: string | null;
  clothingRemark: string | null;
}
