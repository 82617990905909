import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useTheme } from "@material-ui/core/styles";

type MenuProps = {
  handleSendMessage: () => void;
  handleViewMessages: () => void;
  handleMoveToStudents: () => void;
  moveDisabled: boolean;
  deleteApplicant: () => void;
};

const ApplicantsActionMenu: React.FC<MenuProps> = ({
  handleSendMessage,
  handleViewMessages,
  handleMoveToStudents,
  moveDisabled,
  deleteApplicant,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-haspopup="true"
        color="primary"
        variant="contained"
        onClick={handleClick}
      >
        Handlinger
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSendMessage}>Påmind ansøger</MenuItem>
        <MenuItem onClick={handleViewMessages}>Se beskedhistorik</MenuItem>
        <MenuItem disabled={moveDisabled} onClick={handleMoveToStudents}>
          Flyt til studerende
        </MenuItem>
        <MenuItem
          onClick={deleteApplicant}
          style={{ color: theme.palette.error.main }}
        >
          Slet
        </MenuItem>
      </Menu>
    </>
  );
};

export default ApplicantsActionMenu;
