import {
  FETCHING_SETTINGS,
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_ERROR,
  UPDATING_SETTINGS,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_ERROR
} from '../constants';

const initialState = {
  isUpdating: false,
  isFetching: false,
  settings: {},
  error: {}
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_SETTINGS:
      return { ...state, isFetching: true };
    case FETCH_SETTINGS_SUCCESS:
      return { ...state, isFetching: false, settings: action.settings };
    case FETCH_SETTINGS_ERROR:
      return { ...state, isFetching: false, error: action.error };
    case UPDATING_SETTINGS:
      return { ...state, isUpdating: true };
    case UPDATE_SETTINGS_SUCCESS:
      return { ...state, isUpdating: false };
    case UPDATE_SETTINGS_ERROR:
      return { ...state, isUpdating: false, error: action.error };

    default:
      return state;
  }
};

export default settings;
