import React, { useState } from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  BarChart,
  Bar,
  ResponsiveContainer,
} from "recharts";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import amber from "@material-ui/core/colors/amber";
import { parseLine } from "../../helpers/parser";

const useStyles = makeStyles(() => ({
  tooltip: {
    background: "rgba(255, 255, 255, 1)",
    padding: 10,
    border: "solid 0.5px black",
  },
}));
function CustomTooltip({ payload, label, active }: any) {
  const classes = useStyles();

  if (active && payload) {
    return (
      <div className={classes.tooltip}>
        <p className="labelTitle">{label}</p>
        {payload.map((p: any, idx: number) => {
          const data = p.payload;
          const dataKey = p.dataKey;
          const lineTotal = data[dataKey];
          const femaleLineTotal = data[`${dataKey}_female`];
          const maleLineTotal = data[`${dataKey}_male`];
          const changeTotal = data[`${dataKey}_change`];
          let text: string;
          if (
            dataKey === "sport" ||
            dataKey === "outdoor" ||
            dataKey === "x-outdoor"
          ) {
            text = `${parseLine(
              dataKey
            )}: ${lineTotal} / Kvinder: ${femaleLineTotal} / Mænd: ${maleLineTotal} / Udmeldte: ${changeTotal}`;
          } else {
            text = `${parseLine(
              dataKey
            )}: ${lineTotal} / Kvinder: ${femaleLineTotal} / Mænd: ${maleLineTotal}`;
          }
          return (
            <React.Fragment key={idx}>
              <p className="label" style={{ color: p.fill }}>
                <strong>{`${p.name}`}</strong>
              </p>
              <p className="label" style={{ color: p.fill }}>
                {text}
              </p>
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  return null;
}

type ChartProps = {
  data: ChartEntry[];
};
export const StudentsBarChart: React.FC<ChartProps> = ({ data }) => {
  const [state, setState] = useState({
    sport: true,
    outdoor: true,
    ["x-outdoor"]: true,
    total: true,
    discharged: true,
  });

  const setDisplay = (name: string) => (value: boolean) => {
    setState({ ...state, [name]: value });
  };

  const colors = {
    sport: "#9C1918",
    outdoor: "#5B5A37",
    discharged: "#F78AE0",
    "x-outdoor": "#364B55",
    total: amber[300],
  };

  return (
    <React.Fragment>
      <FormControlLabel
        control={
          <Checkbox
            checked={state.sport}
            onChange={() => {
              setDisplay("sport")(!state.sport);
            }}
            name="sport"
          />
        }
        label="Classic Sport"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.outdoor}
            onChange={() => {
              setDisplay("outdoor")(!state.outdoor);
            }}
            name="outdoor"
          />
        }
        label="Friluft"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={state["x-outdoor"]}
            onChange={() => {
              setDisplay("x-outdoor")(!state["x-outdoor"]);
            }}
            name="xOutdoor"
          />
        }
        label="X-Outdoor"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.discharged}
            onChange={() => {
              setDisplay("discharged")(!state.discharged);
            }}
            name="discharged"
          />
        }
        label="Udmeldt"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.total}
            onChange={() => {
              setDisplay("total")(!state.total);
            }}
            name="total"
          />
        }
        label="Total"
      />
      <ResponsiveContainer width={"100%"} height="80%">
        <BarChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {state.sport && (
            <Bar
              type="monotone"
              name="Classic Sports"
              dataKey="sport"
              fill={colors["sport"]}
            />
          )}
          {state.outdoor && (
            <Bar
              type="monotone"
              name="Friluft"
              dataKey="outdoor"
              fill={colors["outdoor"]}
            />
          )}
          {state["x-outdoor"] && (
            <Bar
              type="monotone"
              name="X-Outdoor"
              dataKey="x-outdoor"
              fill={colors["x-outdoor"]}
            />
          )}
          {state.discharged && (
            <Bar
              type="monotone"
              name="Udmeldte"
              dataKey="discharged"
              fill={colors["discharged"]}
            />
          )}
          {state.total && (
            <Bar
              type="monotone"
              name="Total"
              dataKey="total"
              fill={colors["total"]}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

export default StudentsBarChart;
