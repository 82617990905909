import React, { useState } from "react";
import { useFirestore } from "react-redux-firebase";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import EventsTable from "./components/EventsTable";
import { FieldValue } from "../helpers/firebaseHelper";
import Header from "./components/Header";
import NextEventStatsPieChart from "./components/NextEventStatsPieChart";
import EventStatsLineChart from "./components/EventStatsLineChart";
import useEventStats from "./useEventStats";
import useNextEventStats from "./useNextEventStats";
const EVENTS_COLLECTION = "info_events";

const EventsComponent: React.FC = () => {
  const firestore = useFirestore();
  const [selection, setSelection] = useState<InfoEvent[]>([]);
  const [semester, setSemester] = useState<string>("2021");

  const eventStats = useEventStats({ semester });
  const { nextEventStats, nextEventDate } = useNextEventStats();

  const deleteSelected = async () => {
    try {
      const eventsRef = firestore.collection(EVENTS_COLLECTION);
      const batch = firestore.batch();

      if (
        window.confirm(
          `Er du sikker på du vil slette ${selection.length} infoaftener?`
        )
      ) {
        for (const infoEvent of selection) {
          const eventRef = eventsRef.doc(infoEvent.id);
          batch.delete(eventRef);
        }
        await batch.commit();
      }
    } catch (error: any) {
      console.error(error);
      window.alert(`Kunne ikke slette infoaftener ${error.message}`);
    }
  };

  const handleCreateNewEvent = async () => {
    try {
      const doc = firestore.collection(EVENTS_COLLECTION).doc();
      await doc.set({
        date: FieldValue.serverTimestamp(),
        location: "DGI Byen",
        id: doc.id,
        isPublic: false,
        completed: false,
        attendance: 0,
      });
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} style={{ marginBottom: 8 }}>
        <Header
          selection={selection}
          handleCreateEvent={handleCreateNewEvent}
          handleDeleteEvents={deleteSelected}
          semester={semester}
          setSemester={setSemester}
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <Typography variant="subtitle1" style={{ padding: 8, fontWeight: 900 }}>
          Oversigt over infoaften
        </Typography>
        <Box
          mx="auto"
          pt={2}
          boxShadow={2}
          bgcolor="background.paper"
          height={400}
          borderRadius={5}
        >
          <EventStatsLineChart data={eventStats} />
        </Box>
      </Grid>
      <Grid item xs={12} md={5}>
        <Typography variant="subtitle1" style={{ padding: 8, fontWeight: 900 }}>
          Overblik over kommende infoaften
        </Typography>
        <Box
          boxShadow={2}
          height={400}
          mx="auto"
          bgcolor="background.paper"
          borderRadius={5}
        >
          <NextEventStatsPieChart
            nextEventDate={nextEventDate}
            data={nextEventStats}
          />
        </Box>
      </Grid>

      <Grid item xs={12} style={{ marginBottom: 8 }}>
        <EventsTable setSelection={setSelection} semester={semester} />
      </Grid>
    </Grid>
  );
};

export default EventsComponent;
