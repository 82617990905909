import React from "react";
const SubjectsIcon: React.FC = () => (
  <>
    <svg
      width="200"
      height="150"
      viewBox="0 0 800 600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M399.709 571.824C379.513 571.824 360.065 567.336 345.105 558.36L33.9361 378.839C18.2281 369.863 10 357.895 10 345.179C10 332.463 18.2281 320.495 33.9361 311.519L342.861 131.999C372.781 114.795 422.149 114.795 452.069 131.999L763.238 311.519C778.946 320.495 787.174 332.463 787.174 345.179C787.174 357.895 778.946 369.863 763.238 378.839L454.313 558.36C439.353 567.336 419.157 571.824 399.709 571.824ZM396.717 126.015C378.017 126.015 360.065 129.755 345.853 137.983L36.9281 317.503C24.2121 324.983 16.7322 334.707 16.7322 344.431C16.7322 354.903 24.2121 364.627 36.9281 372.107L348.097 551.628C376.521 568.084 422.149 568.084 449.825 551.628L758.75 372.107C771.466 364.627 778.946 354.903 778.946 345.179C778.946 334.707 771.466 324.983 758.75 317.503L447.581 137.983C434.117 130.503 415.417 126.015 396.717 126.015Z"
        fill="#B5CDFB"
      ></path>
      <path
        opacity="0.1"
        d="M705.969 319.358L448.109 164.8C423.935 149.988 384.884 149.988 360.709 164.8L104.709 319.358C80.5345 334.17 80.5345 357.354 104.709 372.166L362.569 526.724C386.743 541.536 426.414 541.536 449.969 526.724L705.969 372.166C730.144 357.998 730.144 334.17 705.969 319.358Z"
        fill="#B5CDFB"
      ></path>
      <path
        d="M768.12 220.423L456.952 40.903C427.779 23.699 380.655 23.699 351.483 40.903L42.5587 220.423C13.3867 237.627 13.3867 264.556 42.5587 281.76L353.727 461.28C382.899 478.484 430.771 478.484 459.195 461.28L768.12 281.76C797.292 265.304 797.292 237.627 768.12 220.423Z"
        fill="#C4B6FF"
      ></path>
      <path
        d="M396.146 70.8467C393.022 69.0615 388.113 69.0615 385.435 70.8467L229.234 161.443C226.11 163.229 226.11 165.906 229.234 167.692C232.358 169.477 237.267 169.477 239.945 167.692L396.146 77.0947C399.27 75.3096 399.27 72.6318 396.146 70.8467Z"
        fill="#7D55FF"
      ></path>
      <path
        d="M426.475 102.981C417.995 98.0715 404.606 98.0715 396.127 102.981C387.647 107.89 388.094 115.477 396.127 120.386C404.16 125.295 417.995 125.295 426.475 120.386C434.954 115.477 434.954 107.89 426.475 102.981Z"
        fill="#7D55FF"
      ></path>
      <path
        d="M359.533 141.809C351.054 136.9 337.665 136.9 329.186 141.809C320.706 146.718 321.152 154.305 329.186 159.214C337.219 164.123 351.054 164.123 359.533 159.214C368.013 154.305 368.013 146.718 359.533 141.809Z"
        fill="#7D55FF"
      ></path>
      <path
        d="M292.588 180.635C284.108 175.726 270.72 175.726 262.24 180.635C253.761 185.544 254.207 193.131 262.24 198.04C270.72 202.949 284.108 202.949 292.588 198.04C301.067 193.131 301.067 185.544 292.588 180.635Z"
        fill="#7D55FF"
      ></path>
      <path
        d="M225.647 219.459C217.167 214.55 203.778 214.55 195.299 219.459C186.819 224.368 187.266 231.955 195.299 236.865C203.332 241.774 217.167 241.774 225.647 236.865C234.126 232.402 234.126 224.368 225.647 219.459Z"
        fill="#7D55FF"
      ></path>
      <path
        d="M159.157 258.287C150.677 253.378 137.288 253.378 128.809 258.287C120.329 263.197 120.329 270.783 128.809 275.693C137.288 280.602 150.677 280.602 159.157 275.693C167.19 271.23 167.19 263.197 159.157 258.287Z"
        fill="#7D55FF"
      ></path>
      <path
        d="M677.307 252.934L465.766 131.097C462.642 129.312 458.179 129.312 455.055 131.097L442.112 138.684C438.988 140.469 439.435 143.146 442.112 144.932L653.654 266.769C656.778 268.554 661.241 268.554 664.365 266.769L677.307 259.182C680.431 257.397 680.431 254.719 677.307 252.934Z"
        fill="#7D55FF"
      ></path>
      <path
        d="M610.808 291.761L398.821 169.478C395.696 167.692 391.234 167.692 388.11 169.478L375.167 177.064C372.043 178.85 372.489 181.527 375.167 183.313L586.708 305.596C589.832 307.381 594.295 307.381 597.419 305.596L610.362 298.009C613.486 296.224 613.486 293.546 610.808 291.761Z"
        fill="#7D55FF"
      ></path>
      <path
        d="M543.858 330.587L332.317 208.304C329.193 206.519 324.73 206.519 321.606 208.304L308.663 215.891C305.539 217.676 305.986 220.354 308.663 222.139L520.205 344.422C523.329 346.207 527.791 346.207 530.915 344.422L543.858 336.835C546.982 335.05 546.982 332.372 543.858 330.587Z"
        fill="#7D55FF"
      ></path>
      <path
        d="M477.362 369.415L265.821 247.132C262.696 245.347 258.234 245.347 255.11 247.132L242.167 254.719C239.043 256.504 239.489 259.182 242.167 260.967L453.708 383.25C456.832 385.035 461.295 385.035 464.419 383.25L477.362 375.663C480.04 373.878 480.04 371.2 477.362 369.415Z"
        fill="#7D55FF"
      ></path>
      <path
        d="M410.416 408.243L198.875 285.96C195.751 284.175 191.288 284.175 188.164 285.96L175.222 293.547C172.098 295.332 172.544 298.01 175.222 299.795L386.763 422.078C389.887 423.863 394.35 423.863 397.474 422.078L410.416 414.491C413.54 412.706 413.54 410.028 410.416 408.243Z"
        fill="#7D55FF"
      ></path>
    </svg>
  </>
);

export default SubjectsIcon;
