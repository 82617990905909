import { db, firebaseAuth } from '../helpers/firebaseHelper';
import { openSnackbar } from './snackbar';
import {
  FETCHING_SETTINGS,
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_ERROR,
  UPDATING_SETTINGS,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_ERROR
} from '../constants';

function fetchingSettings() {
  return {
    type: FETCHING_SETTINGS
  };
}

function fetchSettingsSuccess(settings) {
  return {
    type: FETCH_SETTINGS_SUCCESS,
    settings
  };
}

function fetchSettingsError(error) {
  return {
    type: FETCH_SETTINGS_ERROR,
    error
  };
}

function updatingSettings() {
  return {
    type: UPDATING_SETTINGS
  };
}

function updateSettingsSuccess() {
  return {
    type: UPDATE_SETTINGS_SUCCESS
  };
}

function updateSettingsError(error) {
  return {
    type: UPDATE_SETTINGS_ERROR,
    error
  };
}

export function fetchSettings(type = 'notifications') {
  return async dispatch => {
    dispatch(fetchingSettings());
    firebaseAuth().onAuthStateChanged(async user => {
      if (!user) return;
      try {
        const adminDoc = await db
          .collection('admins')
          .doc(user.uid)
          .collection('settings')
          .doc(type)
          .get();
        if (!adminDoc.exists) {
          dispatch(
            fetchSettingsError({ message: 'Kunne ikke finde indstillinger' })
          );
          dispatch(openSnackbar('Kunne ikke finde indstillinger'));
          return;
        }
        dispatch(fetchSettingsSuccess(adminDoc.data()));
      } catch (e) {
        console.log(e);
        dispatch(fetchSettingsError(e));
        dispatch(openSnackbar('Kunne ikke hente indstillinger'));
      }
    });
  };
}

export function updateSetting(type, value) {
  return async dispatch => {
    dispatch(updatingSettings());
    const user = firebaseAuth().currentUser;
    if (!user) return;

    try {
      const adminRef = db
        .collection('admins')
        .doc(user.uid)
        .collection('settings')
        .doc(type);
      await adminRef.set(value, { merge: true });
      dispatch(updateSettingsSuccess());
      dispatch(openSnackbar('Indstilling opdateret'));
    } catch (e) {
      console.log(e);
      dispatch(updateSettingsError(e));
      dispatch(openSnackbar('Kunne ikke opdatere indstilling'));
    }
  };
}
