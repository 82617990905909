import React, { useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { openSnackbar } from "../actions/snackbar";
import { submit } from "../actions/sheets";
import { FieldValue } from "../helpers/firebaseHelper";
import EmployeesTable from "./components/EmployeesTable";
import Header from "./components/Header";
import { IEmployee, spreadsheetFields, translatedFields } from "./Employee";
import { keyOptions } from "../helpers/keyOptions";

type Selection = IEmployee[];
const EMPLOYEES_COLLECTION = "employees";

export const EmployeesContainer: React.FC = () => {
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selection, setSelection] = useState<Selection>([]);

  const handleCreateEmployee = async () => {
    try {
      const docRef = firestore.collection(EMPLOYEES_COLLECTION).doc();
      const keys: { [key: string]: boolean } = {};
      Object.keys(keyOptions).map((opt) => (keys[opt] = false));

      const employee: IEmployee = {
        firstName: "Ny",
        lastName: "Ansat",
        id: docRef.id,
        email: "",
        phone: "",
        address: "",
        city: "",
        postcode: "",
        createdAt: FieldValue.serverTimestamp(),
        title: "",
        note: "",
        keys,
        isArchived: false,
      };
      await docRef.set(employee);
      history.push(`/employees/${docRef.id}`);
    } catch (error: any) {
      dispatch(
        openSnackbar(`🛑 Kunne ikke oprette ny ansat: ${error.message}`)
      );
    }
  };

  const handleDeleteEmployees = async () => {
    if (
      window.confirm(
        `Er du sikker på du vil slette ${selection.length} ansatt(e)`
      )
    ) {
      try {
        const collectionRef = firestore.collection(EMPLOYEES_COLLECTION);
        const batch = firestore.batch();
        selection
          .map((data) => data.id)
          .forEach((id) => batch.delete(collectionRef.doc(id)));
        await batch.commit();
        dispatch(openSnackbar(`✅ Slettede ${selection.length} ansatt(e)`));
        setSelection([]);
      } catch (error: any) {
        dispatch(
          openSnackbar(`🛑 Kunne ikke slette ansatte: ${error.message}`)
        );
      }
    }
  };

  const handleCreateSpreadsheet = () => {
    const data: { [key: number]: any[] } = {
      0: Object.values(translatedFields),
    };
    let i = 1;
    for (const employee of selection) {
      const entry = [];
      for (const key of Object.keys(spreadsheetFields)) {
        if (employee[key]) {
          entry.push(employee[key]);
        } else {
          entry.push(null);
        }
      }
      data[i] = entry;
      i++;
    }
    dispatch(
      openSnackbar(`✅ Opretter excel ark for ${selection.length} ansatte`)
    );
    dispatch(submit(`Ansatte ${new Date().toLocaleDateString("da-DK")}`, data));
  };
  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Header
          selection={selection}
          newEmployee={() => handleCreateEmployee()}
          deleteEmployees={() => handleDeleteEmployees()}
          createSpreadsheet={() => handleCreateSpreadsheet()}
        />
      </div>
      <EmployeesTable setSelection={setSelection} />
    </div>
  );
};

export default EmployeesContainer;
