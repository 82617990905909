import {
  FETCHING_EXAMINERS,
  FETCH_EXAMINERS_SUCCESS,
  FETCH_EXAMINERS_ERROR,
  DELETING_EXAMINERS,
  DELETE_EXAMINERS_SUCCESS,
  DELETE_EXAMINERS_ERROR,
  FETCHING_EXAMINER,
  FETCH_EXAMINER_SUCCESS,
  FETCH_EXAMINER_ERROR,
  CREATING_EXAMINER,
  CREATE_EXAMINER_SUCCESS,
  CREATE_EXAMINER_ERROR,
  FETCHING_EXAMINER_PAGINATION,
  FETCH_EXAMINER_PAGINATION_SUCCESS,
  FETCH_EXAMINER_PAGINATION_ERROR
} from '../constants';

const initialState = {
  examiners: {},
  isFetching: false,
  error: {},
  isDeleting: false,
  deleteSuccess: false,
  deleteError: {},
  examiner: {},
  isFetchingExaminer: false,
  examinerError: {},
  isCreating: false,
  createError: {},
  isFetchingPagination: false,
  pagination: {},
  paginationError: {}
};

const examiners = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_EXAMINERS:
      return { ...state, isFetching: true };
    case FETCH_EXAMINERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        examiners: { ...action.examiners }
      };
    case FETCH_EXAMINERS_ERROR:
      return { ...state, isFetching: false, error: action.error };

    case DELETING_EXAMINERS:
      return { ...state, isDeleting: true };
    case DELETE_EXAMINERS_SUCCESS:
      return { ...state, isDeleting: false, deleteSuccess: true };
    case DELETE_EXAMINERS_ERROR:
      return { ...state, isDeleting: false, deleteError: action.error };

    case FETCHING_EXAMINER:
      return { ...state, isFetchingExaminer: true, examiner: {} };
    case FETCH_EXAMINER_SUCCESS:
      return { ...state, isFetchingExaminer: false, examiner: action.examiner };
    case FETCH_EXAMINER_ERROR:
      return { ...state, isFetchingExaminer: false, error: action.error };
    case CREATING_EXAMINER:
      return { ...state, isCreating: true };
    case CREATE_EXAMINER_SUCCESS:
      return { ...state, isCreating: false };
    case CREATE_EXAMINER_ERROR:
      return { ...state, isCreating: false, createError: action.error };
    case FETCHING_EXAMINER_PAGINATION:
      return { ...state, isFetchingPagination: false, pagination: {} };
    case FETCH_EXAMINER_PAGINATION_SUCCESS:
      return {
        ...state,
        isFetchingPagination: false,
        pagination: action.pagination
      };
    case FETCH_EXAMINER_PAGINATION_ERROR:
      return {
        ...state,
        isFetchingPagination: false,
        paginationError: action.paginationError
      };
    default:
      return state;
  }
};

export default examiners;
