export enum DropzoneFileTypes {
  Files = "files",
  MeritDocs = "meritDocs",
  MedicalDocs = "medicalDocs",
  ImportantDocs = "importantDocs",
  ExamDocs = "examDocs",
}
export type DropzoneFileListProps = {
  collection: string;
  id: string;
  multiple: boolean;
  heading: string;
  type: DropzoneFileTypes;
};
