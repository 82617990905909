import React, { Component } from "react";
import { DrawerComponent, NewSemesterDialog } from "../../components";
import { connect } from "react-redux";
import { fetchClassTemplates, createClass } from "../../actions/templates";
import { fetchSemesters, createSemester } from "../../actions/semesters";

class DrawerContainer extends Component {
  state = {
    openStudents: false,
    openExams: false,
    openArchived: false,
    openSemesterDialog: false,
  };
  handleToggleExpansion = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  handleToggleDialog = () => {
    this.setState({ openSemesterDialog: !this.state.openSemesterDialog });
  };

  handleToggleArchivedSemestersDialog = () => {
    this.setState({ openArchived: !this.state.openArchived });
  };

  handleCreateClass = (c) => {
    if (c === "") {
      return;
    }
    const lowercase = c.toLowerCase();
    const hyphenated = lowercase.replace(" ", "-");
    const classTemplate = { [hyphenated]: c };

    this.props.createClass(classTemplate);
  };

  handleCreateSemester = (s) => {
    this.props.createSemester(s);
  };

  componentDidMount() {
    this.props.fetchClassTemplates();
    this.props.fetchSemesters();
  }

  render() {
    const {
      handleToggleDrawer,
      open,
      classTemplates,
      semesters,
      didCreateSemester,
    } = this.props;

    return (
      <div className="no-print">
        <DrawerComponent
          open={open}
          handleToggleDrawer={handleToggleDrawer}
          handleToggleExpansion={this.handleToggleExpansion}
          handleToggleDialog={() => this.handleToggleDialog()}
          handleToggleArchivedSemestersDialog={() =>
            this.handleToggleArchivedSemestersDialog()
          }
          openStudents={this.state.openStudents}
          openExams={this.state.openExams}
          semesters={semesters}
        />
        <NewSemesterDialog
          open={this.state.openSemesterDialog && !didCreateSemester}
          handleToggleDialog={this.handleToggleDialog}
          classTemplates={classTemplates}
          handleCreateClass={(c) => this.handleCreateClass(c)}
          handleCreateSemester={(s) => this.handleCreateSemester(s)}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ templates, semesters }) => {
  return {
    classTemplates: templates.classes,
    semesters: semesters.semesters,
    didCreateSemester: semesters.didCreateSemester,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchClassTemplates: () => dispatch(fetchClassTemplates()),
    fetchSemesters: () => dispatch(fetchSemesters()),
    createClass: (c) => dispatch(createClass(c)),
    createSemester: (s) => dispatch(createSemester(s)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerContainer);
