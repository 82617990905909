import { useState, useEffect } from "react";
import { useFirestore } from "react-redux-firebase";
import { parseDate } from "../helpers/parser";

const EVENTS_COLLECTION = "info_events";

type StatProps = {
  semester: string;
};

type MappedEvent = {
  name: string;
  attendance: number;
  attending: number;
  not_attending: number;
  unconfirmed: number;
  completed: boolean;
  interviews: number;
  interviews_sport: number;
  interviews_outdoor: number;
  interviews_xoutdoor: number;
  facebook_interested: number;
  facebook_participants: number;
};

const useEventStats = ({ semester }: StatProps): MappedEvent[] => {
  const firestore = useFirestore();
  const [events, setEvents] = useState<MappedEvent[]>([]);

  useEffect(() => {
    async function fetch() {
      try {
        const eventsRef = firestore.collection(EVENTS_COLLECTION);
        const query = eventsRef
          .orderBy("date")
          .startAt(new Date(`${semester}-09-01`)) // super basic way to calculate the date range, semester is just a string e.g. '2020'
          .endAt(new Date(`${parseInt(semester) + 1}-08-31`));
        const eventDocs = await query.get();

        const stats = eventDocs.docs
          .filter((doc) => doc.exists)
          .map((doc) => doc.data() as InfoEvent)
          .map((infoEvent) => ({
            name: parseDate(infoEvent.date),
            attendance: infoEvent.attendance || 0,
            attending: infoEvent.attending || 0,
            not_attending: infoEvent.not_attending || 0,
            unconfirmed: infoEvent.unconfirmed || 0,
            completed: infoEvent.completed,
            interviews: infoEvent.interviews || 0,
            interviews_sport: infoEvent.interviews_sport || 0,
            interviews_outdoor: infoEvent.interviews_outdoor || 0,
            interviews_xoutdoor: infoEvent.interviews_xoutdoor || 0,
            facebook_interested: infoEvent.facebook_interested || 0,
            facebook_participants: infoEvent.facebook_participants || 0,
          }));
        setEvents(stats);
      } catch (error) {
        console.error(error);
      }
    }
    fetch();
  }, [semester]);
  return events;
};

export default useEventStats;
