import React from "react";
export default function EventsIcon() {
  return (
    <>
      <svg
        width="200"
        height="150"
        viewBox="0 0 800 600"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M623.264 196.214C547.535 215.032 501.235 282.962 521.678 344.368C529.292 367.105 549.612 378.799 575.895 382.471C602.185 386.144 634.256 381.746 664.929 372.452C695.597 363.16 724.773 349.001 745.274 333.236C755.526 325.352 763.569 317.097 768.556 308.885C773.54 300.678 775.436 292.582 773.537 284.941C757.848 221.803 698.805 177.443 623.264 196.214ZM519.952 344.944C499.068 282.216 546.419 213.434 622.826 194.448C699.422 175.415 759.392 220.472 775.303 284.502C777.354 292.757 775.259 301.354 770.111 309.83C764.967 318.3 756.739 326.715 746.384 334.678C725.669 350.608 696.282 364.854 665.457 374.194C634.637 383.532 602.286 387.995 575.644 384.273C548.995 380.55 527.872 368.595 519.952 344.945L519.952 344.944Z"
          fill="#7D55FF"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M549.867 220.862C520.468 226.14 499.11 239.419 493.938 263.248C478.707 333.757 534.584 405.953 619.223 424.172C703.617 442.339 768.521 385.084 783.804 314.336C785.654 305.776 782.996 297.041 776.708 288.43C770.413 279.811 760.529 271.388 748.097 263.533C723.24 247.827 688.392 234.509 652.37 226.445C616.343 218.379 579.239 215.59 549.867 220.862ZM778.177 287.356C784.662 296.236 787.576 305.495 785.583 314.721C770.13 386.256 704.4 444.369 618.84 425.952C533.525 407.587 476.655 334.636 492.159 262.863L492.16 262.862C497.567 237.949 519.876 224.397 549.545 219.071C579.241 213.74 616.607 216.573 652.768 224.669C688.933 232.766 723.99 246.148 749.069 261.995C761.606 269.917 771.698 278.484 778.177 287.356Z"
          fill="#B5CDFB"
        ></path>
        <path
          opacity="0.2"
          d="M10 296.158C10 272.447 29.2209 253.227 52.9311 253.227H514.839C538.549 253.227 557.77 272.447 557.77 296.158V324.697C557.77 348.407 538.549 367.628 514.839 367.628H52.9311C29.2209 367.628 10 348.407 10 324.697V296.158Z"
          fill="#B5CDFB"
        ></path>
        <path
          d="M107.599 282.916L115.053 281.589L114.923 280.319C114.773 278.834 115.49 277.396 116.775 276.636C117.573 276.164 118.386 275.911 118.755 276.494C119.603 277.841 118.697 278.773 118.697 278.773L118.398 281.339L118.774 289.606C118.525 289.859 115.168 288.325 114.823 288.225L107.949 289.334L107.599 282.916Z"
          fill="#E8AC7B"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76.6301 264.737L76.6311 264.738L76.6301 264.737ZM75.3577 255.571C73.1804 258.452 73.7476 262.551 76.6233 264.732C76.6244 264.733 76.6265 264.734 76.6301 264.737C76.6408 264.745 76.6628 264.762 76.695 264.787C76.7594 264.838 76.865 264.921 77.0063 265.035C77.2894 265.263 77.7128 265.612 78.2325 266.065C79.2792 266.977 80.6783 268.277 82.0963 269.825M76.6253 264.733L76.6282 264.736L76.6253 264.733ZM75.3577 255.571C77.5373 252.688 81.6417 252.117 84.5252 254.297L75.3577 255.571ZM84.5252 254.297L80.8187 259.2L84.5252 254.297ZM80.8187 259.2C84.5252 254.297 84.5264 254.298 84.5264 254.298L80.8187 259.2ZM84.5264 254.298L84.5276 254.299L84.5264 254.298ZM84.5276 254.299L84.5304 254.301L84.5276 254.299ZM84.5304 254.301L84.5367 254.305L84.5304 254.301ZM84.5367 254.305L84.5527 254.318L84.5367 254.305ZM84.5527 254.318L84.5982 254.352L84.5527 254.318ZM84.5982 254.352C84.6344 254.38 84.6823 254.417 84.7412 254.463L84.5982 254.352ZM84.7412 254.463C84.8588 254.555 85.0204 254.682 85.2201 254.843L84.7412 254.463ZM85.2201 254.843C85.619 255.165 86.1726 255.622 86.8329 256.198L85.2201 254.843ZM86.8329 256.198C88.1463 257.342 89.9194 258.987 91.7504 260.986L86.8329 256.198ZM91.7504 260.986C93.6151 263.023 94.9838 265.696 96.0161 267.978L91.7504 260.986ZM96.0161 267.978C96.9091 269.952 97.7779 272.169 98.5867 274.232L96.0161 267.978ZM98.5867 274.232L98.5879 274.235C98.7508 274.651 98.9113 275.061 99.0691 275.461M99.0691 275.461C100.057 277.965 100.975 280.202 101.937 282.077L99.0691 275.461ZM101.937 282.077C102.194 282.579 102.437 283.02 102.665 283.405L101.937 282.077ZM102.665 283.405L108.612 281.014C112.196 280.543 115.483 283.067 115.953 286.651C116.424 290.235 113.9 293.521 110.317 293.992L97.7337 297.255L95.4965 295.257C93.199 293.206 91.5329 290.476 90.2883 288.048C89.0033 285.541 87.8794 282.766 86.8923 280.263C86.7095 279.799 86.5318 279.346 86.3582 278.904C85.5507 276.845 84.8315 275.012 84.0903 273.373C83.1491 271.293 82.4758 270.24 82.0963 269.825"
          fill="#C4B6FF"
        ></path>
        <path
          d="M82.0963 269.825L75.3577 255.571L84.5252 254.297L80.8187 259.2L84.5264 254.298L84.5276 254.299L84.5304 254.301L84.5367 254.305L84.5527 254.318L84.5982 254.352L84.7412 254.463L85.2201 254.843L86.8329 256.198L91.7504 260.986L96.0161 267.978L98.5867 274.232L98.5879 274.235C98.7508 274.651 98.9113 275.061 99.0691 275.461C100.057 277.965 100.975 280.202 101.937 282.077C102.194 282.579 102.437 283.02 102.665 283.405L82.0963 269.825Z"
          fill="#C4B6FF"
        ></path>
        <g opacity="0.2">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M76.6301 264.737L76.6311 264.738L76.6301 264.737ZM75.3577 255.571C73.1804 258.452 73.7476 262.551 76.6233 264.732C76.6244 264.733 76.6265 264.734 76.6301 264.737C76.6408 264.745 76.6628 264.762 76.695 264.787C76.7594 264.838 76.865 264.921 77.0063 265.035C77.2894 265.263 77.7128 265.612 78.2325 266.065C79.2792 266.977 80.6783 268.277 82.0963 269.825M76.6253 264.733L76.6282 264.736L76.6253 264.733ZM75.3577 255.571C77.5373 252.688 81.6417 252.117 84.5252 254.297L75.3577 255.571ZM84.5252 254.297L80.8187 259.2L84.5252 254.297ZM80.8187 259.2C84.5252 254.297 84.5264 254.298 84.5264 254.298L80.8187 259.2ZM84.5264 254.298L84.5276 254.299L84.5264 254.298ZM84.5276 254.299L84.5304 254.301L84.5276 254.299ZM84.5304 254.301L84.5367 254.305L84.5304 254.301ZM84.5367 254.305L84.5527 254.318L84.5367 254.305ZM84.5527 254.318L84.5982 254.352L84.5527 254.318ZM84.5982 254.352C84.6344 254.38 84.6823 254.417 84.7412 254.463L84.5982 254.352ZM84.7412 254.463C84.8588 254.555 85.0204 254.682 85.2201 254.843L84.7412 254.463ZM85.2201 254.843C85.619 255.165 86.1726 255.622 86.8329 256.198L85.2201 254.843ZM86.8329 256.198C88.1463 257.342 89.9194 258.987 91.7504 260.986L86.8329 256.198ZM91.7504 260.986C93.6151 263.023 94.9838 265.696 96.0161 267.978L91.7504 260.986ZM96.0161 267.978C96.9091 269.952 97.7779 272.169 98.5867 274.232L96.0161 267.978ZM98.5867 274.232L98.5879 274.235C98.7508 274.651 98.9113 275.061 99.0691 275.461M99.0691 275.461C100.057 277.965 100.975 280.202 101.937 282.077L99.0691 275.461ZM101.937 282.077C102.194 282.579 102.437 283.02 102.665 283.405L101.937 282.077ZM102.665 283.405L108.612 281.014C112.196 280.543 115.483 283.067 115.953 286.651C116.424 290.235 113.9 293.521 110.317 293.992L97.7337 297.255L95.4965 295.257C93.199 293.206 91.5329 290.476 90.2883 288.048C89.0033 285.541 87.8794 282.766 86.8923 280.263C86.7095 279.799 86.5318 279.346 86.3582 278.904C85.5507 276.845 84.8315 275.012 84.0903 273.373C83.1491 271.293 82.4758 270.24 82.0963 269.825"
            fill="#7D55FF"
          ></path>
          <path
            d="M82.0963 269.825L75.3577 255.571L84.5252 254.297L80.8187 259.2L84.5264 254.298L84.5276 254.299L84.5304 254.301L84.5367 254.305L84.5527 254.318L84.5982 254.352L84.7412 254.463L85.2201 254.843L86.8329 256.198L91.7504 260.986L96.0161 267.978L98.5867 274.232L98.5879 274.235C98.7508 274.651 98.9113 275.061 99.0691 275.461C100.057 277.965 100.975 280.202 101.937 282.077C102.194 282.579 102.437 283.02 102.665 283.405L82.0963 269.825Z"
            fill="#7D55FF"
          ></path>
        </g>
        <path
          d="M127.678 247.117L116.595 285.332C116.595 285.332 107.554 300.281 104.949 302.721L111.923 310.209L133.117 256.044L127.678 247.117Z"
          fill="#7D55FF"
        ></path>
        <path
          opacity="0.4"
          d="M127.678 247.117L116.595 285.332C116.595 285.332 107.554 300.281 104.949 302.721L111.923 310.209L133.117 256.044L127.678 247.117Z"
          fill="#C4B6FF"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M125.19 262.066L129.318 264.797L129.529 264.477L125.402 261.746L125.19 262.066Z"
          fill="#C4B6FF"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M121.019 275.039L124.467 277.49L124.689 277.178L121.241 274.727L121.019 275.039Z"
          fill="#C4B6FF"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M130.271 256.509L129.909 256.383L119.44 286.475L110.632 305.509L110.98 305.67L119.796 286.619L130.271 256.509Z"
          fill="#C4B6FF"
        ></path>
        <path
          d="M135.63 248.375L107.6 321.836L130.616 310.209L133.267 293.41L146.371 265.818L135.63 248.375Z"
          fill="#7D55FF"
        ></path>
        <path
          d="M42.7327 283.895C42.7327 283.895 39.3339 284.067 38.7777 285.137C38.2215 286.208 37.6115 310.525 43.2429 313.244C44.4091 313.808 50.7539 315.964 50.7539 315.964L42.7327 283.895Z"
          fill="#C4B6FF"
        ></path>
        <path
          opacity="0.2"
          d="M42.7327 283.895C42.7327 283.895 39.3339 284.067 38.7777 285.137C38.2215 286.208 37.6115 310.525 43.2429 313.244C44.4091 313.808 50.7539 315.964 50.7539 315.964L42.7327 283.895Z"
          fill="#7D55FF"
        ></path>
        <path
          d="M42.0591 270.152C42.0591 270.152 39.7344 270.398 39.1744 271.464C38.6181 272.535 39.6002 282.47 40.8085 282.934C42.0169 283.398 43.3672 281.856 43.3672 281.856L42.0591 270.152Z"
          fill="#C4B6FF"
        ></path>
        <path
          d="M42.0591 270.152C42.0591 270.152 39.7344 270.398 39.1744 271.464C38.6181 272.535 39.6002 282.47 40.8085 282.934C42.0169 283.398 43.3672 281.856 43.3672 281.856L42.0591 270.152Z"
          fill="#7D55FF"
        ></path>
        <path
          opacity="0.5"
          d="M42.0591 270.152C42.0591 270.152 39.7344 270.398 39.1744 271.464C38.6181 272.535 39.6002 282.47 40.8085 282.934C42.0169 283.398 43.3672 281.856 43.3672 281.856L42.0591 270.152Z"
          fill="#C4B6FF"
        ></path>
        <path
          d="M45.1416 301.644C45.7477 304.145 45.0342 315.373 47.4317 316.321C49.9942 317.33 55.1154 318.783 57.137 317.644C57.2636 317.575 58.0078 307.709 58.119 307.617C61.3912 305.047 59.6419 294.912 59.6419 294.912C59.6419 294.912 60.1982 275.16 60.5127 272.375C60.8273 269.586 58.0155 267.737 56.8109 265.869C56.5616 265.481 56.0245 265.159 55.2803 264.929C52.4148 264.024 46.5188 264.461 42.4295 267.86C38.3364 271.262 43.0394 292.89 45.1416 301.644Z"
          fill="#7D55FF"
        ></path>
        <path
          d="M43.9155 293.273C43.9155 293.273 46.0637 294.743 49.6006 294.95C53.1374 295.153 54.2 293.423 54.2 293.423C54.2 293.423 58.2471 316.881 57.1346 317.644C56.0183 318.411 50.6095 318.427 48.4498 318.058C44.5753 317.398 42.0205 293.841 43.9155 293.273Z"
          fill="#7D55FF"
        ></path>
        <path
          opacity="0.5"
          d="M43.9175 293.273C43.9175 293.273 46.0657 294.743 49.6025 294.95C53.1394 295.153 54.202 293.423 54.202 293.423C54.202 293.423 58.249 316.881 57.1366 317.644C56.0203 318.411 50.6114 318.427 48.4517 318.058C44.5773 317.398 42.0225 293.841 43.9175 293.273Z"
          fill="#C4B6FF"
        ></path>
        <path
          d="M53.2741 286.454L58.119 307.617C61.3912 305.047 59.642 294.912 59.642 294.912C59.642 294.912 60.1982 275.16 60.5127 272.375C60.8273 269.587 58.0155 267.738 56.8109 265.87C56.5616 265.482 56.0245 265.16 55.2803 264.93C55.2803 264.93 49.9751 265.317 49.1196 267.465C48.268 269.61 53.2741 286.454 53.2741 286.454Z"
          fill="#7D55FF"
        ></path>
        <path
          opacity="0.6"
          d="M53.2741 286.454L58.119 307.617C61.3912 305.047 59.642 294.912 59.642 294.912C59.642 294.912 60.1982 275.16 60.5127 272.375C60.8273 269.587 58.0155 267.738 56.8109 265.87C56.5616 265.482 56.0245 265.16 55.2803 264.93C55.2803 264.93 49.9751 265.317 49.1196 267.465C48.268 269.61 53.2741 286.454 53.2741 286.454Z"
          fill="#C4B6FF"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.0176 264.642C49.9879 264.54 49.8813 264.482 49.7796 264.512L49.8335 264.696L49.8874 264.88C49.9891 264.85 50.0474 264.744 50.0176 264.642ZM44.4861 282.986C44.6757 282.957 44.6757 282.957 44.6757 282.957L44.6704 282.922L44.655 282.818C44.6415 282.728 44.6218 282.594 44.5971 282.423C44.5477 282.08 44.4779 281.589 44.3969 280.993C44.2349 279.8 44.0277 278.187 43.8472 276.508C43.6666 274.828 43.513 273.084 43.4575 271.628C43.4297 270.9 43.4267 270.246 43.4565 269.711C43.4867 269.169 43.5497 268.767 43.644 268.53C43.8361 268.045 44.3034 267.568 44.9312 267.121C45.554 266.677 46.311 266.28 47.048 265.947C47.7841 265.613 48.4946 265.347 49.0215 265.163C49.2848 265.071 49.5018 265 49.6528 264.952C49.7283 264.928 49.7873 264.91 49.8272 264.898L49.8725 264.884L49.8839 264.881L49.8874 264.88L49.8335 264.696C49.7796 264.512 49.7797 264.512 49.7796 264.512L49.7752 264.513L49.7629 264.517L49.7156 264.531C49.6743 264.543 49.6139 264.562 49.5369 264.587C49.3829 264.635 49.1623 264.707 48.8952 264.801C48.3612 264.987 47.6394 265.258 46.8899 265.597C46.1414 265.936 45.3597 266.345 44.7087 266.808C44.0626 267.268 43.521 267.799 43.2874 268.388C43.1687 268.688 43.1039 269.143 43.0735 269.689C43.0427 270.242 43.0462 270.908 43.0742 271.642C43.1302 273.111 43.2848 274.865 43.4658 276.549C43.6468 278.233 43.8545 279.849 44.0168 281.044C44.098 281.642 44.1679 282.134 44.2175 282.477C44.2422 282.649 44.262 282.783 44.2755 282.875L44.291 282.979L44.2963 283.014C44.2963 283.014 44.2964 283.015 44.4861 282.986ZM44.4861 282.986L44.6757 282.957C44.6915 283.062 44.6195 283.16 44.5147 283.176C44.41 283.192 44.3123 283.12 44.2964 283.015L44.4861 282.986Z"
          fill="#7D55FF"
        ></path>
        <path
          opacity="0.6"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.0157 264.642C49.9859 264.54 49.8794 264.482 49.7777 264.512L49.8316 264.696L49.8855 264.88C49.9871 264.85 50.0454 264.744 50.0157 264.642ZM44.4841 282.986C44.6738 282.957 44.6737 282.957 44.6737 282.957L44.6685 282.922L44.653 282.818C44.6396 282.728 44.6199 282.594 44.5952 282.423C44.5457 282.08 44.476 281.589 44.395 280.993C44.2329 279.8 44.0258 278.187 43.8453 276.508C43.6647 274.828 43.511 273.084 43.4555 271.628C43.4278 270.9 43.4247 270.246 43.4546 269.711C43.4847 269.169 43.5478 268.767 43.642 268.53C43.8342 268.045 44.3015 267.568 44.9292 267.121C45.552 266.677 46.3091 266.28 47.0461 265.947C47.7821 265.613 48.4926 265.347 49.0195 265.163C49.2828 265.071 49.4999 265 49.6509 264.952C49.7264 264.928 49.7853 264.91 49.8252 264.898L49.8706 264.884L49.8819 264.881L49.8855 264.88L49.8316 264.696C49.7777 264.512 49.7778 264.512 49.7777 264.512L49.7732 264.513L49.7609 264.517L49.7136 264.531C49.6723 264.543 49.6119 264.562 49.5349 264.587C49.3809 264.635 49.1604 264.707 48.8932 264.801C48.3592 264.987 47.6374 265.258 46.8879 265.597C46.1394 265.936 45.3577 266.345 44.7067 266.808C44.0606 267.268 43.5191 267.799 43.2854 268.388C43.1667 268.688 43.102 269.143 43.0715 269.689C43.0408 270.242 43.0442 270.908 43.0722 271.642C43.1282 273.111 43.2829 274.865 43.4639 276.549C43.6449 278.233 43.8525 279.849 44.0149 281.044C44.096 281.642 44.1659 282.134 44.2155 282.477C44.2403 282.649 44.26 282.783 44.2736 282.875L44.2891 282.979L44.2944 283.014C44.2944 283.014 44.2945 283.015 44.4841 282.986ZM44.4841 282.986L44.6737 282.957C44.6896 283.062 44.6175 283.16 44.5128 283.176C44.408 283.192 44.3103 283.12 44.2945 283.015L44.4841 282.986Z"
          fill="#C4B6FF"
        ></path>
        <path
          d="M49.2397 265.303C49.6771 265.054 49.5773 264.398 49.0863 264.295C47.2949 263.911 44.2414 263.408 43.0982 264.137C42.9371 264.241 42.7837 264.352 42.6417 264.467C41.5484 265.357 42.0548 267.122 43.455 267.287L44.1033 267.363C44.2222 267.379 44.3411 267.352 44.4447 267.294L45.4804 266.692C45.4804 266.692 42.4499 266.255 43.1289 265.545C43.8079 264.835 44.226 264.406 45.2502 264.628C46.1095 264.816 48.204 265.234 48.86 265.365C48.9904 265.392 49.1247 265.369 49.2397 265.303Z"
          fill="#7D55FF"
        ></path>
        <path
          opacity="0.6"
          d="M49.2417 265.303C49.679 265.054 49.5793 264.398 49.0883 264.295C47.2968 263.911 44.2433 263.408 43.1002 264.137C42.939 264.241 42.7856 264.352 42.6437 264.467C41.5504 265.357 42.0567 267.122 43.4569 267.287L44.1052 267.363C44.2241 267.379 44.343 267.352 44.4466 267.294L45.4824 266.692C45.4824 266.692 42.4519 266.255 43.1308 265.545C43.8098 264.835 44.228 264.406 45.2522 264.628C46.1115 264.816 48.206 265.234 48.8619 265.365C48.9924 265.392 49.1266 265.369 49.2417 265.303Z"
          fill="#C4B6FF"
        ></path>
        <path
          d="M74.883 254.049C69.0369 252.074 62.3391 259.945 55.9406 265.339L58.5452 268.235C58.5452 268.235 62.7841 259.696 69.7888 257.007C73.7821 255.469 80.733 256.025 74.883 254.049Z"
          fill="#7D55FF"
        ></path>
        <path
          opacity="0.6"
          d="M74.883 254.049C69.0369 252.074 62.3391 259.945 55.9406 265.339L58.5452 268.235C58.5452 268.235 62.7841 259.696 69.7888 257.007C73.7821 255.469 80.733 256.025 74.883 254.049Z"
          fill="#C4B6FF"
        ></path>
        <path
          d="M80.6063 419.746H86.0344L86.9512 410.77H80.6063V419.746Z"
          fill="#E8AC7B"
        ></path>
        <path
          d="M86.2242 417.118C86.2165 416.65 86.6807 416.323 87.118 416.496C89.6421 417.486 98.2157 420.773 103.736 421.936C104.177 422.028 104.472 422.323 104.668 422.711C105.401 424.168 104.208 425.871 102.577 425.822L80.1901 425.131C79.7873 425.123 79.4497 424.82 79.4037 424.418C79.2387 422.983 78.9855 419.097 80.0942 417.762C80.3167 417.493 80.8422 417.731 81.3332 417.896C81.8741 418.08 82.7909 420.808 86.0976 418.1C86.2587 417.961 86.2319 417.536 86.2242 417.118Z"
          fill="#C4B6FF"
        ></path>
        <path
          opacity="0.2"
          d="M86.2262 417.118C86.2185 416.65 86.6826 416.323 87.12 416.496C89.6441 417.486 98.2177 420.773 103.738 421.936C104.179 422.028 104.474 422.323 104.67 422.711C105.403 424.168 104.21 425.871 102.579 425.822L80.192 425.131C79.7892 425.123 79.4517 424.82 79.4056 424.418C79.2407 422.983 78.9875 419.097 80.0961 417.762C80.3186 417.493 80.8442 417.731 81.3352 417.896C81.8761 418.08 82.7929 420.808 86.0996 418.1C86.2607 417.961 86.2338 417.536 86.2262 417.118Z"
          fill="#7D55FF"
        ></path>
        <path
          d="M80.2991 414.696H86.9509L91.8457 366.389L91.7767 316.32H76.0027L79.7237 366.389L80.2991 414.696Z"
          fill="#C4B6FF"
        ></path>
        <path
          opacity="0.2"
          d="M80.2972 414.696H86.9489L91.8438 366.389L91.7747 316.32H76.0008L79.7218 366.389L80.2972 414.696Z"
          fill="#7D55FF"
        ></path>
        <path
          d="M86.8205 297.787C82.6085 294.902 64.4141 296.851 64.4141 296.851C64.4141 296.851 60.8235 301.807 59.6727 307.039C57.325 317.726 67.3256 336.899 67.3256 336.899C67.3256 336.899 76.091 336.427 83.2722 332.104C85.6659 330.662 87.8831 328.793 89.5403 326.373C100.116 310.921 87.653 300.265 87.208 299.029C86.9548 298.224 86.8205 297.787 86.8205 297.787Z"
          fill="#7D55FF"
        ></path>
        <path
          d="M69.1733 419.75H74.6052L74.9082 410.773H68.5633L69.1733 419.75Z"
          fill="#E8AC7B"
        ></path>
        <path
          d="M74.3106 417.158C74.3029 416.667 74.7978 416.321 75.2543 416.502C77.8359 417.522 86.3252 420.798 91.8069 421.968C92.248 422.064 92.5396 422.355 92.7352 422.747C93.4641 424.204 92.2634 425.908 90.6331 425.85L68.2496 425.094C67.8468 425.083 67.5093 424.78 67.4671 424.377C67.3059 422.942 67.0643 419.056 68.1767 417.722C68.3992 417.453 68.9248 417.695 69.4119 417.86C69.9528 418.048 70.8581 420.775 74.1763 418.078C74.3375 417.956 74.3183 417.56 74.3106 417.158Z"
          fill="#C4B6FF"
        ></path>
        <path
          opacity="0.2"
          d="M74.3106 417.158C74.3029 416.667 74.7978 416.321 75.2543 416.502C77.8359 417.522 86.3252 420.798 91.8069 421.968C92.248 422.064 92.5396 422.355 92.7352 422.747C93.4641 424.204 92.2634 425.908 90.6331 425.85L68.2496 425.094C67.8468 425.083 67.5093 424.78 67.4671 424.377C67.3059 422.942 67.0643 419.056 68.1767 417.722C68.3992 417.453 68.9248 417.695 69.4119 417.86C69.9528 418.048 70.8581 420.775 74.1763 418.078C74.3375 417.956 74.3183 417.56 74.3106 417.158Z"
          fill="#7D55FF"
        ></path>
        <path
          d="M68.5635 414.7H75.8674L81.4066 366.262L84.2031 326.639L64.2709 316.324L68.5213 366.488L68.5635 414.7Z"
          fill="#7D55FF"
        ></path>
        <path
          d="M87.958 234.32L88.7904 248.59C88.9976 249.408 88.2725 250.159 87.4478 249.979L78.1722 246.577L78.9701 241.663L87.958 234.32Z"
          fill="#242424"
        ></path>
        <path
          d="M65.872 252.424C62.4617 256.87 59.9453 260.821 59.0937 267.588C58.2344 274.409 56.6846 307.652 58.4531 324.189C65.358 327.258 88.8232 324.953 95.1872 320.718C95.6399 320.008 91.8307 269.015 91.8805 268.363C91.9764 267.055 79.9696 247.41 76.6705 247.307C72.4547 247.18 68.4383 249.079 65.872 252.424Z"
          fill="#C4B6FF"
        ></path>
        <path
          d="M75.6656 254.667C77.7754 254.667 79.4863 252.956 79.4863 250.846V242.848H71.8449V250.846C71.8449 252.956 73.5558 254.667 75.6656 254.667Z"
          fill="#E8AC7B"
        ></path>
        <path
          d="M79.4863 244.566L72.5162 244.639C72.5162 244.639 78.393 249.258 79.4863 248.51V244.566Z"
          fill="#242424"
        ></path>
        <path
          d="M76.1864 245.363H80.2258C84.8597 245.363 88.6152 241.607 88.6152 236.973V226.19C88.6152 221.556 84.8597 217.801 80.2258 217.801H74.7555C70.1216 217.801 66.3661 221.556 66.3661 226.19V235.543C66.3661 240.967 70.7622 245.363 76.1864 245.363Z"
          fill="#E8AC7B"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.5955 228.763C86.5573 228.972 86.6953 229.171 86.9037 229.21L90.6455 229.895C90.8539 229.933 91.0538 229.795 91.092 229.587C91.1301 229.378 90.9921 229.179 90.7838 229.14L87.0419 228.455C86.8336 228.417 86.6337 228.555 86.5955 228.763Z"
          fill="#242424"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.6939 228.521C79.6739 228.31 79.4867 228.155 79.2758 228.175L75.7521 228.509C75.5412 228.529 75.3864 228.716 75.4064 228.927C75.4263 229.138 75.6135 229.293 75.8244 229.273L79.3482 228.939C79.5591 228.919 79.7139 228.732 79.6939 228.521Z"
          fill="#242424"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.1936 229.946C84.0621 229.97 83.9749 230.096 83.9989 230.227L84.8832 235.076L84.8833 235.077C84.9422 235.392 84.7756 235.711 84.4809 235.843C84.1331 235.998 83.6977 236.092 83.3311 236.139C83.1492 236.162 82.9897 236.173 82.873 236.175C82.8142 236.176 82.7701 236.175 82.7412 236.172C82.7359 236.172 82.7315 236.171 82.728 236.171C82.6226 236.131 82.5002 236.169 82.4377 236.268C82.3663 236.381 82.4 236.531 82.513 236.602C82.5578 236.63 82.6039 236.64 82.6224 236.644C82.6481 236.649 82.675 236.653 82.7004 236.655C82.7515 236.659 82.8135 236.66 82.8815 236.659C83.0186 236.657 83.196 236.644 83.3926 236.619C83.7828 236.569 84.2702 236.467 84.6783 236.285L84.6792 236.285C85.1765 236.061 85.4591 235.525 85.3593 234.988C85.3593 234.988 85.3592 234.988 85.3592 234.988L84.4751 230.14C84.4511 230.009 84.3251 229.922 84.1936 229.946Z"
          fill="#242424"
        ></path>
        <path
          d="M84.4453 239.496C84.219 240.444 83.3137 241.713 82.3853 241.663C81.5529 241.621 80.8586 240.405 80.6284 239.565L84.4453 239.496Z"
          fill="white"
        ></path>
        <path
          d="M87.5746 232.609C87.1355 232.572 86.8096 232.186 86.8466 231.747C86.8837 231.308 87.2697 230.982 87.7088 231.019C88.1479 231.056 88.4739 231.442 88.4368 231.881C88.3997 232.32 88.0137 232.646 87.5746 232.609Z"
          fill="#242424"
        ></path>
        <path
          d="M78.5883 232.234C78.1492 232.197 77.8232 231.811 77.8603 231.372C77.8974 230.933 78.2834 230.607 78.7225 230.644C79.1616 230.681 79.4875 231.067 79.4505 231.506C79.4134 231.945 79.0274 232.271 78.5883 232.234Z"
          fill="#242424"
        ></path>
        <path
          d="M81.0547 256.445L80.579 248.109L68.1924 247.449C68.1924 247.449 63.3781 252.927 63.9382 254.185C64.4982 255.436 81.0547 256.445 81.0547 256.445Z"
          fill="#C4B6FF"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.5923 247.918C80.4866 247.912 80.3954 247.992 80.3885 248.097L79.7155 258.513L79.7754 258.574L79.9125 258.44C79.7754 258.574 79.7753 258.574 79.7754 258.574L79.855 258.656C79.9072 258.71 79.9839 258.789 80.082 258.89C80.2782 259.092 80.5601 259.384 80.9023 259.742C81.5869 260.457 82.5128 261.434 83.4789 262.483C84.4452 263.533 85.4506 264.653 86.2942 265.654C87.1413 266.659 87.8148 267.531 88.1269 268.09C88.2499 268.309 88.3709 268.666 88.4854 269.157C88.5991 269.644 88.7035 270.25 88.7992 270.957C88.9906 272.372 89.1453 274.183 89.2698 276.252C89.5188 280.388 89.646 285.54 89.705 290.572C89.764 295.604 89.7546 300.514 89.7305 304.167C89.7185 305.993 89.7027 307.504 89.69 308.559C89.6837 309.087 89.6781 309.5 89.674 309.782C89.672 309.922 89.6704 310.03 89.6693 310.103L89.6676 310.212C89.6676 310.212 89.6676 310.213 89.8592 310.216L89.6676 310.212C89.6659 310.318 89.7504 310.406 89.8563 310.407C89.9622 310.409 90.0494 310.325 90.0512 310.219L89.8592 310.216C90.0509 310.219 90.0512 310.219 90.0512 310.219L90.0529 310.109C90.054 310.036 90.0556 309.928 90.0576 309.787C90.0616 309.505 90.0672 309.092 90.0736 308.564C90.0863 307.508 90.1021 305.996 90.1141 304.169C90.1382 300.516 90.1476 295.603 90.0886 290.568C90.0296 285.534 89.9022 280.375 89.6527 276.229C89.528 274.156 89.3726 272.334 89.1794 270.906C89.0828 270.192 88.9764 269.573 88.859 269.07C88.7424 268.571 88.6119 268.171 88.4617 267.902C88.1293 267.308 87.4328 266.409 86.5875 265.407C85.7387 264.4 84.7292 263.275 83.7611 262.224C82.7929 261.172 81.8651 260.193 81.1794 259.477C80.8366 259.118 80.5542 258.826 80.3575 258.623C80.2592 258.521 80.1822 258.442 80.1298 258.388L80.1094 258.367L80.7713 248.122C80.7782 248.016 80.698 247.925 80.5923 247.918Z"
          fill="#7D55FF"
        ></path>
        <path
          d="M84.9722 273.29C84.2201 273.29 83.6104 272.962 83.6104 272.557C83.6104 272.152 84.2201 271.824 84.9722 271.824C85.7243 271.824 86.334 272.152 86.334 272.557C86.334 272.962 85.7243 273.29 84.9722 273.29Z"
          fill="#7D55FF"
        ></path>
        <path
          d="M85.5679 283.715C84.8158 283.715 84.2061 283.387 84.2061 282.983C84.2061 282.578 84.8158 282.25 85.5679 282.25C86.32 282.25 86.9297 282.578 86.9297 282.983C86.9297 283.387 86.32 283.715 85.5679 283.715Z"
          fill="#7D55FF"
        ></path>
        <path
          d="M86.1558 294.141C85.4037 294.141 84.794 293.813 84.794 293.408C84.794 293.004 85.4037 292.676 86.1558 292.676C86.9079 292.676 87.5176 293.004 87.5176 293.408C87.5176 293.813 86.9079 294.141 86.1558 294.141Z"
          fill="#7D55FF"
        ></path>
        <path
          d="M86.7515 304.571C85.9994 304.571 85.3897 304.243 85.3897 303.838C85.3897 303.434 85.9994 303.105 86.7515 303.105C87.5036 303.105 88.1133 303.434 88.1133 303.838C88.1133 304.243 87.5036 304.571 86.7515 304.571Z"
          fill="#7D55FF"
        ></path>
        <path
          d="M73.5262 226.84C73.5262 226.84 82.5678 226.871 84.95 225.743C87.3322 224.615 86.6378 223.222 86.6378 223.222L87.616 225.942L89.2118 226.502C89.2118 226.502 91.0071 223.295 88.8244 220.844C88.8244 220.844 71.7386 214.891 69.3755 221.4C69.3755 221.4 62.6011 224.926 61.7648 228.693C60.9285 232.46 60.6907 249.45 60.6907 249.45C60.6907 249.45 73.6796 253.639 74.5005 251.122L73.5262 226.84Z"
          fill="#242424"
        ></path>
        <path
          d="M88.1387 221.823C88.1387 221.823 85.9176 212.751 80.9269 212.471C75.9361 212.191 62.9165 209.728 61.5202 211.819C60.1239 213.909 63.1467 215.494 63.1467 215.494C63.1467 215.494 58.0332 220.097 59.8093 224.328C60.6149 226.25 59.8093 233.251 59.8093 233.251C59.8093 233.251 78.1611 217.086 88.1387 221.823Z"
          fill="#C4B6FF"
        ></path>
        <path
          d="M88.5911 224.848C86.6746 224.621 84.2867 224.338 81.5544 224.692C79.8799 224.909 79.0302 225.103 77.7653 225.391C77.3618 225.483 76.9159 225.585 76.3875 225.7C76.0157 225.781 75.7279 225.839 75.4785 225.889L75.4785 225.889C74.9533 225.995 74.5985 226.066 73.9887 226.245C72.3509 226.724 70.5153 227.28 68.8972 227.907C67.1773 228.574 66.0903 229.18 65.6488 229.615C64.7416 230.507 64.2542 231.646 64.002 232.655C63.8784 233.149 63.8201 233.578 63.793 233.874C63.7796 234.021 63.7742 234.132 63.772 234.197C63.7709 234.23 63.7706 234.251 63.7705 234.259L63.7705 234.261C63.7827 235.63 62.6873 236.754 61.3163 236.776C59.9398 236.797 58.8067 235.698 58.7856 234.321L61.2779 234.283C58.7856 234.321 58.7855 234.32 58.7855 234.32L58.7855 234.318L58.7854 234.314L58.7853 234.305L58.7852 234.282C58.7851 234.265 58.7851 234.244 58.7853 234.22C58.7857 234.171 58.7868 234.108 58.7894 234.031C58.7944 233.879 58.8054 233.671 58.8284 233.42C58.8741 232.92 58.9686 232.233 59.1656 231.445C59.5543 229.891 60.378 227.807 62.1517 226.062C63.3953 224.838 65.3625 223.931 67.0956 223.259C68.9305 222.548 70.9467 221.94 72.5888 221.46C73.4125 221.219 74.0416 221.092 74.657 220.967C74.8793 220.922 75.0998 220.878 75.3271 220.828C75.7773 220.73 76.1821 220.638 76.5678 220.55C77.8912 220.248 78.9879 219.998 80.9135 219.748C84.3278 219.306 87.4318 219.682 89.3273 219.912L89.3273 219.912C89.6813 219.955 89.9932 219.993 90.257 220.019C91.6268 220.157 92.6261 221.378 92.4888 222.748C92.3516 224.118 91.1299 225.117 89.7601 224.98C89.3931 224.943 89.003 224.897 88.5911 224.848Z"
          fill="#7D55FF"
        ></path>
        <path
          d="M59.0588 218.824C56.3999 218.824 54.2445 216.668 54.2445 214.01C54.2445 211.351 56.3999 209.195 59.0588 209.195C61.7176 209.195 63.873 211.351 63.873 214.01C63.873 216.668 61.7176 218.824 59.0588 218.824Z"
          fill="#7D55FF"
        ></path>
        <path
          d="M70.6355 253.348C73.3629 253.851 75.1275 256.77 76.2477 259.835C77.7859 264.039 77.13 268.497 75.8947 272.801C73.152 282.38 59.2654 313.31 56.5801 311.802L55.5406 306.677C55.5406 306.677 62.0619 295.092 63.3892 292.81C64.3482 291.16 70.4092 276.645 73.689 268.739C74.6135 266.514 74.905 264.059 74.3987 261.703C74.1071 260.341 73.5624 259.022 72.5765 258.278C70.1828 256.475 69.7494 254.913 66.0092 257.004C59.461 260.667 50.289 277.627 50.289 277.627L48.8888 273.729C52.606 264.615 64.5668 252.228 70.6355 253.348Z"
          fill="#7D55FF"
        ></path>
        <path
          opacity="0.2"
          d="M136.893 261.906L140.311 265.566L139.125 267.369L135.914 264.695L136.893 261.906Z"
          fill="#C4B6FF"
        ></path>
        <path
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M141.838 275.545L133.844 269.488L133.38 270.1L141.375 276.157L141.838 275.545Z"
          fill="#C4B6FF"
        ></path>
        <path
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M134.701 285.556L139.189 276.324L138.499 275.988L133.782 285.691L135.632 287.698L136.196 287.178L134.701 285.556Z"
          fill="#C4B6FF"
        ></path>
        <path
          opacity="0.2"
          d="M117.64 310.903L119.742 311.997L122.309 305.003L120.206 303.91L117.64 310.903Z"
          fill="#C4B6FF"
        ></path>
        <path
          opacity="0.2"
          d="M128.943 279.106L131.045 280.2L133.611 273.207L131.509 272.113L128.943 279.106Z"
          fill="#C4B6FF"
        ></path>
        <path
          opacity="0.2"
          d="M132.046 280.286L134.149 281.379L136.715 274.386L134.609 273.293L132.046 280.286Z"
          fill="#C4B6FF"
        ></path>
        <path
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M128.412 295.738L124.271 307.83L124.997 308.079L128.904 296.671L131.904 297.502L132.109 296.763L128.412 295.738Z"
          fill="#C4B6FF"
        ></path>
        <path
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M128.077 284.015L134.134 288.99L134.621 288.397L128.564 283.422L128.077 284.015Z"
          fill="#C4B6FF"
        ></path>
        <path
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126.815 286.843L132.873 291.815L133.359 291.222L127.302 286.25L126.815 286.843Z"
          fill="#C4B6FF"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M119.125 300.589C119.12 300.695 119.201 300.785 119.307 300.79L131.272 301.35C131.378 301.355 131.467 301.273 131.472 301.167C131.477 301.061 131.396 300.971 131.29 300.967L119.325 300.406C119.219 300.402 119.13 300.483 119.125 300.589Z"
          fill="#C4B6FF"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M135.983 256.266C135.884 256.229 135.773 256.279 135.736 256.378L113.01 316.707L128.608 309.096C128.703 309.049 128.742 308.934 128.696 308.839C128.649 308.744 128.535 308.704 128.439 308.751L113.709 315.939L136.095 256.513C136.132 256.414 136.082 256.304 135.983 256.266Z"
          fill="#C4B6FF"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M144.854 267.249L136.065 256.324L135.766 256.565L144.555 267.49L144.854 267.249Z"
          fill="#C4B6FF"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M131.137 268.629C131.068 268.709 131.076 268.83 131.156 268.899L140.186 276.736C140.266 276.806 140.388 276.797 140.457 276.717C140.526 276.637 140.518 276.516 140.438 276.447L131.408 268.609C131.328 268.54 131.207 268.549 131.137 268.629Z"
          fill="#C4B6FF"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M125.461 283.917C125.409 284.01 125.441 284.127 125.533 284.179L134.464 289.246C134.556 289.299 134.673 289.266 134.725 289.174C134.777 289.082 134.745 288.965 134.653 288.913L125.722 283.845C125.63 283.793 125.513 283.825 125.461 283.917Z"
          fill="#C4B6FF"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M141.687 263.35C141.591 263.305 141.477 263.348 141.433 263.444L130.47 287.377L124.451 310.164C124.424 310.266 124.485 310.371 124.587 310.398C124.69 310.425 124.795 310.364 124.822 310.262L130.832 287.507L141.781 263.604C141.825 263.507 141.783 263.394 141.687 263.35Z"
          fill="#C4B6FF"
        ></path>
        <path
          d="M111.56 293.412H114.993L116.712 290.673C116.85 290.262 117.142 289.932 117.533 289.744C118.553 289.253 120.51 288.482 120.82 289.61C121.242 291.144 119.117 291.701 119.117 291.701L118.269 294.14L126.486 292.188C127.242 292.008 127.89 292.74 127.622 293.469L125.727 298.609C125.646 298.832 125.485 299.016 125.278 299.127L118.438 302.829C118.124 302.998 117.744 302.983 117.445 302.787L111.564 298.955V293.412H111.56Z"
          fill="#E8AC7B"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.5644 257.518C62.1144 258.597 60.1918 262.268 61.27 265.718L67.5168 263.765L73.7635 261.813C72.6853 258.363 69.0144 256.44 65.5644 257.518ZM69.3971 292.053C69.8909 293.696 70.8699 294.712 71.3372 295.147C71.8745 295.648 72.4015 295.99 72.7514 296.197C73.4561 296.615 74.1882 296.924 74.7543 297.141C75.9353 297.594 77.3905 298.024 78.8923 298.419C81.9498 299.223 85.9049 300.064 89.8929 300.806C93.8947 301.551 98.0518 302.217 101.538 302.659C103.277 302.879 104.909 303.051 106.301 303.144C107.523 303.226 109.09 303.291 110.416 303.096C113.992 302.571 116.465 299.246 115.939 295.669C115.414 292.093 112.089 289.62 108.512 290.146C108.548 290.141 108.567 290.138 108.567 290.137C108.566 290.13 108.173 290.151 107.178 290.084C106.134 290.014 104.778 289.875 103.182 289.673C99.999 289.27 96.0977 288.646 92.2869 287.937C88.4622 287.226 84.8504 286.451 82.2214 285.76C81.7961 285.648 81.4135 285.543 81.0726 285.445C79.8084 281.29 78.2184 276.142 76.8476 271.722C75.9937 268.97 75.2229 266.493 74.6655 264.704C74.3868 263.81 74.1614 263.087 74.0056 262.588L73.7638 261.814L73.7635 261.813C73.7635 261.813 73.7635 261.813 67.5168 263.765C61.27 265.718 61.27 265.717 61.27 265.718L61.2703 265.718L61.271 265.721L61.5107 266.488C61.6659 266.986 61.8907 267.706 62.1688 268.598C62.7249 270.383 63.494 272.854 64.3457 275.6C66.0513 281.099 68.0803 287.674 69.3966 292.052"
          fill="#C4B6FF"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126.305 288.096L133.801 285.46L133.061 283.355L125.565 285.991L126.305 288.096Z"
          fill="#C4B6FF"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M131.564 289.499L130.586 281.738L128.373 282.017L129.351 289.778L131.564 289.499Z"
          fill="#C4B6FF"
        ></path>
        <path
          d="M522.188 309.554C522.188 325.538 509.214 338.513 493.23 338.513H481.07C465.086 338.513 452.111 325.538 452.111 309.554C452.111 293.571 522.188 293.571 522.188 309.554Z"
          fill="#B5CDFB"
        ></path>
        <path
          d="M474.74 277.964C470.039 283.856 466.152 285.361 466.341 294.073C466.591 305.231 467.845 325.163 469.851 324.536C483.014 320.399 489.783 321.59 497.932 322.217C499.812 322.342 503.949 297.019 503.949 297.019L501.943 284.609C501.943 284.671 479.315 272.26 474.74 277.964Z"
          fill="#2E2427"
        ></path>
        <path
          d="M518.178 336.192C529.649 327.103 506.018 317.074 487.84 317.074C469.663 317.074 448.101 323.342 452.175 337.383C454.494 345.469 514.981 338.699 518.178 336.192Z"
          fill="#7D55FF"
        ></path>
        <path
          d="M503.818 300.379C504.296 297.3 503.269 294.584 501.525 294.314C499.78 294.043 497.978 296.319 497.5 299.398C497.022 302.477 498.049 305.192 499.794 305.463C501.538 305.734 503.34 303.458 503.818 300.379Z"
          fill="#C18970"
        ></path>
        <path
          d="M493.92 311.186V323.095C493.92 327.232 490.535 330.617 486.461 330.617C482.324 330.617 478.939 327.232 478.939 323.095V311.186C478.939 307.049 482.324 303.664 486.461 303.664C488.53 303.664 490.41 304.479 491.726 305.858C493.043 307.237 493.92 309.117 493.92 311.186Z"
          fill="#C18970"
        ></path>
        <path
          opacity="0.26"
          d="M493.92 312.562V317.201C492.604 319.959 489.909 321.651 487.088 321.651C484.33 321.651 480.193 320.022 478.939 317.326V312.562C481.321 314.13 483.891 315.007 486.461 315.007C488.968 315.007 491.538 314.192 493.92 312.562Z"
          fill="black"
        ></path>
        <path
          d="M494.045 314.944C489.03 317.514 483.452 319.77 479.503 316.26C475.679 312.813 471.856 307.234 472.232 300.339C472.232 300.339 468.032 274.578 485.332 273.449C496.991 272.697 500.94 290.248 500.94 290.248C499.874 294.071 499.059 312.311 494.045 314.944Z"
          fill="#C18970"
        ></path>
        <path
          d="M466.341 296.454C466.341 296.893 466.717 297.143 467.156 297.018C469.663 296.078 477.624 293.445 488.781 293.383C489.345 293.383 491.602 290.562 492.605 289.308C492.855 288.995 493.294 288.995 493.545 289.308C494.861 291.126 498.497 296.015 499.186 295.576C501.192 294.323 502.383 294.699 503.072 295.388C503.448 295.765 504.075 297.52 504.075 297.018C504.075 290.374 502.947 269.376 490.16 272.447C489.972 272.51 489.784 272.447 489.659 272.322C486.713 270.003 475.117 272.823 471.293 276.333C465.025 282.1 466.467 290.938 466.341 296.454Z"
          fill="#2E2427"
        ></path>
        <path
          d="M478.628 301.093C479.286 301.093 479.819 300.56 479.819 299.902C479.819 299.244 479.286 298.711 478.628 298.711C477.971 298.711 477.438 299.244 477.438 299.902C477.438 300.56 477.971 301.093 478.628 301.093Z"
          fill="#2E2427"
        ></path>
        <path
          d="M492.039 301.093C492.696 301.093 493.23 300.56 493.23 299.902C493.23 299.244 492.696 298.711 492.039 298.711C491.381 298.711 490.848 299.244 490.848 299.902C490.848 300.56 491.381 301.093 492.039 301.093Z"
          fill="#2E2427"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M485.036 298.524C485.209 298.532 485.343 298.679 485.335 298.851L485.022 298.837C485.335 298.851 485.335 298.851 485.335 298.851L485.335 298.852L485.335 298.854L485.334 298.861L485.333 298.887C485.332 298.909 485.33 298.94 485.326 298.981C485.32 299.062 485.31 299.178 485.293 299.324C485.26 299.615 485.202 300.023 485.101 300.493C484.901 301.428 484.53 302.632 483.84 303.651L483.835 303.657L483.831 303.663C483.545 304.044 483.61 304.595 483.956 304.854L483.967 304.862L483.976 304.871C484.524 305.358 485.328 305.857 486.087 305.857C486.261 305.857 486.401 305.997 486.401 306.17C486.401 306.344 486.261 306.484 486.087 306.484C485.097 306.484 484.15 305.862 483.569 305.347C482.925 304.854 482.866 303.912 483.325 303.294C483.948 302.371 484.297 301.258 484.488 300.362C484.584 299.915 484.639 299.528 484.67 299.253C484.686 299.115 484.696 299.006 484.701 298.932C484.704 298.895 484.706 298.867 484.707 298.848L484.709 298.828L484.709 298.823L484.709 298.822C484.717 298.649 484.864 298.516 485.036 298.524Z"
          fill="#2E2427"
        ></path>
        <path
          opacity="0.24"
          d="M476.433 307.487C478.164 307.487 479.567 306.084 479.567 304.353C479.567 302.622 478.164 301.219 476.433 301.219C474.702 301.219 473.299 302.622 473.299 304.353C473.299 306.084 474.702 307.487 476.433 307.487Z"
          fill="#E84B4B"
        ></path>
        <path
          opacity="0.24"
          d="M493.919 307.487C495.65 307.487 497.053 306.084 497.053 304.353C497.053 302.622 495.65 301.219 493.919 301.219C492.188 301.219 490.785 302.622 490.785 304.353C490.785 306.084 492.188 307.487 493.919 307.487Z"
          fill="#E84B4B"
        ></path>
        <path
          d="M487.84 310.43C487.84 310.43 487.088 312.874 485.396 312.874C483.704 312.874 482.889 310.43 482.889 310.43H487.84Z"
          fill="white"
        ></path>
        <path
          opacity="0.41"
          d="M477.874 305.729C481.094 305.729 483.704 303.119 483.704 299.9C483.704 296.68 481.094 294.07 477.874 294.07C474.655 294.07 472.045 296.68 472.045 299.9C472.045 303.119 474.655 305.729 477.874 305.729Z"
          fill="white"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M477.874 294.163C474.704 294.163 472.134 296.733 472.134 299.903C472.134 303.073 474.704 305.643 477.874 305.643C481.044 305.643 483.614 303.073 483.614 299.903C483.614 296.733 481.044 294.163 477.874 294.163ZM471.955 299.903C471.955 296.634 474.605 293.984 477.874 293.984C481.142 293.984 483.792 296.634 483.792 299.903C483.792 303.172 481.142 305.822 477.874 305.822C474.605 305.822 471.955 303.172 471.955 299.903Z"
          fill="#FFF1CF"
        ></path>
        <path
          opacity="0.41"
          d="M492.794 305.729C496.014 305.729 498.623 303.119 498.623 299.9C498.623 296.68 496.014 294.07 492.794 294.07C489.575 294.07 486.965 296.68 486.965 299.9C486.965 303.119 489.575 305.729 492.794 305.729Z"
          fill="white"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M492.792 294.163C489.622 294.163 487.052 296.733 487.052 299.903C487.052 303.073 489.622 305.643 492.792 305.643C495.962 305.643 498.532 303.073 498.532 299.903C498.532 296.733 495.962 294.163 492.792 294.163ZM486.873 299.903C486.873 296.634 489.523 293.984 492.792 293.984C496.06 293.984 498.71 296.634 498.71 299.903C498.71 303.172 496.06 305.822 492.792 305.822C489.523 305.822 486.873 303.172 486.873 299.903Z"
          fill="#FFF1CF"
        ></path>
        <path
          d="M435.575 310.07C435.575 326.439 422.288 339.62 406.025 339.62H393.589C377.22 339.62 364.039 326.333 364.039 310.07C364.039 293.701 435.575 293.701 435.575 310.07Z"
          fill="#B5CDFB"
        ></path>
        <path
          d="M404.962 277.334C410.277 282.968 411.34 286.05 411.871 294.979C412.509 306.352 404.962 324.316 402.836 323.784C389.124 320.596 393.163 322.934 384.979 324.21C383.065 324.528 379.77 302.207 379.77 302.207L377.963 286.263C377.857 286.369 399.86 271.913 404.962 277.334Z"
          fill="#2E2427"
        ></path>
        <path
          d="M401.134 317.723C382.532 317.723 360.529 324.1 364.675 338.45C365.525 341.32 373.71 342.383 384.127 342.383C403.259 342.383 428.557 339.619 432.065 337.174C444.395 328.671 419.735 317.723 401.134 317.723Z"
          fill="#C4B6FF"
        ></path>
        <path
          d="M381.745 307.345C383.516 306.926 384.359 304.085 383.629 301C382.898 297.915 380.87 295.755 379.099 296.174C377.328 296.594 376.485 299.434 377.216 302.519C377.946 305.604 379.974 307.764 381.745 307.345Z"
          fill="#C18970"
        ></path>
        <path
          d="M413.484 298.502C413.688 295.338 412.381 292.679 410.565 292.562C408.749 292.444 407.112 294.913 406.907 298.077C406.703 301.24 408.009 303.9 409.825 304.017C411.641 304.134 413.279 301.665 413.484 298.502Z"
          fill="#BF7F66"
        ></path>
        <path
          d="M388.592 311.665V323.782C388.592 328.034 391.993 331.436 396.245 331.436C400.497 331.436 403.898 328.034 403.898 323.782V311.665C403.898 307.413 400.497 304.012 396.245 304.012C394.119 304.012 392.206 304.862 390.824 306.244C389.442 307.626 388.592 309.539 388.592 311.665Z"
          fill="#C18970"
        ></path>
        <path
          opacity="0.26"
          d="M388.592 313.047V317.83C389.974 320.594 392.631 322.294 395.607 322.294C398.371 322.294 402.623 320.594 403.898 317.83V313.047C401.453 314.641 398.796 315.492 396.245 315.492C393.694 315.598 391.037 314.748 388.592 313.047Z"
          fill="black"
        ></path>
        <path
          d="M388.273 316.554C393.588 318.786 399.434 320.593 403.154 316.661C404.536 315.172 409.638 309.326 409.957 301.461C410.489 289.13 404.536 273.399 393.801 273.612C381.896 273.824 379.238 291.894 379.238 291.894C380.726 295.721 382.959 314.216 388.273 316.554Z"
          fill="#C18970"
        ></path>
        <path
          d="M414.104 294.764C413.892 296.359 403.369 293.276 391.995 294.127C391.464 294.127 388.913 291.469 387.744 290.3C387.425 289.981 386.999 290.087 386.787 290.406C385.618 292.32 382.323 297.634 381.578 297.209C379.453 296.146 378.283 296.571 377.539 297.315C377.22 297.741 376.689 299.548 376.689 299.016C376.157 292.213 375.626 270.742 388.913 272.868C389.125 272.868 389.338 272.868 389.444 272.655C392.314 270.104 404.432 272.017 408.577 275.313C415.38 280.84 414.955 289.556 414.104 294.764Z"
          fill="#2E2427"
        ></path>
        <path
          d="M403.793 301.035C404.498 301.035 405.069 300.464 405.069 299.76C405.069 299.055 404.498 298.484 403.793 298.484C403.089 298.484 402.518 299.055 402.518 299.76C402.518 300.464 403.089 301.035 403.793 301.035Z"
          fill="#2E2427"
        ></path>
        <path
          d="M390.08 302.203C390.785 302.203 391.356 301.632 391.356 300.928C391.356 300.223 390.785 299.652 390.08 299.652C389.376 299.652 388.805 300.223 388.805 300.928C388.805 301.632 389.376 302.203 390.08 302.203Z"
          fill="#2E2427"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M396.076 298.879C396.387 298.843 396.668 299.066 396.704 299.377L396.704 299.377L396.705 299.381C396.705 299.385 396.706 299.392 396.707 299.4C396.709 299.418 396.713 299.446 396.718 299.482C396.729 299.555 396.746 299.663 396.771 299.799C396.822 300.072 396.904 300.455 397.031 300.892C397.287 301.776 397.712 302.834 398.385 303.652L398.388 303.655L398.391 303.659C398.959 304.369 399.025 305.469 398.222 306.135C397.656 306.696 396.7 307.427 395.565 307.553C395.254 307.588 394.974 307.363 394.939 307.052C394.904 306.74 395.129 306.46 395.44 306.425C396.205 306.34 396.941 305.812 397.44 305.313L397.462 305.29L397.487 305.271C397.719 305.085 397.78 304.714 397.507 304.37C396.694 303.382 396.216 302.157 395.941 301.208C395.802 300.728 395.712 300.307 395.656 300.006C395.628 299.854 395.609 299.732 395.596 299.647C395.59 299.604 395.585 299.571 395.582 299.547C395.581 299.535 395.579 299.526 395.579 299.519L395.578 299.511L395.577 299.509L395.577 299.508C395.577 299.507 395.577 299.507 396.141 299.442L395.577 299.507C395.541 299.196 395.764 298.915 396.076 298.879Z"
          fill="#2E2427"
        ></path>
        <path
          opacity="0.24"
          d="M405.494 309.432C407.255 309.432 408.682 308.005 408.682 306.244C408.682 304.482 407.255 303.055 405.494 303.055C403.732 303.055 402.305 304.482 402.305 306.244C402.305 308.005 403.732 309.432 405.494 309.432Z"
          fill="#E84B4B"
        ></path>
        <path
          opacity="0.24"
          d="M388.38 310.178C390.141 310.178 391.569 308.751 391.569 306.99C391.569 305.228 390.141 303.801 388.38 303.801C386.619 303.801 385.191 305.228 385.191 306.99C385.191 308.751 386.619 310.178 388.38 310.178Z"
          fill="#E84B4B"
        ></path>
        <path
          d="M394.334 311.453C394.334 311.453 395.291 313.897 396.991 313.685C398.692 313.578 399.33 311.027 399.33 311.027L394.334 311.453Z"
          fill="white"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M391.012 296.513C391.102 296.792 390.949 297.092 390.67 297.182L387.374 298.245C387.095 298.335 386.796 298.182 386.705 297.902C386.615 297.623 386.769 297.323 387.048 297.233L390.343 296.17C390.623 296.08 390.922 296.234 391.012 296.513Z"
          fill="#2E2427"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M403.286 296.518C403.374 296.237 403.672 296.081 403.952 296.169L407.353 297.232C407.633 297.319 407.79 297.617 407.702 297.898C407.614 298.178 407.316 298.334 407.036 298.246L403.635 297.183C403.355 297.096 403.199 296.798 403.286 296.518Z"
          fill="#2E2427"
        ></path>
        <path
          d="M347.936 310.031C347.936 326.4 334.649 339.581 318.387 339.581H305.95C289.581 339.581 276.4 326.294 276.4 310.031C276.4 293.662 347.936 293.662 347.936 310.031Z"
          fill="#B5CDFB"
        ></path>
        <path
          d="M345.555 335.92C350.486 329.98 332.084 320.391 313.604 318.411C295.125 316.431 271.637 328.311 275.985 334.717C279.414 348.348 343.42 338.482 345.555 335.92Z"
          fill="#7D55FF"
        ></path>
        <path
          d="M315.817 333.085C318.69 332.736 322.727 315.11 322.727 315.11L321.524 305.133L306.965 309.248L304.17 321.089C304.519 323.962 315.817 333.085 315.817 333.085Z"
          fill="#603E31"
        ></path>
        <path
          d="M330.336 290.961L328.473 281.605L325.367 280.828V288.593C325.367 290.573 325.057 294.765 326.532 295.814C326.804 296.008 327.231 295.969 327.735 295.814C329.754 295.192 330.88 293.018 330.336 290.961Z"
          fill="#0C0A0A"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M329.508 293.973C329.518 293.945 329.55 293.93 329.579 293.941L332.956 295.222C332.985 295.233 332.999 295.265 332.989 295.294C332.978 295.322 332.946 295.337 332.917 295.326L329.54 294.045C329.511 294.034 329.497 294.002 329.508 293.973Z"
          fill="#FFF1CF"
        ></path>
        <path
          d="M312.751 314.645C314.459 315.616 316.439 316.004 318.419 315.771L322.689 315.266C329.328 314.451 333.404 306.609 331.114 299.077L328.28 289.915C326.571 284.325 321.796 280.753 316.827 281.335L312.867 281.801C307.937 282.422 304.171 287.042 303.821 292.866L303.278 302.455C302.929 309.171 307.665 311.772 312.751 314.645Z"
          fill="#805440"
        ></path>
        <path
          d="M299.589 310.491L296.056 288.556C295.513 286.265 297.065 284.014 299.395 283.742L304.752 283.082L305.645 306.22C306.15 310.297 303.665 309.986 299.589 310.491Z"
          fill="#0C0A0A"
        ></path>
        <path
          d="M311.468 294.729L317.136 294.03C317.796 293.952 318.223 293.37 318.145 292.71C318.068 292.05 317.485 291.623 316.825 291.7L311.157 292.399C310.497 292.477 310.07 293.059 310.148 293.719C310.225 294.34 310.808 294.806 311.468 294.729Z"
          fill="#161112"
        ></path>
        <path
          d="M323.659 293.213L329.327 292.514C329.987 292.437 330.414 291.854 330.337 291.194C330.259 290.534 329.677 290.107 329.017 290.185L323.349 290.884C322.689 290.961 322.262 291.544 322.339 292.204C322.417 292.825 322.999 293.291 323.659 293.213Z"
          fill="#161112"
        ></path>
        <path
          d="M327.698 296.552C328.148 296.552 328.513 296.187 328.513 295.737C328.513 295.287 328.148 294.922 327.698 294.922C327.248 294.922 326.883 295.287 326.883 295.737C326.883 296.187 327.248 296.552 327.698 296.552Z"
          fill="#161112"
        ></path>
        <path
          d="M315.817 297.99C316.267 297.99 316.632 297.625 316.632 297.175C316.632 296.724 316.267 296.359 315.817 296.359C315.367 296.359 315.002 296.724 315.002 297.175C315.002 297.625 315.367 297.99 315.817 297.99Z"
          fill="#161112"
        ></path>
        <path
          opacity="0.23"
          d="M327.696 300.631C330.398 300.631 332.588 298.441 332.588 295.739C332.588 293.038 330.398 290.848 327.696 290.848C324.995 290.848 322.805 293.038 322.805 295.739C322.805 298.441 324.995 300.631 327.696 300.631Z"
          fill="white"
        ></path>
        <path
          opacity="0.23"
          d="M315.817 302.065C318.519 302.065 320.709 299.874 320.709 297.173C320.709 294.471 318.519 292.281 315.817 292.281C313.116 292.281 310.926 294.471 310.926 297.173C310.926 299.874 313.116 302.065 315.817 302.065Z"
          fill="white"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M327.697 290.904C325.026 290.904 322.861 293.069 322.861 295.74C322.861 298.411 325.026 300.576 327.697 300.576C330.368 300.576 332.533 298.411 332.533 295.74C332.533 293.069 330.368 290.904 327.697 290.904ZM322.75 295.74C322.75 293.008 324.965 290.793 327.697 290.793C330.429 290.793 332.644 293.008 332.644 295.74C332.644 298.472 330.429 300.687 327.697 300.687C324.965 300.687 322.75 298.472 322.75 295.74Z"
          fill="#FFF1CF"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M315.818 292.337C313.147 292.337 310.982 294.502 310.982 297.174C310.982 299.845 313.147 302.01 315.818 302.01C318.489 302.01 320.654 299.845 320.654 297.174C320.654 294.502 318.489 292.337 315.818 292.337ZM310.871 297.174C310.871 294.441 313.086 292.227 315.818 292.227C318.55 292.227 320.765 294.441 320.765 297.174C320.765 299.906 318.55 302.12 315.818 302.12C313.086 302.12 310.871 299.906 310.871 297.174Z"
          fill="#FFF1CF"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M322.557 295.896C322.782 296.006 322.942 296.181 322.963 296.394L322.881 296.403C322.864 296.228 322.732 296.073 322.521 295.969C322.31 295.867 322.027 295.819 321.724 295.857C321.42 295.895 321.156 296.009 320.973 296.158C320.79 296.309 320.694 296.491 320.711 296.666L320.629 296.674C320.608 296.462 320.726 296.255 320.921 296.095C321.117 295.934 321.396 295.815 321.713 295.775C322.031 295.736 322.33 295.785 322.557 295.896Z"
          fill="#FFF1CF"
        ></path>
        <path
          d="M320.436 302.802C320.785 302.919 321.173 302.996 321.6 302.958C322.998 302.802 323.968 301.482 323.774 300.085C323.658 299.114 323.037 298.338 322.221 297.988"
          fill="#805440"
        ></path>
        <path
          d="M325.678 305.016C326.028 307.888 324.009 310.412 322.534 310.451C321.097 310.49 318.845 309.092 318.496 306.219L325.678 305.016Z"
          fill="white"
        ></path>
        <path
          d="M310.499 299.006L311.154 298.926L310.915 296.96L310.26 297.04L310.499 299.006Z"
          fill="#FFF1CF"
        ></path>
        <path
          d="M332.751 296.299L333.406 296.219L333.167 294.253L332.512 294.333L332.751 296.299Z"
          fill="#FFF1CF"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M305.593 296.067C305.604 296.038 305.636 296.024 305.665 296.035L310.673 297.937C310.701 297.948 310.716 297.98 310.705 298.009C310.694 298.037 310.662 298.051 310.634 298.041L305.626 296.138C305.597 296.127 305.583 296.096 305.593 296.067Z"
          fill="#FFF1CF"
        ></path>
        <path
          d="M303.248 304.859C305.292 304.61 306.614 301.665 306.201 298.28C305.789 294.896 303.798 292.355 301.754 292.604C299.711 292.853 298.389 295.798 298.802 299.182C299.214 302.567 301.205 305.108 303.248 304.859Z"
          fill="#805440"
        ></path>
        <path
          d="M302.5 300.59C302.5 300.59 304.596 308.16 305.334 309.286C305.994 310.295 306.732 306.103 306.732 306.103L307.275 302.958L302.5 300.59Z"
          fill="#805440"
        ></path>
        <path
          d="M304.947 294.651C304.947 294.651 305.879 289.837 309.606 288.44C313.333 287.042 311.897 285.023 311.897 285.023L302.502 285.334L301.803 289.837L304.947 294.651Z"
          fill="#2E2427"
        ></path>
        <path
          d="M337.441 281.181L294.232 289.101C293.494 289.217 293.028 289.877 293.106 290.576L293.339 292.168C293.455 292.905 294.115 293.371 294.814 293.293L338.024 285.335C338.761 285.218 339.227 284.558 339.15 283.86L338.917 282.268C338.8 281.569 338.14 281.064 337.441 281.181Z"
          fill="#2E2427"
        ></path>
        <path
          d="M294.27 292.127L326.337 286.614C328.783 286.303 330.53 284.09 330.219 281.645C329.909 279.199 327.696 277.452 325.25 277.762L301.568 280.635C299.123 280.946 293.998 285.954 293.998 285.954L294.27 292.127Z"
          fill="#2E2427"
        ></path>
        <path
          d="M294.04 287.081C293.264 280.869 308.676 272.716 317.528 271.629C326.379 270.542 329.485 275.395 330.223 281.646"
          fill="#2E2427"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M322.532 298.199C322.716 298.09 322.954 298.151 323.063 298.335L324.073 300.044C324.7 301.105 324.141 302.578 322.89 302.833C322.553 302.912 322.228 302.974 321.947 303.005C321.679 303.034 321.4 303.044 321.186 302.982C320.98 302.924 320.86 302.709 320.919 302.503C320.978 302.296 321.193 302.177 321.399 302.236C321.457 302.252 321.605 302.262 321.861 302.233C322.103 302.206 322.397 302.151 322.718 302.076L322.724 302.074L322.731 302.073C323.415 301.936 323.786 301.084 323.404 300.439L322.395 298.73C322.286 298.546 322.347 298.308 322.532 298.199Z"
          fill="#2E2427"
        ></path>
        <path
          d="M259.364 310.031C259.364 326.4 246.077 339.581 229.814 339.581H217.378C201.009 339.581 187.828 326.294 187.828 310.031C187.828 293.662 259.364 293.662 259.364 310.031Z"
          fill="#B5CDFB"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215.762 298.983C215.762 298.36 216.267 297.855 216.889 297.855H226.98C227.603 297.855 228.108 298.36 228.108 298.983C228.108 299.606 227.603 300.111 226.98 300.111H216.889C216.267 300.111 215.762 299.606 215.762 298.983Z"
          fill="#022859"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215.762 304.174C215.762 303.552 216.267 303.047 216.889 303.047H226.98C227.603 303.047 228.108 303.552 228.108 304.174C228.108 304.797 227.603 305.302 226.98 305.302H216.889C216.267 305.302 215.762 304.797 215.762 304.174Z"
          fill="#022859"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215.762 309.401C215.762 308.778 216.267 308.273 216.889 308.273H226.98C227.603 308.273 228.108 308.778 228.108 309.401C228.108 310.024 227.603 310.529 226.98 310.529H216.889C216.267 310.529 215.762 310.024 215.762 309.401Z"
          fill="#022859"
        ></path>
        <path
          d="M211.554 289.072L209.957 287.293L210.32 295.968L211.663 297.13L211.554 289.072Z"
          fill="#754C24"
        ></path>
        <path
          d="M215.876 314.202C215.899 314.196 215.923 314.193 215.946 314.193C215.971 314.193 215.995 314.19 216.019 314.184C218.434 313.537 220.379 313.214 221.282 313.358C222.771 313.649 226.872 314.919 231.554 316.552C237.702 318.654 250.508 320.27 253.478 327.659C254.733 330.774 255.592 334.689 255.864 336.257C231.554 343.161 169.431 344.774 188.506 326.244C196.882 318.107 210.633 315.584 215.876 314.202Z"
          fill="#C4B6FF"
        ></path>
        <path
          d="M231.483 288.602L233.007 288.638L232.318 296.66L231.41 297.095L231.483 288.602Z"
          fill="#754C24"
        ></path>
        <path
          d="M221.1 326.277C212.715 326.604 210.973 316.259 212.098 314.989C212.098 314.989 213.949 314.771 215.909 314.154C215.946 314.154 215.946 314.154 215.982 314.154C218.414 313.501 220.374 313.174 221.281 313.319C222.769 313.61 226.871 314.88 231.553 316.513C232.17 319.962 228.069 326.023 221.1 326.277Z"
          fill="#7D55FF"
        ></path>
        <path
          opacity="0.7"
          d="M221.1 326.277C212.715 326.604 210.973 316.259 212.098 314.989C212.098 314.989 213.949 314.771 215.909 314.154C215.946 314.154 215.946 314.154 215.982 314.154C218.414 313.501 220.374 313.174 221.281 313.319C222.769 313.61 226.871 314.88 231.553 316.513C232.17 319.962 228.069 326.023 221.1 326.277Z"
          fill="#C4B6FF"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M221.427 303.25C224.905 303.25 227.724 306.069 227.724 309.547V318.367C227.724 321.844 224.905 324.663 221.427 324.663C217.95 324.663 215.131 321.844 215.131 318.367V309.547C215.131 306.069 217.95 303.25 221.427 303.25Z"
          fill="#C69C6D"
        ></path>
        <path
          opacity="0.3"
          d="M227.744 309.547V312.342C227.707 312.306 227.127 313.867 226.509 313.976C224.15 314.411 218.778 314.302 216.673 313.758C215.947 313.576 215.185 310.963 215.148 310.89V309.547C215.148 308.64 215.33 307.805 215.657 307.043H227.199C227.562 307.805 227.744 308.64 227.744 309.547Z"
          fill="#824400"
        ></path>
        <path
          d="M231.482 286.895V304.246C231.482 304.463 231.409 304.645 231.337 304.826L227.09 311.832C226.909 312.122 226.582 312.34 226.219 312.34H223.533C223.533 312.34 219.286 312.34 219.504 312.34H216.818C216.455 312.34 216.128 312.158 215.947 311.832L211.7 304.826C211.591 304.645 211.555 304.463 211.555 304.246V288.601C211.519 288.601 220.121 280.798 231.482 286.895Z"
          fill="#C69C6D"
        ></path>
        <path
          d="M231.482 296.985C231.482 296.985 232.136 295.933 232.898 295.679C233.914 295.316 235.148 295.751 235.257 297.312C235.475 300.034 233.406 304.68 231.482 301.886"
          fill="#C69C6D"
        ></path>
        <path
          d="M211.592 296.985C211.592 296.985 210.938 295.933 210.176 295.679C209.16 295.316 207.926 295.751 207.817 297.312C207.599 300.034 209.668 304.68 211.592 301.886"
          fill="#C69C6D"
        ></path>
        <path
          d="M217.58 304.938C217.58 304.938 218.944 308.154 220.542 308.008C221.591 307.935 222.992 307.031 223.797 306.447C224.051 306.263 223.948 305.881 223.638 305.835L217.58 304.938Z"
          fill="#F2F2F2"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M221.687 299.697C221.774 299.62 221.907 299.628 221.983 299.715L221.826 299.854C221.983 299.715 221.983 299.715 221.983 299.715L221.984 299.716L221.985 299.717L221.988 299.72L221.998 299.732C222.007 299.743 222.02 299.758 222.036 299.777C222.067 299.815 222.112 299.87 222.164 299.939C222.267 300.076 222.401 300.268 222.523 300.488C222.644 300.706 222.757 300.96 222.813 301.219C222.868 301.479 222.868 301.761 222.74 302.017C222.607 302.283 222.372 302.432 222.125 302.511C221.879 302.59 221.606 302.605 221.36 302.596C221.112 302.586 220.88 302.55 220.71 302.517C220.625 302.501 220.555 302.485 220.506 302.473C220.481 302.467 220.462 302.462 220.449 302.458L220.433 302.454L220.428 302.453L220.427 302.452L220.426 302.452C220.426 302.452 220.426 302.452 220.483 302.25L220.426 302.452C220.315 302.421 220.25 302.305 220.281 302.193C220.313 302.081 220.429 302.017 220.54 302.048M220.54 302.048L220.544 302.049L220.556 302.052C220.567 302.055 220.584 302.06 220.606 302.065C220.649 302.076 220.713 302.09 220.791 302.105C220.947 302.136 221.157 302.168 221.376 302.176C221.598 302.185 221.817 302.169 221.997 302.111C222.174 302.055 222.298 301.963 222.365 301.83C222.436 301.687 222.445 301.511 222.402 301.307C222.359 301.103 222.266 300.89 222.156 300.691C222.046 300.494 221.924 300.318 221.828 300.192C221.781 300.129 221.74 300.078 221.712 300.044C221.698 300.027 221.687 300.014 221.68 300.005L221.671 299.996L221.67 299.994"
          fill="#381B00"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M225.025 292.101C225.239 291.562 225.849 291.298 226.387 291.512L230.235 293.036C230.774 293.25 231.038 293.859 230.824 294.398C230.611 294.937 230.001 295.201 229.462 294.987L225.614 293.463C225.075 293.249 224.812 292.639 225.025 292.101Z"
          fill="#1E0E00"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M218.804 292.189C218.969 292.744 218.652 293.328 218.096 293.493L213.813 294.764C213.257 294.929 212.673 294.612 212.508 294.056C212.344 293.5 212.66 292.916 213.216 292.752L217.499 291.481C218.055 291.316 218.639 291.633 218.804 292.189Z"
          fill="#1E0E00"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M220.184 296.85C220.184 296.85 220.184 296.85 219.937 296.589C219.691 296.328 219.691 296.328 219.691 296.328L219.692 296.328L219.693 296.327L219.696 296.324L219.704 296.316C219.711 296.31 219.721 296.302 219.732 296.291C219.756 296.271 219.79 296.243 219.832 296.21C219.917 296.144 220.039 296.057 220.189 295.97C220.487 295.798 220.922 295.613 221.426 295.613C221.888 295.613 222.338 295.786 222.657 295.946C222.82 296.028 222.959 296.111 223.06 296.176C223.111 296.209 223.153 296.238 223.185 296.261C223.205 296.276 223.247 296.306 223.276 296.335L222.769 296.843C222.78 296.853 222.787 296.859 222.787 296.859C222.787 296.859 222.781 296.854 222.765 296.843C222.743 296.827 222.711 296.805 222.67 296.778C222.587 296.725 222.472 296.656 222.336 296.588C222.056 296.448 221.725 296.331 221.426 296.331C221.094 296.331 220.785 296.454 220.548 296.591C220.431 296.658 220.337 296.726 220.273 296.776C220.241 296.8 220.217 296.82 220.202 296.834C220.194 296.84 220.189 296.845 220.186 296.848L220.183 296.85L220.184 296.85ZM220.183 296.85C220.183 296.851 220.183 296.851 220.183 296.85V296.85Z"
          fill="#381B00"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.088 295.378C233.195 295.584 233.115 295.837 232.91 295.944L230.333 297.287C230.127 297.395 229.873 297.315 229.766 297.109C229.659 296.904 229.739 296.65 229.945 296.543L232.522 295.2C232.727 295.093 232.981 295.173 233.088 295.378Z"
          fill="#381B00"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M209.913 295.378C210.02 295.173 210.273 295.093 210.479 295.2L213.056 296.543C213.262 296.65 213.342 296.904 213.234 297.109C213.127 297.315 212.874 297.395 212.668 297.287L210.091 295.944C209.886 295.837 209.806 295.584 209.913 295.378Z"
          fill="#381B00"
        ></path>
        <path
          d="M227.033 297.213C227.033 297.531 226.579 297.531 226.034 297.531C225.489 297.531 225.08 297.531 225.08 297.213C225.08 296.896 225.489 296.441 226.034 296.441C226.533 296.441 227.033 296.896 227.033 297.213Z"
          fill="black"
        ></path>
        <path
          d="M217.799 297.143C217.799 297.461 217.344 297.461 216.799 297.461C216.254 297.461 215.846 297.461 215.846 297.143C215.846 296.825 216.254 296.371 216.799 296.371C217.299 296.371 217.799 296.825 217.799 297.143Z"
          fill="black"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M227.582 296.959C226.628 296.516 225.636 296.546 224.591 296.964C224.483 297.007 224.361 296.955 224.318 296.847C224.275 296.739 224.327 296.617 224.435 296.574C225.567 296.121 226.681 296.078 227.759 296.579C227.864 296.628 227.91 296.752 227.861 296.857C227.812 296.963 227.687 297.008 227.582 296.959Z"
          fill="#381B00"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M218.381 296.897C217.427 296.454 216.435 296.483 215.389 296.901C215.282 296.944 215.16 296.892 215.117 296.785C215.074 296.677 215.126 296.555 215.234 296.512C216.366 296.059 217.48 296.016 218.558 296.516C218.663 296.565 218.709 296.69 218.66 296.795C218.611 296.9 218.486 296.946 218.381 296.897Z"
          fill="#381B00"
        ></path>
        <path
          d="M210.177 292.014C210.177 292.014 206.329 276.951 207.636 274.845C208.943 272.776 222.119 270.236 228.87 272.159C230.939 272.776 230.721 277.604 230.721 277.604C230.721 277.604 232.246 274.628 233.117 274.41C233.988 274.192 235.258 283.847 232.645 291.578L229.85 288.203C229.85 288.203 225.131 288.675 221.465 288.529C217.11 288.384 212.681 288.058 212.681 287.949C212.718 287.949 210.177 292.014 210.177 292.014Z"
          fill="#2E2427"
        ></path>
        <path
          opacity="0.2"
          d="M226.51 300.942C228.434 300.942 229.994 299.382 229.994 297.457C229.994 295.533 228.434 293.973 226.51 293.973C224.585 293.973 223.025 295.533 223.025 297.457C223.025 299.382 224.585 300.942 226.51 300.942Z"
          fill="#F2F2F2"
        ></path>
        <path
          opacity="0.2"
          d="M216.492 300.942C218.417 300.942 219.977 299.382 219.977 297.457C219.977 295.533 218.417 293.973 216.492 293.973C214.568 293.973 213.008 295.533 213.008 297.457C213.008 299.382 214.568 300.942 216.492 300.942Z"
          fill="#F2F2F2"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M226.509 294.427C224.834 294.427 223.476 295.785 223.476 297.46C223.476 299.135 224.834 300.493 226.509 300.493C228.184 300.493 229.541 299.135 229.541 297.46C229.541 295.785 228.184 294.427 226.509 294.427ZM222.572 297.46C222.572 295.286 224.335 293.523 226.509 293.523C228.683 293.523 230.445 295.286 230.445 297.46C230.445 299.634 228.683 301.396 226.509 301.396C224.335 301.396 222.572 299.634 222.572 297.46Z"
          fill="#381B00"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M216.491 294.427C214.816 294.427 213.458 295.785 213.458 297.46C213.458 299.135 214.816 300.493 216.491 300.493C218.166 300.493 219.524 299.135 219.524 297.46C219.524 295.785 218.166 294.427 216.491 294.427ZM212.555 297.46C212.555 295.286 214.317 293.523 216.491 293.523C218.665 293.523 220.427 295.286 220.427 297.46C220.427 299.634 218.665 301.396 216.491 301.396C214.317 301.396 212.555 299.634 212.555 297.46Z"
          fill="#381B00"
        ></path>
      </svg>
    </>
  );
}
