import { db, FieldValue } from "../helpers/firebaseHelper";
import { push } from "connected-react-router";
import {
  SENDING_MESSAGES,
  SEND_MESSAGES_SUCCESS,
  SEND_MESSAGES_ERROR,
  RECIPIENTS_DID_SET,
  FETCHING_MESSAGES,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_ERROR,
} from "../constants";

export function sendingMessages() {
  return {
    type: SENDING_MESSAGES,
  };
}

export function sendMessagesSuccess() {
  return {
    type: SEND_MESSAGES_SUCCESS,
  };
}

export function sendMessagesError(e) {
  return {
    type: SEND_MESSAGES_ERROR,
    error: e,
  };
}

export function recipientsDidSet(recipients, multiple) {
  return {
    type: RECIPIENTS_DID_SET,
    recipients,
    multiple,
  };
}

export function setRecipients(recipients) {
  return dispatch => {
    const multiple = recipients.length > 1;
    dispatch(recipientsDidSet(recipients, multiple));
    dispatch(push("/messages"));
  };
}

export function removeRecipient(recipient) {
  return (dispatch, getState) => {
    const { recipients } = getState().messages;
    const indexToRemove = recipients.indexOf(recipient);
    const numberToRemove = 1;
    recipients.splice(indexToRemove, numberToRemove);
    dispatch(setRecipients(recipients));
  };
}

//Opts include isForEmailDelivery and isForSMSDelivery bools
export function sendMessages(recipients, message, opts) {
  return async dispatch => {
    dispatch(sendingMessages());

    const refs = [];
    for (let recipient of recipients) {
      const ref = db.collection("messages").doc();
      const id = ref.id;
      const status = "pending";
      refs.push(ref);
      await ref.set({
        ...recipient,
        ...opts,
        message,
        status,
        id,
        createdAt: FieldValue.serverTimestamp(),
      });
    }
    //Map over the refences and attach a listener for each so we can monitor if they fail or succeed
    refs.map(ref => {
      const unsubscribe = ref.onSnapshot(doc => {
        if (!doc.exists) {
          unsubscribe();
          dispatch(sendMessagesError({ error: "Dokumentet eksisterer ikke" }));
          return;
        }

        const { status } = doc.data();
        const idx = recipients.map(r => r.email).indexOf(doc.data().email);
        recipients[idx] = doc.data();
        if (status === "success" || status === "error") {
          dispatch(setRecipients(recipients));
          dispatch(sendMessagesSuccess());
          unsubscribe();
        }
      });
      return unsubscribe;
    });
  };
}

export function fetchMessages(collection, docId) {
  return async dispatch => {
    dispatch({ type: FETCHING_MESSAGES });
    if (!collection || !docId) {
      dispatch({
        type: FETCH_MESSAGES_ERROR,
        error: "Missing collection or id",
      });
      return;
    }
    const ref = db
      .collection(collection)
      .doc(docId)
      .collection("messages")
      .orderBy("sent", "desc");
    try {
      const data = await ref.get();
      const messages = [];
      data.forEach(m => {
        if (!m.exists) return;
        messages.push(m.data());
      });
      dispatch({ type: FETCH_MESSAGES_SUCCESS, messages: messages });
    } catch (e) {
      dispatch({ type: FETCH_MESSAGES_ERROR, error: e });
    }
  };
}
