import { internalContactGroups } from "../helpers/contactGroups";
import { subjects } from "../helpers/subjects";
import { keyOptions } from "../helpers/keyOptions";

export default class Contact {
  constructor() {
    this.title = "";
    // Used if contact is external (i.e. business name)
    this.name = "";
    // The contact person at a business (again, if contact is external)
    this.att = "";
    this.isExternal = false;
    this.isArchived = false;
    this.firstName = "";
    this.lastName = "";
    this.email = "";
    this.phone = "";
    this.address = "";
    this.postcode = "";
    this.city = "";
    this.note = "";
    this.equipment = {
      phone: false,
      phoneDescription: "",
      computer: false,
      computerDescription: "",
    };
    this.group = "";
    this.associatedLines = {
      sport: false,
      outdoor: false,
    };
    this.memberOf = {};
    Object.keys(internalContactGroups).map(key => (this.memberOf[key] = false));
    this.subjects = {};
    Object.keys(subjects).map(key => (this.subjects[key] = false));
    this.keys = {};
    Object.keys(keyOptions).map(key => (this.keys[key] = false));
  }
}
