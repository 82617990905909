import { useState, useEffect } from "react";
import { useFirestore } from "react-redux-firebase";

const useExaminers = (): Examiner[] => {
  const [examiners, setExaminers] = useState<Examiner[]>([]);
  const firestore = useFirestore();
  useEffect(() => {
    async function loadExaminers() {
      const examiners = await firestore
        .collection("examiners")
        .where("isArchived", "==", false)
        .get();

      setExaminers(
        examiners.docs
          .filter((doc) => doc.exists)
          .map((doc) => doc.data() as Examiner)
      );
    }
    loadExaminers();
  }, []);
  return examiners;
};

export default useExaminers;
