// Common reasons between students and applicants
const dischargeReasonsCommon = [];

// Reasons for when an applicant is nolonger interested themselves
const dischargeReasonsApplicantNoLongerInterested = [
  "Ansøgeren har valgt udd. fra grundet økonomi",
  "Ansøgeren har valgt udd. fra grundet private årsager",
  "Ansøgeren har valgt udd. fra grundet anden udd."
];

// Other reasons for why an applicant may be considered discharged
const dischargeReasonsApplicantOther = [
  "Ansøgeren har ikke svaret tilbage på opkald",
  "Ansøgeren mødte ikke op/aflyste sin samtale",
  "Ansøgeren er ikke vendt tilbage med kontrakt efter samtale",
  "Ansøgeren er ikke optaget"
];

const dischargeReasonsApplicants = [
  ...dischargeReasonsApplicantOther,
  ...dischargeReasonsApplicantNoLongerInterested,
  ...dischargeReasonsCommon
];

const dischargeReasonsStudents = [
  "Den studerende er bortvist fra udd. grundet fravær",
  "Den studerende er bortvist fra udd. grundet manglende respekt for regelsættet",
  "Den studerende er bortvist fra udd. grundet manglende betaling",
  "Den studerende melder sig pr. dags dato ud af udd. grundet private årsager",
  "Den studerende melder sig pr. dags dato ud af udd. grundet økonomi",
  "Den studerende melder sig pr. dags dato ud af udd. grundet forkert studievalg/nyt job",
  ...dischargeReasonsCommon
];

export {
  dischargeReasonsApplicants,
  dischargeReasonsStudents,
  dischargeReasonsApplicantNoLongerInterested,
  dischargeReasonsApplicantOther
};
