const subjectsList = [
  "Anatomi",
  "Bold",
  "Ernæring & Sundhed",
  "Event & Projektstyrring",
  "Friluftskultur & -historie",
  "Functional Movement",
  "Fysiologi",
  "Førstehjælp & Wilderness First Aid",
  "Førstehjælp",
  "Havkajak",
  "Idrætskultur",
  "Idrætspsykologi & Personlig Udvikling",
  "Kano",
  "Klatring",
  "Kommunikation & Undervisning",
  "Livredning",
  "Løb",
  "Løb, Trail & OCR",
  "Mountainbike",
  "Naturelære",
  "Personlig Træner",
  "Rytmisk bevægelse & Dans",
  "Spring",
  "Surf & SUP",
  "Svømning",
  "Svømning/Open-water",
  "Telemark og Turski",
  "Trekking, Basic & Bushcraft",
  "Turledelse, Logistik & Sikkerhed",
  "Vinterfjeld",
  "Værkstedsfag",
  "Yoga",
];

export { subjectsList };
