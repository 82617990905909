import { firebaseAuth } from "./firebaseHelper";
const provider = new firebaseAuth.GoogleAuthProvider();
provider.addScope("profile");
provider.addScope("email");
provider.setCustomParameters({ hd: "ppkbh.dk" });

export function login() {
  console.log("Logging in");
  firebaseAuth().signInWithRedirect(provider);
  return firebaseAuth().getRedirectResult();
}

export function loginWithPopup() {
  return firebaseAuth().signInWithPopup(provider);
}

export function logout() {
  return firebaseAuth().signOut();
}
