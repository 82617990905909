import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "recompose";
import classNames from "classnames";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import FontAwesome from "react-fontawesome";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import MessageIcon from "@material-ui/icons/Message";
import AirplanemodeActive from "@material-ui/icons/AirplanemodeActive";
import FileCopy from "@material-ui/icons/FileCopy";
import SetGraduatedDialog from "./SetGraduatedDialog";
import School from "@material-ui/icons/School";
import Avatar from "@material-ui/core/Avatar";
import { toggleGradingDialog } from "../../../actions/students";
import { orange, purple } from "@material-ui/core/colors";
import StudentsPropMenu from "./StudentsPropMenu";

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: 2,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary[100],
          backgroundColor: theme.palette.primary[100],
        }
      : {
          color: theme.palette.primary[100],
          backgroundColor: theme.palette.primary[100],
        },
  button: {
    margin: theme.spacing(1),
  },
  spacer: {
    flex: "1 1",
  },
  actions: {
    color: theme.palette.text.primary,
    width: "80%",
  },
  title: {
    flex: "0 0 auto",
  },
  avatar: {
    width: 32,
    height: 32,
  },
});

let StudentsTableToolbar = (props) => {
  const {
    numSelected,
    classes,
    studentCount,
    handleDeleteItems,
    handleSendMessages,
    handleCreateSpreadsheet,
    handleCreateTravelInfo,
    handleCreateCertificateRequest,
    handleSetGraduated,
    toggleGradingDialog,
    handleSetProp,
  } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography type="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography type="headline">{studentCount} studerende</Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <div>
            <StudentsPropMenu handleSetProp={(prop) => handleSetProp(prop)} />
            <Tooltip title="Send besked(er)">
              <IconButton
                className={classes.button}
                aria-label="Message"
                onClick={handleSendMessages}
              >
                <MessageIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Sæt dimmiteret">
              <IconButton
                variant="outlined"
                className={classes.button}
                onClick={() => toggleGradingDialog()}
              >
                <School />
              </IconButton>
            </Tooltip>
            <Tooltip title="Opret spreadsheet">
              <IconButton
                className={classes.button}
                aria-label="Create spreadsheet"
                onClick={() => handleCreateSpreadsheet()}
              >
                <FontAwesome name="table" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Opret rejseinfo spreadsheet">
              <IconButton
                className={classes.button}
                aria-label="Travel info"
                onClick={() => handleCreateTravelInfo()}
              >
                <AirplanemodeActive />
              </IconButton>
            </Tooltip>
            <Tooltip title="Instruktør beviser">
              <IconButton
                className={classes.button}
                aria-label="Certificate Request"
                onClick={() => handleCreateCertificateRequest()}
              >
                <FileCopy />
              </IconButton>
            </Tooltip>
            <Tooltip title="Slet">
              <IconButton
                className={classes.button}
                aria-label="Delete"
                onClick={() => handleDeleteItems()}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <SetGraduatedDialog
              handleSetGraduated={(date) => handleSetGraduated(date)}
            />
          </div>
        ) : null}
      </div>
    </Toolbar>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleGradingDialog: () => dispatch(toggleGradingDialog()),
  };
};

StudentsTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  studentCount: PropTypes.number.isRequired,
};

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(toolbarStyles)
)(StudentsTableToolbar);
