import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import { successRateData } from "../../helpers/statisticsCalculator";

type ChartProps = {
  data: any[];
};
const InterviewSuccessRate: React.FC<ChartProps> = ({ data }) => {
  const colors = {
    sport: "#9C1918",
    outdoor: "#5B5A37",
    "x-outdoor": "#364B55",
  };
  return (
    <ResponsiveContainer width={"100%"} height="100%">
      <BarChart width={730} height={250} data={successRateData(data)}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="total" fill="#8884d8" />
        <Bar dataKey="rate" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default InterviewSuccessRate;
