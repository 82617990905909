const internalContactGroups = {
  leadership: "Ledelsesgruppe",
  administration: "Administration",
  steering: "Styringsgruppe",
  teacher: "Undervisere",
  subs: "Praktikanter",
  advisory: "Advisory",
  contractors: "Time ansatte",
  associates: "Tilknyttede"
};

const externalContactGroups = {
  denmark: "Danmark",
  international: "Udlandet",
  boardingschool: "Højskole"
};
export { internalContactGroups, externalContactGroups };
