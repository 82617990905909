import {
  FETCHING_CLASS_TEMPLATES,
  FETCH_CLASS_TEMPLATES_SUCCESS,
  FETCH_CLASS_TEMPLATES_ERROR,
  CREATING_NEW_CLASS,
  CREATE_NEW_CLASS_SUCCESS,
  CREATE_NEW_CLASS_ERROR
} from '../constants';

export const initialState = {
  classes: {},
  isFetching: false,
  isCreating: false,
  error: {}
};

const templates = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_CLASS_TEMPLATES:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_CLASS_TEMPLATES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        classes: action.classes
      };
    case FETCH_CLASS_TEMPLATES_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case CREATING_NEW_CLASS:
      return {
        ...state,
        isCreating: true
      };
    case CREATE_NEW_CLASS_SUCCESS:
      return {
        ...state,
        isCreating: false
      };
    case CREATE_NEW_CLASS_ERROR:
      return {
        ...state,
        isCreating: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default templates;
