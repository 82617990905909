import React, { Component } from "react";
import { PersonsTable, ContactsActionList } from "../../components";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { fetchContacts, deleteContacts } from "../../actions/contacts";
import { createContactsSheet } from "../../actions/sheets";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const styles = (theme) => ({
  paper: { margin: 16 },
  appBar: { marginBotton: 24 },
});

const internalColumnData = [
  { id: "firstName", align: "left", disablePadding: true, label: "Fornavn" },
  { id: "lastName", align: "left", disablePadding: true, label: "Efternavn" },
  { id: "email", align: "left", disablePadding: true, label: "Email" },
  { id: "phone", align: "left", disablePadding: true, label: "Telefon" },
];

const externalColumnData = [
  { id: "name", align: "left", disablePadding: true, label: "Firma navn" },
  { id: "att", align: "left", disablePadding: true, label: "Kontakt navn" },
  { id: "email", align: "left", disablePadding: true, label: "Email" },
  { id: "phone", align: "left", disablePadding: true, label: "Telefon" },
];

class ContactsContainer extends Component {
  state = {
    tabValue: 0,
    filter: "all",
  };

  componentDidMount() {
    this.props.fetchContacts();
  }

  handleCreateNewContact = () => {
    this.props.history.push("/contacts/new");
  };

  handleDeleteItems = (items) => {
    if (window) {
      if (
        window.confirm(
          `Slet ${items.length} kontakter? Dette vil fjerne kontakten samt den dertilhørende offentlige profil`
        )
      ) {
        this.props.deleteContacts(items);
      }
    }
  };

  handleTabValueChange = (event, value) => {
    let query;
    if (value === 0) {
      query = { isExternal: false };
    } else if (value === 1) {
      query = { isExternal: true };
    } else if (value === 2) {
      query = { isArchived: true };
    }

    this.setState({ tabValue: value }, () => this.props.fetchContacts(query));
  };

  handleMemberOfChange = (event) => {
    this.setState({ filter: event.target.value });
  };

  handleCreateSheet = (ids, isExternal) => {
    this.props.createContactsSheet(ids, isExternal);
  };

  render() {
    let { classes, contacts, isFetching } = this.props;
    const { tabValue, filter } = this.state;
    let isExternal = tabValue === 1;

    if (filter !== "all") {
      if (tabValue === 0) {
        isExternal = false;
        contacts = contacts.filter((c) => c.memberOf && c.memberOf[filter]);
      } else if (tabValue === 1) {
        contacts = contacts.filter((c) => c.group && c.group === filter);
      }
    }
    return (
      <Paper className={classes.paper}>
        {isFetching && <LinearProgress />}
        <Grid container>
          <Grid item xs={12}>
            <Grid container style={{ marginTop: 24 }}>
              <Grid item xs={12} md={3}>
                <ContactsActionList
                  isExternal={isExternal}
                  handleCreateNewContact={this.handleCreateNewContact}
                  memberOfValue={filter}
                  memberOfDidChange={this.handleMemberOfChange}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Tabs
                  value={tabValue}
                  onChange={this.handleTabValueChange}
                  indicatorColor="primary"
                  textColor="primary"
                  scrollButtons="auto"
                  centered
                >
                  <Tab label={"Interne"} />;
                  <Tab label={"Eksterne"} />;
                  <Tab label={"Arkiverede"} />;
                </Tabs>

                <PersonsTable
                  canonicalName={"Kontakter"}
                  customize={{}}
                  sortProp={
                    tabValue !== 1
                      ? internalColumnData[0].id
                      : externalColumnData[0].id
                  }
                  columnData={
                    tabValue !== 1 ? internalColumnData : externalColumnData
                  }
                  items={contacts}
                  handleDeleteItems={this.handleDeleteItems}
                  handleCreateSheet={(ids) =>
                    this.handleCreateSheet(ids, isExternal)
                  }
                  path="contacts"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = ({ contacts, sheets }) => {
  const { isFetching, error } = contacts;
  const { isCreatingContactsSheet, contactSheetsError } = sheets;
  return {
    contacts: contacts.contacts,
    isFetching,
    error,
    isCreatingContactsSheet,
    contactSheetsError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchContacts: (query) => dispatch(fetchContacts(query)),
    deleteContacts: (ids) => dispatch(deleteContacts(ids)),
    createContactsSheet: (ids, isExternal) =>
      dispatch(createContactsSheet(ids, isExternal)),
  };
};

export default withRouter(
  compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
  )(ContactsContainer)
);
