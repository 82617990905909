import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchStudent,
  fetchClasses,
  saveStudent,
  saveSafetyInformation,
  createAccountForStudent,
  moveStudentToApplicants,
} from "../../actions/students";
import { setRecipients } from "../../actions/messages";
import { fetchSemesters } from "../../actions/semesters";
import {
  uploadFile,
  unsubscribeFilesListener,
} from "../../actions/fileUploads";
import { StudentComponent } from "../../components";
import { StudentFilesContainer } from "../";

class StudentContainer extends Component {
  componentDidMount() {
    const {
      params,
      params: { id },
    } = this.props.match;

    this.props.fetchStudent(id);
    this.props.fetchClasses(params.semester);
    this.props.fetchSemesters();
  }

  componentWillUnmount() {
    unsubscribeFilesListener();
  }

  handleFileUpload = (path, file) => {
    if (!file) {
      return;
    }

    const { student } = this.props;
    if (!student.id) {
      return;
    }
    this.props.uploadFile(path, file);
  };

  saveSafetyInfo = (si) => {
    const { student } = this.props;
    this.props.saveSafetyInformation(student.id, si);
  };

  removeSemester = () => {
    if (window) {
      if (
        window.confirm(
          "Er du sikker på du vil fjerne årgangen fra denne studerende? Dette vil flytte den studerende tilbage under Ansøgere"
        )
      ) {
        this.props.moveStudentToApplicants(this.props.student);
      }
    }
  };

  handleSendMessage = () => {
    const { student } = this.props;
    const recipient = [
      {
        name: `${student.firstName} ${student.lastName}`,
        email: student.email,
        phone: student.phone,
        collection: "students",
        docId: student.studentId || student.id,
        status: "not_submitted",
      },
    ];
    this.props.setRecipients(recipient);
  };

  handleViewMessages = () => {
    const { student } = this.props;
    if (!student) return;
    this.props.history.push(`/messages/students/${student.id}`);
  };

  render() {
    const {
      isFetching,
      progress,
      isUploading,
      uploadSucceeded,
      isSaving,
      student,
      semesters,
      classNames,
      creatingAccount,
      createAccountSuccess,
      createAccountError,
      success,
      pagination,
      isFetchingPagination,
      safetyInformation,
    } = this.props;

    return (
      <StudentComponent
        saveStudent={(s) => this.props.saveStudent(s)}
        student={student}
        safetyInformation={safetyInformation}
        semesters={semesters}
        pagination={pagination}
        isFetchingPagination={isFetchingPagination}
        classNames={classNames}
        saveSafetyInfo={(si) => this.saveSafetyInfo(si)}
        handleFileChange={(path, file) => this.handleFileUpload(path, file)}
        uploadSucceeded={uploadSucceeded}
        progress={progress}
        isSaving={isSaving}
        success={success}
        isUploading={isUploading}
        isFetching={isFetching}
        creatingAccount={creatingAccount}
        createAccountSuccess={createAccountSuccess}
        createAccountError={createAccountError}
        handleSendMessage={this.handleSendMessage}
        handleViewMessages={this.handleViewMessages}
        removeSemester={this.removeSemester}
        handleCreateAccount={() => this.props.createAccountForStudent(student)}
      >
        <StudentFilesContainer studentId={student.id} />
      </StudentComponent>
    );
  }
}

const mapStateToProps = ({ students, fileUploads, semesters, exams }) => {
  const {
    isFetching,
    error,
    student,
    isSaving,
    classNames,
    creatingAccount,
    createAccountSuccess,
    createAccountError,
    grades,
    isFetchingGrades,
    success,
    pagination,
    isFetchingPagination,
    safetyInformation,
  } = students;
  const { isUploading, progress, uploadSucceeded } = fileUploads;
  const { isUpdating, updateSuccess, saveGradeSuccess } = exams;
  const examSubjects = exams.subjects;
  return {
    isFetching,
    creatingAccount,
    createAccountSuccess,
    createAccountError,
    isSaving,
    error,
    student,
    pagination,
    isFetchingPagination,
    safetyInformation,
    classNames,
    isUploading,
    progress,
    uploadSucceeded,
    grades,
    isFetchingGrades,
    success,
    isUpdating,
    updateSuccess,
    examSubjects,
    isSavingGrade: exams.isSaving,
    saveGradeSuccess,
    fileError: fileUploads.error,
    semesters: semesters.semesters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStudent: (id) => dispatch(fetchStudent(id)),
    fetchClasses: (semester) => dispatch(fetchClasses(semester)),
    saveStudent: (student) => dispatch(saveStudent(student)),
    saveSafetyInformation: (id, si) => dispatch(saveSafetyInformation(id, si)),
    uploadFile: (fullPath, file) => dispatch(uploadFile(fullPath, file)),
    fetchSemesters: () => dispatch(fetchSemesters()),
    createAccountForStudent: (student) =>
      dispatch(createAccountForStudent(student)),
    unsubscribeFilesListener: () => dispatch(unsubscribeFilesListener()),
    moveStudentToApplicants: (student) =>
      dispatch(moveStudentToApplicants(student)),
    setRecipients: (recipients) => dispatch(setRecipients(recipients)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StudentContainer)
);
