import React from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SendIcon from "@material-ui/icons/Send";
import CircularProgress from "@material-ui/core/CircularProgress";
import Transition from "react-transition-group/Transition";
import DoneIcon from "@material-ui/icons/Done";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorIcon from "@material-ui/icons/Error";
import Tooltip from "@material-ui/core/Tooltip";
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";

const duration = 500;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
};

const Fade = ({ in: inProp, children }) => (
  <Transition in={inProp} timeout={duration}>
    {(state) => (
      <div
        style={{
          ...defaultStyle,
          ...transitionStyles[state],
        }}
      >
        {children}
      </div>
    )}
  </Transition>
);

export const RecipientChip = ({
  recipient,
  classes,
  handleRemoveRecipient,
}) => {
  const status = recipient.status;
  const not_submitted = status === "not_submitted";
  const iconPending = status === "not_submitted" || status === "pending";
  let icon;
  if (iconPending) {
    icon = <CancelIcon className={classes.svgContrast} />;
  }
  if (status === "success") {
    icon = <DoneIcon className={classes.svgContrast} />;
  }
  if (status === "error") {
    icon = (
      <Tooltip placement="bottom" title={recipient.error}>
        <ErrorIcon className={classes.svgContrast} />
      </Tooltip>
    );
  }
  return (
    <Chip
      label={recipient.name}
      className={classNames(classes.chip, {
        [classes.chipError]: status === "error",
      })}
      deleteIcon={icon}
      onDelete={not_submitted ? () => handleRemoveRecipient(recipient) : null}
    />
  );
};

const chipColor = blue[500];
const chipErrorColor = red[500];
const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  textArea: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  progress: {
    margin: `0 ${theme.spacing(2)}px`,
  },
  messageSending: {
    color: blue[500],
  },
  greenAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: green[500],
    float: "right",
    width: "35px",
    height: "35px",
  },
  phoneError: {
    color: red[500],
  },
  chip: {
    margin: 4,
    backgroundColor: chipColor,
    color: theme.palette.getContrastText(chipColor),
  },
  chipError: {
    margin: 4,
    backgroundColor: chipErrorColor,
    color: theme.palette.getContrastText(chipErrorColor),
  },
  chipWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  svgContrast: {
    color: theme.palette.getContrastText(chipColor),
  },
});

const MessagesComponent = ({
  message,
  phone,
  email,
  name,
  multiple,
  messageLength,
  recipients,
  classes,
  onMessageChange,
  onPhoneChange,
  onEmailChange,
  onNameChange,
  handleSend,
  isForSMSDelivery,
  isForEmailDelivery,
  handleCheckboxChange,
  isSending,
  messageSendSuccess,
  phoneError,
  handleRemoveRecipient,
}) => (
  <div>
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Fade in={!isSending}>
          <Typography type="headline">
            {multiple ? "Send beskeder" : "Send besked"}
          </Typography>
          <Typography type="subheading">
            {multiple
              ? "Husk: For at du kan sende SMS'er skal telefonnumre have en lande kode. Hvis du sender SMSer til personer uden landekode vil disse ikke blive sendt."
              : "Send en besked ved at indtaste email og/eller telefon nummer. Telefonnummeret skal være inklusive landekoden. Eks. +4533298025"}
          </Typography>
        </Fade>
        <Fade in={isSending}>
          <Typography className={classes.messageSending} type="headline">
            Din besked er igang med at blive sendt. Du kan sikkert navigere væk
            fra denne side.
          </Typography>
        </Fade>
      </Grid>
      <Grid item xs={12}>
        {!multiple && (
          <TextField
            id="name"
            label="Navn"
            required
            disabled={multiple}
            onChange={onNameChange}
            className={classes.textField}
            value={name}
            type="text"
            margin="normal"
          />
        )}
      </Grid>
      {!multiple && [
        <Grid key="grid-email" item xs={6}>
          <TextField
            id="email"
            label="Email"
            disabled={multiple}
            onChange={onEmailChange}
            className={classes.textField}
            value={email}
            type="email"
            margin="normal"
          />
        </Grid>,
        <Grid key="grid-tel" item xs={6}>
          <TextField
            error={phoneError.length > 1 && phone !== ""}
            id="phone"
            label="Telefon"
            disabled={messageLength > 160 || multiple}
            onChange={onPhoneChange}
            className={classes.textField}
            value={phone}
            type="tel"
            margin="normal"
          />
          {phoneError.length > 1 && phone !== "" && (
            <Typography className={classes.phoneError} type="caption">
              {phoneError}
            </Typography>
          )}
        </Grid>,
      ]}
      <Grid item xs={12}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={isForEmailDelivery}
                onChange={handleCheckboxChange("isForEmailDelivery")}
                value="email"
              />
            }
            label={"Email"}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isForSMSDelivery}
                onChange={handleCheckboxChange("isForSMSDelivery")}
                value="sms"
              />
            }
            label={"SMS"}
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="message"
          label="Besked"
          placeholder="Besked"
          onChange={onMessageChange}
          className={classes.textArea}
          multiline
          value={message}
          rows={6}
          fullWidth
          margin="normal"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography type="caption">
          Du kan maks bruge 160 bogstaver når du sender sms. {messageLength}/160
          bogstaver. Beskeden skal minimum være 15 bogstaver.
        </Typography>
      </Grid>
      <Grid item xs={4}>
        {isSending ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <Button
            variant="contained"
            color="primary"
            disabled={
              message.length < 15 ||
              messageSendSuccess ||
              !(isForEmailDelivery || isForSMSDelivery)
            }
            className={classes.button}
            onClick={handleSend}
          >
            Send
            <SendIcon className={classes.rightIcon} />
          </Button>
        )}
        {messageSendSuccess && (
          <Avatar className={classes.greenAvatar}>
            <DoneIcon color="secondary" />
          </Avatar>
        )}
      </Grid>
    </Grid>
    <Grid className={classes.container} container>
      <Grid item xs={12}>
        <Typography type="subheading">Modtagere:</Typography>
        <div className={classes.chipWrapper}>
          {recipients.map((recipient, i) => {
            return (
              <RecipientChip
                recipient={recipient}
                key={i}
                classes={classes}
                handleRemoveRecipient={handleRemoveRecipient}
              />
            );
          })}
        </div>
      </Grid>
    </Grid>
  </div>
);

MessagesComponent.propTypes = {
  message: PropTypes.string.isRequired,
  recipients: PropTypes.array.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool.isRequired,
  phoneError: PropTypes.string.isRequired,
  messageLength: PropTypes.number.isRequired,
  isForEmailDelivery: PropTypes.bool.isRequired,
  isForSMSDelivery: PropTypes.bool.isRequired,
  handleSend: PropTypes.func.isRequired,
  handleRemoveRecipient: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
};
export default withStyles(styles)(MessagesComponent);
