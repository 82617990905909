import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import NoteIcon from "@material-ui/icons/Note";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import _ from "lodash";

import { parseDate } from "../../helpers/parser";
import { Tooltip } from "@material-ui/core";
import { blue, red, green, yellow } from "@material-ui/core/colors";

const NavigateTableCell = withRouter(({ history, id }) => {
  const newPath = history.location.pathname + `/${id}`;
  return (
    <TableCell padding="none">
      <IconButton onClick={() => history.push(newPath)}>
        <EditIcon />
      </IconButton>
    </TableCell>
  );
});

const EnhancedTableCell = ({
  data,
  dateField,
  selectField,
  selectOptions,
  displayAsTooltip,
  onSelectChange,
  ...props
}) => {
  let display = data;

  if (dateField) {
    display = parseDate(data);
  } else if (displayAsTooltip && data) {
    display = (
      <Tooltip title={data}>
        <NoteIcon />
      </Tooltip>
    );
  } else if (selectField && selectOptions && !_.isNil(data)) {
    display = (
      <Select
        labelId="table-select"
        id="table-select"
        value={data}
        onChange={event => onSelectChange(event.target.value)}
      >
        {selectOptions.map(opt => {
          return (
            <MenuItem value={opt} key={opt}>
              {opt.toUpperCase()}
            </MenuItem>
          );
        })}
      </Select>
    );
  }
  return <TableCell {...props}>{display}</TableCell>;
};

const PersonsTableBody = ({
  data,
  page,
  rowsPerPage,
  handleClick,
  handleKeyDown,
  isSelectedFunc,
  columnData,
  customize,
  onSelectChange
}) => (
  <TableBody>
    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(d => {
      const isSelected = isSelectedFunc(d.id);
      let rowColor = "inherit";
      let shade = 500;

      if (customize.colors) {
        switch (d.line) {
          case "Sports":
            rowColor = blue[shade];
            break;
          case "X-Outdoor":
            rowColor = red[shade];
            break;
          case "Friluft":
            rowColor = green[shade];
            break;
          default:
            rowColor = yellow[shade];
            break;
        }
      }

      return (
        <TableRow
          hover
          style={{ backgroundColor: rowColor }}
          onClick={event => handleClick(event, d.id)}
          onKeyDown={event => handleKeyDown(event, d.id)}
          role="checkbox"
          aria-checked={isSelected}
          tabIndex={-1}
          key={d.id}
          selected={isSelected}
        >
          <TableCell padding="none">
            <Checkbox checked={isSelected} />
          </TableCell>
          <NavigateTableCell padding="none" id={d.id} />
          {columnData.map((cell, idx) => {
            return (
              <EnhancedTableCell
                style={
                  customize
                    ? { fontSize: customize.fontSize, padding: 0 }
                    : null
                }
                key={idx}
                padding={cell.disablePadding ? "none" : "default"}
                align={cell.align}
                displayAsTooltip={cell.displayAsTooltip}
                dateField={cell.dateField}
                selectField={cell.selectField}
                selectOptions={cell.selectOptions}
                onSelectChange={caller => {
                  d["caller"] = caller;
                  onSelectChange(d);
                }}
                data={d[cell.id]}
              />
            );
          })}
        </TableRow>
      );
    })}
  </TableBody>
);

PersonsTableBody.propTypes = {
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
  isSelectedFunc: PropTypes.func.isRequired,
  columnData: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectChange: PropTypes.func
};

export default PersonsTableBody;
