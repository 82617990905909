import React, { useState } from "react";
import { DataGrid, ValueGetterParams, GridRowId } from "@material-ui/data-grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import useStudentGrades from "../../hooks/useStudentGrades";
import { parseExamResult, parseExamType } from "../../helpers/parser";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    hegith: 400,
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  buttonRow: {
    padding: 16,
  },
}));

type GradeTableProps = {
  studentId: string;
};

const columns = [
  {
    field: "name",
    headerName: "Fag",
    width: 150,
    editable: false,
    sortable: true,
    valueGetter: (params: ValueGetterParams) => {
      return params.row.subject.name;
    },
  },

  {
    field: "value",
    headerName: "Værdi",
    width: 150,
    editable: true,
    valueGetter: (params: ValueGetterParams) =>
      parseExamResult(params.row.value),
  },
  {
    field: "type",
    headerName: "Type",
    width: 200,
    editable: false,
    sortable: true,
    valueGetter: (params: ValueGetterParams) => parseExamType(params.row.type),
  },
];

const StudentGradeTable: React.FC<GradeTableProps> = ({ studentId }) => {
  const [selections, setSelections] = useState<GridRowId[]>([]);
  const grades = useStudentGrades(studentId);
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={grades}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(selected) =>
            setSelections(selected.selectionModel)
          }
        />
      </div>
      <div className={classes.buttonRow}>
        <Button
          onClick={() => console.log("delete")}
          disabled={selections.length === 0}
          variant="contained"
          color="secondary"
        >
          Slet
        </Button>
      </div>
    </Paper>
  );
};

export default StudentGradeTable;
