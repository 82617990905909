import React from "react";
import Grid from "@material-ui/core/Grid";
import { DropzoneFileList } from "../../containers";
import { DropzoneFileTypes } from "../../containers/DropzoneFileList/DropzoneTypes";

type StudentFileProps = {
  id: string;
};
const StudentFilesComponent: React.FC<StudentFileProps> = ({ id }) => {
  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12} lg={10}>
          <DropzoneFileList
            collection="students"
            id={id}
            multiple
            type={DropzoneFileTypes.ExamDocs}
            heading="Eksamens- og instruktørbeviser"
          />
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={12} lg={10}>
          <DropzoneFileList
            collection="students"
            id={id}
            multiple
            type={DropzoneFileTypes.MedicalDocs}
            heading="Lægeerklæring"
          />
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={12} lg={10}>
          <DropzoneFileList
            collection="students"
            id={id}
            multiple
            type={DropzoneFileTypes.MeritDocs}
            heading="Meritdokumentation"
          />
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={12} lg={10}>
          <DropzoneFileList
            collection="students"
            id={id}
            multiple
            type={DropzoneFileTypes.ImportantDocs}
            heading="Vigtige dokumenter og aftaler"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default StudentFilesComponent;
