import React from "react";

import { StudentFilesComponent } from "../../components";

type StudentFileProps = {
  studentId: string;
};
const StudentFilesContainer: React.FC<StudentFileProps> = ({ studentId }) => {
  return <StudentFilesComponent id={studentId} />;
};

export default StudentFilesContainer;
