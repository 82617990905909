import React from "react";
import { Field, FieldArray, Formik } from "formik";
import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import { TextField } from "formik-material-ui";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Typography from "@material-ui/core/Typography";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { CheckboxField } from "../../components";
import { DropzoneFileList } from "../../containers";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DropzoneFileTypes } from "../../containers/DropzoneFileList/DropzoneTypes";
import { DateField } from "../../components";
import SubjectsTransferList from "./SubjectsTransferList";
import { parseLine } from "../../helpers/parser";
import { subjectsList } from "../../helpers/subjectsList";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import green from "@material-ui/core/colors/green";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    list: {
      width: "100%",
      maxWidth: 500,
      backgroundColor: theme.palette.background.paper,
      position: "relative",
      overflow: "auto",
      maxHeight: 400,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    checkmark: { color: green[500] },
    approvedSubjectsList: {
      width: 300,
      maxHeight: 350,
      overflow: "scroll",
    },
  })
);

type FormProps = {
  examiner: Examiner;
  semesters: { title: string }[];
  handleSubmit: (values: Examiner) => void;
};

const ExaminerForm: React.FC<FormProps> = ({
  examiner,
  semesters,
  handleSubmit,
}) => {
  const classes = useStyles();

  return (
    <Formik initialValues={examiner} onSubmit={handleSubmit}>
      {({
        values: { id, examinations, approvedSubjects },
        handleSubmit,
        resetForm,
        touched,
        isSubmitting,
        dirty,
      }) => (
        <form onSubmit={handleSubmit}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={3}>
                <Field
                  name="firstName"
                  label="Fornavn*"
                  type="text"
                  style={{ marginBottom: "8px" }}
                  component={TextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  name="lastName"
                  label="Efternavn*"
                  type="text"
                  style={{ marginBottom: "8px" }}
                  component={TextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  name="phone"
                  label="Telefon*"
                  type="tel"
                  style={{ marginBottom: "8px" }}
                  component={TextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  name="email"
                  label="email*"
                  type="email"
                  style={{ marginBottom: "8px" }}
                  component={TextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={3}>
                  <CheckboxField
                    name="paymentInformationForwarded"
                    label="Løninformation videresendt"
                  />
                </Grid>
                <Grid item xs={3}>
                  <CheckboxField
                    name="examinerReport"
                    label="Censor Indeberetning"
                  />
                </Grid>
                <Grid item xs={3}>
                  <DateField name="lastUsed" label="Sidst anvendt" />
                </Grid>
              </Grid>
              <Grid item xs={12} container justify="space-between">
                <CheckboxField name="approved" label="Godkendt" />
                <DateField name="approvedUntil" label={"Godkendt til"} />
                <FieldArray
                  name="approvedSubjects"
                  render={({ remove, push }) => (
                    <List
                      className={classes.approvedSubjectsList}
                      aria-label="approved subjects"
                      subheader={
                        <ListSubheader
                          component="div"
                          id="nested-list-subheader"
                          style={{ backgroundColor: "white" }}
                        >
                          Godkendt I
                        </ListSubheader>
                      }
                    >
                      {subjectsList.sort().map((subject) => {
                        const isAssigned = approvedSubjects
                          ? approvedSubjects.includes(subject)
                          : false;
                        const idx = approvedSubjects
                          ? approvedSubjects.indexOf(subject)
                          : -1;
                        return (
                          <ListItem
                            key={subject}
                            button
                            onClick={() =>
                              isAssigned ? remove(idx) : push(subject)
                            }
                          >
                            <ListItemIcon>
                              {isAssigned ? (
                                <CheckCircleOutlineIcon
                                  className={classes.checkmark}
                                />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText primary={subject} />
                          </ListItem>
                        );
                      })}
                    </List>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} container>
                <Grid item xs={12}>
                  <Field
                    name="note"
                    label="Note"
                    type="text"
                    style={{ marginBottom: "8px" }}
                    component={TextField}
                    fullWidth
                    multiline
                    rows={6}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} container>
                <Grid item xs={12}>
                  <DropzoneFileList
                    multiple
                    heading="Filer"
                    type={DropzoneFileTypes.Files}
                    collection="examiners"
                    id={id}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} container>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Legacy - bliver snart fjernet
                  </Typography>
                  <List subheader={<ListSubheader />} className={classes.list}>
                    <ListSubheader>{`Har været censor i:`}</ListSubheader>
                    {examinations &&
                      examinations.map((examination: any) => (
                        <ListItem
                          key={`${examination.line}${examination.subject}${examination.semester}`}
                        >
                          <ListItemText
                            primary={`${examination.subject}`}
                            secondary={`${parseLine(examination.line)} ${
                              examination.semester
                            }`}
                          />
                        </ListItem>
                      ))}
                  </List>
                </Grid>
              </Grid>
              <Grid item xs={12} container>
                {/* <ExaminationsComponent
                  examinerID={id}
                  semesters={semesters}
                  examinations={examinations || []}
                /> */}
                <SubjectsTransferList examinerID={id} semesters={semesters} />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.button}
                  disabled={isSubmitting || !dirty}
                >
                  Opdater
                </Button>

                <Button
                  className={classes.button}
                  disabled={!touched}
                  onClick={() => resetForm()}
                >
                  Fortryd
                </Button>
                <CheckboxField
                  name="isArchived"
                  label="Arkiveret"
                  style={{ marginTop: 16 }}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </form>
      )}
    </Formik>
  );
};

export default ExaminerForm;
