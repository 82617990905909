const keyOptions: { [key: string]: string } = {
  ml_ib: "ML/IB",
  kontor: "Kontor",
  daek2_boldrum_toejlager: "Dæk2/Boldrum",
  daek2_bur: "Dæk2 bur",
  gymh: "GymH",
  forh: "ForH",
  nd: "ND",
  alarmfob: "Alarmbrik",
  keyfob: "Nøglebrik",
  clothes: "Tøjlager",
};
export { keyOptions };
