import {
  ADDING_EXAM_SUBJECT,
  FETCHING_EXAM_SUBJECTS,
  FETCH_EXAM_SUBJECT_SUCCESS,
  FETCH_EXAM_SUBJECT_ERROR,
  SETTING_EXAMS,
  SET_EXAMS_SUCCESS,
  SET_EXAMS_ERROR,
  UPDATING_EXAM,
  UPDATE_EXAM_SUCCESS,
  UPDATE_EXAM_ERROR,
  DELETING_GRADES,
  DELETE_GRADES_SUCCESS,
  DELETE_GRADES_ERROR,
  SAVING_GRADE,
  SAVE_GRADE_SUCCESS,
  SAVE_GRADE_ERROR
} from '../constants';

const initialState = {
  error: {},
  isFetching: false,
  subjects: {},
  isSetting: false,
  success: false,
  isUpdating: false,
  updateSuccess: false,
  updateError: {},
  isDeleting: false,
  deleteSuccess: true,
  deleteError: {},
  isSaving: false,
  saveGradeSuccess: false,
  saveGradeError: {}
};

const exams = (state = initialState, action) => {
  switch (action.type) {
    case ADDING_EXAM_SUBJECT:
      return { ...state };

    case FETCHING_EXAM_SUBJECTS:
      return { ...state, isFetching: true };
    case FETCH_EXAM_SUBJECT_SUCCESS:
      return { ...state, isFetching: false, subjects: action.subjects };
    case FETCH_EXAM_SUBJECT_ERROR:
      return { ...state, isFetching: false, error: action.error };
    case SETTING_EXAMS:
      return { ...state, isSetting: true };
    case SET_EXAMS_SUCCESS:
      return { ...state, isSetting: false, success: true };
    case SET_EXAMS_ERROR:
      return { ...state, isSetting: false, success: false };

    case UPDATING_EXAM:
      return { ...state, isUpdating: true };
    case UPDATE_EXAM_SUCCESS:
      return { ...state, isUpdating: false, updateSuccess: true };
    case UPDATE_EXAM_ERROR:
      return { ...state, isUpdating: false, updateError: action.error };
    case DELETING_GRADES:
      return { ...state, isDeleting: true };
    case DELETE_GRADES_SUCCESS:
      return { ...state, isDeleting: false, deleteSuccess: true };
    case DELETE_GRADES_ERROR:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: false,
        deleteError: action.error
      };
    case SAVING_GRADE:
      return { ...state, isSaving: true };
    case SAVE_GRADE_SUCCESS:
      return { ...state, isSaving: false, saveGradeSuccess: true };
    case SAVE_GRADE_ERROR:
      return {
        ...state,
        isSaving: false,
        saveGradeSuccess: false,
        saveGradeError: action.error
      };
    default:
      return state;
  }
};

export default exams;
