import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../reducers";
import ExaminersTable from "./components/ExaminersTable";
import Header from "./components/Header";
import { createExaminersSheet } from "../actions/sheets";

const ExaminersComponent: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const [query, setQuery] = React.useState<string | null>(null);
  const [selection, setSelection] = React.useState<Examiner[]>([]);

  useFirestoreConnect([{ collection: "semesters" }]);

  const [filters, setFilters] = useState<Filters>({
    approved: null,
    examinerReport: null,
    paymentInformationForwarded: null,
  });

  const semesters = useSelector(
    ({ firestore: { ordered } }: AppState) => ordered.semesters || []
  );

  const handleCreateNewExaminer = async () => {
    try {
      const doc = firestore.collection("examiners").doc();
      const newExaminer: Examiner = {
        id: doc.id,
        firstName: "",
        lastName: "",
        isArchived: false,
        approved: false,
        approvedSubjects: [],
        approvedUntil: null,
        lastUsed: null,
        email: "",
        phone: "",
        paymentInformationForwarded: false,
        examinerReport: false,
        note: "",
        examinations: [],
        subjects: {},
        semesters: [],
      };
      await doc.set(newExaminer);
      history.push(`/examiners/${doc.id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteExaminers = async () => {
    if (selection.length === 0) return;

    if (
      window.confirm(
        `Er du sikker på du vil slette ${selection.length} censorer?`
      )
    ) {
      const batch = firestore.batch();
      const collectionRef = firestore.collection("examiners");
      selection.forEach((e) => batch.delete(collectionRef.doc(e.id)));

      try {
        await batch.commit();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleCreateSpreadsheet = () => {
    dispatch(createExaminersSheet(selection));
  };

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Header
          newExaminer={handleCreateNewExaminer}
          deleteExaminer={handleDeleteExaminers}
          createSpreadsheet={() => handleCreateSpreadsheet()}
          selection={selection}
          filters={filters}
          setFilters={setFilters}
          semesters={semesters}
          setQuery={setQuery}
          query={query}
        />
      </div>

      <ExaminersTable
        setSelection={setSelection}
        query={query}
        filters={filters}
      />
    </div>
  );
};

export default ExaminersComponent;
