import React from "react";
import { useField, getIn } from "formik";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

export const SelectField = ({ label, options, ...props }) => {
  const [field, meta] = useField(props);
  const errorText =
    getIn(meta.touched, field.name) && getIn(meta.errors, field.name);

  return (
    <FormControl fullWidth error={!!errorText}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select {...field} {...props}>
        {options.map((op, i) => (
          <MenuItem key={`${op.value}-${i}`} value={op.value}>
            {op.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};
export default SelectField;
