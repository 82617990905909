import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";

const styles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752
  },
  demo: {
    background: theme.palette.background.paper
  },
  title: {
    margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
});

class NewSemesterDialog extends React.Component {
  state = {
    startYear: "",
    add: false,
    newClass: ""
  };

  getDates = () => {
    var currentYear = new Date().getFullYear();
    var yearsAhead = [1, 2, 3, 4, 5];
    var menuItems = [];
    yearsAhead.map(add1 => {
      var futureDate = new Date(currentYear + add1, 8);
      while (futureDate.getDay() !== 1) {
        futureDate.setDate(futureDate.getDate() + 1);
      }
      return menuItems.push(
        <MenuItem
          key={add1}
          value={`${futureDate.getFullYear()}-${futureDate.getFullYear() + 1}`}
        >
          {futureDate.toDateString()}
        </MenuItem>
      );
    });
    return menuItems;
  };

  handleSelectChange = year => event => {
    this.setState({ startYear: event.target.value, touched: true });
  };

  addSemesterHandler = () => {
    this.props.handleCreateSemester(this.state.startYear);
  };

  render() {
    const { classes, handleToggleDialog, open, classTemplates } = this.props;
    return (
      <div>
        <Dialog open={open} onClose={handleToggleDialog}>
          <DialogTitle>{"Tilføj nyt semester?"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Når du tilføjer et nyt semester bliver alle hold og linier
              automatisk oprettet som flg.:
            </DialogContentText>
            <Grid container>
              <Grid item xs={12}>
                <FormControl
                  className={classes.formControl}
                  error={this.state.startYear === ""}
                >
                  <InputLabel htmlFor="age-helper">Start dato</InputLabel>
                  <Select
                    value={this.state.startYear}
                    onChange={this.handleSelectChange("startYear")}
                    input={<Input id="start-year-helper" />}
                  >
                    <MenuItem value="">
                      <em>Vælg</em>
                    </MenuItem>
                    {this.getDates()}
                  </Select>
                  <FormHelperText>
                    Vælg startår for det semester du vil oprette
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.demo}>
                  <List>
                    {Object.keys(classTemplates).map(key => {
                      return (
                        <ListItem button key={key}>
                          <ListItemIcon>
                            <ClearIcon />
                          </ListItemIcon>
                          <ListItemText primary={classTemplates[key]} />
                        </ListItem>
                      );
                    })}
                    {this.state.add && (
                      <div>
                        <Input
                          name="add"
                          type="text"
                          value={this.state.newClass}
                          placeholder="Holdnavn"
                          className={classes.textField}
                          onChange={e =>
                            this.setState({ newClass: e.target.value })
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() =>
                                  this.props.handleCreateClass(
                                    this.state.newClass
                                  )
                                }
                              >
                                <DoneIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </div>
                    )}
                    <ListItem
                      button
                      onClick={() => {
                        this.setState({ add: !this.state.add });
                      }}
                    >
                      <ListItemIcon>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemText primary={"Tilføj hold"} />
                    </ListItem>
                  </List>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleToggleDialog()} color="default">
              Annuller
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={this.state.startYear === ""}
              onClick={() => {
                this.addSemesterHandler();
              }}
            >
              Tilføj
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
NewSemesterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleToggleDialog: PropTypes.func.isRequired
};
export default withStyles(styles)(NewSemesterDialog);
