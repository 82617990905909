import {
  FETCHING_SEARCH_RESULTS,
  FETCH_SEARCH_RESULTS_SUCCESS,
  FETCH_SEARCH_RESULTS_ERROR
} from "../constants";

const initialState = {
  results: [],
  error: {},
  isFetching: false
};

const search = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_SEARCH_RESULTS:
      return { ...state, isFetching: true };
    case FETCH_SEARCH_RESULTS_SUCCESS:
      return { ...state, isFetching: false, results: action.results };
    case FETCH_SEARCH_RESULTS_ERROR:
      return { ...state, isFetching: false, error: action.error };

    default:
      return state;
  }
};

export default search;
