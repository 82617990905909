import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Toolbar from "@material-ui/core/Toolbar";
import FontAwesome from "react-fontawesome";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Message from "@material-ui/icons/Message";
const toolbarStyles = theme => ({
  root: {
    paddingRight: 2
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary[100],
          backgroundColor: theme.palette.primary[100]
        }
      : {
          color: theme.palette.primary[100],
          backgroundColor: theme.palette.primary[100]
        },
  button: {
    margin: theme.spacing(1)
  },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.primary,
    width: "30%"
  },
  title: {
    flex: "0 0 auto"
  }
});

let PersonsTableToolbar = props => {
  const {
    numSelected,
    classes,
    count,
    handleDeleteItems,
    handleSendMessages,
    handleCreateSheet,
    canonicalName
  } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography type="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography type="headline">
            {count} {canonicalName}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <div>
            <IconButton
              className={classes.button}
              aria-label="Slet"
              onClick={() => handleDeleteItems()}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Send besked"
              onClick={() => handleSendMessages()}
            >
              <Message />
            </IconButton>
            {handleCreateSheet && (
              <IconButton
                className={classes.button}
                aria-label="Create spreadsheet"
                onClick={() => handleCreateSheet()}
              >
                <FontAwesome name="table" />
              </IconButton>
            )}
          </div>
        ) : null}
      </div>
    </Toolbar>
  );
};

PersonsTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  canonicalName: PropTypes.string.isRequired,
  handleSendMessages: PropTypes.func.isRequired
};

PersonsTableToolbar = withStyles(toolbarStyles)(PersonsTableToolbar);
export default PersonsTableToolbar;
