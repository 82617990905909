import React, { Component } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "recompose";
import { authSuccess, authUser } from "../../actions/auth";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import green from "@material-ui/core/colors/green";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AppState } from "../../reducers";
import logo from "./logo.png";
import { login, loginWithPopup } from "../../helpers/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minWidth: "400px",
    marginTop: theme.spacing(3),
    zIndex: 1,
    overflow: "hidden",
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
    textAlign: "center",
  }),
  content: {
    width: "100%",
    flexGrow: 1,
    padding: theme.spacing(3),
    height: "calc(100% - 56px)",
    marginTop: 56,
    [theme.breakpoints.up("sm")]: {
      content: {
        height: "calc(100% - 64px)",
        marginTop: 64,
      },
    },
  },
  button: {
    margin: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(3),
  },
  imageDiv: {
    backgroundImage: `url(${logo})`,
    backgroundRepeat: "no-repeat",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}));

const LoginContainer: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { error, isAuthed, authenticating } = useSelector(
    (state: AppState) => state.auth
  );

  const signIn = async () => {
    console.log("SIGNING IN");

    try {
      const result = await loginWithPopup();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <main className={classes.content}>
          {!isAuthed && (
            <Grid
              container
              alignItems={"center"}
              justify={"center"}
              spacing={4}
            >
              <Grid item xs={4} style={{ textAlign: "center" }}>
                <Paper className={classes.paper} elevation={4}>
                  <img src={logo} alt={"Paul Petersen logo"} />
                  <Typography variant="h1" component="h3">
                    Velkommen til PP KBH Intra
                  </Typography>
                  <Typography variant="body1" component="p">
                    Log ind for at forstætte
                  </Typography>
                  <Divider className={classes.divider} />
                  <div className={classes.wrapper}>
                    <Button className={classes.button} onClick={() => signIn()}>
                      Login
                    </Button>
                    {authenticating && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                  {error !== null && <Divider className={classes.divider} />}
                  <Typography variant="body2" component="p">
                    {error}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          )}
        </main>
      </div>
    </div>
  );
};

export default LoginContainer;
