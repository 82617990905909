import {
  FILE_UPLOAD_PROGRESS,
  FILE_UPLOAD_SUCCEEDED,
  FILE_UPLOAD_FAILED,
  FILES_UPLOAD_PROGRESS,
  FILES_UPLOAD_SUCCEEDED,
  FILES_UPLOAD_ERROR,
  FETCHING_FILES_FOR_COLLECTION,
  FETCH_FILES_FOR_COLLECTION_SUCCESS,
  FETCH_FILES_FOR_COLLECTION_ERROR,
  DELETING_FILE,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_ERROR
} from "../constants";

export const initialState = {
  isUploading: false,
  uploadSucceeded: false,
  progress: 0,
  filesRemaining: 0,
  isFetching: false,
  isDeleting: false,
  downloadURL: "",
  files: {
    examDocs: [],
    meritDocs: [],
    medicalDocs: [],
    importantDocs: [],
    files: []
  },
  error: {}
};

const fileUploads = (state = initialState, action) => {
  switch (action.type) {
    case FILE_UPLOAD_PROGRESS:
      return {
        ...state,
        isUploading: true,
        progress: action.progress
      };
    case FILE_UPLOAD_SUCCEEDED:
      return {
        ...state,
        isUploading: false,
        downloadURL: action.downloadURL,
        uploadSucceeded: true
      };
    case FILE_UPLOAD_FAILED:
      return {
        ...state,
        isUploading: false,
        uploadSucceeded: false,
        error: action.error
      };
    case FILES_UPLOAD_PROGRESS:
      return {
        ...state,
        isUploading: true,
        filesRemaining: action.filesRemaining,
        progress: action.progress
      };
    case FILES_UPLOAD_SUCCEEDED:
      return {
        ...state,
        isUploading: false,
        filesRemaining: 0,
        progress: 0,
        uploadSucceeded: true
      };
    case FILES_UPLOAD_ERROR:
      return {
        ...state,
        isUploading: false,
        error: action.error
      };
    case FETCHING_FILES_FOR_COLLECTION:
      return { ...state, isFetching: true };
    case FETCH_FILES_FOR_COLLECTION_SUCCESS:
      return { ...state, isFetching: false, files: { ...action.files } };
    case FETCH_FILES_FOR_COLLECTION_ERROR:
      return { ...state, isFetching: false, error: action.error };
    case DELETING_FILE:
      return { ...state, isDeleting: true };
    case DELETE_FILE_SUCCESS:
      return { ...state, isDeleting: false };
    case DELETE_FILE_ERROR:
      return { ...state, isDeleting: false, error: action.error };
    default:
      return state;
  }
};

export default fileUploads;
