import React from "react";
import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { routerActions } from "connected-react-router";

const locationHelper = locationHelperBuilder({});

const LoadingScreen = () => {
  return (
    <Grid container>
      <Grid item xs={12} md={4} lg={2}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export const UserIsAuthenticated = connectedRouterRedirect({
  wrapperDisplayName: "UserIsAuthenticated",
  AuthenticatingComponent: LoadingScreen,
  allowRedirectBack: true,
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || "/login",
  authenticatingSelector: ({ firebase: { auth, profile, isInitializing } }) =>
    !auth.isLoaded || isInitializing === true,
  authenticatedSelector: ({ firebase: { auth } }) =>
    auth.isLoaded && !auth.isEmpty,
  redirectAction: newLoc => dispatch => {
    routerActions.replace(newLoc); // or
    dispatch({ type: "UNAUTHED_REDIRECT" });
  }
});

export const UserIsNotAuthenticated = connectedRouterRedirect({
  wrapperDisplayName: "UserIsNotAuthenticated",
  AuthenticatingComponent: LoadingScreen,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || "/",
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) =>
    !auth.isLoaded || isInitializing === true,
  authenticatedSelector: ({ firebase: { auth } }) =>
    auth.isLoaded && auth.isEmpty,
  redirectAction: newLoc => dispatch => {
    // routerActions.replace or other redirect
    dispatch({ type: "UNAUTHED_REDIRECT" });
  }
});

// export const UserIsAuthenticated = Component => props => {
//   return <Component {...props} />;
// };

// export const UserIsNotAuthenticated = Component => props => {
//   return <Component {...props} />;
// };
