import React from "react";
import PropTypes from "prop-types";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(2),
  }),
  button: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    width: "100%",
  },
  input: {
    display: "none",
  },
});

const StudentActionsMenu = ({
  handleProfileImageFile,
  classes,
  handleSendMessage,
  creatingAccount,
  createAccountSuccess,
  createAccountError,
  hasAccount,
  handleViewMessages,
  handleCreateAccount,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="primary"
        variant="contained"
        onClick={handleClick}
      >
        {creatingAccount ? "Opretter konto" : "Handlinger"}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <input
            accept="jpg,jpeg,JPG,JPEG,png,PNG"
            className={classes.input}
            id="file"
            type="file"
            onChange={(e) => handleProfileImageFile(e.target.files[0])}
          />
          <label htmlFor="file">Upload billede</label>
        </MenuItem>
        <MenuItem onClick={handleSendMessage}>Send besked</MenuItem>
        <MenuItem onClick={handleViewMessages}>Se beskedhistorik</MenuItem>
        <MenuItem
          onClick={handleCreateAccount}
          disabled={hasAccount || creatingAccount}
        >
          {" "}
          {creatingAccount ? "Opretter..." : "Opret konto"}
        </MenuItem>
      </Menu>
    </>
  );
};

StudentActionsMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  handleProfileImageFile: PropTypes.func.isRequired,
  handleSendMessage: PropTypes.func.isRequired,
  handleViewMessages: PropTypes.func.isRequired,
  handleCreateAccount: PropTypes.func.isRequired,
};

export default withStyles(styles)(StudentActionsMenu);
