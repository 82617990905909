import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useSelector, useDispatch } from "react-redux";
import { openSnackbar } from "../actions/snackbar";
import { AppState } from "../reducers";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Form from "./components/Form";
import { IEmployee } from "./Employee";

const useStyles = makeStyles((theme) => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
}));

const EmployeeContainer: React.FC = () => {
  const { id }: { id: string } = useParams();
  const classes = useStyles();
  useFirestoreConnect([
    {
      collection: "employees",
      doc: id,
    },
  ]);

  const employee = useSelector(
    ({ firestore: { data } }: AppState) => data.employees && data.employees[id]
  );

  const firestore = useFirestore();
  const dispatch = useDispatch();

  const saveEmployee = async (employee: IEmployee) => {
    try {
      await firestore.collection("employees").doc(id).update(employee);
      dispatch(openSnackbar(`✅ ${employee.firstName} opdateret`));
    } catch (error) {
      dispatch(openSnackbar(`🛑 Kunne ikke opdatere ${employee.firstName}`));
      console.log(error);
    }
  };
  return (
    <Grid container justify="center">
      <Grid item xs={12} lg={10} xl={8}>
        <Paper className={classes.root} elevation={6}>
          {employee ? (
            <Form
              employee={employee}
              doSubmit={(employee) => saveEmployee(employee)}
            />
          ) : null}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EmployeeContainer;
