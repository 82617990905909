import {
  FETCH_APPLICANTS_SUCCESS,
  FETCH_APPLICANTS_ERROR,
  FETCHING_APPLICANTS,
  FETCH_APPLICANT_SUCCESS,
  FETCH_APPLICANT_ERROR,
  FETCHING_APPLICANT,
  SAVE_APPLICANT_SUCCESS,
  SAVE_APPLICANT_ERROR,
  SAVING_APPLICANT,
  MOVE_APPLICANT_SUCCESS,
  MOVE_APPLICANT_ERROR,
  MOVING_APPLICANT,
  CREATING_APPLICANT,
  CREATE_APPLICANT_SUCCESS,
  CREATE_APPLICANT_ERROR,
  FETCHING_APPLICANT_WITH_RANGE_QUERY,
  FETCH_APPLICANT_RANGE_QUERY_SUCCESS,
  FETCH_APPLICANT_RANGE_QUERY_ERROR,
  DELETING_APPLICANT,
  DELETE_APPLICANT_SUCCESS,
  DELETE_APPLICANT_ERROR,
  DELETING_APPLICANTS,
  DELETE_APPLICANTS_SUCCESS,
  DELETE_APPLICANTS_ERROR
} from "../constants";

export const initialState = {
  applicants: {},
  error: {},
  isFetching: false,
  moving: false,
  moveError: {},
  moveSuccess: false,
  success: false,
  isSaving: false,
  isCreating: false,
  isDeleting: false,
  deleteSuccess: false,
  deleteError: {},
  applicant: {},
  comparison: {}
};

const applicants = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APPLICANTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        applicants: action.applicants
      };
    case FETCH_APPLICANTS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case FETCHING_APPLICANTS:
      return {
        ...state,
        isFetching: true
      };
    case FETCHING_APPLICANT:
      return {
        ...state,
        isFetching: true
      };
    case FETCH_APPLICANT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        applicant: action.applicant
      };
    case FETCH_APPLICANT_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case SAVING_APPLICANT:
      return {
        ...state,
        isSaving: true
      };
    case SAVE_APPLICANT_SUCCESS:
      return {
        ...state,
        isSaving: false,
        success: true
      };
    case SAVE_APPLICANT_ERROR:
      return {
        ...state,
        isSaving: false,
        error: action.error,
        success: false
      };
    case MOVING_APPLICANT:
      return { ...state, moving: true };
    case MOVE_APPLICANT_SUCCESS:
      return { ...state, moving: false, moveSuccess: true };
    case MOVE_APPLICANT_ERROR:
      return {
        ...state,
        moving: false,
        moveSuccess: false,
        moveError: action.error
      };

    case CREATING_APPLICANT:
      return { ...state, isCreating: true };
    case CREATE_APPLICANT_SUCCESS:
      return { ...state, isCreating: false };
    case CREATE_APPLICANT_ERROR:
      return { ...state, isCreating: false };
    case FETCHING_APPLICANT_WITH_RANGE_QUERY:
      return { ...state, isFetching: true };
    case FETCH_APPLICANT_RANGE_QUERY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        applicants: action.applicants,
        comparison: action.comparison
      };
    case FETCH_APPLICANT_RANGE_QUERY_ERROR:
      return { ...state, isFetching: false, error: action.error };
    case DELETING_APPLICANT:
      return { ...state, isDeleting: false };
    case DELETE_APPLICANT_SUCCESS:
      return { ...state, isDeleting: false, deleteSuccess: true };
    case DELETE_APPLICANT_ERROR:
      return { ...state, isDeleting: false, deleteError: action.error };
    case DELETING_APPLICANTS:
      return { ...state, isDeleting: true };
    case DELETE_APPLICANTS_SUCCESS:
      return { ...state, isDeleting: false, deleteSuccess: true };
    case DELETE_APPLICANTS_ERROR:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: false,
        error: action.error
      };
    default:
      return state;
  }
};
export default applicants;
