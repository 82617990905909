import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {
  internalContactGroups,
  externalContactGroups
} from "../../helpers/contactGroups";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    background: theme.palette.background.paper
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%"
  }
});

const ContactsActionList = ({
  classes,
  handleCreateNewContact,
  memberOfValue,
  memberOfDidChange,
  isExternal
}) => {
  const contactGroups = isExternal
    ? externalContactGroups
    : internalContactGroups;

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        style={{ width: "100%", margin: 8 }}
        onClick={handleCreateNewContact}
      >
        Ny Kontakt
      </Button>
      <Divider />
      <FormControl className={classes.formControl}>
        <InputLabel>Vælg gruppe</InputLabel>
        <Select value={memberOfValue} onChange={memberOfDidChange}>
          <MenuItem value="all">Alle</MenuItem>
          {Object.keys(contactGroups).map(key => {
            return (
              <MenuItem key={key} value={key}>
                {contactGroups[key]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

ContactsActionList.propTypes = {
  handleCreateNewContact: PropTypes.func.isRequired
};

export default withStyles(styles)(ContactsActionList);
