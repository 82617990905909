import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useFirebase } from "react-redux-firebase";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../actions/snackbar";
import { firebaseAuth } from "../../helpers/firebaseHelper";

const useStyles = makeStyles(() => ({
  avatar: {
    height: 150,
    width: 150,
    border: `solid ${blue.A400} 5px`,
  },
  progress: {
    marginTop: 8,
  },
}));

type DisplayImageProps = {
  collection: string;
  itemID: string;
  imageURL: string;
};
const DisplayImage: React.FC<DisplayImageProps> = ({
  collection,
  itemID,
  imageURL,
}) => {
  const classes = useStyles();
  const firebase = useFirebase();
  const [isUploading, setIsUploading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const dispatch = useDispatch();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const user = firebaseAuth().currentUser;
      if (!user) {
        dispatch(
          openSnackbar(`🛑 Upload mislykkedes: Kunne ikke finde bruger`)
        );
        return;
      }
      const { uid } = user;
      const storageRef = firebase.storage().ref();
      const file = acceptedFiles[0];
      if (!file) return;
      const ext = file.name.split(".").pop();
      const fileName = `profile.${ext}`;
      const fileRef = storageRef.child(`${collection}/${itemID}/${fileName}`);

      setIsUploading(true);
      const task = fileRef.put(file, { customMetadata: { uploadedBy: uid } });
      task.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          setIsUploading(false);
          dispatch(
            openSnackbar(`🛑 Kunne ikke uploade billede: ${error.message}`)
          );
        },
        () => {
          setIsUploading(false);
          dispatch(openSnackbar(`✅ Billedet blev uploaded`));
        }
      );
    },
    [firebase, collection, itemID, dispatch]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: "image/jpeg",
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Avatar className={classes.avatar}>
          <CloudUploadIcon />
        </Avatar>
      ) : (
        <Avatar src={imageURL} className={classes.avatar} />
      )}
      {isUploading && (
        <LinearProgress
          variant="determinate"
          value={progress}
          className={classes.progress}
        />
      )}
    </div>
  );
};

export default DisplayImage;
