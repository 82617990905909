import {
  FETCHING_UPLOADS,
  FETCH_UPLOADS_SUCCESS,
  FETCH_UPLOADS_ERROR,
  FETCHING_USER_UPLOADS,
  FETCH_USER_UPLOADS_SUCCESS,
  FETCH_USER_UPLOADS_ERROR,
  USER_UPLOADS_PROGRESS
} from '../constants';

const initialState = {
  uploads: {},
  userUploads: {},
  error: {},
  progress: {},
  success: false,
  isFetching: false,
  isFetchingUserUploads: false
};

const uploads = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_UPLOADS:
      return { ...state, isFetching: true };
    case FETCH_UPLOADS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        uploads: action.uploads,
        success: true
      };
    case FETCH_UPLOADS_ERROR:
      return { ...state, isFetching: false, error: action.error };
    case FETCHING_USER_UPLOADS:
      return { ...state, isFetchingUserUploads: true };
    case FETCH_USER_UPLOADS_SUCCESS:
      return {
        ...state,
        isFetchingUserUploads: false,
        userUploads: action.userUploads,
        success: true
      };
    case FETCH_USER_UPLOADS_ERROR:
      return { ...state, isFetchingUserUploads: false, error: action.error };
    case USER_UPLOADS_PROGRESS:
      return {
        ...state,
        progress: action.progress
      };
    default:
      return state;
  }
};
export default uploads;
