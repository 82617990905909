import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import NotificationCardComponent from "./NotificationCardComponent";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";

const styles = (theme) => ({
  list: {},
  listItem: {},
  flex: {
    flex: 1,
  },
  drawerPaper: {
    height: "100%",
    width: 400,
  },
  listFull: {
    width: "auto",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  greenAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: green[500],
  },
});

const NotificationsComponent = ({ classes, open, toggleNotifications }) => {
  const uid = useSelector((state) => state.auth.user.uid || "");
  const firestore = useFirestore();
  useFirestoreConnect([
    {
      collection: "users",
      doc: uid,
      subcollections: [
        { collection: "notifications", orderBy: ["created_at", "desc"] },
      ],
      storeAs: `${uid}-notifications`,
    },
  ]);

  const notifications = useSelector(
    (state) => state.firestore.ordered[`${uid}-notifications`] || []
  );

  const clearNotifications = async (ids) => {
    const ref = firestore
      .collection("users")
      .doc(uid)
      .collection("notifications");
    const batch = firestore.batch();
    ids.map((id) => {
      batch.delete(ref.doc(id));
      return null;
    });
    await batch.commit();
  };

  const setNotificationsRead = async (ids) => {
    const ref = firestore
      .collection("users")
      .doc(uid)
      .collection("notifications");
    const batch = firestore.batch();
    ids.map((id) => {
      batch.update(ref.doc(id), { read: true });
      return null;
    });
    await batch.commit();
  };

  return (
    <div>
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        onClose={() => toggleNotifications()}
        open={open}
      >
        <List className={classes.list}>
          <ListItem
            button
            className={classes.listItem}
            id="list-button"
            onClick={() => {
              clearNotifications(notifications.map((n) => n.id));
            }}
          >
            <ListItemIcon>
              <ClearAllIcon />
            </ListItemIcon>
            <ListItemText primary={"Ryd alle"} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setNotificationsRead(notifications.map((n) => n.id));
            }}
          >
            <ListItemIcon>
              <DoneAllIcon />
            </ListItemIcon>
            <ListItemText primary={"Marker alle som læst"} />
          </ListItem>
        </List>
        <Divider />
        {notifications.length > 0 ? (
          notifications.map((notification, i) => {
            return (
              <NotificationCardComponent
                key={i}
                notification={notification}
                notificationRead={(ids) => {
                  setNotificationsRead(ids);
                }}
                clearNotification={(ids) => {
                  clearNotifications(ids);
                }}
              />
            );
          })
        ) : (
          <Card>
            <CardHeader
              avatar={
                <Avatar className={classes.greenAvatar} size={50}>
                  <ThumbUpIcon />
                </Avatar>
              }
              title={"Du er up-to-date"}
            />
          </Card>
        )}
      </Drawer>
    </div>
  );
};
NotificationsComponent.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(NotificationsComponent);
