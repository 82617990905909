export function timeAgo(dateString) {
  const date = new Date(dateString).getTime();
  const now = new Date().getTime();

  const secondsAgo = Math.round((now - date) / 1000);

  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  if (secondsAgo < minute) {
    return `${secondsAgo}s siden`;
  } else if (secondsAgo < hour) {
    return `${Math.round(secondsAgo / minute)}m siden`;
  } else if (secondsAgo < day) {
    return `${Math.round(secondsAgo / hour)}t siden`;
  } else if (secondsAgo < week) {
    return `${Math.round(secondsAgo / day)}d siden`;
  } else {
    return `${Math.round(secondsAgo / week)}uger siden`;
  }
}
