import React from "react";
import { useField, FieldAttributes } from "formik";
import { convertToPlainDate } from "../../helpers/parser";
import { DatePicker, DateTimePicker } from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";

type DateFieldProps = {
  label: string;
  disabled?: boolean;
  maxDate?: Date;
  showDeleteIcon?: boolean | null;
} & FieldAttributes<unknown>;

export const DateField: React.FC<DateFieldProps> = ({
  label,
  disabled,
  maxDate,
  showDeleteIcon = false,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const errorText = meta.error && meta.touched ? meta.error : "";

  const valueOrNull = (value: moment.Moment | null): Date | null => {
    return convertToPlainDate(value);
  };

  return (
    <DatePicker
      disabled={disabled}
      {...field}
      label={label}
      autoOk
      maxDate={maxDate}
      variant="inline"
      value={valueOrNull(field.value as moment.Moment)}
      format="DD MMM YYYY"
      invalidLabel={errorText}
      helperText={errorText}
      InputProps={{
        endAdornment: showDeleteIcon ? (
          <InputAdornment position="end">
            <IconButton onClick={() => setValue(null)}>
              <Delete />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      onChange={(value) => {
        if (value) {
          setValue(value.toDate());
        }
      }}
    />
  );
};

export const DateTimeField: React.FC<DateFieldProps> = ({
  label,
  disabled,
  showDeleteIcon = false,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const errorText = meta.error && meta.touched ? meta.error : "";

  const valueOrNull = (value: moment.Moment | null): Date | null => {
    return convertToPlainDate(value);
  };

  return (
    <DateTimePicker
      {...field}
      disabled={disabled}
      label={label}
      ampm={false}
      variant="inline"
      style={{ minWidth: 200 }}
      value={valueOrNull(field.value as moment.Moment)}
      format="LLL"
      invalidLabel={errorText}
      InputProps={{
        endAdornment: showDeleteIcon ? (
          <InputAdornment position="end">
            <IconButton onClick={() => setValue(null)}>
              <Delete />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      onChange={(value) => {
        if (value) {
          setValue(value.toDate());
        }
      }}
    />
  );
};
