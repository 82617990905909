import React from "react";
import PropTypes from "prop-types";
import keycode from "keycode";
import Table from "@material-ui/core/Table";
import StudentsTableHead from "./StudentsTableHead";
import StudentsTableToolbar from "./StudentsTableToolbar";
import StudentsTableBody from "./StudentsTableBody";
import StudentsTableFooter from "./StudentsTableFooter";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import IconButton from "@material-ui/core/IconButton";

class StudentsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "firstName",
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 35,
      filters: {},
    };
  }

  componentDidMount() {
    this.setState({
      data: this.props.students.sort((a, b) =>
        a.firstName < b.firstName ? -1 : 1
      ),
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        data: nextProps.students.sort((a, b) =>
          a.firstName < b.firstName ? -1 : 1
        ),
      });
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    const data =
      order === "desc"
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ data, order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.state.data.map((s) => s.id) });
      return;
    }
    this.setState({ selected: [] });
  };

  handleKeyDown = (event, id) => {
    if (keycode(event) === "space") {
      this.handleClick(event, id);
    }
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleBackButtonClick = () => {
    const { page } = this.state;
    if (page === 0) return;
    this.handleChangePage(null, page - 1);
  };

  handleNextButtonClick = () => {
    const { page, rowsPerPage, data } = this.state;
    if (page >= Math.ceil(data.length / rowsPerPage) - 1) return;
    this.handleChangePage(null, page + 1);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  handleSendMessages = () => this.props.handleSendMessages(this.state.selected);

  handleSetGraduated = (date) =>
    this.props.handleSetGraduated(date, this.state.selected);

  handleSetProp = (prop) => {
    this.props.handleSetProp({ [prop]: true }, this.state.selected);
  };

  render() {
    const {
      handleDeleteItems,
      handleCreateSpreadsheet,
      handleCreateTravelInfo,
      handleCreateCertificateRequest,
    } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;

    return (
      <div>
        <StudentsTableToolbar
          numSelected={selected.length}
          studentCount={data.length}
          handleDeleteItems={() => handleDeleteItems(selected)}
          handleCreateSpreadsheet={() => handleCreateSpreadsheet(selected)}
          handleCreateTravelInfo={() => handleCreateTravelInfo(selected)}
          handleCreateCertificateRequest={() =>
            handleCreateCertificateRequest(selected)
          }
          handleSendMessages={this.handleSendMessages}
          handleSetGraduated={this.handleSetGraduated}
          handleSetProp={this.handleSetProp}
        />
        <div>
          <div style={{ float: "right" }}>
            <IconButton
              onClick={this.handleBackButtonClick}
              disabled={page === 0}
            >
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton
              onClick={this.handleNextButtonClick}
              disabled={page >= Math.ceil(data.length / rowsPerPage) - 1}
            >
              <KeyboardArrowRight />
            </IconButton>
          </div>
          <Table>
            <StudentsTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <StudentsTableBody
              data={data}
              page={page}
              rowsPerPage={rowsPerPage}
              handleClick={this.handleClick}
              handleKeyDown={this.handleKeyDown}
              isSelectedFunc={this.isSelected}
            />
            <StudentsTableFooter
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPageOptions={[25, 35, 65]}
            />
          </Table>
        </div>
      </div>
    );
  }
}

StudentsTable.propTypes = {
  students: PropTypes.array.isRequired,
};

export default StudentsTable;
