import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import { Field } from "formik";
import { Select } from "formik-material-ui";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {
  dischargeReasonsApplicants,
  dischargeReasonsStudents,
} from "../../helpers/dischargeReasons";

const DischargedSelect = ({ classes, type, setFieldValue, disabled }) => {
  const dischargeReasons =
    type === "applicant"
      ? dischargeReasonsApplicants
      : dischargeReasonsStudents;

  return (
    <React.Fragment>
      <FormControl className={classes.dischargeSelect}>
        <InputLabel htmlFor="class">Grund for udmeldelse</InputLabel>
        <Field
          disabled={disabled}
          name="dischargeReasons"
          component={Select}
          multiple={true}
          style={{maxWidth: 350}}
          onChange={(event) =>
            { 
              console.log(event.target);
              setFieldValue(
              "dischargeReasons",
              [].slice
                .call(event.target.value)
                .map((option) => option)
            )}
          }
        >
          {dischargeReasons.map((reason) => {
            return (
              <MenuItem key={reason} value={reason}>
                {reason}
              </MenuItem>
            );
          })}
        </Field>
      </FormControl>
    </React.Fragment>
  );
};

DischargedSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default DischargedSelect;
