import React, { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";

type Hook = () => Semester[];

const useSemesters: Hook = () => {
  const [semesters, setSemesters] = useState<Semester[]>([]);
  const firestore = useFirestore();
  useEffect(() => {
    async function loadSemesters() {
      const semesters = await firestore.collection("semesters").get();
      setSemesters(
        semesters.docs
          .filter((doc) => doc.exists)
          .map((doc) => doc.data() as Semester)
      );
    }
    loadSemesters();
  }, []);

  return semesters;
};

export default useSemesters;
