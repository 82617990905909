import React, { Component } from "react";
import { SettingsComponent } from "../../components";
import { connect } from "react-redux";
import { verifyEmail } from "../../actions/auth";
import { fetchSettings, updateSetting } from "../../actions/settings";

class SettingsContainer extends Component {
  state = {
    applicantsOverdue: false,
    newApplicant: false,
    email: ""
  };

  componentDidMount() {
    this.props.fetchSettings();

    if (this.props.user.providerData) {
      this.setState({ email: this.props.user.providerData[0].email });
    }

    if (this.props.settings) {
      this.setState({ ...this.props.settings });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.user.providerData !== nextProps.user.providerData) {
      this.setState({ email: nextProps.user.providerData[0].email });
    }
    if (this.props.settings !== nextProps.settings) {
      this.setState({ ...nextProps.settings });
    }
  }

  handleNotificationChange = name => (event, checked) => {
    this.setState({ [name]: checked }, () => {
      this.props.updateSetting("notifications", { [name]: checked });
    });
  };

  handleVerifyEmail = () => {
    this.props.verifyEmail(this.state.email);
  };

  render() {
    const {
      user,
      isVerifying,
      verifySuccess,
      isUpdating,
      isFetching
    } = this.props;
    const { email, applicantsOverdue, newApplicant } = this.state;

    return (
      <SettingsComponent
        email={email}
        isUpdating={isUpdating}
        isFetching={isFetching}
        emailVerified={user.emailVerified}
        isVerifying={isVerifying}
        verifySuccess={verifySuccess}
        handleNotificationChange={this.handleNotificationChange}
        applicantsOverdue={applicantsOverdue}
        newApplicant={newApplicant}
        handleVerifyEmail={this.handleVerifyEmail}
      />
    );
  }
}

const mapStateToProps = ({ auth, settings }) => {
  const { user, isVerifying, verifySuccess } = auth;
  const { isUpdating, isFetching } = settings;
  return {
    user,
    isVerifying,
    verifySuccess,
    settings: settings.settings,
    isUpdating,
    isFetching
  };
};

const mapDispatchToProps = dispatch => {
  return {
    verifyEmail: email => dispatch(verifyEmail(email)),
    fetchSettings: () => dispatch(fetchSettings()),
    updateSetting: (type, value) => dispatch(updateSetting(type, value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsContainer);
