export const referrers = [
  "Facebook",
  "Google",
  "Instagram",
  "Infoaften København",
  "Infoaften Århus",
  "En ven",
  "Avis annonce",
  "Flyers/plakater",
  "Anden",
];
