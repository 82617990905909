import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import CloudDownload from "@material-ui/icons/CloudDownload";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListSubheader from "@material-ui/core/ListSubheader";

const listHeight = 350;
const styles = theme => ({
  list: {
    overflow: "auto",
    height: listHeight
  }
});

const FilesComponent = ({
  classes,
  isFetching,
  isUploading,
  fetchStatus,
  files,
  handleDeleteFile,
  height = listHeight,
  heading,
  type
}) => {
  return (
    <List
      className={classes.list}
      style={{ height: height }}
      subheader={<ListSubheader component="div">{heading}</ListSubheader>}
    >
      {(isFetching || isUploading) && (
        <ListItem>
          <CircularProgress size={24} />
          <ListItemText
            primary={isFetching || isUploading ? fetchStatus : "Filer"}
          />
        </ListItem>
      )}
      {files[type].map(file => {
        return (
          <ListItem key={file.id} dense button className={classes.listItem}>
            <IconButton
              aria-label="Download"
              onClick={() => window.open(file.downloadURL, "_blank")}
            >
              <CloudDownload />
            </IconButton>
            <ListItemText primary={truncateFileName(file.fileName)} />
            <ListItemSecondaryAction>
              <IconButton
                aria-label="Delete"
                onClick={() => handleDeleteFile(file)}
              >
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

const truncateFileName = name => {
  //If the first part of the filename is too long, truncate it
  const maxLength = 25;
  const arr = name.split(" ");
  const first = arr[0];
  if (first) {
    if (first.length > maxLength) {
      const truncated = first.substring(0, maxLength) + "...";
      return truncated;
    } else {
      return name;
    }
  }
};

FilesComponent.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  fetchStatus: PropTypes.string,
  files: PropTypes.object.isRequired,
  handleDeleteFile: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default withStyles(styles)(FilesComponent);
