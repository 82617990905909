import React from "react";
import { Field, Formik } from "formik";
import { convertToPlainDate } from "../../helpers/parser";
import MomentUtils from "@date-io/moment";
import { useFirestore } from "react-redux-firebase";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import { DateTimeField, CheckboxField } from "../../components";

import CompleteEventDialog from "./CompleteEventDialog";

const EVENTS_COLLECTION = "info_events";

function parseDateString(value: any, originalValue: any) {
  const parsedDate = convertToPlainDate(originalValue)
    ? convertToPlainDate(originalValue)
    : convertToPlainDate(value);

  return parsedDate;
}

const validationSchema = Yup.object().shape({
  date: Yup.date().transform(parseDateString).label("Dato").required(),
  location: Yup.string().min(3).label("Sted").required(),
  isPublic: Yup.boolean().label("Offentliggjort").required(),
  attendees: Yup.array().of(Yup.string()),
  completed: Yup.boolean().label("Afviklet").required(),
});

type FormProps = {
  event: InfoEvent;
};
const EventForm: React.FC<FormProps> = ({ event }) => {
  const setCompleted = (
    props: EventCompletedProps,
    setFieldValue: (param: string, value: any) => void,
    handleSubmit: () => void
  ) => {
    const {
      attendance,
      interviews,
      interviewsSport,
      interviewsOutdoor,
      interviewsXOutdoor,
      facebookInterested,
      facebookParticipants,
    } = props;
    setFieldValue("completed", true);
    setFieldValue("attendance", attendance);
    setFieldValue("interviews", interviews);
    setFieldValue("interviews_sport", interviewsSport);
    setFieldValue("interviews_outdoor", interviewsOutdoor);
    setFieldValue("interviews_xoutdoor", interviewsXOutdoor);
    setFieldValue("facebook_interested", facebookInterested);
    setFieldValue("facebook_participants", facebookParticipants);

    handleSubmit();
    setDialogOpen(true);
  };

  const [firebaseError, setFirebaseError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

  const firestore = useFirestore();

  return (
    <Formik
      initialValues={event}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        try {
          actions.setSubmitting(true);

          const eventRef = firestore
            .collection(EVENTS_COLLECTION)
            .doc(event.id);
          await eventRef.update(values);
          setSuccess(true);
          setFirebaseError(null);
          actions.resetForm({ values });
        } catch (error: any) {
          console.error(error);
          setSuccess(false);
          setFirebaseError(error.message);
        } finally {
          setDialogOpen(false);
          actions.setSubmitting(false);
        }
      }}
    >
      {({
        handleSubmit,
        dirty,
        setFieldValue,
        resetForm,
        isSubmitting,
        errors,
        values: { completed },
      }) => (
        <form onSubmit={handleSubmit}>
          <CompleteEventDialog
            open={dialogOpen}
            handleClose={() => setDialogOpen(false)}
            handleConfirm={(attendance) =>
              setCompleted(attendance, setFieldValue, handleSubmit)
            }
          />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container>
              <Grid item xs={12} style={{ padding: 8 }}>
                {Object.keys(errors).length > 0 && (
                  <Alert severity="error">
                    {Object.values(errors).map((error) => {
                      return error;
                    })}
                  </Alert>
                )}
                {firebaseError && !completed && (
                  <Alert severity="error">
                    Kunne ikke opdatere infoaften: {firebaseError}
                  </Alert>
                )}
                {success && !completed && (
                  <Alert onClose={() => setSuccess(false)} severity="success">
                    Infoaften opdateret 🎉
                  </Alert>
                )}
                {completed && (
                  <Alert severity="warning">
                    Denne infoaften er afviklet. Du kan ikke foretage ændringer.
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <DateTimeField
                  disabled={completed}
                  name="date"
                  label={"Dato for infoaften"}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  disabled={completed}
                  name="location"
                  label="Sted"
                  component={TextField}
                  placeholder={"eks. DGI Byen"}
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CheckboxField
                  disabled={completed}
                  name="isPublic"
                  label="Offentliggjort"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  size="small"
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 16 }}
                  disabled={!dirty || isSubmitting}
                >
                  Opdater
                </Button>
                <Button
                  disabled={!dirty || isSubmitting}
                  size="small"
                  variant="contained"
                  color="default"
                  onClick={() => resetForm()}
                  style={{ marginRight: 16 }}
                >
                  Fortryd
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  disabled={isSubmitting || completed}
                  onClick={() => setDialogOpen(true)}
                >
                  Afviklet
                </Button>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </form>
      )}
    </Formik>
  );
};

export default EventForm;
