import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import { connect } from "react-redux";
import { compose } from "recompose";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SchoolIcon from "@material-ui/icons/School";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import PeopleIcon from "@material-ui/icons/People";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import Typography from "@material-ui/core/Typography";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import MessageIcon from "@material-ui/icons/Message";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import LocalLibrary from "@material-ui/icons/LocalLibrary";
import AccountCircle from "@material-ui/icons/AccountCircle";
import SettingsPower from "@material-ui/icons/SettingsPower";
import Archive from "@material-ui/icons/Archive";
import Drawer from "@material-ui/core/Drawer";
import Edit from "@material-ui/icons/Edit";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { drawerWidth } from "../../constants";
import * as packageInfo from "../../../package.json";

const ListItemButton = ({ history, semester }) => (
  <ListItem
    button
    onClick={() => {
      history.push(`/semesters/${semester}?tab=0`);
    }}
  >
    <ListItemText inset primary={semester} />
  </ListItem>
);

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: "relative",
    height: "100%",
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  link: {
    textDecoration: "none",
    color: "black",
  },
  toolbar: {
    ...theme.mixins.toolbar,
    paddingLeft: theme.spacing(3),
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  title: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1) / 2,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

const DrawerComponent = ({
  open,
  openStudents,
  openExams,
  handleToggleExpansion,
  semesters,
  handleToggleDrawer,
  handleToggleDialog,
  handleToggleArchivedSemestersDialog,
  history,
  auth,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Drawer
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        open={open}
      >
        <div className={classes.drawerInner}>
          <div className={classes.drawerHeader}>
            <IconButton
              onClick={() => {
                handleToggleDrawer();
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <div className={classes.toolbar}>
              <Link className={classes.title} to="/">
                <Typography variant="h6" color="inherit">
                  PP KBH Intra
                </Typography>
              </Link>
              <Typography variant="caption">
                <Link to="/changes" style={{ color: "inherit" }}>
                  {packageInfo.version}
                </Link>
              </Typography>
            </div>
          </div>
          <List>
            <Link to="/" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
            </Link>
            <Link to="/applicants" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <PersonAddIcon />
                </ListItemIcon>
                <ListItemText primary="Ansøgere" />
              </ListItem>
            </Link>
            <ListItem
              button
              onClick={() => handleToggleExpansion("openStudents")}
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Studerende" />{" "}
              {openStudents ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openStudents} transitionduration="auto" unmountOnExit>
              {semesters
                .sort()
                .reverse()
                .slice(0, 5)
                .map((semester) => {
                  return (
                    <ListItemButton
                      history={history}
                      key={semester}
                      classes={classes}
                      semester={semester}
                    />
                  );
                })}
              <ListItem
                button
                className={classes.nested}
                onClick={() => handleToggleDialog()}
              >
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary={"Tilføj semester"} />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                onClick={() => handleToggleArchivedSemestersDialog()}
              >
                <ListItemIcon>
                  <Archive />
                </ListItemIcon>
                <ListItemText primary={"Arkiverede"} />
              </ListItem>
            </Collapse>
            <Link to="/examiners" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <LocalLibrary />
                </ListItemIcon>
                <ListItemText primary="Censorer" />
              </ListItem>
            </Link>
            <Link to="/contacts" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <PermContactCalendar />
                </ListItemIcon>
                <ListItemText primary="Kontakter" />
              </ListItem>
            </Link>

            {/* <ListItem
              dense
              button
              onClick={() => handleToggleExpansion("openExams")}
            >
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText primary="Eksamensresultater" />
              {openExams ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openExams} transitionduration="auto" unmountOnExit>
              <Link to="/exams" className={classes.link}>
                <ListItem dense button className={classes.nested}>
                  <ListItemIcon>
                    <AssignmentInd />
                  </ListItemIcon>
                  <ListItemText primary={"Tildel resultater"} />
                </ListItem>
              </Link>
              <Link to="/exams/add" className={classes.link}>
                <ListItem dense button className={classes.nested}>
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Tilføj eksamensfag"} />
                </ListItem>
              </Link>
              <Link to="/exams/edit" className={classes.link}>
                <ListItem dense button className={classes.nested}>
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  <ListItemText primary={"Rediger eksamensfag"} />
                </ListItem>
              </Link>
            </Collapse> */}
            <Link to="/messages" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <MessageIcon />
                </ListItemIcon>
                <ListItemText primary="Beskeder" />
              </ListItem>
            </Link>
            <Link to="/uploads" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <CloudUploadIcon />
                </ListItemIcon>
                <ListItemText primary="Uploads" />
              </ListItem>
            </Link>
          </List>
          <Divider />
          <List>
            <Link to="/settings" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  {!auth.emailVerified ? (
                    <Badge badgeContent={1} color="secondary">
                      <SettingsPower />
                    </Badge>
                  ) : (
                    <SettingsPower />
                  )}
                </ListItemIcon>
                <ListItemText primary="Indstillinger" />
              </ListItem>
            </Link>
            <Link to="/users" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText primary="Brugere" />
              </ListItem>
            </Link>
          </List>
        </div>
      </Drawer>
    </div>
  );
};

DrawerComponent.propTypes = {
  semesters: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  openStudents: PropTypes.bool.isRequired,
  handleToggleExpansion: PropTypes.func.isRequired,
  handleToggleDrawer: PropTypes.func.isRequired,
  handleToggleDialog: PropTypes.func.isRequired,
};

const mapStateToProps = ({ firebase }) => {
  return {
    auth: firebase.auth,
  };
};

export const TestableDrawerComponent = DrawerComponent;
export default withRouter(
  compose(connect(mapStateToProps, null))(DrawerComponent)
);
