import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import {
  Index,
  Hits,
  InstantSearch,
  Highlight,
  connectSearchBox,
} from "react-instantsearch-dom";
import algoliasearch from "algoliasearch";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "../../BaseComponents/Box";
import { AppSearch } from "../../components";
import { Link } from "react-router-dom";
import "./search.css";

const useStyles = makeStyles({
  root: {
    marginTop: 24,
    minHeight: 600,
  },
  paper: {
    padding: 16,
    minHeight: 400,
  },
  result: {
    marginTop: 8,
  },
  a: {
    textDecoration: "none",
    color: "inherit",
  },
  headline: {
    color: "#fc4f4f",
  },
  column: {
    display:'flex',
    flexDirection:"column"
  }
});

// Algolia search
const searchClient = algoliasearch(
  "9QYZAZ7D0L",
  "a4e74a7a956dba71873600a68a6cd0a6"
);

const CustomSearchBox = connectSearchBox(AppSearch);

const StudentHit = ({ hit, setOpen }) => {
  const classes = useStyles();

  return (
    <Link
      to={`/semesters/${hit.semester}/student/${hit.id}`}
      onClick={() => setOpen(false)}
      className={classes.a}
    >
      <div className={classes.column}>
      <Typography variant="overline">
        <Highlight hit={hit} attribute="firstName" className="hit-name" />{" "}
        <Highlight hit={hit} attribute="lastName" className="hit-name" />{" "}
      </Typography>
      <Typography variant="caption" className="hit-email">
        <Highlight hit={hit} attribute="email" />{" "}
        <Highlight hit={hit} attribute="phone" />
      </Typography>
      </div>
    </Link>
  );
};

const ApplicantHit = ({ hit, setOpen }) => {
  const classes = useStyles();
  return (
    <Link
      to={`/applicants/${hit.id}`}
      onClick={() => setOpen(false)}
      className={classes.a}
    >
      <div className={classes.column}>
      <Typography variant="overline">
        <Highlight hit={hit} attribute="name" className="hit-name" />{" "}
      </Typography>
      <Typography variant="caption" className="hit-email">
        <Highlight hit={hit} attribute="email" />{" "}
        <Highlight hit={hit} attribute="phone" />
      </Typography>
      </div>
    </Link>
  );
};

const ContactsHit = ({ hit, setOpen }) => {
  const classes = useStyles();
  return (
    <Link
      to={`/contacts/${hit.id}`}
      onClick={() => setOpen(false)}
      className={classes.a}
    >
      <div className={classes.column}>
      <Typography variant="overline">
        <Highlight hit={hit} attribute="name" className="hit-name" />{" "}
      </Typography>
      <Typography variant="caption" className="hit-email">
        <Highlight hit={hit} attribute="email" />{" "}
        <Highlight hit={hit} attribute="phone" />
      </Typography>
      </div>
    </Link>
  );
};


const ExaminersHit = ({ hit, setOpen }) => {
  const classes = useStyles();
  return (
    <Link
      to={`/examiners/${hit.id}`}
      onClick={() => setOpen(false)}
      className={classes.a}
    >
      <div className={classes.column}>
      <Typography variant="overline">
        <Highlight hit={hit} attribute="firstName" className="hit-name" />{" "}
        <Highlight hit={hit} attribute="firstName" className="hit-name" />{" "}
      </Typography>
      <Typography variant="caption" className="hit-email">
        <Highlight hit={hit} attribute="email" />{" "}
        <Highlight hit={hit} attribute="phone" />
      </Typography>
      </div>
    </Link>
  );
};

const SearchContainer = ({ open, setOpen }) => {
  return (
    <Dialog fullScreen={true} open={open}>
      <DialogContent>
        <InstantSearch indexName="applicants" searchClient={searchClient}>
          <CustomSearchBox setOpen={setOpen} />
          <Box display="flex" flexDirection="row" flexWrap='wrap'>
            <Box p={1} width={1 / 4}>
              <Index indexName="applicants">
                <Typography variant="h5">Ansøgere</Typography>
                <Hits
                  hitComponent={({ hit }) => (
                    <ApplicantHit hit={hit} setOpen={setOpen} />
                  )}
                />
              </Index>
            </Box>
            <Box p={1} width={1 / 4}>
              <Index indexName="students">
                <Typography variant="h5">Studerende</Typography>
                <Hits
                  hitComponent={({ hit }) => (
                    <StudentHit hit={hit} setOpen={setOpen} />
                  )}
                />
              </Index>
            </Box>
            <Box p={1} width={1 / 4}>
              <Index indexName="contacts">
                <Typography variant="h5">Kontakter</Typography>
                <Hits
                  hitComponent={({ hit }) => (
                    <ContactsHit hit={hit} setOpen={setOpen} />
                  )}
                />
              </Index>
            </Box>
            <Box p={1} width={1 / 4}>
              <Index indexName="examiners">
                <Typography variant="h5">Censorer</Typography>
                <Hits
                  hitComponent={({ hit }) => (
                    <ExaminersHit hit={hit} setOpen={setOpen} />
                  )}
                />
              </Index>
            </Box>
          </Box>
        </InstantSearch>
      </DialogContent>
    </Dialog>
  );
};

export default SearchContainer;
