import { firebase } from "../helpers/firebaseHelper";

export interface IEmployee {
  [key: string]: any;
  id: string;
  keys: Record<string, unknown>;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  postcode: string;
  email: string;
  phone: string;
  createdAt: firebase.firestore.FieldValue;
  note: string | null;
  title: string;
  isArchived: boolean;
}

export const spreadsheetFields: { [key: string]: boolean } = {
  firstName: true,
  lastName: true,
  address: true,
  city: true,
  postcode: true,
  email: true,
  phone: true,
  note: true,
  title: true,
};

export const translatedFields: { [key: string]: string } = {
  firstName: "Fornavn",
  lastName: "Efternavn",
  address: "Adresse",
  city: "By",
  postcode: "Postnummer",
  email: "Email",
  phone: "Telefon",
  note: "Note",
  title: "Titel",
};
