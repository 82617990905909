import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { toggleGradingDialog } from "../../../actions/students";
import LoadableButton from "../../Buttons/LoadableButton";

class SetGraduatedDialog extends React.Component {
  state = {
    date: ""
  };
  render() {
    const {
      open,
      error,
      isSetting,
      toggleGradingDialog,
      handleSetGraduated
    } = this.props;

    const { date } = this.state;
    return (
      <div>
        <Dialog
          open={open}
          onClose={() => toggleGradingDialog()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Sæt dimmiteret dato</DialogTitle>
          <DialogContent>
            <DialogContentText>Vælg dato for dimmision</DialogContentText>
            {error && error !== "" && (
              <Typography variant="caption">{error.message}</Typography>
            )}
            <TextField
              autoFocus
              margin="dense"
              id="date"
              label="Dato"
              type="date"
              value={date}
              onChange={event => this.setState({ date: event.target.value })}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => toggleGradingDialog()} color="primary">
              Annuller
            </Button>
            <LoadableButton
              disabled={date === ""}
              loading={isSetting}
              onClick={() => handleSetGraduated(date)}
              color="primary"
            >
              Sæt
            </LoadableButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = ({ students }) => {
  return {
    open: students.gradingDialogOpen,
    error: students.setPropertyError,
    isSetting: students.isSettingProperty
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleGradingDialog: () => dispatch(toggleGradingDialog())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetGraduatedDialog);
