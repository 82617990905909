import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Delete from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    width: '100%'
  },
  tileRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    width: '100%',
    height: '100%'
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)'
  },
  flex: {
    flex: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  button: {
    margin: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  }
});

const UserUploadsComponent = ({
  classes,
  userUploads,
  downloadAll,
  deleteAll,
  progress
}) => {
  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Toolbar>
          {progress.mediaLink ? (
            <Typography
              variant="h6"
              color="inherit"
              className={classes.flex}>
              <a href={progress.mediaLink}>Hent</a>
            </Typography>
          ) : (
            <Typography
              variant="h6"
              color="inherit"
              className={classes.flex}>
              {progress.message}
            </Typography>
          )}
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={downloadAll}>
            Download alle
            <CloudDownload className={classes.rightIcon} />
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={deleteAll}>
            Slet alle
            <Delete className={classes.rightIcon} />
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.tileRoot}>
        <GridList cellHeight={300} className={classes.gridList}>
          <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }} />
          {Object.keys(userUploads).map(key => {
            const upload = userUploads[key];
            return (
              <GridListTile key={key}>
                <img
                  src={upload.downloadURL}
                  download={upload.name}
                  alt={upload.userName}
                />
                <GridListTileBar
                  title={upload.name}
                  actionIcon={
                    <IconButton
                      className={classes.icon}
                      onClick={() => window.open(upload.downloadURL, '_blank')}>
                      <CloudDownload />
                    </IconButton>
                  }
                />
              </GridListTile>
            );
          })}
        </GridList>
      </div>
    </div>
  );
};

UserUploadsComponent.propTypes = {
  userUploads: PropTypes.object.isRequired
};
export default withStyles(styles)(UserUploadsComponent);
