import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import {
  fetchStudents,
  deleteStudents,
  fetchClasses,
  setQueryForStudents,
  getClassImages,
  setProperty,
} from "../../actions/students";
import { setRecipients } from "../../actions/messages";
import { submitSheet, createTravelInfoSheet } from "../../actions/sheets";
import { setModalOpen } from "../../actions/certificates";
import {
  StudentsComponent,
  ConfirmDeleteDialog,
  CreateSpreadsheetDialog,
} from "../../components";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3),
  }),
});

class StudentsContainer extends Component {
  state = {
    deleteDialogOpen: false,
    spreadsheetDialogOpen: false,
    items: [],
  };
  componentDidMount() {
    const { semester } = this.props.match.params;
    this.fetchStudents(semester);
  }

  fetchStudents = (semester) => {
    const query = {
      semester: semester,
    };

    this.props.fetchClasses(semester);
    this.props.setQueryForStudents(query);
  };

  handleFetchStudents = (withQueries) => {
    const { semester } = this.props.match.params;
    const query = {
      ...withQueries,
      semester,
    };
    this.props.setQueryForStudents(query);
  };

  itemsShouldDelete = () => {
    this.props.deleteStudents(this.state.items);
    this.setState({ deleteDialogOpen: false });
  };

  handleDeleteItems = (items) => {
    this.setState({ items, deleteDialogOpen: true });
  };

  handleSendMessages = (studentsIdArr) => {
    const { students } = this.props;

    const studentsArr = students.filter((s) => {
      return studentsIdArr.includes(s.id);
    });
    const recipients = studentsArr.map((s) => {
      return {
        name: `${s.firstName} ${s.lastName}`,
        email: s.email,
        phone: s.phone,
        collection: "students",
        docId: s.studentId,
        status: "not_submitted",
      };
    });
    this.props.setRecipients(recipients);
  };

  handleCreateSpreadsheet = (items) => {
    this.setState({
      spreadsheetDialogOpen: !this.state.spreadsheetDialogOpen,
      items,
    });
  };

  handleCreateTravelInfo = (items) => {
    this.props.createTravelInfoSheet(items);
  };

  closeSpreadsheetDialog = () =>
    this.setState({ spreadsheetDialogOpen: false });

  handleCreate = (values, translations) => {
    // Items is set in 'handleCreateSpreadsheet' when the modal is openend
    const { items } = this.state;
    this.props.submitSheet(values, items, translations);
  };

  handleGetClassImages = () => {
    this.props.getClassImages();
  };

  handleCreateCertificateRequest = (ids) => {
    this.props.setModalOpen(true, ids);
  };

  handleSetGraduated = (date, ids) => {
    this.props.setProperty({ graduated: true, graduatedDate: date }, ids);
  };

  handleSetProp = (prop, ids) => this.props.setProperty(prop, ids);

  render() {
    const {
      students,
      isFetching,
      error,
      classNames,
      classes,
      isFetchingClasses,
      isDeleting,
      didDeleteItems,
      isCreatingTravelInfo,
    } = this.props;
    return (
      <div>
        <div className={classes.root}>
          <CreateSpreadsheetDialog
            open={this.state.spreadsheetDialogOpen}
            handleClose={this.closeSpreadsheetDialog}
            handleCreate={this.handleCreate}
          />
          <ConfirmDeleteDialog
            open={this.state.deleteDialogOpen}
            itemsShouldDelete={() => this.itemsShouldDelete()}
            handleRequestClose={() =>
              this.setState({ deleteDialogOpen: false })
            }
            itemType={"studerende"}
            itemCount={this.state.items.length}
            isDeleting={isDeleting}
            didDeleteItems={didDeleteItems}
          />
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <StudentsComponent
                students={students}
                isFetching={isFetching || isCreatingTravelInfo}
                error={error}
                classNames={classNames}
                isFetchingClasses={isFetchingClasses}
                fetchStudents={(withQueries) => {
                  this.handleFetchStudents(withQueries);
                }}
                handleDeleteItems={(items) => this.handleDeleteItems(items)}
                handleSendMessages={this.handleSendMessages}
                handleCreateSpreadsheet={this.handleCreateSpreadsheet}
                handleCreateTravelInfo={this.handleCreateTravelInfo}
                handleCreateCertificateRequest={
                  this.handleCreateCertificateRequest
                }
                handleGetClassImages={this.handleGetClassImages}
                handleSetGraduated={this.handleSetGraduated}
                handleSetProp={this.handleSetProp}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ students, sheets }) => {
  const {
    isFetching,
    error,
    isDeleting,
    didDeleteItems,
    classNames,
    isFetchingClasses,
  } = students;
  const { isCreatingTravelInfo, travelInfoError } = sheets;
  return {
    students: students.students,
    isFetching,
    error,
    isDeleting,
    didDeleteItems,
    isFetchingClasses,
    classNames,
    isCreatingTravelInfo,
    travelInfoError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStudents: (query) => dispatch(fetchStudents(query)),
    fetchClasses: (semester) => dispatch(fetchClasses(semester)),
    setQueryForStudents: (q) => dispatch(setQueryForStudents(q)),
    deleteStudents: (ids) => dispatch(deleteStudents(ids)),
    setRecipients: (recipients) => dispatch(setRecipients(recipients)),
    submitSheet: (values, items, translations) =>
      dispatch(submitSheet(values, items, translations)),
    createTravelInfoSheet: (items) => dispatch(createTravelInfoSheet(items)),
    getClassImages: () => dispatch(getClassImages()),
    setModalOpen: (open, ids) => dispatch(setModalOpen(open, ids)),
    setProperty: (prop, ids) => dispatch(setProperty(prop, ids)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(StudentsContainer);
