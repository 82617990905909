import {
  SENDING_MESSAGES,
  SEND_MESSAGES_SUCCESS,
  SEND_MESSAGES_ERROR,
  RECIPIENTS_DID_SET,
  FETCHING_MESSAGES,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_ERROR
} from "../constants";

export const initialState = {
  isSending: false,
  messagesSendSuccess: false,
  error: {},
  recipients: [],
  messages: [],
  isFetching: false,
  fetchError: {},
  multiple: false
};

const messages = (state = initialState, action) => {
  switch (action.type) {
    case SENDING_MESSAGES:
      return { ...state, isSending: true };
    case SEND_MESSAGES_SUCCESS:
      return { ...state, isSending: false, messagesSendSuccess: true };
    case SEND_MESSAGES_ERROR:
      return {
        ...state,
        isSending: false,
        messagesSendSuccess: false,
        error: action.error
      };
    case RECIPIENTS_DID_SET:
      return {
        ...state,
        recipients: action.recipients,
        multiple: action.multiple
      };
    case FETCHING_MESSAGES:
      return { ...state, isFetching: true };
    case FETCH_MESSAGES_SUCCESS:
      return { ...state, isFetching: false, messages: action.messages };
    case FETCH_MESSAGES_ERROR:
      return { ...state, isFetching: false, fetchError: action.error };
    default:
      return state;
  }
};

export default messages;
