import React, { useEffect } from "react";
import styled from "styled-components";
import {
  useTable,
  useSortBy,
  Column,
  CellProps,
  HeaderProps,
  usePagination,
  useRowSelect,
  Row as TableRow,
} from "react-table";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  Button,
  Select,
  TextField,
  MenuItem,
  Typography,
  Checkbox,
} from "@material-ui/core";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import NavigateNext from "@material-ui/icons/NavigateNext";
import SkipPrevious from "@material-ui/icons/SkipPrevious";
import SkipNext from "@material-ui/icons/SkipNext";
import { AppState } from "../../reducers";
import { parseDate } from "../../helpers/parser";

type StyleProps = { tableColor: string };

const EVENTS_COLLECTION = "info_events";

const Styles = styled.div`
  table {
    border-spacing: 0;
    width: 100%;
    background-color: ${(props: StyleProps) => props.tableColor};
    box-shadow: rgba(0, 0, 0, 0.05) 1px 1px 10px 10px;
    border-radius: 10px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;

      :last-child {
        border-right: 0;
      }
    }
    tbody {
      text-align: center;
    }
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify: center;
  align-items: center;
`;

const Row = styled.tr`
  border-bottom: solid black 0.5px;
  background-color: ${(props) => props.color};
  height: 60px;
`;

type LinkProps = { linkcolor: string };
const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props: LinkProps) => props.linkcolor};
`;

type NameLinkableCellProps = { value: string; row: TableRow<Data> };

const NameLinkableCell: React.FC<NameLinkableCellProps> = ({
  value: initialValue,
  row: {
    original: { id },
  },
}) => {
  const theme = useTheme();
  return (
    <Typography variant="body1">
      <StyledLink linkcolor={theme.palette.primary.main} to={`/events/${id}`}>
        {_.startCase(initialValue)}
      </StyledLink>
    </Typography>
  );
};

type DateCellProps = { value: string; row: TableRow<Data> };
const DateCell = ({
  row: {
    original: { date, id },
  },
}: DateCellProps) => {
  const theme = useTheme();
  return (
    <Typography variant="body1">
      <StyledLink linkcolor={theme.palette.primary.main} to={`/events/${id}`}>
        {parseDate(date, "LLL")}
      </StyledLink>
    </Typography>
  );
};

type Data = InfoEvent;

type Props = {
  columns: Column<Data>[];
  data: Data[];
  setSelection: (selection: Selection) => void;
};

const Table = ({ columns, data, setSelection }: Props) => {
  // Use the state and functions returned from useTable to build your UI
  const theme = useTheme();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    selectedFlatRows,
    page,
    // Pagination
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 20, pageIndex: 0 },
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    setSelection(selectedFlatRows.map((d: TableRow<Data>) => d.original));
  }, [selectedFlatRows, setSelection]);
  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Row
                {...row.getRowProps()}
                color={
                  i % 2 === 0
                    ? theme.palette.background.paper
                    : theme.palette.background.default
                }
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </Row>
            );
          })}
        </tbody>
      </table>
      <PaginationContainer className="pagination">
        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <SkipPrevious />
        </Button>
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          <NavigateBefore />
        </Button>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          <NavigateNext />
        </Button>
        <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          <SkipNext />
        </Button>
        Side{" "}
        <strong>
          {pageIndex + 1} af {pageOptions.length}
        </strong>{" "}
        <span style={{ marginRight: 10 }}> | Gå til side: </span>{" "}
        <TextField
          type="number"
          defaultValue={pageIndex + 1}
          style={{ marginRight: 16, width: "100px" }}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
        />
        <Select
          value={pageSize}
          style={{ marginLeft: 16 }}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[20, 30, 40, 50].map((pageSize) => (
            <MenuItem key={pageSize} value={pageSize}>
              Vis {pageSize}
            </MenuItem>
          ))}
        </Select>
      </PaginationContainer>
    </>
  );
};

type Selection = InfoEvent[];
type TableProps = {
  setSelection: (selection: Selection) => void;
  semester: string;
};
const EventsTable: React.FC<TableProps> = React.memo(
  ({ setSelection, semester }) => {
    const theme = useTheme();
    const columns: Column<InfoEvent>[] = React.useMemo(
      () => [
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<Data>) => (
            <div>
              <Checkbox
                color="primary"
                indeterminate
                {...getToggleAllRowsSelectedProps()}
              />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }: CellProps<Data>) => (
            <div>
              <Checkbox color="primary" {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        {
          Header: "Dato",
          accessor: "date",
          Cell: DateCell,
          sortType: (rowA: TableRow<Data>, rowB: TableRow<Data>) => {
            const dateA = rowA.values.date;
            const dateB = rowB.values.date;
            if (dateA < dateB) {
              return 1;
            } else if (dateA > dateB) {
              return -1;
            }
            return 0;
          },
        },
        {
          Header: "Sted",
          accessor: "location",
          Cell: NameLinkableCell,
        },
        {
          Header: "Offentliggjort",
          accessor: "isPublic",
          Cell: ({ row }: CellProps<Data>) => (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            <p>{row.original.isPublic ? "Ja" : "Nej"}</p>
          ),
        },
        {
          Header: "Afviklet",
          accessor: "completed",
          Cell: ({ row }: CellProps<Data>) => (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            <p>{row.original.completed ? "Ja" : "Nej"}</p>
          ),
        },
      ],
      []
    );

    useFirestoreConnect([
      {
        collection: EVENTS_COLLECTION,
        orderBy: ["date", "asc"],
        startAt: new Date(`${semester}-09-01`),
        endAt: new Date(`${parseInt(semester) + 1}-09-01`),
      },
    ]);

    const events = useSelector(
      ({ firestore: { ordered } }: AppState) => ordered[EVENTS_COLLECTION] || []
    );

    return (
      <Styles tableColor={theme.palette.background.default}>
        <Table
          columns={columns}
          data={_.sortBy(events, ["date"])}
          setSelection={(selections) => setSelection(selections)}
        />
      </Styles>
    );
  }
);

export default EventsTable;
