import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  StudentForm,
  SafetyInsuranceForm,
  StudentTabBar,
  StudentGradeTable,
} from "../../components";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import StudentActionsMenu from "./StudentActionsMenu";
import { withFirebase } from "react-redux-firebase";
const TabContainer = (props) => {
  return <div style={{ padding: 8 * 3 }}>{props.children}</div>;
};

class StudentComponent extends Component {
  state = {
    value: 0,
    anchorEl: null,
    menuOpen: false,
    selections: [],
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const value = parseInt(query.get("tab"), 10);
    this.setState({ value: value || 0 });
  }

  handleChange = (_, value) => {
    this.props.history.push({ search: `?tab=${value}` });
    this.setState({ value });
  };
  handleOpenMenu = (event) => {
    this.setState({ menuOpen: true, anchorEl: event.currentTarget });
  };
  handleCloseMenu = (event) => {
    this.setState({ menuOpen: false });
  };

  gradeDidSelect = (gradeId) => {
    this.props.gradeDidSelect(gradeId);
  };

  handleProfileImageFile = async (file) => {
    const storageRef = this.props.firebase.storage().ref();

    const { student } = this.props;
    if (!student.id) {
      return;
    }

    if (!file.type.startsWith("image/")) {
      alert("Vælg venligst et billede");
      return;
    }
    const ext = file.name.split(".").pop();
    const fileName = `profile.${ext}`;
    const path = `students/${student.id}/${fileName}`;
    const fileRef = storageRef.child(path);
    try {
      await fileRef.put(file);
    } catch (error) {
      console.error(error);
    }

    // this.props.handleFileChange(path, file);
  };

  render() {
    const {
      saveStudent,
      saveSafetyInfo,
      progress,
      isUploading,
      isFetching,
      isSaving,
      student,
      safetyInformation,
      semesters,
      classNames,
      creatingAccount,
      // createAccountSuccess,
      // createAccountError,
      handleCreateAccount,
      pagination,
      isFetchingPagination,
      removeSemester,
      handleSendMessage,
      handleViewMessages,
      location: { search },
    } = this.props;

    const { value } = this.state;
    return (
      <>
        <Grid container justify="center" spacing={8}>
          <Grid item xs={12} lg={8}>
            <Paper elevation={4}>
              <StudentTabBar
                handleChange={this.handleChange}
                isFetching={isFetching}
                value={value}
              />
              {(isSaving || isFetching) && <LinearProgress />}
              {isUploading && (
                <LinearProgress mode="determinate" value={progress} />
              )}

              {value === 0 && (
                <TabContainer>
                  {student && !isFetching && (
                    <StudentForm
                      saveStudent={(s) => saveStudent(s)}
                      removeSemester={removeSemester}
                      semesters={semesters}
                      classNames={classNames}
                      isSaving={isSaving}
                      initialValues={student}
                      actionsMenu={
                        <StudentActionsMenu
                          handleProfileImageFile={this.handleProfileImageFile}
                          handleSendMessage={handleSendMessage}
                          handleViewMessages={handleViewMessages}
                          handleCreateAccount={handleCreateAccount}
                          creatingAccount={creatingAccount}
                        />
                      }
                    />
                  )}
                </TabContainer>
              )}
              {value === 1 && (
                <TabContainer>
                  <SafetyInsuranceForm
                    saveSafetyInfo={(si) => saveSafetyInfo(si)}
                    safetyInformation={safetyInformation}
                  />
                </TabContainer>
              )}
              {value === 2 && (
                <TabContainer>{this.props.children}</TabContainer>
              )}
            </Paper>
            {value === 3 && (
              <TabContainer>
                <StudentGradeTable studentId={student.id} />
              </TabContainer>
            )}
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} md={8}>
            <PreviousNextButtonsWithRouter
              pagination={pagination}
              isFetching={isFetchingPagination}
              semester={student.semester}
              search={search || ""}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

const previousNextStyles = (theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
});

const PreviousNextButtons = ({
  history,
  pagination,
  search,
  semester,
  isFetching,
  classes,
}) => {
  const next = pagination["next"];
  const previous = pagination["previous"];
  let nextKey, previousKey;
  if (next) {
    nextKey = Object.keys(next)[0];
  }
  if (previous) {
    previousKey = Object.keys(previous)[0];
  }
  return (
    <Grid
      item
      xs={12}
      container
      justify="space-between"
      style={{ marginTop: 8, padding: 16 }}
    >
      <Button
        disabled={!previousKey || isFetching}
        color="primary"
        variant="contained"
        onClick={() =>
          history.push(`/semesters/${semester}/student/${previousKey}${search}`)
        }
      >
        <KeyboardArrowLeft className={classes.leftIcon} />
        {(previousKey && previous[previousKey]) || "Ej muligt"}
      </Button>
      <Button
        disabled={!nextKey || isFetching}
        color="primary"
        variant="contained"
        className={classes.buttonRight}
        onClick={() =>
          history.push(`/semesters/${semester}/student/${nextKey}${search}`)
        }
      >
        {(nextKey && next[nextKey]) || "Ej muligt"}
        <KeyboardArrowRight className={classes.rightIcon} />
      </Button>
    </Grid>
  );
};

const PreviousNextButtonsWithRouter = withRouter(
  withStyles(previousNextStyles)(PreviousNextButtons)
);

export default withRouter(withFirebase(StudentComponent));
