import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell, Sector } from "recharts";
import {
  interviewersPieChartData,
  pieChartData,
} from "../../helpers/statisticsCalculator";
import renderActiveShape from "./renderActiveShape";
import green from "@material-ui/core/colors/green";

type ChartProps = { data: Statistic[] };
const InterviewersPieCount: React.FC<ChartProps> = ({ data }) => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  const onPieEnter = (data: any, index: number) => {
    setActiveIndex(index);
  };

  const total = interviewersPieChartData(data).reduce((acc: any, data: any) => {
    return acc + data["value"];
  }, 0);

  return (
    <ResponsiveContainer width={"100%"} height="100%">
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={(props) => renderActiveShape(props, total, green[500])}
          data={interviewersPieChartData(data)}
          dataKey="value"
          nameKey="name"
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          onMouseEnter={onPieEnter}
        >
          {interviewersPieChartData(data).map((entry: any) => (
            <Cell key={entry} fill={green[500]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default InterviewersPieCount;
